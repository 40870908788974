import React, { useEffect, useRef, useState } from "react";
import Tool from "./Tool";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  removeSelectedTemplate,
  removeSelectedTool,
  setSelectedToolId,
  setSelectedTool,
  removeSelectedTools,
} from "src/features/courseTemplate/courseTemplateSlice";
import { GetTemplate } from "./Templates/Authoring/utils/GetTemplate";
import { Mcr } from "./Templates/Preview";
import { GetPreviewTemplate } from "./Templates/Preview/utils/GetPreviewTempalte";
import { Rnd } from "react-rnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import KeyValue from "./StyleSettings/StyleComponents/KeyValue";
import SelectInput from "./StyleSettings/StyleComponents/SelectInput";
import { useLMS } from "src/context/Lms";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import { Recycle } from "lucide-react";
// @ts-ignore
import DefaultImage from "src/assets/Course/textwithimage/defaultImage.png";
import axios from "axios";

export type Tool = {
  id: number;
  type: string;
  x: number;
  y: number;
  width: string | number;
  height: string | number;
  content: string;
};

type Props = {
  topic: any;
  handleTopicChange: (topic: any) => void;
  openTemplate: any;
  tabTemplate: any;
};

const Canvas = ({ topic, handleTopicChange, openTemplate, tabTemplate }: Props) => {
  const [toolsData, setToolsData] = useState<Array<CanvasTool>>([]);
  // const [selectedToolId, setSelectedToolId] = useState<string | null>(null);

  // const selectedTool = tools.find(tool => tool.id === selectedToolId) || null;
  const [template, setTemplate] = useState<any>(null);

  const [openAuthoringModal, setOpenAuthoringModal] = useState(false);
  const [selectedTemplateState, setSelectedTemplateState] = useState<any>(null);

  const canvasRef = useRef<HTMLDivElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });



  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const canvasWidth = canvas.offsetWidth;
        const canvasHeight = canvas.offsetHeight;
        setCanvasDimensions({ width: canvasWidth, height: canvasHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(topic, openTemplate, tabTemplate);
  }, [topic, openTemplate, tabTemplate]);

  useEffect(() => {
    console.log(topic.tools);
    setToolsData(topic.tools?.data);
    if(!tabTemplate) {
      setTemplate(topic.template);
    }
    // setTemplate(topic.template);
  }, [topic]);

  // const { selectedTemplate } = useSelector((state: any) => state.courseTemplate);
  const { selectedTemplate, selectedTool, selectedTools } = useSelector(
    (state: any) => state.courseTemplate
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTemplate) {
      console.log(selectedTemplate);
      setTemplate(selectedTemplate);
      setSelectedTemplateState(selectedTemplate);
      dispatch(removeSelectedTemplate());
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTools) {
      console.log(selectedTools);
      setToolsData(selectedTools.data);
      handleTopicChange({...topic, tools: selectedTools})
      // setSelectedTemplateState(selectedTools);
      dispatch(removeSelectedTools());
    }
  }, [selectedTools])

  useEffect(() => {
    if (selectedTool) {
      const presentTool =
        topic.tools.data &&
        topic.tools.data.length > 0 &&
        topic.tools.data.find((tool: any) => tool.id === selectedTool.id);
      if (presentTool) return;
      const updatedToolsData = [...topic.tools.data, selectedTool];
      setToolsData(updatedToolsData);
      console.log(updatedToolsData);
      handleTopicChange({
        ...topic,
        tools: { ...topic.tools, data: updatedToolsData },
      });
      dispatch(removeSelectedTool());
    }
  }, [selectedTool]);

  useEffect(() => {
    console.log(template, toolsData);
  }, [toolsData, template]);

  function handleDataChange(data: any) {
    console.log("data", data);
    setTemplate({ ...template, data });
    handleTopicChange({ ...topic, template: { ...template, data } });
  }

  // const addTool = (type: string) => {
  //     const newTool = {
  //         id: Date.now(),
  //         type,
  //         x: 50,
  //         y: 50,
  //         width: 100,
  //         height: 50,
  //         content: type === 'text' ? 'Text' : 'Image'
  //     };
  //     setTools([...tools, newTool]);
  // };

  // const handleDragStop = (id: number, x: number, y: number) => {
  //     const updatedTools = tools.data.map(tool => tool.id === id ? { ...tool, x, y } : tool);
  //     setTools(updatedTools);
  //     handleTopicChange({ ...topic, tools: updatedTools })
  // };

  // const handleResizeStop = (id: number, width: string, height: string) => {
  //     const updatedTools = tools.data.map(tool => tool.id === id ? { ...tool, width, height } : tool);
  //     setTools(updatedTools);
  //     handleTopicChange({ ...topic, tools: updatedTools })
  // };

  // Add a new tool to the canvas
  const addTool = (type: ToolType) => {
    const newTool: CanvasTool = {
      id: `tool_${toolsData.length + 1}`,
      type,
      properties: {
        position: { x: 50, y: 50 },
        size: { width: 100, height: 100 },
        ...(type === "text" ? { content: "Sample Text", fontSize: 16 } : {}),
      },
    };
    setToolsData([...toolsData, newTool]);
  };

  // Update a tool's properties
  const updateTool = (
    id: string,
    newProperties: Partial<CanvasTool["properties"]>
  ) => {
    console.log(toolsData);
    const updatedToolsData = toolsData.map((tool) =>
      tool.id === id
        ? { ...tool, properties: { ...tool.properties, ...newProperties } }
        : tool
    );
    setToolsData(updatedToolsData);
    console.log(updatedToolsData);
    handleTopicChange({
      ...topic,
      tools: { ...topic.tools, data: updatedToolsData },
    });
  };

  // Delete a tool
  const deleteTool = (id: string) => {
    const updatedToolsData = toolsData.filter((tool) => tool.id !== id);
    setToolsData(updatedToolsData);
    dispatch(setSelectedToolId(null));
    handleTopicChange({
      ...topic,
      tools: { ...topic.tools, data: updatedToolsData },
    });
  };

  useEffect(() => {
    console.log(template?.type);
  }, []);

  useEffect(() => {
    console.log(openAuthoringModal);
  }, [openAuthoringModal]);

  return (
    <div className="canvas-container">
      {/* <div className="toolbar">
                <button onClick={() => addTool('text')}>Add Text</button>
                <button onClick={() => addTool('image')}>Add Image</button>
                <button onClick={() => addTool('shape')} className="btn">Add Shape</button>
            </div> */}
      <div
        ref={canvasRef}
        style={{ height: `${(canvasDimensions.width * 9) / 16}px` }}
        className={`canvas !rounded-none ${openAuthoringModal ? "no-relative" : ""
          } ${template?.type === "clicktoreveal" ? "!bg-[#d1d1d1]" : "bg-[#fff]"
          }`}
        id="canvas"
      >
        {!template && !tabTemplate ? (
          toolsData.length > 0 ? (
            toolsData.map((tool) => (
              // <Tool
              //     key={tool.id}
              //     tool={tool}
              //     onDragStop={handleDragStop}
              //     onResizeStop={handleResizeStop}
              // />

              <CanvasTool
                tool={tool}
                selectedTool={selectedTool?.id || null}
                onSelectTool={(id) => {
                  const selectedTool =
                    (toolsData &&
                      toolsData.length > 0 &&
                      toolsData.find((tool) => tool.id === id)) ||
                    null;
                  dispatch(setSelectedTool(selectedTool));
                }}
                onUpdateTool={updateTool}
              />
            ))
          ) : (
            <div className="text-lg font-medium flex w-full h-full justify-center items-center">
              <span>
                Insert a template or create a blank presentation template
              </span>
            </div>
          )
        ) : (
          (() => {
            console.log(template);
            const TemplateComponent = GetPreviewTemplate.get(template?.type);
            return TemplateComponent ? (
              <TemplateComponent
                data={template.data}
                topic={topic}
                handleDataChange={handleDataChange}
                handleOpenAuthoringModal={(state: boolean) =>
                  setOpenAuthoringModal(state)
                }
                handleCancelTemplateForm={() => {
                  if (selectedTemplateState) {
                    setTemplate(null);
                    setSelectedTemplateState(null);
                  }
                }}
                openTemplate={openTemplate}
                canvasDimensions={canvasDimensions}
              />
            ) : null;
          })()
        )}
      </div>
      {/* <LayersPanel
                tools={tools}
                selectedTool={selectedToolId}
                onSelectTool={setSelectedToolId}
                onDeleteTool={deleteTool}
            />
            <PropertiesPanel
                selectedTool={selectedTool}
                onUpdateTool={updateTool}
            /> */}
    </div>
  );
};

export default Canvas;

export type ToolType = "text" | "image" | "shape";

export interface CanvasTool {
  id: string;
  type: ToolType;
  properties: {
    position: { x: number; y: number };
    size: { width: number; height: number };
    content?: string;
    fontSize?: number;
    [key: string]: any;
  };
}

interface CanvasProps {
  tool: CanvasTool;
  selectedTool?: string | null;
  onSelectTool?: (id: string) => void;
  onUpdateTool?: (
    id: string,
    properties: Partial<CanvasTool["properties"]>
  ) => void;
  preview?: boolean;
  topic?: any;
}

export const CanvasTool: React.FC<CanvasProps> = ({
  tool,
  selectedTool,
  onSelectTool,
  onUpdateTool,
  preview,
  topic,
}) => {
  const style = {
    backgroundColor: tool.properties.backgroundColor || "transparent",
    color: tool.properties.textColor || "#000",
    borderWidth: tool.properties.borderWidth || 0,
    borderColor: tool.properties.borderColor || "transparent",
    opacity: tool.properties.opacity || 1,
    transform: `rotate(${tool.properties.rotation || 0}deg)`,
  };

  // const [rotation, setRotation] = useState(0);

  // const handleRotate = (e: any) => {
  //   // Calculate rotation based on mouse event
  //   const rect = e.target.getBoundingClientRect();
  //   const centerX = rect.left + rect.width / 2;
  //   const centerY = rect.top + rect.height / 2;
  //   const angle = Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);
  //   setRotation(angle);
  // };


  const { learnerData } = useSelector((state: any) => state.lmsLearnerData);
  const dispatch = useDispatch();

  const {
    isInitialized,
    setSuspendData,
    getSuspendData,
    setScore,
    setSuccessStatus,
  } = useLMS();

  useEffect(() => {
    console.log(selectedTool)
  }, [selectedTool])

  useEffect(() => {
    console.log("learnerData", learnerData)
    if (!isInitialized) return;
    setSuspendData(JSON.stringify(learnerData));
  }, [learnerData]);

  useEffect(() => {
    if (preview && topic.template === null && topic.tools.id) {

      const findTemplate = learnerData.templates.find(
        (template: any) => template.id === topic.tools.id
      );

      console.log("findTemplate", findTemplate);

      if (findTemplate) {
        dispatch(
          setLmsLearnerData({
            templates: learnerData.templates.map((template: any) => {
              if (template.id === topic.tools.id) {
                return {
                  ...template,
                  data: { ...template.data, completed: true },
                };
              }
              return template;
            }),
            score: learnerData.score,
          })
        );
      } else {
        dispatch(
          setLmsLearnerData({
            templates: [
              ...learnerData.templates,
              {
                id: topic.tools.id,
                title: "blank",
                type: "blank",
                data: { visited: true, completed: true },
              },
            ],
            score: learnerData.score,
          })
        );
      }
    }
  }, []);

  return (
    <>
      {!preview ? (

        <Rnd
          key={tool.id}
          size={{
            width: tool.properties.size.width,
            height: tool.properties.size.height,
          }}
          position={{
            x: tool.properties.position.x,
            y: tool.properties.position.y,
          }}
          onDragStop={(e, d) =>
            onUpdateTool &&
            onUpdateTool(tool.id, { position: { x: d.x, y: d.y } })
          }
          onResizeStop={(e, direction, ref, delta, position) =>
            onUpdateTool &&
            onUpdateTool(tool.id, {
              size: { width: ref.offsetWidth, height: ref.offsetHeight },
              position,
            })
          }
          bounds="parent"
          onClick={() => onSelectTool && onSelectTool(tool.id)}
          style={style}
          className={`border-2 ${selectedTool === tool.id ? "border-blue" : "border-transparent"
            }`}
          resizeHandleStyles={{
            top: { cursor: 'ns-resize' },
            right: { cursor: 'ew-resize' },
            bottom: { cursor: 'ns-resize' },
            left: { cursor: 'ew-resize' },
            topRight: { cursor: 'nesw-resize' },
            bottomRight: { cursor: 'nwse-resize' },
            bottomLeft: { cursor: 'nesw-resize' },
            topLeft: { cursor: 'nwse-resize' },
          }}
          // lockAspectRatio={true}
        >
          {tool.type === "text" && (
            <input
              style={{
                fontSize: `${tool.properties.fontSize}px`,
                fontWeight: tool.properties.fontWeight,
                width: tool.properties.size.width,
                height: tool.properties.size.height,
              }}
              className="bg-transparent"
              value={tool.properties.content}
              onChange={(e) =>
                onUpdateTool &&
                onUpdateTool(tool.id, { content: e.target.value })
              }
            />
          )}
          {tool.type === "image" && (
            <img
              src={
                tool.properties.src ||
                DefaultImage
              }
              alt="Image"
              className="w-full h-full"
            />
          )}
          {tool.type === "shape" && (
            <div className="bg-black w-full h-full"></div>
          )}
        </Rnd>

      ) : (
        <div
          key={tool.id}
          style={{
            ...style,
            width: tool.properties.size.width,
            height: tool.properties.size.height,
            position: "absolute",
            left: tool.properties.position.x,
            top: tool.properties.position.y,
          }}
          className={`border ${selectedTool === tool.id ? "border-blue" : "border-transparent"
            }`}
        >
          {tool.type === "text" && (
            <input
              style={{
                fontSize: `${tool.properties.fontSize}px`,
                fontWeight: tool.properties.fontWeight,
                width: tool.properties.size.width,
                height: tool.properties.size.height,
              }}
              className="bg-transparent"
              value={tool.properties.content}
              onChange={(e) =>
                onUpdateTool &&
                onUpdateTool(tool.id, { content: e.target.value })
              }
            />
          )}
          {tool.type === "image" && (
            <img
              src={
                tool.properties.src ||
                DefaultImage
              }
              alt="Image"
              className="w-full h-full"
            />
          )}
          {tool.type === "shape" && (
            <div className="bg-black w-full h-full"></div>
          )}
        </div>
      )}
    </>
  );
};
interface LayersPanelProps {
  tools: CanvasTool[];
  selectedTool: string | null;
  onSelectTool: (id: string) => void;
  onDeleteTool: (id: string) => void;
  onReorderTools: (newTools: CanvasTool[]) => void;
}

export const LayersPanel: React.FC<LayersPanelProps> = ({
  tools,
  selectedTool,
  onSelectTool,
  onDeleteTool,
  onReorderTools,
}) => {
  useEffect(() => {
    console.log(tools);
  }, []);

  const handleDragEnd = (result: any) => {
    console.log(result);
    if (!result.destination) return; // If dropped outside the list, do nothing

    // Reorder tools based on drag result
    const reorderedTools = Array.from(tools);
    const [removed] = reorderedTools.splice(result.source.index, 1);
    reorderedTools.splice(result.destination.index, 0, removed);

    onReorderTools(reorderedTools); // Update tools in the parent state
  };

  return (
    <div className="h-full w-full bg-gray-200 p-4">
      <h3 className="font-bold mb-4">Layers</h3>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="layers">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-2"
            >
              {tools.map((tool, index) => (
                <Draggable
                  key={tool.id}
                  draggableId={tool.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`flex justify-between items-center p-2 rounded border cursor-pointer ${selectedTool === tool.id
                        ? "bg-secondary text-white"
                        : "bg-white"
                        }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectTool(tool.id);
                      }}
                    >
                      {tool.type.toUpperCase()}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteTool(tool.id);
                        }}
                        className="text-red-500"
                      >
                        Delete
                      </button>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

interface PropertiesPanelProps {
  selectedTool: CanvasTool | null;
  onUpdateTool: (
    id: string,
    properties: Partial<CanvasTool["properties"]>
  ) => void;
  topic: any
}

export const PropertiesPanel: React.FC<PropertiesPanelProps> = ({
  selectedTool,
  onUpdateTool,
  topic,
}) => {
  const { accessToken } = useSelector((state: any) => state.accessToken);
  if (!selectedTool) {
    return (
      <div className="h-full w-full bg-gray-200 p-4">
        <h3 className="font-bold mb-4">Properties</h3>
        <p>Select a tool to edit its properties</p>
      </div>
    );
  }

  const handlePropertyChange = (key: string, value: any) => {
    onUpdateTool(selectedTool.id, { [key]: value });
  };

  async function handleSaveTemplate() {
    console.log(accessToken)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/customtemplate/save`,
        {
          data: topic
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      if (res && res.data) {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="h-full w-full bg-gray-200 p-4">
      <h3 className="font-bold mb-4">Properties</h3>
      {selectedTool.type === "text" && (
        <>
          <KeyValue
            label="Content"
            inputType="text"
            value={selectedTool.properties.content || ""}
            onChange={(value) => handlePropertyChange("content", value)}
          />

          <KeyValue
            label="Font Size"
            inputType="number"
            value={selectedTool.properties.fontSize || 16}
            onChange={(value) => handlePropertyChange("fontSize", value)}
          />

          <div className="font-extrabold"></div>

          <SelectInput
            label="Font weight"
            value={selectedTool.properties.fontWeight || "400"}
            onChange={(value: string) => {
              handlePropertyChange("fontWeight", value);
            }}
            options={[
              { value: "200", label: "Extra-light" },
              { value: "300", label: "Light" },
              { value: "400", label: "Normal" },
              { value: "500", label: "Medium" },
              { value: "600", label: "Semi-bold" },
              { value: "700", label: "Bold" },
              { value: "800", label: "Extra-bold" },
            ]}
          />

          <KeyValue
            label="Text Color"
            inputType="color"
            value={selectedTool.properties.textColor || "#000000"}
            onChange={(value) => handlePropertyChange("textColor", value)}
          />

          <KeyValue
            label="Background Color"
            inputType="color"
            value={selectedTool.properties.backgroundColor || "#000000"}
            onChange={(value) => handlePropertyChange("backgroundColor", value)}
          />
          <KeyValue
            label="Rotate"
            inputType="number"
            value={selectedTool.properties.rotation || 0}
            onChange={(value) => handlePropertyChange("rotation", value)}
          />
        </>
      )}
      {selectedTool.type === "image" && (
        <>
          <KeyValue
            label="Image URL"
            inputType="text"
            value={selectedTool.properties.src || ""}
            onChange={(value) => handlePropertyChange("src", value)}
          />
        </>
      )}
      {selectedTool.type === "shape" && (
        <>
          <KeyValue
            label="Background Color"
            inputType="color"
            value={selectedTool.properties.backgroundColor || "#000000"}
            onChange={(value) => handlePropertyChange("backgroundColor", value)}
          />

          <KeyValue
            label="Border Width"
            inputType="number"
            value={selectedTool.properties.borderWidth || 0}
            onChange={(value) => handlePropertyChange("borderWidth", value)}
          />

          <KeyValue
            label="Border Color"
            inputType="color"
            value={selectedTool.properties.borderColor || "#000000"}
            onChange={(value) => handlePropertyChange("borderColor", value)}
          />

          <KeyValue
            label="Opacity"
            inputType="range"
            value={selectedTool.properties.opacity || 1}
            onChange={(value) => handlePropertyChange("opacity", value)}
          />

        </>
      )}
      <button onClick={() => handleSaveTemplate()} className="bg-green-400 text-white px-2 py-1 rounded-md">Save Template</button>
    </div>
  );
};
