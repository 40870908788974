import React, { useState } from "react";
import axios from "axios";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "src/components/ui/card";
import { Button, Input } from "src/components/ui";
import { Label } from "src/components/ui/label";

const UploadScorm: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [courseId, setCourseId] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file || !courseId) {
            setResponseMessage("Please provide both a file and a Course ID.");
            return;
        }

        setLoading(true);
        setResponseMessage(null);

        const formData = new FormData();
        formData.append("file", file);
        // formData.append("courseId", courseId);
        try {
            const response = await axios.post(`https://cloud.scorm.com/api/v2/courses/importJobs/upload?courseId=${courseId}`, formData, {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Basic ${btoa(`${process.env.REACT_APP_SCORM_APP_USERNAME}:${process.env.REACT_APP_SCORM_APP_PASSWORD}`)}`
                },
            });

            console.log("response: ", response)

            setResponseMessage(`Upload Successful`);
        } catch (error: any) {
            setResponseMessage(`Upload Failed: ${error.response?.data?.error || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <Card className="w-full max-w-md shadow-md">
                <CardHeader>
                    <CardTitle>Upload SCORM Package</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-4">
                        <div>
                            <Label htmlFor="courseId">Course ID</Label>
                            <Input
                                id="courseId"
                                type="text"
                                placeholder="Enter Course ID"
                                value={courseId}
                                onChange={(e) => setCourseId(e.target.value)}
                            />
                        </div>
                        <div>
                            <Label htmlFor="file">SCORM Package</Label>
                            <Input
                                id="file"
                                type="file"
                                accept=".zip"
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                </CardContent>
                <CardFooter>
                    <Button
                        className="w-full"
                        onClick={handleUpload}
                        disabled={loading}
                    >
                        {loading ? "Uploading..." : "Upload"}
                    </Button>
                </CardFooter>
                {responseMessage && (
                    <div className="px-4 py-2 text-sm text-center text-gray-600">
                        {responseMessage}
                    </div>
                )}
            </Card>
        </div>
    );
};

export default UploadScorm;
