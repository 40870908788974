import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setCanvasActiveComponent } from 'src/features/canvasActiveComponent/canvasActiveComponent';
// @ts-ignore
import DefaultImage from "src/assets/Course/textwithimage/defaultImage.png";
import Box from 'src/components/Course/AnimationComponent/Box';
import parse, { domToReact } from 'html-react-parser';

type Props = {
    data: any;
    handleDataChange?: (data: any) => void;
    preview?: boolean;
}

function TextWithImageLayout({ data, handleDataChange, preview }: Props) {

    const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(data)
        if (handleDataChange) handleDataChange(data);
    }, [data]);

    // Render text with selectable nodes
    const renderTextWithSelection = (htmlString: string) => {
        return parse(htmlString, {
            replace: (domNode: any, index: number) => {
                if (domNode.type === 'tag') {
                    const componentType = `text-node-${index}`;

                    // Handle node click for selection
                    const handleNodeClick = (e: React.MouseEvent) => {
                        e.stopPropagation();
                        dispatch(
                            setCanvasActiveComponent({
                                templateType: "textwithimage",
                                componentType,
                            })
                        );
                    };

                    // Fetch animation configuration for this node
                    const animateComponent = data?.animateComponent?.[componentType] || {};

                    console.log("animateComponent: ", animateComponent);

                    return (
                        <div
                            key={componentType}
                            data-component-type={componentType}
                            onClick={handleNodeClick}
                            className={`relative inline-block ${!preview &&
                                canvasActiveComponent?.templateType === "textwithimage" &&
                                canvasActiveComponent?.componentType === componentType
                                ? "border-2 border-blue border-dashed"
                                : ""
                                }`}
                        >
                            <Box
                                isAnimate={(data?.customAnimate) || false}
                                effect={animateComponent.effect || 'fade'}
                                direction={animateComponent.direction || 'right'}
                                inStart={animateComponent.animationConfig?.in.start || 0}
                                inDuration={(animateComponent.animationConfig?.in.end-animateComponent.animationConfig?.in.start) || 0}
                                outStart={animateComponent.animationConfig?.out.start || 0}
                                outDuration={(animateComponent.animationConfig?.out.end-animateComponent.animationConfig?.in.start) || 0}
                            >
                                {domToReact(domNode.children)}
                            </Box>
                        </div>
                    );
                }
                return domNode;
            },
        });
    };

    return (
        <div style={{
            ...data.styles.globalBG,
            backgroundImage: (data.layoutOption === "text-image-centered" && data.imageFile?.url) ? "url(" + data.imageFile?.url + ")" : "none"
        }}
            className={`flex ${data.layoutOption === 'text-right-image-left' && "flex-row-reverse"} h-full w-full ${data.layoutOption === "text-image-centered" && data.imageFile?.url && ``} justify-center items-center`}>

            <div
                style={{ ...data.styles.textContainer, backgroundColor: data.layoutOption === "text-image-centered" ? "" : data.styles.textContainer.backgroundColor }} //"rgba(0,0,0,0.5)" is been removed as in the preview we were getting gray color overlapping
                onClick={(e) => {
                    e.stopPropagation();
                    if (canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'text') {
                        dispatch(setCanvasActiveComponent(null));
                    } else {
                        dispatch(setCanvasActiveComponent({
                            templateType: "textwithimage",
                            componentType: 'text'
                        }));
                    }
                }}
                className={`flex flex-col w-full h-full ${!preview && canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
            >
                {data.logoImageFile?.url &&
                    <div
                        style={data.styles.logoImageContainer}
                        className='flex w-full'
                    >
                        <img style={data.styles.logoImage} src={data.logoImageFile?.url} alt="logo image" className="w-[10rem] aspect-[16/9] object-contain" />
                    </div>
                }
                {/* <Box
                    isAnimate={(data?.animate) || false}
                    effect={data?.animateComponent?.prompt?.effect || 'slide'}
                    direction={data?.animateComponent?.prompt?.direction || 'right'}
                    delay={data?.animateComponent?.prompt?.start || 0}
                    duration={(data?.animateComponent?.prompt?.end - data?.animateComponent?.prompt?.start) || 0}
                    speed={data?.animateComponent?.prompt?.speed || 1}
                    className="w-full h-full"
                > */}
                <div
                    style={data.styles.text}
                    className="flex flex-wrap grow overflow-y-scroll h-40">
                    {renderTextWithSelection(data.text)}
                    {/* <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre> */}
                </div>
                {/* </Box> */}
            </div>

            {
                (data.layoutOption !== "only-text" && data.layoutOption !== "text-image-centered") && (

                    <div
                        style={{ ...data.styles.imageContainer, width: `${100 - parseInt(data.styles.textContainer.width.replace(/\D/g, ''))}%` }}
                        className={`flex w-full h-full ${!preview && canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'image' ? 'border-2 border-dashed border-blue' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'image') {
                                dispatch(setCanvasActiveComponent(null));
                            } else {
                                dispatch(setCanvasActiveComponent({
                                    templateType: "textwithimage",
                                    componentType: 'image'
                                }));
                            }
                        }}
                    >
                        <Box
                            isAnimate={(data?.customAnimate) || false}
                            effect={data?.animateComponent?.image?.effect || 'slide'}
                            direction={data?.animateComponent?.image?.direction || 'right'}
                            inStart={data?.animateComponent?.image?.animationConfig?.in.start || 0}
                            inDuration={(data?.animateComponent?.image?.animationConfig?.in.end-data?.animateComponent?.image?.animationConfig?.in.start) || 0}
                            outStart={data?.animateComponent?.image?.animationConfig?.out.start || 0}
                            outDuration={(data?.animateComponent?.image?.animationConfig?.out.end-data?.animateComponent?.image?.animationConfig?.out.start) || 0}
                            // speed={data?.animateComponent?.prompt?.speed || 1}
                            className="w-full h-full"
                        >
                            <img
                                style={data.styles.image}
                                src={data.imageFile?.url ? data.imageFile?.url : DefaultImage}
                                alt="Image"
                                className='w-full h-full object-contain' />

                        </Box>
                    </div>
                )
            }

        </div>
    )
}

export default TextWithImageLayout