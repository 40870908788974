import { Edit } from "lucide-react";
import React, { useEffect, useState } from "react";
import AuthoringModal, {
  QuestionType,
} from "../../Authoring/utils/AuthoringModal";
import { GetTemplate } from "../../Authoring/utils/GetTemplate";
import { Option } from "../../Authoring/utils/DefaultTemplateData";
// @ts-ignore
import addButton from "src/assets/Course/add.svg";
// @ts-ignore
import removeButton from "src/assets/Course/remove.svg";
import { set, template, update } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "src/components/UploadDropdowns";
// @ts-ignore
import DefaultOptionImage from "src/assets/Course/defaultOptionImage.png";
import UploadImage from "src/components/Course/UploadImage/UploadImage";
import { v4 as uuid } from "uuid";
import { setSelectedTab } from "src/features/selectedTab/selectedTabSlice";
import { Button } from "src/components/ui/button1";
import { Separator } from "src/components/ui/separator";
import { ComboboxDemo } from "src/components/ui/comboBox2";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";

type Props = {
  data: any;
  handleDataChange: (data: any) => void;
  children: React.ReactNode;
  handleOpenAuthoringModal: (openAuthoringModal: boolean) => void;
  handleCancelTemplateForm?: () => void;
  type: string;
  openTemplate?: any;
};

function Layout({
  children,
  data,
  handleDataChange,
  type,
  handleOpenAuthoringModal,
  handleCancelTemplateForm,
  openTemplate,
}: Props) {
  const [openAuthoringModal, setOpenAuthoringModal] = useState(false);
  const [templateData, setTemplateData] = useState(data);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(
    data?.isSubmitType ?? true
  );
  const [showNextButton, setShowNextButton] = useState<boolean>(
    data?.isNextType ?? false
  );
  const [showGlobalFeedbackPopup, setShowGlobalFeedbackPopup] =
    useState<boolean>(data?.isGlobalPopup ?? false);
  const [showTimer, setShowTimer] = useState<boolean>(data?.isTimer ?? false);

  const [haveOptionImage, setHaveOptionImage] =
    useState<boolean>(data?.haveOptionImage ?? false);
  // const [optionFeedback, setOptionFeedback] = useState<boolean>(false);
  // const [globalFeedback, setGlobalFeedback] = useState<boolean>(false);
  const [optionQuantity, setOptionQuantity] = useState<number>(
    data?.optionquantity ?? 4
  ); 
  const [timer, setTimer] = useState<number>(data.timer ?? 60);
  const [currentComponent, setCurrentComponent] = useState<any>(null);

  const [selectedCell, setSelectedCell] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const [showComponent, setShowComponent] = useState(false);

  const [imageOrOptionClicked, setImageOrOptionClicked] = useState("");
  const [optionImage, setOptionImage] = useState(null);

  const [tabTemplateType, setTabTemplateType] = useState("");

  const dispatch = useDispatch();

  let count = 0;

  const { selectedComponent } = useSelector(
    (state: any) => state.componentProperties
  );

  const { selectedTab } = useSelector((state: any) => state.selectedTab)

  useEffect(() => {
    dispatch(setSelectedComponent(null));
  }, [selectedTab]);


  useEffect(() => {
    console.log("showTimer", showTimer, showGlobalFeedbackPopup);
  }, [showTimer, showGlobalFeedbackPopup]);


  useEffect(() => {
    console.log(haveOptionImage, timer);
  }, [haveOptionImage, timer]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    console.log("openTemplate", openTemplate);
    if (openTemplate) setOpenAuthoringModal(true);
  }, [openTemplate]);

  useEffect(() => {
    console.log("selectedComponent", selectedComponent);
    if (selectedComponent) {
      setCurrentComponent(selectedComponent);
    }
  }, [selectedComponent]);
  
  useEffect(() => {
    if (currentComponent) {
      console.log("currentComponent updated:", currentComponent);
      dispatch(setSelectedComponent(null));
    }
  }, [currentComponent]);
  

  // useEffect(() => {
  //   console.log(selectedComponent);
  //   if (selectedComponent) {
  //     setCurrentComponent(selectedComponent);
  //     dispatch(setSelectedComponent(null));
  //   } else {
  //     setCurrentComponent(null);
  //   }
  // }, [selectedComponent]);
  

  useEffect(() => {
    console.log("templateData", templateData, "selectedTab: ", selectedTab);
    if (selectedTab && (selectedTab.content?.templateType === "scr" || selectedTab.content?.templateType === "mcr" || selectedTab.content?.templateType === "true/false")) {
      setShowSubmitButton(selectedTab.content.templateData.data.isSubmitType ?? true);
      setShowNextButton(selectedTab.content.templateData.data.isNextType ?? false);
      setShowGlobalFeedbackPopup(selectedTab.content.templateData.data.isGlobalPopup ?? false);
      setOptionQuantity(selectedTab.content.templateData.data.optionquantity ?? 4);
      setTimer(selectedTab.content.templateData.data.timer ?? 60);
      setHaveOptionImage(selectedTab.content.templateData.data.haveOptionImage ?? false);
    } else {
      setShowSubmitButton(templateData.isSubmitType ?? true);
      setShowNextButton(templateData.isNextType ?? false);
      setShowGlobalFeedbackPopup(templateData.isGlobalPopup ?? false);
      setOptionQuantity(templateData.optionquantity ?? 4);
      setTimer(templateData.timer ?? 60);
      setHaveOptionImage(templateData.haveOptionImage ?? false);
    }
  }, [templateData, selectedTab]);

  useEffect(() => {
    console.log(templateData);
    setTemplateData(data);
    setHaveOptionImage(data?.haveOptionImage ?? false);
  }, [data]);

  useEffect(() => {
    console.log("openAuthoringModal", openAuthoringModal);
    handleOpenAuthoringModal(openAuthoringModal);
  }, [openAuthoringModal]);

  const handleTemplateDataChange = (data: any) => {
    setTemplateData(data);
  };

  function updateOptionQuantity(newOptionQuantity: number) {
    if (selectedTab) {
      const newTabs = templateData.tabs.map((tab: any) => {
        if (tab._id === selectedTab._id) {

          let updatedOptions = [...tab.content.templateData.data.options];

          if (newOptionQuantity < updatedOptions.length) {
            updatedOptions = updatedOptions.slice(0, newOptionQuantity);
          }
          const updatedTab = {
            ...tab,
            content: {
              ...tab.content,
              templateData: {
                ...tab.content.templateData,
                data: {
                  ...tab.content.templateData.data,
                  optionquantity: newOptionQuantity,
                  options: updatedOptions,
                },
              },
            },
          }
          dispatch(setSelectedTab(updatedTab));
          return updatedTab;
        } else {
          return tab;
        }
      });

      const updatedData = {
        ...templateData,
        tabs: newTabs,
      };

      handleTemplateDataChange(updatedData);
    } else {
      setOptionQuantity(newOptionQuantity);

      let updatedOptions = [...templateData.options];

      if (newOptionQuantity < updatedOptions.length) {
        updatedOptions = updatedOptions.slice(0, newOptionQuantity);
      }

      const updatedData = {
        ...templateData,
        optionquantity: newOptionQuantity,
        options: updatedOptions,
      };
      handleTemplateDataChange(updatedData);
    }
  }

  const handleTimerChange = (newTimerValue: number) => {
    if (selectedTab) {
      const newTabs = templateData.tabs.map((tab: any) => {
        if (tab._id === selectedTab._id) {
          const updatedTab = {
            ...tab,
            content: {
              ...tab.content,
              templateData: {
                ...tab.content.templateData,
                data: {
                  ...tab.content.templateData.data,
                  timer: newTimerValue,
                },
              },
            },
          };
          dispatch(setSelectedTab(updatedTab));
          return updatedTab;
        } else {
          return tab;
        }
      });

      const updatedData = {
        ...templateData,
        tabs: newTabs,
      };

      handleTemplateDataChange(updatedData);
    } else {
      // console.log(newTimerValue);
      setTimer(newTimerValue);
      const updatedData = {
        ...templateData,
        timer: newTimerValue,
      };
      handleTemplateDataChange(updatedData);
    }
  };

  // const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const imageUrl = reader.result as string;
  //       setImage(imageUrl);
  //       const updatedData = {
  //         ...templateData,
  //         image: imageUrl, // Update templateData with the image URL
  //       };
  //       handleTemplateDataChange(updatedData);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  function handleSubmit() {
    console.log("Rendering...");
    handleDataChange(templateData);
    setOpenAuthoringModal(false);
  }

  const handleClick = (cell: any) => {
    setSelectedCell(cell);
    //alert(`Selected cell: ${cell}`);
  };



  //   useEffect(() => {
  //     setOptionQuantity(optionquantity);
  //   }, [optionquantity]);

  // function handleFeedbacksAndOptions(optionFeedback: boolean, globalFeedback: boolean, optionQuantity: number) {
  //     console.log(optionFeedback, globalFeedback, optionQuantity);
  //     if (optionFeedback) setOptionFeedback(optionFeedback);
  //     if (globalFeedback) setGlobalFeedback(globalFeedback);
  //     if (optionQuantity) setOptionQuantity(optionQuantity);
  // }

  return (
    <>
      {/* <div className="absolute top-0 right-0"> */}
      {!openAuthoringModal && (
        <Button
          className={"absolute top-[-2.7rem] right-[0.5rem] z-10 px-2 py-1 bg-tertiary text-white hover:bg-tertiary/40 rounded-md"}
          onClick={() => { dispatch(setCanvasActiveComponent(null)); setOpenAuthoringModal(true); }}
        >
          Edit
        </Button>
      )}
      {/* </div> */}
      {children}
      <AuthoringModal
        onOpened={openAuthoringModal}
        onClose={() => {
          setOpenAuthoringModal(false);
          handleCancelTemplateForm && handleCancelTemplateForm();
        }}
        handleSubmit={() => handleSubmit()}
        handleOptionQuantity={(optionQuantity: number) =>
          setOptionQuantity(optionQuantity)
        }
        data={templateData}
        handleDataChange={(data: any) => {
          handleTemplateDataChange(data);
          // console.log("handledatachange", data);
          // handleDataChange(templateData);
        }}
      >
        <div className="flex h-full">
          {(() => {
            // console.log('type',type);
            const TemplateComponent = GetTemplate.get(type);
            return TemplateComponent ? (
              <TemplateComponent
                optionQuantity={optionQuantity}
                data={templateData}
                handleDataChange={(data: any) => {
                  handleTemplateDataChange(data);
                }}
                handleImageOrOptionClicked={(id: string) => { console.log(id); setImageOrOptionClicked(id) }}
                handleSelectedTemplate={(tabTemplateType: string) => { setTabTemplateType(tabTemplateType); console.log(tabTemplateType) }}
              />
            ) : null;
          })()}
          <div className="bg-[#EFEFEF] border-l border-gray-300 w-[20rem] flex-col overflow-y-scroll no-scrollbar">
            <span className="text-lg font-medium w-full flex flex-col justify-start my-4 px-4">
              <span>Template Settings</span>
              {(type === "scr" || tabTemplateType === "scr") && <span className="font-normal text-sm ">Single Choice Response{' '}{`(${type})`}</span>}
              {(type === "mcr" || tabTemplateType === "mcr") && <span className="font-normal text-sm ">Multiple Choice Response{' '}{`(${type})`}</span>}
              {(type === "true/false" || tabTemplateType === "true/false") && <span className="font-normal text-sm ">True or False{' '}{`(${type})`}</span>}
              {(type === "draganddrop" || tabTemplateType === "draganddrop") && <span className="font-normal text-sm ">Drag and drop{' '}{`(${type})`}</span>}
              {(type === "matching" || tabTemplateType === "matching") && <span className="font-normal text-sm ">Matching</span>}
            </span>
            <Separator />
            {/* <div className="w-full bg-gray-300 h-[0.0625rem]"></div> */}
            <div className="w-full py-8 px-4 flex flex-col space-y-7">

              {type === "draganddrop" && (
                <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                  <div className="col-span-4 flex flex-col space-y-1">
                    <span className="">Drag & Drop type</span>
                    <div className="">
                      <ComboboxDemo
                        targetValue={templateData?.dragDropType}
                        sections={[
                          { _id: "1", value: "Table", label: "Table" },
                          { _id: "2", value: "Image", label: "Image" },
                        ]}
                        handleTargetValue={(targetValue: any) => handleTemplateDataChange({ ...templateData, dragDropType: targetValue })}
                      />
                    </div>
                  </div>
                </div>
              )}


              {type === "draganddrop" && (
                <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                  <div className="col-span-4 flex flex-col space-y-1">
                    <span className="">Number of dragable items</span>
                    {!isDisabled && (
                      <span className="text-gray-400 text-xs">
                        Select the number of choices available for the question
                      </span>
                    )}
                  </div>
                  <input
                    className="col-span-2 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="number"
                    value={optionQuantity}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      updateOptionQuantity(newValue);
                    }}
                  />
                </div>
              )}

              {/* {type === "draganddrop" && (
              <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                <div className="col-span-4 flex flex-col space-x-5">
                  <h1 className="ml-6 text-sm font-bold">Drag & Drop Image</h1>
                  <div className="border border-black p-4 mt-2 w-60 h-32 flex flex-col justify-between">
                    <ComboboxDemo/>
                    <span className="mt-auto text-center">Limit to 3 sections</span>
                  </div>
                </div>
            </div>
            )} */}

              {/* {type === "matching" && (
                <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                  <div className="col-span-4 flex flex-col space-y-1">
                    <span className="">Matching type</span>
                    <div className="">
                      <ComboboxDemo
                        targetValue={templateData?.matchingType}
                        sections={[
                          { _id: "1", value: "Table", label: "Text" },
                          { _id: "2", value: "Image", label: "Image" },
                        ]}
                        handleTargetValue={(targetValue: any) => handleTemplateDataChange({ ...templateData, matchingType: targetValue })}
                      />
                    </div>
                  </div>
                </div>
              )} */}


              {type === "matching" && (
                <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                  <div className="col-span-4 flex flex-col space-y-1">
                    <span className="">Number of match items</span>
                    {!isDisabled && (
                      <span className="text-gray-400 text-xs">
                        Select the number of choices available for the question
                      </span>
                    )}
                  </div>
                  <input
                    className="col-span-2 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="number"
                    value={optionQuantity}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      updateOptionQuantity(newValue);
                    }}
                  />
                </div>
              )}

              {(type === "scr" || type === "mcr" || tabTemplateType === "scr" || tabTemplateType === "mcr") && (
                <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                  <div className="col-span-4 flex flex-col space-y-1">
                    <span className="">Number of choices</span>
                    {!isDisabled && (
                      <span className="text-gray-400 text-xs">
                        Select the number of choices available for the question
                      </span>
                    )}
                  </div>
                  <input
                    className="col-span-2 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="number"
                    value={optionQuantity}
                    onChange={(e) => {
                      const newValue = Number(e.target.value);
                      updateOptionQuantity(newValue);
                    }}
                  />
                </div>
              )}

              {(type === "scr" || type === "mcr" || type === "true/false" || tabTemplateType === "scr" || tabTemplateType === "mcr" || tabTemplateType === "true/false") && (
                <>
                  <div className="w-full grid grid-cols-5 items-start space-x-5 text-sm">
                    <div className="col-span-4 flex flex-col space-y-1">
                      <span className="">Show Timer</span>
                      {!isDisabled && (
                        <span className="text-gray-400 text-xs">
                          Set the timer for the template
                        </span>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        const newValue = !showTimer;
                        setShowTimer(newValue);

                        if (newValue) {
                          const updatedData = {
                            ...templateData,
                            isTimer: newValue,
                            timer: 60,
                          };
                          console.log("updatedData", updatedData);
                          handleTemplateDataChange(updatedData);
                        }
                        else if (!newValue) {
                          const updatedData = {
                            ...templateData,
                            isTimer: newValue,
                            timer: null,
                          };
                          console.log("updatedData", updatedData);
                          handleTemplateDataChange(updatedData);
                        }
                      }}
                      className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${showTimer ? "bg-[#5934ff]" : "bg-gray-400"
                        }`}
                    >
                      <div
                        className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showTimer
                          ? "transform translate-x-[18px]"
                          : ""
                          }`}
                      ></div>
                    </button>
                  </div>

                  {showTimer && (
                    <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                      <div className="col-span-4 flex flex-col space-y-1">
                        <span>Timer (seconds)</span>
                        {!isDisabled && (
                          <span className="text-gray-400 text-xs">
                            Set the timer for the template
                          </span>
                        )}
                      </div>
                      <input
                        className="col-span-2 px-2 py-1 border border-gray-400 rounded-md outline-none"
                        type="number"
                        value={timer}
                        onChange={(e) => handleTimerChange(Number(e.target.value))}
                        max={300}
                      />
                    </div>
                  )}

                  {(type === "scr" || tabTemplateType === "scr") && (
                    <div className="flex flex-col space-y-7 text-sm">
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Show Submit button</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enable / disable to display the submit button in the
                              template
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newSubmitType = !showSubmitButton;
                            setShowSubmitButton(newSubmitType);

                            // Disable the Next button when the Submit button is enabled
                            if (newSubmitType) {
                              setShowNextButton(false);
                            }

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isSubmitType: newSubmitType,
                                          isNextType: newSubmitType ? false : showNextButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isSubmitType: newSubmitType,
                                isNextType: newSubmitType ? false : showNextButton, // Set isNextType to false if Submit is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${showSubmitButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showSubmitButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Enable Next button as submit</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enabling this will use 'Next' button instead of
                              'Submit' button for submission
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newNextType = !showNextButton;
                            setShowNextButton(newNextType);

                            // Disable the Submit button when the Next button is enabled
                            if (newNextType) {
                              setShowSubmitButton(false);
                            }

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isNextType: newNextType,
                                          isSubmitType: newNextType ? false : showSubmitButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isNextType: newNextType,
                                isSubmitType: newNextType ? false : showSubmitButton, // Set isSubmitType to false if Next is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`col-span-1 relative w-[42px] h-[24px] rounded-full disabled:cursor-not-allowed cursor-pointer transition-colors ${showNextButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showNextButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                    </div>
                  )}

                  {(showComponent && (type === "mcr" || tabTemplateType === "scr")) && (
                    <div className="flex flex-col space-y-7 text-sm">
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Enable Submit button</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enable / disable to display the submit button in the
                              template
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newSubmitType = !showSubmitButton;
                            setShowSubmitButton(newSubmitType);

                            // Disable the Next button when the Submit button is enabled
                            if (newSubmitType) {
                              setShowNextButton(false);
                            }

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isSubmitType: newSubmitType,
                                          isNextType: newSubmitType ? false : showNextButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isSubmitType: newSubmitType,
                                isNextType: newSubmitType ? false : showNextButton, // Set isNextType to false if Submit is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${showSubmitButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                          disabled={true}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showSubmitButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Enable Next button as submit</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enabling this will use 'Next' button instead of
                              'Submit' button for submission
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newNextType = !showNextButton;
                            setShowNextButton(newNextType);

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isNextType: newNextType,
                                          isSubmitType: newNextType ? false : showSubmitButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isNextType: newNextType,
                                isSubmitType: newNextType ? false : showSubmitButton, // Set isSubmitType to false if Next is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`col-span-1 relative w-[42px] h-[24px] rounded-full disabled:cursor-not-allowed cursor-pointer transition-colors ${showNextButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showNextButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                    </div>
                  )}

                  {(type === "true/false" || tabTemplateType === "true/false") && (
                    <div className="flex flex-col space-y-7 text-sm">
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Show Submit button</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enable / disable to display the submit button in the
                              template
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newSubmitType = !showSubmitButton;
                            setShowSubmitButton(newSubmitType);

                            // Disable the Next button when the Submit button is enabled
                            if (newSubmitType) {
                              setShowNextButton(false);
                            }

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isSubmitType: newSubmitType,
                                          isNextType: newSubmitType ? false : showNextButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isSubmitType: newSubmitType,
                                isNextType: newSubmitType ? false : showNextButton, // Set isNextType to false if Submit is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${showSubmitButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showSubmitButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                      <div className="w-full grid grid-cols-5 items-start space-x-5">
                        <div className="col-span-4 flex flex-col space-y-1">
                          <span className="">Enable Next button as submit</span>
                          {!isDisabled && (
                            <span className="text-gray-400 text-xs">
                              Enabling this will use 'Next' button instead of
                              'Submit' button for submission
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => {
                            const newNextType = !showNextButton;
                            setShowNextButton(newNextType);

                            // Disable the Submit button when the Next button is enabled
                            if (newNextType) {
                              setShowSubmitButton(false);
                            }

                            if (selectedTab) {
                              const newTabs = templateData.tabs.map((tab: any) => {
                                if (tab._id === selectedTab._id) {
                                  const updatedTab = {
                                    ...tab,
                                    content: {
                                      ...tab.content,
                                      templateData: {
                                        ...tab.content.templateData,
                                        data: {
                                          ...tab.content.templateData.data,
                                          isNextType: newNextType,
                                          isSubmitType: newNextType ? false : showSubmitButton,
                                        },
                                      },
                                    },
                                  };
                                  dispatch(setSelectedTab(updatedTab));
                                  return updatedTab;
                                } else {
                                  return tab;
                                }
                              });

                              const updatedData = {
                                ...templateData,
                                tabs: newTabs,
                              };

                              handleTemplateDataChange(updatedData);
                            } else {

                              const updatedData = {
                                ...templateData,
                                isNextType: newNextType,
                                isSubmitType: newNextType ? false : showSubmitButton, // Set isSubmitType to false if Next is enabled
                              };

                              handleTemplateDataChange(updatedData);
                            }
                          }}
                          className={`col-span-1 relative w-[42px] h-[24px] rounded-full disabled:cursor-not-allowed cursor-pointer transition-colors ${showNextButton ? "bg-[#5934ff]" : "bg-gray-400"
                            }`}
                        >
                          <div
                            className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showNextButton ? "transform translate-x-[18px]" : ""
                              }`}
                          ></div>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="w-full grid grid-cols-5 items-start space-x-5 text-sm">
                    <div className="col-span-4 flex flex-col space-y-1">
                      <span className="">Show Global feedback in pop-up</span>
                      {!isDisabled && (
                        <span className="text-gray-400 text-xs">
                          This will display the global feedback in a pop-up screen
                        </span>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        const newValue = !showGlobalFeedbackPopup;
                        setShowGlobalFeedbackPopup(newValue);

                        if (selectedTab) {
                          const newTabs = templateData.tabs.map((tab: any) => {
                            if (tab._id === selectedTab._id) {
                              const updatedTab = {
                                ...tab,
                                content: {
                                  ...tab.content,
                                  templateData: {
                                    ...tab.content.templateData,
                                    data: {
                                      ...tab.content.templateData.data,
                                      isGlobalPopup: newValue,
                                    },
                                  },
                                },
                              };
                              dispatch(setSelectedTab(updatedTab));
                              return updatedTab;
                            } else {
                              return tab;
                            }
                          });

                          const updatedData = {
                            ...templateData,
                            tabs: newTabs,
                          };

                          handleTemplateDataChange(updatedData);
                        } else {

                          const updatedData = {
                            ...templateData,
                            isGlobalPopup: newValue,
                          };

                          handleTemplateDataChange(updatedData);
                        }
                      }}
                      className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${showGlobalFeedbackPopup ? "bg-[#5934ff]" : "bg-gray-400"
                        }`}
                    >
                      <div
                        className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${showGlobalFeedbackPopup
                          ? "transform translate-x-[18px]"
                          : ""
                          }`}
                      ></div>
                    </button>
                  </div>

                  <div className="w-full grid grid-cols-5 items-start space-x-5 text-sm">
                    <div className="col-span-4 flex flex-col space-y-1">
                      <span className="">Show images in choices</span>
                      <span className="text-gray-400 text-xs">
                        (optional)
                      </span>
                    </div>
                    <button
                      onClick={() => {
                        const newValue = !haveOptionImage;
                        setHaveOptionImage(newValue);

                        // Define the default imageFile object
                        const defaultOptionImage = {
                          _id: uuid(), // or some unique identifier
                          url: 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg',
                          name: 'default-image-name',
                          type: 'image/jpg', // or the correct MIME type
                          size: 12345,
                          createdOn: Date.now(),
                          folderId: null,
                          userId: 'default-user-id',
                          __v: 0,
                        };

                        if (selectedTab) {

                          const newTabs = templateData?.tabs?.map((tab: any) => {
                            if (tab._id === selectedTab._id) {

                              const updatedOptions = tab.content.templateData.data.options.map((option: Option) => {
                                if (newValue) {
                                  return { ...option, optionImage: defaultOptionImage };
                                } else {
                                  const { optionImage, ...rest } = option;
                                  return rest;
                                }
                              });

                              const updatedTab = {
                                ...tab,
                                content: {
                                  ...tab.content,
                                  templateData: {
                                    ...tab.content.templateData,
                                    data: {
                                      ...tab.content.templateData.data,
                                      haveOptionImage: newValue,
                                      options: updatedOptions,
                                    },
                                  },
                                },
                              };
                              dispatch(setSelectedTab(updatedTab));
                              return updatedTab;
                            } else {
                              return tab;
                            }
                          });

                          const updatedData = {
                            ...templateData,
                            tabs: newTabs,
                          };

                          handleTemplateDataChange(updatedData);
                        } else {

                          const updatedOptions = data.options.map((option: Option) => {
                            if (newValue) {
                              return { ...option, optionImage: defaultOptionImage };
                            } else {
                              const { optionImage, ...rest } = option;
                              return rest;
                            }
                          });

                          console.log(updatedOptions);

                          const updatedData = {
                            ...templateData,
                            haveOptionImage: newValue,
                            options: updatedOptions,
                          };

                          handleTemplateDataChange(updatedData);
                        }
                      }}
                      className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${haveOptionImage ? "bg-[#5934ff]" : "bg-gray-400"
                        }`}
                    >
                      <div
                        className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${haveOptionImage
                          ? "transform translate-x-[18px]"
                          : ""
                          }`}
                      ></div>
                    </button>
                  </div>
                </>
              )}
            </div>

            {currentComponent && (
              <>
                <span className="text-lg font-medium w-full flex justify-start my-4 px-4">
                  <span>{currentComponent?.type}</span>&nbsp;Properties
                </span>

                <Separator />
                {/* <div className="w-full bg-gray-300 h-[0.0625rem]"></div> */}

                <div className="py-8 px-4 flex flex-col space-y-7">
                  <div className="w-full grid grid-cols-6 items-start space-x-5 text-sm">
                    <div className="col-span-4 flex flex-col space-y-1">
                      <span className="">Maximum characters allowed</span>
                      {!isDisabled && (
                        <span className="text-gray-400 text-xs">
                          Specify the maximum number of characters allowed for
                          the text component
                        </span>
                      )}
                    </div>
                    <input
                      className="col-span-2 px-2 py-1 border border-gray-400 rounded-md outline-none"
                      type="number"
                      value={
                        (selectedTab ? selectedTab?.content?.templateData?.data : templateData)?.componentProperties?.find(
                          (comp: any) => comp.type === currentComponent?.type
                        )?.maxLength || currentComponent?.maxLength
                      }
                      onChange={(e) => {
                        const newValue = Number(e.target.value);

                        if (currentComponent) {
                          const updatedComponent = {
                            ...currentComponent,
                            maxLength: newValue,
                          };

                          // Check if the component of the same type (e.g., "Question") already exists
                          const existingComponentProperties = Array.isArray(
                            selectedTab ? selectedTab.content.templateData.data : templateData.componentProperties
                          )
                            ? (selectedTab ? selectedTab.content.templateData.data : templateData.componentProperties)
                            : [];

                          const updatedComponentProperties =
                            existingComponentProperties.map((comp: any) =>
                              comp.type === currentComponent.type
                                ? updatedComponent
                                : comp
                            );

                          // If the component type doesn't exist, push it as a new property
                          const componentTypeExists =
                            existingComponentProperties.some(
                              (comp: any) => comp.type === currentComponent.type
                            );

                          if (!componentTypeExists) {
                            updatedComponentProperties.push(updatedComponent);
                          }

                          if (selectedTab) {
                            const newTabs = templateData.tabs.map((tab: any) => {
                              if (tab._id === selectedTab._id) {
                                const updatedTab = {
                                  ...tab,
                                  content: {
                                    ...tab.content,
                                    templateData: {
                                      ...tab.content.templateData,
                                      data: {
                                        ...tab.content.templateData.data,
                                        componentProperties: updatedComponentProperties,
                                      },
                                    },
                                  },
                                };
                                dispatch(setSelectedTab(updatedTab));
                                return updatedTab;
                              } else {
                                return tab;
                              }
                            });

                            const updatedData = {
                              ...templateData,
                              tabs: newTabs,
                            };

                            setCurrentComponent(updatedComponent);
                            handleTemplateDataChange(updatedData);
                          } else {
                            const updatedTemplateData = {
                              ...templateData,
                              componentProperties: updatedComponentProperties,
                            };

                            setCurrentComponent(updatedComponent);
                            handleTemplateDataChange(updatedTemplateData);
                          }

                        }
                      }}
                    />
                  </div>

                  {type !== 'draganddrop' && type !== 'matching' && (
                    <div className="flex-col space-y-3 text-sm">
                      <span className="">Display zones</span>
                      <div className="grid-container">
                        {Array.isArray(currentComponent?.zone) &&
                          currentComponent?.zone?.map(
                            (row: number[], rowIndex: number) => (
                              <div key={rowIndex} className="grid-row">
                                {Array.isArray(row) && // Ensure 'row' is an array before mapping
                                  row.map((cell, cellIndex) => {
                                    count++;
                                    return (
                                      <div
                                        key={cellIndex}
                                        className={`grid-cell ${cell === 1 ? "selected" : ""
                                          }`}
                                        onClick={() => {
                                          const newZoneValue =
                                            currentComponent?.zone?.map(
                                              (
                                                rowCopy: number[],
                                                rowCopyIndex: number
                                              ) =>
                                                rowCopyIndex === rowIndex
                                                  ? [
                                                    ...rowCopy.slice(
                                                      0,
                                                      cellIndex
                                                    ),
                                                    rowCopy[cellIndex] === 1
                                                      ? 0
                                                      : 1,
                                                    ...rowCopy.slice(
                                                      cellIndex + 1
                                                    ),
                                                  ]
                                                  : rowCopy
                                            );

                                          if (currentComponent) {
                                            const updatedComponent = {
                                              ...currentComponent,
                                              zone: newZoneValue,
                                            };

                                            const existingComponentProperties =
                                              Array.isArray(
                                                (selectedTab ? selectedTab.content.templateData.data : templateData).componentProperties
                                              )
                                                ? (selectedTab ? selectedTab.content.templateData.data : templateData).componentProperties
                                                : [];

                                            const updatedComponentProperties =
                                              existingComponentProperties.map(
                                                (comp: any) =>
                                                  comp.type ===
                                                    currentComponent.type
                                                    ? updatedComponent
                                                    : comp
                                              );

                                            // If the component type doesn't exist, push it as a new property
                                            const componentTypeExists =
                                              existingComponentProperties.some(
                                                (comp: any) =>
                                                  comp.type ===
                                                  currentComponent.type
                                              );

                                            if (!componentTypeExists) {
                                              updatedComponentProperties.push(
                                                updatedComponent
                                              );
                                            }

                                            if (selectedTab) {
                                              const newTabs = templateData.tabs.map((tab: any) => {
                                                if (tab._id === selectedTab._id) {
                                                  const updatedTab = {
                                                    ...tab,
                                                    content: {
                                                      ...tab.content,
                                                      templateData: {
                                                        ...tab.content.templateData,
                                                        data: {
                                                          ...tab.content.templateData.data,
                                                          componentProperties: updatedComponentProperties,
                                                        },
                                                      },
                                                    },
                                                  };
                                                  dispatch(setSelectedTab(updatedTab));
                                                  return updatedTab;
                                                } else {
                                                  return tab;
                                                }
                                              });

                                              const updatedData = {
                                                ...templateData,
                                                tabs: newTabs,
                                              };

                                              setCurrentComponent(updatedComponent);
                                              handleTemplateDataChange(updatedData);
                                            } else {

                                              const updatedTemplateData = {
                                                ...templateData,
                                                componentProperties:
                                                  updatedComponentProperties,
                                              };

                                              setCurrentComponent(updatedComponent);
                                              handleTemplateDataChange(
                                                updatedTemplateData
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        <span className="cell-label">
                                          {String.fromCharCode(count + 96)}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            )

                          )}
                      </div>
                      {!isDisabled && (
                        <p className="text-xs mt-1 text-gray-400">
                          Choose the display position of the component in the
                          template
                        </p>
                      )}
                    </div>)}
                </div>
              </>
            )}

            {
              type === "textwithimage" && (
                <></>
              )
            }

          </div>
        </div>
      </AuthoringModal>
    </>
  );
}

export default Layout;
