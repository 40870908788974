import React, { useEffect, useState } from "react";
import Question from "../Preview/common/Question";
import Layout from "./common/Layout";
import { Option } from "../Authoring/utils/DefaultTemplateData";
import { Button } from "src/components/ui/button1";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import { useLMS } from "src/context/Lms";

type Props = {
  type?: string;
  quizSettings?: any;
  topics?: any[];
  topic: any;
  handleDataChange: (data: any) => void;
  data: any;
  handleSubmit?: () => void;
  disableNext?: boolean;
  handleOpenAuthoringModal?: (state: boolean) => void;
  handleCancelTemplateForm?: () => void;
  openTemplate?: any;
  handleNextDisable?: (res: any, submitted: any) => void;
  canvasDimensions: {
    width: number;
    height: number;
  };
  nestedTemplate?: boolean;
};

function Mcr({
  type,
  topics,
  topic,
  data,
  quizSettings,
  handleDataChange,
  handleSubmit,
  disableNext,
  handleOpenAuthoringModal,
  handleCancelTemplateForm,
  openTemplate,
  handleNextDisable,
  canvasDimensions,
  nestedTemplate,
}: Props) {
  const [responses, setResponses] = useState<Array<Option>>([]);
  const [openFeedback, setOpenFeedback] = useState("");
  const [status, setStatus] = useState("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(true);
  const [responsed, setResponsed] = useState<boolean | undefined>(false);

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData);

  const {
    isInitialized,
    setSuspendData,
    getSuspendData,
    setScore,
    setSuccessStatus,
  } = useLMS();

  const dispatch = useDispatch();

  const minPassingScore = quizSettings?.passingRequirements?.passingScore || 0;

  const templateType = "mcr";

  useEffect(() => {
    // Add your effect logic here if necessary
  }, [openFeedback]);

  // Function to reset state
  const resetTemplateState = () => {
    setSubmitted(false);
    setStatus("");
    setResponsed(undefined);
    setOpenFeedback("");
  };

  useEffect(() => {
    console.log("responsed", responsed);
  }, [responsed]);

  useEffect(() => {
    // Reset state when template changes
    console.log("reset");
    resetTemplateState();
  }, [data]);

  useEffect(() => {
    if (responses && handleNextDisable) {
      const res = responses.find(
        (detail: any) => detail.response === true
      )?.response;
      if (res) setResponsed(res);
      else setResponsed(false);
      handleNextDisable(res, submitted);
    }
  }, [responses, submitted]);

  useEffect(() => {
    // console.log("submitted", submitted, "topic", topic);
  }, [submitted, topic]);

  useEffect(() => {
    console.log("learnerData line: 81", learnerData);
    if (!isInitialized) return;
    // console.log("index line : 105", learnerData);
    setSuspendData(JSON.stringify(learnerData));

    setScore(learnerData.score.raw);

    if (learnerData.score.raw >= minPassingScore) {
      setSuccessStatus("passed");
    } else {
      setSuccessStatus("failed");
    }
  }, [learnerData]);

  function checkResponse() {
    let correct = true;
    let feedback = data.globalFeedback;

    responses.forEach((item, index) => {
      if (item.response !== data.options[index].correct) {
        correct = false;
        // feedback = data.options[index].feedback; // Uncomment if needed
      }
    });

    setStatus(correct ? "correct" : "incorrect");
    setSubmitted(true);

    setTimeout(() => {
      setOpenFeedback(feedback);
    }, 2000);

    if (!type) return;

    const quizTemplates = topics
      ? topics.filter(
          (topic) =>
            topic.template &&
            (topic.template.type === "scr" ||
              topic.template.type === "mcr" ||
              topic.template.type === "true/false" ||
              topic.template.type === "matching" ||
              topic.template.type === "fillintheblanks" ||
              topic.template.type === "draganddrop")
        )
      : [];
    const noOfQuizes = quizTemplates.length;
    // const noOfQuizes = 3;

    console.log("line 123", learnerData);

    const findTemplate = learnerData.templates.find(
      (template: any) => template.data.id === topic.template.id
    );

    var score = 0;
    if (correct && noOfQuizes > 0) {
      score = learnerData.score.raw + Math.round(100 / noOfQuizes);
    } else {
      score = learnerData.score.raw + 0;
    }

    console.log("score: ", score);

    if (findTemplate) {
      dispatch(
        setLmsLearnerData({
          templates: learnerData.templates.map((template: any) => {
            if (template.data.id === topic.template.id) {
              return {
                ...template,
                data: { ...template.data, completed: true },
              };
            }
            return template;
          }),
          score: { ...learnerData.score, raw: score },
        })
      );
    } else {
      dispatch(
        setLmsLearnerData({
          templates: [
            ...learnerData.templates,
            {
              id: topic.template.id,
              title: topic.template.title,
              type: topic.template.type,
              data: { visited: true, completed: true },
            },
          ],
          score: { ...learnerData.score, raw: score },
        })
      );
    }
  }

  return (
    <>
      {!type ? (
        <>
          {!nestedTemplate ? (
            <Layout
              type={templateType}
              data={data}
              handleDataChange={(data) => handleDataChange(data)}
              handleOpenAuthoringModal={(state) => {
                handleOpenAuthoringModal && handleOpenAuthoringModal(state);
              }}
              handleCancelTemplateForm={() => {
                handleCancelTemplateForm && handleCancelTemplateForm();
              }}
              openTemplate={openTemplate}
            >
              <div
                style={data?.styles?.container}
                onClick={() => {
                  if (
                    canvasActiveComponent?.templateType === templateType &&
                    canvasActiveComponent?.componentType === "mcr"
                  ) {
                    dispatch(setCanvasActiveComponent(null));
                  } else {
                    dispatch(
                      setCanvasActiveComponent({
                        templateType,
                        componentType: "mcr",
                      })
                    );
                  }
                }}
                className={`flex flex-col w-full ${
                  canvasActiveComponent?.templateType === templateType &&
                  canvasActiveComponent?.componentType === "mcr"
                    ? "border-2 border-dashed border-blue"
                    : ""
                }`}
              >
                <Question
                  type={templateType}
                  data={data}
                  canvasDimensions={canvasDimensions}
                />
                <Button
                  disabled
                  style={{
                    fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                    paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                    paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
                    paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                    paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
                    borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                    marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
                    bottom: `${20 * (canvasDimensions.width / 960)}px`,
                  }}
                  className={`absolute bottom-5 rounded-md disabled:bg-blue/40 text-white ml-4 px-4 py-1 max-w-max cursor-not-allowed`}
                >
                  Submit
                </Button>
              </div>
            </Layout>
          ) : (
            <div
              style={data?.styles?.container}
              onClick={(e) => {
                e.stopPropagation();
                if (canvasActiveComponent?.templateType) {
                  dispatch(setCanvasActiveComponent(null));
                } else {
                  dispatch(
                    setCanvasActiveComponent({
                      templateType,
                      componentType: "mcr",
                    })
                  );
                }
              }}
              className={`flex flex-col w-full hover:cursor-pointer ${
                canvasActiveComponent?.templateType === templateType &&
                canvasActiveComponent?.componentType === "mcr"
                  ? "border-2 border-dashed border-blue"
                  : ""
              }`}
            >
              <Question
                type={templateType}
                data={data}
                canvasDimensions={canvasDimensions}
              />
              <Button
                disabled
                style={{
                  fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
                  paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
                  paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
                  paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
                  paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
                  borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
                  marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
                  bottom: `${20 * (canvasDimensions.width / 960)}px`,
                }}
                className={`absolute bottom-5 rounded-md disabled:bg-blue/40 text-white ml-4 px-4 py-1 max-w-max cursor-not-allowed`}
              >
                Submit
              </Button>
            </div>
          )}
        </>
      ) : (
        <div style={data?.styles?.container} className="flex flex-col w-full">
          <Question
            coursePreview={true}
            handleResponses={(responses) => setResponses(responses)}
            type="mcr"
            data={data}
            submitted={submitted}
            status={status}
            openFeedback={openFeedback}
            handleOpenFeedback={(openFeedBack: any) =>
              setOpenFeedback(openFeedBack)
            }
            canvasDimensions={canvasDimensions}
          />
          <Button
            disabled={!responsed}
            style={{
              fontSize: `calc(${16 * (canvasDimensions.width / 960)}px)`,
              paddingTop: `${4 * (canvasDimensions.width / 960)}px`,
              paddingRight: `${16 * (canvasDimensions.width / 960)}px`,
              paddingBottom: `${4 * (canvasDimensions.width / 960)}px`,
              paddingLeft: `${16 * (canvasDimensions.width / 960)}px`,
              borderRadius: `${6 * (canvasDimensions.width / 960)}px`,
              marginLeft: `${16 * (canvasDimensions.width / 960)}px`,
              bottom: `${6 * (canvasDimensions.width / 960)}px`,
            }}
            className={`absolute bottom-1.5 left-0 rounded-md bg-blue hover:bg-blue disabled:bg-blue/40 text-white ml-4 px-4 py-1 max-w-max ${
              submitted && "bg-blue/40 hover:bg-blue/40 cursor-not-allowed"
            }`}
            onClick={() => checkResponse()}
          >
            Submit
          </Button>
        </div>
      )}
    </>
  );
}

export default Mcr;
