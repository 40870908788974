import React, { forwardRef, useEffect } from 'react';
import { motion, MotionProps, useAnimation } from 'framer-motion';
import { useSelector } from 'react-redux';

type BoxProps = {
    children: React.ReactNode;
    isAnimate?: boolean;
    effect: 'fade' | 'slide' | 'zoom'; // Supported effects
    inStart?: number; // Time in seconds for fade-in to start
    inDuration?: number; // Duration in seconds for fade-in animation
    outStart?: number; // Time in seconds for fade-out to start
    outDuration?: number; // Duration in seconds for fade-out animation
    direction?: 'left' | 'right'; // Applicable for slide effect
} & MotionProps & React.HTMLAttributes<HTMLDivElement>;

const Box = forwardRef<HTMLDivElement, BoxProps>(({
    children,
    isAnimate = false,
    effect = 'fade',
    inStart = 0,
    inDuration = 1,
    outStart = 0,
    outDuration = 1,
    direction = 'left',
    ...rest
}, ref) => {
    const controls = useAnimation();
    const { isPlaying, currentTime } = useSelector((state: any) => state.templateAudio);

    const getAnimationProps = (isIn: boolean) => {
        const baseTransition = isIn
            ? { duration: inDuration }
            : { duration: outDuration };

        switch (effect.toLowerCase()) {
            case 'fade':
                return isIn
                    ? { opacity: 1, transition: baseTransition }
                    : { opacity: 0, transition: baseTransition };
            case 'slide':
                const slideDistance = direction === 'left' ? '-10vw' : '10vw';
                return isIn
                    ? { x: 0, opacity: 1, transition: baseTransition }
                    : { x: slideDistance, opacity: 0, transition: baseTransition };
            case 'zoom':
                return isIn
                    ? { scale: 1, opacity: 1, transition: baseTransition }
                    : { scale: 0.5, opacity: 0, transition: baseTransition };
            default:
                return isIn
                    ? { opacity: 1, transition: baseTransition }
                    : { opacity: 0, transition: baseTransition };
        }
    };

    useEffect(() => {
        if (isAnimate && isPlaying) {
            // Apply fade-in effect at the specified start time
            if (currentTime >= inStart && currentTime < inStart + inDuration) {
                controls.start(getAnimationProps(true)); // Start the "in" animation
            }
            // Apply fade-out effect at the specified start time
            else if (currentTime >= outStart && currentTime < outStart + outDuration) {
                controls.start(getAnimationProps(false)); // Start the "out" animation
            }
            // Hide the component when outside defined intervals
            else if (currentTime < inStart || currentTime >= outStart + outDuration) {
                controls.set({ opacity: 0 });
            }
        }
    }, [currentTime, isPlaying, inStart, inDuration, outStart, outDuration, isAnimate, controls, effect, direction]);

    return (!isAnimate ? <>{children}</> :
        <motion.div
            ref={ref as React.Ref<HTMLDivElement>}
            initial={{ opacity: 0 }} // Initial state for the component
            animate={controls} // Controlled animation
            {...rest}
        >
            {children}
        </motion.div>
    );
});

export default Box;
