import React from "react"
import { ClickToReveal, Accordion, DragAndDrop, Mcr, Scr, Tabs, TextWImage, TrueFalse, FlipCard, Hotspot, Matching, FillInTheBlanks } from "../index"

export const GetPreviewTemplate = new Map([
    ['mcr', (props: any) => <Mcr {...props} />],
    ['scr', (props: any) => <Scr {...props} />],
    ['true/false', (props: any) => <TrueFalse {...props} />],
    ['textwithimage', (props: any) => <TextWImage {...props} />],
    ['tabs', (props: any) => <Tabs {...props} />],
    ['clicktoreveal', (props: any) => <ClickToReveal {...props} />],
    ['accordion', (props: any) => <Accordion {...props} />],
    ['draganddrop', (props: any) => <DragAndDrop {...props} />],
    ['flipcard', (props: any) => <FlipCard {...props} />],
    ['hotspot', (props: any) => <Hotspot {...props} />],
    ['matching', (props: any) => <Matching {...props} />],
    ['fillintheblanks', (props: any) => <FillInTheBlanks {...props} />],
])
