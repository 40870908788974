import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedFlipcard: null,
};
export const selectedFlipcardSlice = createSlice({
    name: "selectedFlipcard",
    initialState,
    reducers: {
        setSelectedFlipcard: (state, action) => {
            state.selectedFlipcard = action.payload;
        },
    },
});

export const { setSelectedFlipcard } = selectedFlipcardSlice.actions;
export default selectedFlipcardSlice.reducer;