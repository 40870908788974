import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { GetPreviewTemplate } from "../../utils/GetPreviewTempalte";
import { setSelectedCard } from "src/features/selectedCard/selectedCardSlice";
import { motion, AnimatePresence } from "framer-motion";
// @ts-ignore
import DefaultImage from "src/assets/Course/defaultImage.jpg";

type Props = {
  data: any;
  preview?: boolean;
  handleDataChange?: (data: any) => void;
};

function ClickToRevealLayout({ data, handleDataChange, preview }: Props) {
  const [openTemplate, setOpenTemplate] = useState(false);

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );
  const { selectedCard } = useSelector((state: any) => state.selectedCard);

  const dispatch = useDispatch();

  useEffect(() => {
    if (handleDataChange) handleDataChange(data);
  }, [data]);

  useEffect(() => {
    console.log("selectedCard", selectedCard);
    dispatch(setSelectedCard("front"));
  }, []);

  useEffect(() => { }, [selectedCard]);

  useEffect(() => {
    if (!preview) dispatch(setSelectedCard("front"));
  }, [preview]);

  const animationVariantsFront = {
    horizontal: { rotateY: selectedCard === "front" ? 0 : 180 },
    vertical: { rotateX: selectedCard === "front" ? 0 : 180 },
  };

  const animationVariantsBack = {
    horizontal: { rotateY: selectedCard === "back" ? 0 : 180 },
    vertical: { rotateX: selectedCard === "back" ? 0 : 180 },
  };

  return (
    <div style={{ ...data.styles.global }}
      onClick={() => {
        if (
          canvasActiveComponent?.templateType === "clicktoreveal" &&
          canvasActiveComponent?.componentType === "globalBG"
        ) {
          dispatch(setCanvasActiveComponent(null));
        } else {
          dispatch(
            setCanvasActiveComponent({
              templateType: "clicktoreveal",
              componentType: "globalBG",
            })
          );
        }
      }}
      className={` w-full h-full ${canvasActiveComponent?.templateType === "clicktoreveal" &&
          canvasActiveComponent?.componentType === "globalBG"
          ? "border-2 border-dashed border-blue"
          : ""
        }`}
    >
      <motion.div
        className="w-full h-full"
        transition={{ duration: 0.7 }}
        variants={animationVariantsFront}
        animate={data.transition}
      >
        <motion.div
          transition={{ duration: 0.7 }}
          variants={animationVariantsFront}
          animate={data.transition}
          className="w-full h-full rounded-md flex items-center justify-center"
        >
          {" "}
          {selectedCard === "front" && (
            <motion.div
              transition={{ duration: 0.7 }}
              variants={animationVariantsFront}
              animate={data.transition}
              style={{
                backfaceVisibility: "hidden",
                ...data.front.styles.parentContainer,
              }}
              className=""
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (preview) dispatch(setSelectedCard("back"));

                  if (
                    canvasActiveComponent?.templateType === "clicktoreveal" &&
                    canvasActiveComponent?.componentType === "wholeFront"
                  ) {
                    dispatch(setCanvasActiveComponent(null));
                  } else {
                    dispatch(
                      setCanvasActiveComponent({
                        templateType: "clicktoreveal",
                        componentType: "wholeFront",
                      })
                    );
                  }
                }}
                style={{ ...data.front.styles.container }}
                className={`shadow-lg flex flex-col h-full w-full justify-center items-center ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                    canvasActiveComponent?.componentType === "wholeFront"
                    ? "border-2 border-dashed border-blue"
                    : ""
                  }`}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "promptLabelWrap"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "clicktoreveal",
                          componentType: "promptLabelWrap",
                        })
                      );
                    }
                  }}
                  style={{ ...data.front.styles.promptLabelWrap }}
                  className={`flex flex-col ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "promptLabelWrap"
                      ? "border-2 border-dashed border-blue"
                      : ""
                    }`}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "prompt"
                      ) {
                        dispatch(setCanvasActiveComponent(null));
                      } else {
                        dispatch(
                          setCanvasActiveComponent({
                            templateType: "clicktoreveal",
                            componentType: "prompt",
                          })
                        );
                      }
                    }}
                    className={` flex flex-col w-full ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "prompt"
                        ? "border-2 border-dashed border-blue"
                        : ""
                      }`}
                    style={{ ...data.front.styles.promptContainer }}
                  >
                    <div
                      style={{ ...data.front.styles.prompt }}
                      className="flex flex-wrap grow"
                    >
                      {data.front.prompt}
                    </div>
                  </div>

                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "questionLabel"
                      ) {
                        dispatch(setCanvasActiveComponent(null));
                      } else {
                        dispatch(
                          setCanvasActiveComponent({
                            templateType: "clicktoreveal",
                            componentType: "questionLabel",
                          })
                        );
                      }
                    }}
                    className={`font-semibold  flex flex-col ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "questionLabel"
                        ? "border-2 border-dashed border-blue"
                        : ""
                      }`}
                    style={{ ...data.front.styles.questionLabelContainer }}
                  >
                    <div
                      style={{ ...data.front.styles.questionLabel }}
                      className="flex flex-wrap grow"
                    >
                      Question
                    </div>
                  </div>
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "questionImageWrap"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "clicktoreveal",
                          componentType: "questionImageWrap",
                        })
                      );
                    }
                  }}
                  style={{ ...data.front.styles.questionImageWrap }}
                  className={`flex ${data.front.layoutOption === "front-text-right-image-left" &&
                    "flex-row-reverse"
                    } ${(data.front.layoutOption === "front-only-text-square" ||
                      data.front.layoutOption === "front-text-image-square") &&
                    "flex flex-col-reverse "
                    } ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "questionImageWrap"
                      ? "border-2 border-dashed border-blue"
                      : ""
                    }`}
                >
                  <div
                    style={{
                      ...data.front.styles.textContainer,
                      // backgroundColor:
                      //     data.front.layoutOption === "text-image-centered"
                      //     ? "rgba(0,0,0,0.5)"
                      //     : data.front.styles.textContainer.backgroundColor,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "question"
                      ) {
                        dispatch(setCanvasActiveComponent(null));
                      } else {
                        dispatch(
                          setCanvasActiveComponent({
                            templateType: "clicktoreveal",
                            componentType: "question",
                          })
                        );
                      }
                    }}
                    className={`flex flex-col  ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "question"
                        ? "border-2 border-dashed border-blue"
                        : ""
                      }`}
                  >
                    <div
                      style={data.front.styles.text}
                      className="flex flex-wrap grow"
                    >
                      {data.front.question}
                    </div>
                  </div>

                  {data.front.layoutOption !== "front-only-text" &&
                    data.front.layoutOption !== "front-only-text-square" && (
                      <div
                        style={{
                          ...data.front.styles.imageContainer,
                        }}
                        className={`flex   ${canvasActiveComponent?.templateType ===
                            "clicktoreveal" &&
                            canvasActiveComponent?.componentType === "image"
                            ? "border-2 border-dashed border-blue"
                            : ""
                          }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            canvasActiveComponent?.templateType ===
                            "clicktoreveal" &&
                            canvasActiveComponent?.componentType === "image"
                          ) {
                            dispatch(setCanvasActiveComponent(null));
                          } else {
                            dispatch(
                              setCanvasActiveComponent({
                                templateType: "clicktoreveal",
                                componentType: "image",
                              })
                            );
                          }
                        }}
                      >
                        {data.front?.imageFile ? (
                          <img
                            style={data.front.styles.image}
                            src={data.front?.imageFile?.url}
                            alt="image"
                            className="w-full h-full"
                          />
                        ) : (
                          <img
                            style={data.front.styles.image}
                            src={DefaultImage}
                            alt="image"
                            className="w-full h-full "
                          />
                        )}
                      </div>
                    )}
                </div>
              </div>
            </motion.div>
          )}
          {selectedCard === "back" && (
            <motion.div
              initial={
                data.transition === "horizontal"
                  ? { rotateY: 180 }
                  : { rotateX: 180 }
              }
              variants={animationVariantsBack}
              animate={data.transition}
              transition={{ duration: 0.7 }}
              style={{
                backfaceVisibility: "hidden",
                ...data.back.styles.parentContainer,
              }}
              className=""
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (preview) dispatch(setSelectedCard("front"));

                  if (
                    canvasActiveComponent?.templateType === "clicktoreveal" &&
                    canvasActiveComponent?.componentType === "wholeBack"
                  ) {
                    dispatch(setCanvasActiveComponent(null));
                  } else {
                    dispatch(
                      setCanvasActiveComponent({
                        templateType: "clicktoreveal",
                        componentType: "wholeBack",
                      })
                    );
                  }
                }}
                style={{ ...data.back.styles.container }}
                className={` shadow-lg flex flex-col h-full w-full justify-center items-center ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                    canvasActiveComponent?.componentType === "wholeBack"
                    ? "border-2 border-dashed border-blue"
                    : ""
                  }`}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "answerLabel"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "clicktoreveal",
                          componentType: "answerLabel",
                        })
                      );
                    }
                  }}
                  className={`font-semibold flex flex-col ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "answerLabel"
                      ? "border-2 border-dashed border-blue"
                      : ""
                    }`}
                  style={{ ...data.back.styles.answerLabelContainer }}
                >
                  <div
                    style={{ ...data.back.styles.answerLabel }}
                    className="flex flex-wrap grow"
                  >
                    Answer
                  </div>
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "answerImageWrap"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "clicktoreveal",
                          componentType: "answerImageWrap",
                        })
                      );
                    }
                  }}
                  style={{ ...data.back.styles.answerImageWrap }}
                  className={`flex ${data.back.layoutOption === "back-text-right-image-left" &&
                    "flex-row-reverse"
                    }  ${(data.back.layoutOption === "back-only-text-square" ||
                      data.back.layoutOption === "back-text-image-square") &&
                    "flex flex-col-reverse"
                    } ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                      canvasActiveComponent?.componentType === "answerImageWrap"
                      ? "border-2 border-dashed border-blue"
                      : ""
                    }`}
                >
                  <div
                    style={{
                      ...data.back.styles.textContainer,
                      // backgroundColor:
                      //     data.back.layoutOption === "text-image-centered"
                      //     ? "rgba(0,0,0,0.5)"
                      //     : data.back.styles.textContainer.backgroundColor,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "answer"
                      ) {
                        dispatch(setCanvasActiveComponent(null));
                      } else {
                        dispatch(
                          setCanvasActiveComponent({
                            templateType: "clicktoreveal",
                            componentType: "answer",
                          })
                        );
                      }
                    }}
                    className={`flex flex-col  ${canvasActiveComponent?.templateType === "clicktoreveal" &&
                        canvasActiveComponent?.componentType === "answer"
                        ? "border-2 border-dashed border-blue"
                        : ""
                      }`}
                  >
                    <div
                      style={data.back.styles.text}
                      className="flex flex-wrap grow"
                    >
                      {data.back.answer}
                    </div>
                  </div>

                  {data.back.layoutOption !== "back-only-text" &&
                    data.back.layoutOption !== "back-only-text-square" && (
                      <div
                        style={{
                          ...data.back.styles.imageContainer
                        }}
                        className={`flex  ${canvasActiveComponent?.templateType ===
                            "clicktoreveal" &&
                            canvasActiveComponent?.componentType === "answerimage"
                            ? "border-2 border-dashed border-blue"
                            : ""
                          }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            canvasActiveComponent?.templateType ===
                            "clicktoreveal" &&
                            canvasActiveComponent?.componentType === "answerimage"
                          ) {
                            dispatch(setCanvasActiveComponent(null));
                          } else {
                            dispatch(
                              setCanvasActiveComponent({
                                templateType: "clicktoreveal",
                                componentType: "answerimage",
                              })
                            );
                          }
                        }}
                      >
                        {data.back?.imageFile ? (
                          <img
                            style={data.back.styles.image}
                            src={data.back?.imageFile?.url}
                            alt="image"
                            className="w-full h-full "
                          />
                        ) : (
                          <img
                            style={data.back.styles.image}
                            src={DefaultImage}
                            alt="image"
                            className="w-full h-full"
                          />
                        )}
                      </div>
                    )}
                </div>
              </div>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </div>
  );
}

export default ClickToRevealLayout;
