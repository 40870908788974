import React, { Suspense, useEffect, useState, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Command, CommandInput, CommandList, CommandItem, CommandGroup } from '../ui/command';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuItem } from '../ui/dropdown-menu';
import { Button } from '../ui/button';
import { useSelector } from "react-redux";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import Layout1 from "../../assets/Course/textwithimage/Layout1.png";
import Layout2 from "../../assets/Course/textwithimage/Layout2.png";
import Layout3 from "../../assets/Course/textwithimage/Layout3.png";
import { Input } from "../ui/input";
import Layout4 from "../../assets/Course/textwithimage/Layout4.png";
import { Separator } from "../ui/separator";
import { Slider } from "../ui/slider";
import { cn } from "../lib/utils";
import RichTextEditor from "src/utils/RichTE";
import UploadImage from "./UploadImage/UploadImage";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import { useDispatch } from "react-redux";
import { setSelectedCard } from "src/features/selectedCard/selectedCardSlice";
import {
  AccordionType,
  DefaultTemplateData,
  FlipCardType,
  Tab,
} from "./Templates/Authoring/utils/DefaultTemplateData";
import { v4 as uuid } from "uuid";
import Text from "src/utils/Text/Txt";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import DeleteIcon from "../../assets/Course/trash.png";
import ImageLeft from "../../assets/Course/clicktoreveal/leftImage.png";
import ImageRight from "../../assets/Course/clicktoreveal/rightImage.png";
import SimpleText from "../../assets/Course/clicktoreveal/simpleText.png";
import BackimageLeft from "../../assets/Course/clicktoreveal/backLeftImage.png";
import BackimageRight from "../../assets/Course/clicktoreveal/backRightImage.png";
import BackSimpleText from "../../assets/Course/clicktoreveal/backSimpleText.png";
import FrontSmallSimple from "../../assets/Course/clicktoreveal/frontSmallSimple.png";
import BackSmallSimple from "../../assets/Course/clicktoreveal/backSmallSimple.png";
import FrontSmallWithImage from "../../assets/Course/clicktoreveal/frontSmallWithImage.png";
import BackSmallWithImage from "../../assets/Course/clicktoreveal/backSmallWithImage.png";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { LayoutStyles } from "./LayoutSettings";
import { setSelectedAccordion } from "src/features/selectedAccordion/selectedAccordionSlice";
import { setSelectedFlipcard } from "src/features/selectedFlipcard/selectedFlipcardSlice";
import { setIsAddingHotspot } from "src/features/isAddingHotspot/isAddingHotspotSlice";

import FlipcardImageWithText from "../../assets/Course/flipcards/thumbfcimgtxtlayout.png";
import FlipcardAudioWithText from "../../assets/Course/flipcards/thumbfcaudiotxtlayout.png";
import FlipcardVideoWithText from "../../assets/Course/flipcards/thumbfcembedvidlayout.png";
import FlipcardOnlyText from "../../assets/Course/flipcards/thumbfctxtonlylayout.png";
import RemoveLogo from "../../assets/remove1.png";
import { setSelectedHotspot } from "src/features/selectedHotspot/selectedHotspotSlice";
import FillInTheBlanksContent from "./ContentSettings/Templates/FillInTheBlanks";
import { Switch } from "../ui/switch";
import { CanvasTool, LayersPanel, PropertiesPanel } from "./canvas";
import {
  setSelectedTemplate,
  setSelectedTool,
  setSelectedToolId,
} from "src/features/courseTemplate/courseTemplateSlice";
import { setSelectedTab } from "src/features/selectedTab/selectedTabSlice";
import { CircleSlash, LayoutList, List, ListTodo, PanelTop } from 'lucide-react'
import MatchingLogo from '../../assets/Course/templateRibbonIcons/matching.png';
import FlipcardLogo from '../../assets/Course/templateRibbonIcons/flipcard.png';
import DragAndDropLogo from '../../assets/Course/templateRibbonIcons/dragAndDrop.png';
import HotspotLogo from '../../assets/Course/templateRibbonIcons/hotspot.png';
import FillInTheBlanksLogo from '../../assets/Course/templateRibbonIcons/fillInTheBlanks.png';
import { Template } from "./TemplateRibbon";

const Scr = React.lazy(() => import("./StyleSettings/Templates/Scr"));
const Mcr = React.lazy(() => import("./StyleSettings/Templates/Mcr"));
const TrueFalse = React.lazy(
  () => import("./StyleSettings/Templates/TrueFalse")
);
const TextWImage = React.lazy(
  () => import("./StyleSettings/Templates/TextWImage")
);
const TabLoad = React.lazy(() => import("./StyleSettings/Templates/Tabs"));
const Accordion = React.lazy(
  () => import("./StyleSettings/Templates/Accordion")
);
const Flipcard = React.lazy(() => import("./StyleSettings/Templates/FlipCard"));
const Hotspot = React.lazy(() => import("./StyleSettings/Templates/Hotspot"));
const DragAndDrop = React.lazy(
  () => import("./StyleSettings/Templates/DragAndDrop")
);
const FillInTheBlanks = React.lazy(
  () => import("./StyleSettings/Templates/FillInTheBlanks")
);

// importing the audio settings here

const AudioSettings = React.lazy(() => import("./AudioSettings"));

const TemplatesArray = [
  {
      id: 1,
      title: "MCR",
      type: "mcr",
      description: "Multiple Choice Response",
      icon: <ListTodo size={24} />
  },
  {
      id: 2,
      title: "SCR",
      type: "scr",
      description: "Single Choice Response",
      icon: <List size={24} />
  },
  {
      id: 3,
      title: "True/False",
      type: "true/false",
      description: "True or False",
      icon: <CircleSlash size={24} />
  },
  {
      id: 4,
      title: "Text with Image",
      type: "textwithimage",
      description: "Text with Image",
      icon: <LayoutList size={24} />
  },
  {
      id: 7,
      title: "Drag And Drop",
      type: "draganddrop",
      description: "Drag and drop",
      icon: <span><img src={DragAndDropLogo} className='w-6 h-6' /></span>
  },
  {
      id: 8,
      title: "Accordion",
      type: "accordion",
      description: "Accordion",
      icon: <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-list-collapse"><path d="m3 10 2.5-2.5L3 5"/><path d="m3 19 2.5-2.5L3 14"/><path d="M10 6h11"/><path d="M10 12h11"/><path d="M10 18h11"/></svg></span>
  },
  {
      id: 9,
      title: "FlipCard",
      type: "flipcard",
      description: "Flip card to see back content",
      icon: <span><img src={FlipcardLogo} className='w-6 h-6' alt="" /></span>
  },
  {
      id: 10,
      title: "Fill In The Blanks",
      type: "fillintheblanks",
      description: "fillintheblanks",
      icon: <span><img src={FillInTheBlanksLogo} className='w-6 h-6' alt="" /></span>
  },
  {
      id: 11,
      title: "Hotspot",
      type: "hotspot",
      description: "Hotspot",
      icon: <span><img src={HotspotLogo} className='w-6 h-6' alt="" /></span>
  },
  {
      id: 12,
      title: "Matching",
      type: "matching",
      description: "Match the following",
      icon: <span><img src={MatchingLogo} className='w-6 h-6' alt="" /></span>
  },
]

type SettingProps = {
  templateData: any;
  topic: any;
  tools?: CanvasTool[];
  handleChange: (templateData: any) => void;
  handleToolDataChange?: (toolsData: any) => void;
  handleTabTemplate?: (template: any) => void;
};

const Settings = ({
  templateData,
  topic,
  tools,
  handleChange,
  handleToolDataChange,
  handleTabTemplate,
}: SettingProps) => {
  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );
  const { selectedCard } = useSelector((state: any) => state.selectedCard);
  const { selectedTab } = useSelector((state: any) => state.selectedTab);
  const { selectedAccordion } = useSelector(
    (state: any) => state.selectedAccordion
  );
  const { selectedFlipcard } = useSelector(
    (state: any) => state.selectedFlipcard
  );
  const { isAddingHotspot } = useSelector(
    (state: any) => state.isAddingHotspot
  );
  const { selectedHotspot } = useSelector(
    (state: any) => state.selectedHotspot
  );

  const { selectedTool } = useSelector((state: any) => state.courseTemplate);

  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useDispatch();

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    console.log(canvasActiveComponent);
  }, [canvasActiveComponent]);

  useEffect(() => {
    console.log("templateData", templateData);
  }, [templateData]);

  useEffect(() => {
    if (tools) console.log(tools);
  }, [tools]);

  useEffect(() => {
    console.log("selectedTool: ", selectedTool, selectedTool?.id);
  }, [selectedTool]);

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    console.log(selectedFlipcard?.layoutOption);
  }, [selectedFlipcard?.layoutOption]);

  useEffect(() => {
    console.log(isAddingHotspot);
  }, [isAddingHotspot]);

  // useEffect(() => {
  //   dispatch(setSelectedAccordion(templateData?.data?.accordions[0]));
  // }, []);

  const addAccordion = () => {
    if (templateData.data.accordions.length >= 4) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
      return;
    }

    const newAccordion: AccordionType = {
      _id: uuid(),
      title: `Accordion ${templateData.data.accordions.length + 1}`,
      content: {
        text: `<p style="text-align: left;"><strong><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 24px;">Add The Heading</span></span></span></span></strong></p>
        <p><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></span></span></span></p>`,
        image: null,
      },
    };
    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        accordions: [...templateData.data.accordions, newAccordion],
      },
    });
    dispatch(
      setSelectedAccordion({
        ...newAccordion,
        index: templateData.data.accordions.length,
      })
    );
  };

  const addFlipcard = () => {
    if (templateData.data.flipcards.length >= 4) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
      return;
    }

    const newFlipcard: any = {
      _id: uuid(),
      front: {
        text: `Title ${templateData.data.flipcards.length + 1}`,
        imageFile: null,
        styles: {
          // aboveContainer:{
          //     width: "100%",
          //     height: "100%",
          //     justifyContent: "flex-start",
          //     alignItems: "center",
          // },
          container: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
          textContainer: {
            width: "100%",
            height: "12%",
            paddingTop: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            objectFit: "contain",
            backgroundColor: "black",
            opacity: "0.4",
            color: "#ffffff",
            overflowY: "auto",
          },
          text: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            alignItems: "center",
          },
        },
      },
      back: {
        text: `<p><strong><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 12px;">Lorem ipsum dolor sit amet</span></span></span></span></strong></p>
<p><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 10px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 10px;">dolor sit amet, consectetur adipiscing elit. Suspendisse dapibus posuere ultricies. Nulla sit amet enim cursus, feugiat lacus id, varius orci. Nullam elementum lorem ac posuere elementum. Vestibulum quam enim, gravida et justo sed, pretium pulvinar mauris. Nam faucibus hendrerit accumsan.<br> Quisque et blandit enim, nec vulputate urna. Suspendisse convallis, purus finibus congue malesuada, neque elit bibendum ex, in tincidunt dolor ante id leo. Maecenas a erat elementum massa sagittis faucibus vitae sit amet arcu. Nullam vel mi et diam pellentesque sagittis. Aenean ac purus justo.</span></span></span></span></p>`,
        imageFile: null,
        videoUrl: "https://youtu.be/rT6GiYEIdGs?si=BTlMHzV1B0v1f32Q",
        audioUrl:
          "https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav",
        styles: {
          container: {
            width: "100%",
            height: "100%",
            backgroundColor: "#82c2de",
            paddingTop: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
          },
          textImageWrap: {
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "7px",
          },
          textContainer: {
            width: "100%",
            height: "60%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
            color: "#000000",
          },
          text: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // overflowY: "auto",
          },
          imageContainer: {
            width: "100%",
            height: "40%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
            // borderRadius: "10px",
            // backgroundColor: "#ffffff",
          },
          image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "cover",
            justifyContent: "center",
            alignItems: "flex-start",
            // borderRadius: "10px",
          },
        },
      },
      selectedFace: "Front",
      layoutOption: `flipcard-image-with-text`,
    };
    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        flipcards: [...templateData.data.flipcards, newFlipcard],
      },
    });
    dispatch(
      setSelectedFlipcard({
        ...newFlipcard,
        index: templateData.data.flipcards.length,
      })
    );
  };

  // Update the selected face ("front" or "back") for the selected flip card
  const handleFaceSelection = (value: string) => {
    const updatedFlipcards = templateData.data.flipcards.map((flipcard: any) =>
      flipcard._id === selectedFlipcard._id
        ? { ...flipcard, selectedFace: value }
        : flipcard
    );
    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        flipcards: updatedFlipcards,
      },
    });
    dispatch(setSelectedFlipcard({ ...selectedFlipcard, selectedFace: value }));
  };

  const addTab = () => {
    const newTab: Tab = {
      _id: uuid(),
      content: {
        templateType: "",
        templateData: {},
      },
      title: `Tab ${templateData.data.tabs.length + 1}`,
    };
    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        tabs: [...templateData.data.tabs, newTab],
      },
    });
    dispatch(setSelectedTab(newTab));
  };

  const deleteAccordion = () => {
    const updatedAccordions = templateData?.data?.accordions.filter(
      (accordion: any) => accordion?._id !== selectedAccordion?._id
    );

    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        accordions: updatedAccordions,
      },
    });

    dispatch(setSelectedAccordion(null));
  };

  const deleteFlipcard = () => {
    const updatedFlipcards = templateData?.data?.flipcards.filter(
      (flipcard: any) => flipcard?._id !== selectedFlipcard?._id
    );

    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        flipcards: updatedFlipcards,
      },
    });

    dispatch(setSelectedFlipcard(null));
  };

  const deleteHotspot = () => {
    if (selectedHotspot) {
      const updatedHotspots = templateData.data.hotspots?.filter(
        (hotspot: any) => hotspot?._id !== selectedHotspot?._id
      );

      handleChange({
        ...templateData,
        data: {
          ...templateData.data,
          hotspots: updatedHotspots,
        },
      });

      // if (updatedHotspots.length > 0) {
      //   dispatch(setSelectedHotspot(selectedHotspot[0]));
      // } else {
      dispatch(setSelectedHotspot(null));
      // }
    }
  };

  const deleteTab = () => {
    const currentTabs = templateData?.data?.tabs || [];
    const deletedTabIndex = currentTabs.findIndex(
      (tab: any) => tab?._id === selectedTab?._id
    );

    const updatedTabs = currentTabs.filter(
      (tab: any) => tab?._id !== selectedTab?._id
    );

    // Determine the next tab to select
    let newSelectedTab = null;
    if (updatedTabs.length > 0) {
      if (deletedTabIndex >= updatedTabs.length) {
        // Deleted the last tab, select the first tab
        newSelectedTab = updatedTabs[0];
      } else {
        // Select the next tab
        newSelectedTab = updatedTabs[deletedTabIndex] || updatedTabs[0];
      }
    }

    handleChange({
      ...templateData,
      data: {
        ...templateData.data,
        tabs: updatedTabs,
      },
    });

    dispatch(setSelectedTab(newSelectedTab));
  };

  useEffect(() => {
    console.log("selectedAccordion", selectedAccordion);
  }, [selectedAccordion]);

  useEffect(() => {
    console.log("selectedFlipcard", selectedFlipcard);
  }, [selectedFlipcard]);

  useEffect(() => {
    console.log("selectedFlipcardSelectedFace", selectedFlipcard?.selectedFace);
  }, [selectedFlipcard?.selectedFace]);

  async function handleUpdateTemplateData(key: string, value: any) {
    const keys = key.split(".");
    let updatedData = { ...templateData.data }; // Shallow copy of the top-level object

    let current = updatedData;

    for (let i = 0; i < keys.length - 1; i++) {
      const keyPart = keys[i];
      const arrayIndexMatch = keyPart.match(/^(\w+)\[(\d+)\]$/);

      if (arrayIndexMatch) {
        const arrayKey = arrayIndexMatch[1];
        const arrayIndex = parseInt(arrayIndexMatch[2], 10);

        if (!Array.isArray(current[arrayKey])) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist as an array in templateData`
          );
          return;
        }

        current[arrayKey] = [...current[arrayKey]]; // Shallow copy of the array
        current = current[arrayKey][arrayIndex] = {
          ...current[arrayKey][arrayIndex],
        }; // Shallow copy of the array element
      } else {
        if (current[keyPart] === undefined) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist in templateData`
          );
          return;
        }

        current[keyPart] = { ...current[keyPart] }; // Shallow copy of nested object
        current = current[keyPart];
      }
    }

    // Update the final property in the path
    const lastKey = keys[keys.length - 1];
    current[lastKey] = value;

    // If updating layoutOption for a flipcard, apply corresponding layoutStyles to back.styles
    if (key.includes("layoutOption")) {
      const layoutStyles = LayoutStyles.get(value);
      if (layoutStyles) {
        if (current.back) {
          current.back = {
            ...current.back,
            styles: { ...current.back.styles, ...layoutStyles },
          };
        } else {
          console.warn(
            "No 'back' property found on the current flipcard object."
          );
        }
        console.log(
          "Applied Layout Styles:",
          layoutStyles,
          current.back?.styles
        );
      } else {
        console.warn(`No styles found for layout option ${value}`);
      }
    }

    console.log("Updated Key:", key);
    console.log("Updated Value:", value);
    console.log("Updated Data:", updatedData);

    handleChange({
      ...templateData,
      data: updatedData,
    });
  }

  async function handleUpdateTemplateDataAccordion(key: string, value: any) {
    const keys = key.split(".");
    let updatedData = { ...templateData.data }; // Shallow copy of the top-level object
    let current = updatedData;

    // Traverse the key path to locate the target object
    for (let i = 0; i < keys.length - 1; i++) {
      const keyPart = keys[i];
      const arrayIndexMatch = keyPart.match(/^(\w+)\[(\d+)\]$/);

      if (arrayIndexMatch) {
        const arrayKey = arrayIndexMatch[1];
        const arrayIndex = parseInt(arrayIndexMatch[2], 10);

        if (!Array.isArray(current[arrayKey])) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist as an array in templateData`
          );
          return;
        }

        current[arrayKey] = [...current[arrayKey]]; // Shallow copy of the array
        current = current[arrayKey][arrayIndex] = {
          ...current[arrayKey][arrayIndex],
        }; // Shallow copy of the array element
      } else {
        if (current[keyPart] === undefined) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist in templateData`
          );
          return;
        }

        current[keyPart] = { ...current[keyPart] }; // Shallow copy of the nested object
        current = current[keyPart];
      }
    }

    // Update the final key with the new value
    current[keys[keys.length - 1]] = value;

    // Check if updating layout option, then apply new styles if necessary
    if (key === "layoutOption") {
      if (templateData.type === "flipcard") {
        const updatedFlipcards = updatedData.flipcards.map((flipcard: any) =>
          flipcard._id === selectedFlipcard._id
            ? { ...flipcard, layoutOption: value }
            : flipcard
        );

        dispatch(
          setSelectedFlipcard({
            ...selectedFlipcard,
            layoutOption: value,
          })
        );

        updatedData = {
          ...updatedData,
          flipcards: updatedFlipcards,
        };
      }

      if (templateData.type === "accordion") {
        const updatedAccordions = updatedData.accordions.map((accordion: any) =>
          accordion._id === selectedAccordion._id
            ? { ...accordion, layoutOption: value }
            : accordion
        );

        dispatch(
          setSelectedAccordion({
            ...selectedAccordion,
            layoutOption: value,
          })
        );

        updatedData = {
          ...updatedData,
          accordions: updatedAccordions,
        };
      }
    }

    console.log("Updated Key:", key);
    console.log("Updated Value:", value);
    console.log("Updated Data:", updatedData);

    handleChange({
      ...templateData,
      data: updatedData,
    });
  }

  // Update a tool's properties
  const updateTool = (
    id: string,
    newProperties: Partial<CanvasTool["properties"]>
  ) => {
    console.log(id, newProperties);
    if (tools && handleToolDataChange) {
      const updatedTools = tools.map((tool) => {
        if (tool.id === id) {
          const updatedTool = {
            ...tool,
            properties: { ...tool.properties, ...newProperties },
          };
          dispatch(setSelectedTool(updatedTool));
          return updatedTool;
        } else {
          return tool;
        }
      });
      // setTools(updatedTools);
      console.log(updatedTools);
      handleToolDataChange({
        ...topic,
        tools: { ...topic.tools, data: updatedTools },
      });
    }
  };

  // Delete a tool
  const deleteTool = (id: string) => {
    if (tools && handleToolDataChange) {
      const updatedTools = tools.filter((tool) => tool.id !== id);
      // setTools(updatedTools);
      // setSelectedToolId(null);
      dispatch(setSelectedTool(null));
      handleToolDataChange({
        ...topic,
        tools: { ...topic.tools, data: updatedTools },
      });
    }
  };

  const handleReorderTools = (newTools: CanvasTool[]) => {
    // setTools(newTools);
    if (handleToolDataChange)
      handleToolDataChange({
        ...topic,
        tools: { ...topic.tools, data: newTools },
      });
  };

  return (
    <div className="sidebar bg-[#EFEFEF] border-l border-gray-300 relative flex w-[20rem] flex-col overflow-y-scroll">
      <Tabs defaultValue="content" className="">
        {!(tools && tools.length > 0) ? (
          <TabsList className="">
            {(templateData.type === "textwithimage" ||
              templateData.type === "clicktoreveal" ||
              templateData.type === "flipcard" ||
              templateData.type === "accordion" ||
              templateData.type === "hotspot" ||
              templateData.type === "fillintheblanks" ||
              templateData.type === "tabs" ||
              selectedTab?.content?.templateType === "textwithimage") && (
              <TabsTrigger value="content">Content</TabsTrigger>
            )}
            <TabsTrigger value="style">Style</TabsTrigger>
            {templateData.type !== "hotspot" && (
              <TabsTrigger value="layout">Layout</TabsTrigger>
            )}
            <TabsTrigger value="audio">Audio</TabsTrigger>

            {(templateData.type === "scr" ||
              templateData.type === "mcr" ||
              templateData.type === "true/false" ||
              templateData.type === "tabs" ||
              templateData.type === "fillintheblanks" ||
              templateData.type === "tabs" ||
              templateData.type === "matching") && (
              <TabsTrigger value="grading">Grading</TabsTrigger>
            )}
          </TabsList>
        ) : (
          <TabsList>
            <TabsTrigger value="layers">Layers</TabsTrigger>
            <TabsTrigger value="properties">Properties</TabsTrigger>
          </TabsList>
        )}

        {templateData.length === 0 ? (
          <>
            <TabsContent value="layers">
                <LayersPanel
                  tools={tools || []}
                  selectedTool={selectedTool?.id || ""}
                  onSelectTool={(id) => {
                    const selectedTool = (tools && tools.length > 0 && tools.find(tool => tool.id === id)) || null
                    dispatch(setSelectedTool(selectedTool))
                  }}
                  onDeleteTool={deleteTool}
                  onReorderTools={handleReorderTools}
                />
              </TabsContent>
              <TabsContent value="properties">
                <PropertiesPanel
                  selectedTool={selectedTool}
                  onUpdateTool={updateTool}
                  topic={topic}
                />
              </TabsContent>
          </>
        ) : (
          <>
            {templateData.type === "scr" && (
              <>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Scr
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3"></TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="grading">
                  <div className="p-3 flex flex-col space-y-3">
                    <div className="flex items-start justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                      <span>Graded</span>
                      <Switch
                        checked={templateData.data?.haveGraded}
                        onCheckedChange={() => {
                          const newValue = !templateData.data?.haveGraded;

                          const updatedData = {
                            ...templateData.data,
                            haveGraded: newValue,
                          };

                          console.log(updatedData);
                          handleChange({
                            ...templateData,
                            data: updatedData,
                          });
                        }}
                      />
                    </div>
                    {/* <div className="flex items-center justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                  <div className="font-[500] text-[1.125rem] leading-normal">
                    Score
                  </div>
                  <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                    <input
                      type="number"
                      placeholder="Enter score"
                      value={templateData.data?.score || 0}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        const updatedScore = e.target.value;

                        handleChange({
                          ...templateData,
                          data: {
                            ...templateData.data,
                            score: updatedScore,
                          },
                        });
                      }}
                      className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                    />
                  </div>
                </div> */}
                  </div>
                </TabsContent>
              </>
            )}

            {templateData.type === "mcr" && (
              <>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Mcr
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3"></TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="grading">
                  <div className="p-3 flex flex-col space-y-3">
                    <div className="flex items-start justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                      <span>Graded</span>
                      <Switch
                        checked={templateData.data?.haveGraded}
                        onCheckedChange={() => {
                          const newValue = !templateData.data?.haveGraded;

                          const updatedData = {
                            ...templateData.data,
                            haveGraded: newValue,
                          };

                          console.log(updatedData);
                          handleChange({
                            ...templateData,
                            data: updatedData,
                          });
                        }}
                      />
                    </div>
                    {/* <div className="flex items-center justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                  <div className="font-[500] text-[1.125rem] leading-normal">
                    Score
                  </div>
                  <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                    <input
                      type="number"
                      placeholder="Enter score"
                      value={templateData.data?.score || 0}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        const updatedScore = e.target.value;

                        handleChange({
                          ...templateData,
                          data: {
                            ...templateData.data,
                            score: updatedScore,
                          },
                        });
                      }}
                      className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                    />
                  </div>
                </div> */}
                  </div>
                </TabsContent>
              </>
            )}

            {templateData.type === "true/false" && (
              <>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <TrueFalse
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3"></TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="grading">
                  <div className="p-3 flex flex-col space-y-3">
                    <div className="flex items-start justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                      <span>Graded</span>
                      <Switch
                        checked={templateData.data?.haveGraded}
                        onCheckedChange={() => {
                          const newValue = !templateData.data?.haveGraded;

                          const updatedData = {
                            ...templateData.data,
                            haveGraded: newValue,
                          };

                          console.log(updatedData);
                          handleChange({
                            ...templateData,
                            data: updatedData,
                          });
                        }}
                      />
                    </div>
                    {/* <div className="flex items-center justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                  <div className="font-[500] text-[1.125rem] leading-normal">
                    Score
                  </div>
                  <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                    <input
                      type="number"
                      placeholder="Enter score"
                      value={templateData.data?.score || 0}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        const updatedScore = e.target.value;

                        handleChange({
                          ...templateData,
                          data: {
                            ...templateData.data,
                            score: updatedScore,
                          },
                        });
                      }}
                      className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                    />
                  </div>
                </div> */}
                  </div>
                </TabsContent>
              </>
            )}

            {templateData.type === "textwithimage" && (
              <>
                <TabsContent value="content">
                  <div className="flex flex-grow">
                    <div className="space-y-6 w-full">
                      <div className="flex flex-col">
                        <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4">
                          <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Logo</span>
                            <span className="text-[1rem] text-gray-400">
                              (optional)
                            </span>
                            <span>
                              <button
                                onClick={() => {
                                  const newValue =
                                    !templateData.data?.haveImage;

                                  let updatedData = { ...templateData.data };
                                  if (newValue) {
                                    updatedData = {
                                      ...templateData.data,
                                      haveImage: newValue,
                                    };
                                  } else {
                                    const { logoImageFile, ...rest } =
                                      templateData.data;
                                    updatedData = {
                                      ...rest,
                                      haveImage: newValue,
                                    };
                                  }

                                  console.log(updatedData);
                                  handleChange({
                                    ...templateData,
                                    data: updatedData,
                                  });
                                }}
                                className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${
                                  templateData.data?.haveImage
                                    ? "bg-[#5934ff]"
                                    : "bg-gray-400"
                                }`}
                              >
                                <div
                                  className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${
                                    templateData.data?.haveImage
                                      ? "transform translate-x-[18px]"
                                      : ""
                                  }`}
                                ></div>
                              </button>
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              const existingComponent =
                                templateData.data?.componentProperties?.find(
                                  (comp: any) => comp.type === "Image"
                                );
                              if (existingComponent) {
                                dispatch(
                                  setSelectedComponent({
                                    type: existingComponent.type,
                                    maxLength: existingComponent.maxLength,
                                    zone: existingComponent.zone,
                                  })
                                );
                              }
                            }}
                            className="rounded-md border-2 border-gray-300"
                          >
                            {templateData.data?.haveImage && (
                              <div className="flex space-x-5 items-start">
                                <UploadImage
                                  name="logoImageFile"
                                  type="image"
                                  image={templateData.data?.logoImageFile}
                                  handleUploadFromDevice={(file) => {
                                    console.log(file);
                                    handleChange({
                                      ...templateData,
                                      data: {
                                        ...templateData.data,
                                        logoImageFile: file,
                                      },
                                    });
                                  }}
                                  handleUploadFromMedia={(file) => {
                                    console.log(file);
                                    handleChange({
                                      ...templateData,
                                      data: {
                                        ...templateData.data,
                                        logoImageFile: file,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4 w-full">
                          <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Main Image</span>
                          </div>
                          <div
                            onClick={() => {
                              const existingComponent =
                                templateData.data?.componentProperties?.find(
                                  (comp: any) => comp.type === "Image"
                                );
                              if (existingComponent) {
                                dispatch(
                                  setSelectedComponent({
                                    type: existingComponent.type,
                                    maxLength: existingComponent.maxLength,
                                    zone: existingComponent.zone,
                                  })
                                );
                              }
                            }}
                            className="rounded-md border-2 border-gray-300"
                          >
                            <div className="flex space-x-5 items-start">
                              <UploadImage
                                name="imageFile"
                                type="image"
                                image={templateData.data?.imageFile}
                                handleUploadFromDevice={(file) => {
                                  console.log(file);
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      imageFile: file,
                                    },
                                  });
                                }}
                                handleUploadFromMedia={(file) => {
                                  console.log(file);
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      imageFile: file,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full">
                        {/* <RichTextEditor
                        placeholder="Enter Acknowledgement Text"
                        value={templateData.data.text}
                        onChange={(content, contentLength) => {
                          handleChange({
                            ...templateData,
                            data: {
                              ...templateData.data,
                              text: content,
                            }
                          });
                        }}
                      /> */}

                        <Editor
                          apiKey="iiet6dxb5byz1gk6b3jkzloisyg5vvwodaoxllymfx6o01op"
                          onInit={(_evt, editor) =>
                            (editorRef.current = editor)
                          }
                          initialValue={templateData.data.text}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          onChange={(e) => {
                            handleChange({
                              ...templateData,
                              data: {
                                ...templateData.data,
                                text: e.target.getContent(),
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </TabsContent>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <TextWImage
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3">
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "layoutOption",
                        "text-left-image-right"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${
                        templateData.data.layoutOption ===
                          "text-left-image-right" &&
                        "border-blue border-[3px] rounded-md"
                      }`}
                      src={Layout1}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "layoutOption",
                        "text-right-image-left"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${
                        templateData.data.layoutOption ===
                          "text-right-image-left" &&
                        "border-blue border-[3px] rounded-md"
                      }`}
                      src={Layout4}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData("layoutOption", "only-text")
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${
                        templateData.data.layoutOption === "only-text" &&
                        "border-blue border-[3px] rounded-md"
                      }`}
                      src={Layout2}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "layoutOption",
                        "text-image-centered"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${
                        templateData.data.layoutOption ===
                          "text-image-centered" &&
                        "border-blue border-[3px] rounded-md"
                      }`}
                      src={Layout3}
                      alt="image"
                    />
                  </div>
                </TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
              </>
            )}

            {templateData.type === "tabs" && (
              <>
                <TabsContent value="content">
                  <div className="flex flex-grow">
                    <div className="flex flex-col w-full space-y-[1.25rem] p-3">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={addTab}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Add New Tab</span>
                          <span className="text-md">+</span>
                        </button>
                        {/* {showTooltip && (
                            <div className="absolute top-[0.69rem] left-0 bg-gray-800/70 text-white text-sm px-2 py-1 rounded shadow-lg">
                              Cannot add more than 4 Tabs
                            </div>
                          )} */}
                        <button
                          onClick={deleteTab}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Delete Tab</span>
                          <span>
                            <img src={DeleteIcon} alt="delete" />
                          </span>
                        </button>
                      </div>

                      {selectedTab && (
                        <>
                          <div className="flex flex-col items-start space-y-4 w-full">
                            <div className="font-[500] text-[1.125rem] leading-normal">
                              Tab Title
                            </div>
                            <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                              <input
                                type="text"
                                placeholder="Enter title"
                                value={selectedTab.title}
                                onChange={(e) => {
                                  const updatedTitle = e.target.value;
                                  const updatedTabs =
                                    templateData.data.tabs.map((tab: any) =>
                                      tab._id === selectedTab._id
                                        ? { ...tab, title: updatedTitle }
                                        : tab
                                    );

                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      tabs: updatedTabs,
                                    },
                                  });
                                  dispatch(
                                    setSelectedTab({
                                      ...selectedTab,
                                      title: updatedTitle,
                                    })
                                  );
                                }}
                                className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                              />
                            </div>
                          </div>

                          <div className="font-[500] text-[1.125rem] leading-normal">
                            Tab Content
                          </div>

                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="outline">Choose Template</Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-64">
                              <Command>
                                <CommandInput placeholder="Search..." />
                                <CommandList>
                                  <CommandGroup heading="Templates">
                                  {TemplatesArray.map((template) => (
                                    <CommandItem asChild key={template.id}>
                                      <button
                                        key={template.id}
                                        className="w-full flex items-center space-x-4 text-left px-2 py-1 hover:bg-gray-300 rounded-md"
                                        onClick={() => {
                                          const updatedTemplate = { ...template, id: new Date().getTime()};
                                            const updatedTabs =
                                              templateData.data.tabs.map((tab: any) =>
                                                tab._id === selectedTab._id
                                                  ? { ...tab, content: { ...tab.content, templateId: updatedTemplate.id } }
                                                  : tab
                                              );
          
                                            handleChange({
                                              ...templateData,
                                              data: {
                                                ...templateData.data,
                                                tabs: updatedTabs,
                                              },
                                            });
                                            dispatch(
                                              setSelectedTab({
                                                ...selectedTab,
                                                content: { 
                                                  ...selectedTab.content, 
                                                  templateId: updatedTemplate.id 
                                                }
                                              })
                                            );

                                            if(handleTabTemplate) handleTabTemplate(updatedTemplate);
                                          
                                        }}
                                      >
                                        {template.icon}
                                        <span>{template.title}</span>
                                      </button>
                                    </CommandItem>
                                  ))}
                                  </CommandGroup>
                                </CommandList>
                              </Command>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </>
                      )}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <TabLoad
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>

                <TabsContent value="layout" className="space-y-3 p-3">
                  <div className="flex justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Tabs direction
                    </div>
                    <Select
                      value={
                        templateData.data.layoutOption === "horizontal-tab-top"
                          ? "Horizontal"
                          : templateData.data.layoutOption ===
                            "vertical-tab-left"
                          ? "Vertical"
                          : undefined
                      }
                      onValueChange={(value: string) => {
                        const newValue =
                          value === "Horizontal"
                            ? "horizontal-tab-top"
                            : "vertical-tab-left";
                        handleUpdateTemplateData("layoutOption", newValue);
                      }}
                    >
                      <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Position" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Horizontal">Horizontal</SelectItem>
                        <SelectItem value="Vertical">Vertical</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Tabs position
                    </div>

                    <Select
                      value={
                        templateData.data.styles.tabButton?.justifyContent ===
                        "center"
                          ? "Center"
                          : templateData.data.styles.tabButton
                              ?.justifyContent === "flex-start"
                          ? "Left"
                          : templateData.data.styles.tabButton
                              ?.justifyContent === "flex-end"
                          ? "Right"
                          : undefined
                      }
                      onValueChange={(value: string) => {
                        const newValue =
                          value === "Center"
                            ? "center"
                            : value === "Left"
                            ? "flex-start"
                            : "flex-end";
                        handleUpdateTemplateData(
                          "styles.tabButton.justifyContent",
                          newValue
                        );
                      }}
                    >
                      <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Position" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Left">Left</SelectItem>
                        <SelectItem value="Right">Right</SelectItem>
                        <SelectItem value="Center">Center</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-left w-full text-sm">Tabs width</div>
                    <div className="flex items-center space-x-2">
                      <Input
                        type="number"
                        value={parseInt(
                          templateData?.data?.styles?.tabButton?.width
                        )}
                        onChange={(e) => {
                          handleUpdateTemplateData(
                            "styles.tabButton.width",
                            `${e.target.value}${
                              templateData?.data?.styles?.tabButton?.width?.includes(
                                "%"
                              )
                                ? "%"
                                : "px"
                            }`
                          );
                        }}
                        className="w-[5rem] px-2 py-1 rounded-md"
                      />
                      <select
                        value={
                          templateData?.data?.styles?.tabButton?.width?.includes(
                            "%"
                          )
                            ? "%"
                            : "px"
                        }
                        onChange={(e) => {
                          const newValue = `${parseInt(
                            templateData?.data?.styles?.tabButton?.width
                          )}${e.target.value}`;
                          handleUpdateTemplateData(
                            "styles.tabButton.width",
                            newValue
                          );
                        }}
                        className="w-[4rem] px-2 py-1 rounded-md text-xs"
                      >
                        <option value="px">px</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-left w-full text-sm">Tabs height</div>
                    <div className="flex items-center space-x-2">
                      <Input
                        type="number"
                        value={parseInt(
                          templateData?.data?.styles?.tabButton?.height
                        )}
                        onChange={(e) => {
                          handleUpdateTemplateData(
                            "styles.tabButton.height",
                            `${e.target.value}${
                              templateData?.data?.styles?.tabButton?.height?.includes(
                                "%"
                              )
                                ? "%"
                                : "px"
                            }`
                          );
                        }}
                        className="w-[5rem] px-2 py-1 rounded-md"
                      />
                      <select
                        value={
                          templateData?.data?.styles?.tabButton?.height?.includes(
                            "%"
                          )
                            ? "%"
                            : "px"
                        }
                        onChange={(e) => {
                          const newValue = `${parseInt(
                            templateData?.data?.styles?.tabButton?.height
                          )}${e.target.value}`;
                          handleUpdateTemplateData(
                            "styles.tabButton.height",
                            newValue
                          );
                        }}
                        className="w-[4rem] px-2 py-1 rounded-md text-xs"
                      >
                        <option value="px">px</option>
                        <option value="%">%</option>
                      </select>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
              </>
            )}

            {templateData.type === "clicktoreveal" && (
              <>
                {/* <TabsContent value="content">
              <div className="flex flex-grow">
                <div className="flex flex-col space-y-[1.25rem] p-3">
                  <div className="flex w-full justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Select front/back
                    </div>
                    <Select
                      value={
                        selectedCard === "front"
                          ? "Front"
                          : "Back"
                      }
                      onValueChange={(value: string) => {
                        const newValue = value === "Front" ? "front" : "back";
                        dispatch(setCanvasActiveComponent(null));
                        dispatch(setSelectedCard(newValue));
                      }}
                    >
                      <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Position" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Front">Front</SelectItem>
                        <SelectItem value="Back">Back</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex w-full justify-between items-center">
                    <div className="text-left w-full text-sm">Transition</div>
                    <Select
                      value={
                        templateData.data.transition === "horizontal"
                          ? "Horizontal"
                          : "Vertical"
                      }
                      onValueChange={(value: string) => {
                        const newValue =
                          value === "Horizontal"
                            ? "horizontal"
                            : "vertical";
                        handleUpdateTemplateData("transition", newValue);
                      }}
                    >
                      <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Select Transition" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Horizontal">
                          Horizontal flip
                        </SelectItem>
                        <SelectItem value="Vertical">Vertical flip</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {selectedCard === "back" ? (
                    <>
                      <div className="flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                          <span>Image</span>
                        </div>
                        <div className="rounded-md border-2 border-gray-300">
                          <div className="flex space-x-5 items-start">
                            <UploadImage
                              name="imageFile"
                              type="image"
                              image={templateData.data?.back?.imageFile}
                              handleUploadFromDevice={(file) => {
                                console.log(file);
                                handleChange({
                                  ...templateData,
                                  data: {
                                    ...templateData.data,
                                    back: {
                                      ...templateData.data.back,
                                      imageFile: file,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Answer
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData?.data?.back?.answer}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  back: {
                                    ...templateData.data.back,
                                    answer: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                          <span>Image</span>
                        </div>
                        <div className="rounded-md border-2 border-gray-300">
                          <div className="flex space-x-5 items-start">
                            <UploadImage
                              name="imageFile"
                              type="image"
                              image={templateData.data?.front?.imageFile}
                              handleUploadFromDevice={(file) => {
                                console.log(file);
                                handleChange({
                                  ...templateData,
                                  data: {
                                    ...templateData.data,
                                    front: {
                                      ...templateData.data.front,
                                      imageFile: file,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Prompt
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData.data.front.prompt}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  front: {
                                    ...templateData.data.front,
                                    prompt: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Question
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData.data.front.question}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  front: {
                                    ...templateData.data.front,
                                    question: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </TabsContent> */}

                {/* <TabsContent value="style">
              <div className="p-3 space-y-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    {templateData.title}
                  </h3>
                </div>

                {selectedCard === "back" ? (
                  <>
                    {canvasActiveComponent?.componentType === "wholeBack" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Container
                        </div>
                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Width</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.parentContainer
                                  .width
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.width",
                                  `${e.target.value}${templateData.data.back.styles.parentContainer.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.parentContainer.width.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.parentContainer
                                    .width
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.width",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <div className="text-left w-full text-sm">Height</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.parentContainer
                                  .height
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.height",
                                  `${e.target.value}${templateData.data.back.styles.parentContainer.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.parentContainer.height.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.parentContainer
                                    .height
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.height",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div className="text-left w-full text-sm">
                            Padding
                          </div>
                          <div className="flex flex-row flex-wrap text-xs">
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingTop">Top</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingTop
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingTop",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingTop.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingTop
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingTop",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingRight">Right</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingRight
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingRight",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingRight.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingRight
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingRight",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingBottom">Bottom</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingBottom
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingBottom",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingBottom.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingBottom
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingBottom",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingLeft">Left</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingLeft
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingLeft",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingLeft.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingLeft
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingLeft",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Gap</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.container.gap
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.container.gap",
                                  `${e.target.value}${templateData.data.back.styles.container.gap.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.container.gap.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.container.gap
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.container.gap",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex py-1 w-full justify-between items-center">
                          <label htmlFor="paddingLeft">Rounded Corners</label>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.container
                                  .borderRadius
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.container.borderRadius",
                                  `${e.target.value}${templateData.data.back.styles.container.borderRadius.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.container.borderRadius.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.container
                                    .borderRadius
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.container.borderRadius",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                          <div className="text-left w-full text-sm ">
                            Background Color
                          </div>
                          <input
                            value={
                              templateData.data.back.styles.container
                                .backgroundColor
                            }
                            onChange={(e) => {
                              console.log(e.target.value);
                              handleUpdateTemplateData(
                                "back.styles.container.backgroundColor",
                                e.target.value
                              );
                            }}
                            type="color"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    )}

                    {canvasActiveComponent?.componentType ===
                      "answerImageWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Answer Image Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.answerImageWrap
                                      .gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.answerImageWrap.gap",
                                      `${e.target.value}${templateData.data.back.styles.answerImageWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.answerImageWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles
                                        .answerImageWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.answerImageWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {canvasActiveComponent?.componentType === "answerLabel" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Answer Label
                        </div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.answerLabel
                                    .width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.width",
                                    `${e.target.value}${templateData.data.back.styles.answerLabel.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.answerLabel.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.answerLabel
                                      .width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.answerLabel
                                    .height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.height",
                                    `${e.target.value}${templateData.data.back.styles.answerLabel.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.answerLabel.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.answerLabel
                                      .height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.answerLabel
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.answerLabel
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.answerLabel
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.answerLabel.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.answerLabel
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.answerLabel
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.answerLabel
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.answerLabel.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingTop",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingRight",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingBottom",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingLeft",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "answer" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Text</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.text.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.text.width",
                                    `${e.target.value}${templateData.data.back.styles.text.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.text.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.text.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.text.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.text.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.text.height",
                                    `${e.target.value}${templateData.data.back.styles.text.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.text.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.text.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.text.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.text
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.text
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.text
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.text.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.text
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.text
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.text
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.text.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingTop",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingRight",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingBottom",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingLeft",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                const newValue =
                                  templateData.data.back.styles.text
                                    .overflowY === "auto"
                                    ? "hidden"
                                    : "auto";
                                handleUpdateTemplateData(
                                  "back.styles.text.overflowY",
                                  newValue
                                );
                              }}
                              className="text-left w-full text-sm"
                            >
                              {templateData.data.back.styles.text.overflowY ===
                                "auto"
                                ? "Enable"
                                : "Disable"}{" "}
                              Scroll
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "answerimage" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Image</div>
                        <div className="space-y-3 w-full">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.image.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.image.width",
                                    `${e.target.value}${templateData.data.back.styles.image.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.image.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.image.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.image.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.image.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.image.height",
                                    `${e.target.value}${templateData.data.back.styles.image.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.image.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.image.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.image.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.imageContainer
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.imageContainer
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.imageContainer
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.imageContainer.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.imageContainer
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.imageContainer
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.imageContainer
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.imageContainer.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Object Fit
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.image
                                  .objectFit === "contain"
                                  ? "Contain"
                                  : templateData.data.back.styles.image
                                    .objectFit === "cover"
                                    ? "Cover"
                                    : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Contain" ? "contain" : "cover";
                                handleUpdateTemplateData(
                                  "back.styles.image.objectFit",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {canvasActiveComponent?.componentType === "wholeFront" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Container
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Width</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.parentContainer
                                  .width
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.width",
                                  `${e.target.value}${templateData.data.front.styles.parentContainer.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.parentContainer.width.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.parentContainer
                                    .width
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.width",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <div className="text-left w-full text-sm">Height</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.parentContainer
                                  .height
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.height",
                                  `${e.target.value}${templateData.data.front.styles.parentContainer.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.parentContainer.height.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.parentContainer
                                    .height
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.height",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div className="text-left w-full text-sm">
                            Padding
                          </div>
                          <div className="flex flex-row flex-wrap text-xs">
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingTop">Top</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingTop
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingTop",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingTop.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingTop
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingTop",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingRight">Right</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingRight
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingRight",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingRight.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingRight
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingRight",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingBottom">Bottom</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingBottom
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingBottom",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingBottom.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingBottom
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingBottom",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingLeft">Left</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingLeft
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingLeft",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingLeft.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingLeft
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingLeft",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Gap</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.container.gap
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.container.gap",
                                  `${e.target.value}${templateData.data.front.styles.container.gap.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.container.gap.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.container.gap
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.container.gap",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex py-1 w-full justify-between items-center">
                          <label htmlFor="paddingLeft">Rounded Corners</label>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.container
                                  .borderRadius
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.container.borderRadius",
                                  `${e.target.value}${templateData.data.front.styles.container.borderRadius.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.container.borderRadius.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.container
                                    .borderRadius
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.container.borderRadius",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                          <div className="text-left w-full text-sm ">
                            Background Color
                          </div>
                          <input
                            value={
                              templateData.data.front.styles.container
                                .backgroundColor
                            }
                            onChange={(e) => {
                              console.log(e.target.value);
                              handleUpdateTemplateData(
                                "front.styles.container.backgroundColor",
                                e.target.value
                              );
                            }}
                            type="color"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    )}

                    {canvasActiveComponent?.componentType ===
                      "questionImageWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Question Image Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles
                                      .questionImageWrap.gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionImageWrap.gap",
                                      `${e.target.value}${templateData.data.front.styles.questionImageWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionImageWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles
                                        .questionImageWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionImageWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {canvasActiveComponent?.componentType ===
                      "promptLabelWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Prompt Label Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.promptLabelWrap
                                      .gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.promptLabelWrap.gap",
                                      `${e.target.value}${templateData.data.front.styles.promptLabelWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.promptLabelWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles
                                        .promptLabelWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.promptLabelWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {canvasActiveComponent?.componentType === "prompt" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Prompt</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.prompt.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.width",
                                    `${e.target.value}${templateData.data.front.styles.prompt.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.prompt.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.prompt.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.prompt.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.height",
                                    `${e.target.value}${templateData.data.front.styles.prompt.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.prompt.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.prompt.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.prompt
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles.prompt
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles.prompt
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.prompt.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.prompt
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles.prompt
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles.prompt
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.prompt.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingTop",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingRight",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingBottom",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingLeft",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType ===
                      "questionLabel" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Question Label
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">
                                Width
                              </div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.questionLabel
                                      .width
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.width",
                                      `${e.target.value}${templateData.data.front.styles.questionLabel.width.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionLabel.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.questionLabel
                                        .width
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.width",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Height
                              </div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.questionLabel
                                      .height
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.height",
                                      `${e.target.value}${templateData.data.front.styles.questionLabel.height.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionLabel.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.questionLabel
                                        .height
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.height",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Horizontal Alignment
                              </div>
                              <Select
                                value={
                                  templateData.data.front.styles.questionLabel
                                    .justifyContent === "center"
                                    ? "Center"
                                    : templateData.data.front.styles.questionLabel
                                      .justifyContent === "flex-start"
                                      ? "Left"
                                      : templateData.data.front.styles.questionLabel
                                        .justifyContent === "flex-end"
                                        ? "Right"
                                        : undefined
                                }
                                onValueChange={(value: string) => {
                                  const newValue =
                                    value === "Center"
                                      ? "center"
                                      : value === "Left"
                                        ? "flex-start"
                                        : "flex-end";
                                  handleUpdateTemplateData(
                                    "front.styles.questionLabel.justifyContent",
                                    newValue
                                  );
                                }}
                              >
                                <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className="flex justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Vertical Alignment
                              </div>
                              <Select
                                value={
                                  templateData.data.front.styles.questionLabel
                                    .alignItems === "center"
                                    ? "Middle"
                                    : templateData.data.front.styles.questionLabel
                                      .alignItems === "flex-start"
                                      ? "Top"
                                      : templateData.data.front.styles.questionLabel
                                        .alignItems === "flex-end"
                                        ? "Bottom"
                                        : undefined
                                }
                                onValueChange={(value: string) => {
                                  const newValue =
                                    value === "Middle"
                                      ? "center"
                                      : value === "Top"
                                        ? "flex-start"
                                        : "flex-end";
                                  handleUpdateTemplateData(
                                    "front.styles.questionLabel.alignItems",
                                    newValue
                                  );
                                }}
                              >
                                <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div className="text-left w-full text-sm">
                                Padding
                              </div>
                              <div className="flex flex-row flex-wrap text-xs">
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingTop
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingTop",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingTop.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingTop
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingTop",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingRight
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingRight",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingRight.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingRight
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingRight",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingBottom
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingBottom",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingBottom.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingBottom
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingBottom",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingLeft
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingLeft",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingLeft.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingLeft
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingLeft",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {canvasActiveComponent?.componentType === "question" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Text</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.text.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.text.width",
                                    `${e.target.value}${templateData.data.front.styles.text.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.text.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.text.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.text.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.text.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.text.height",
                                    `${e.target.value}${templateData.data.front.styles.text.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.text.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.text.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.text.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.text
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles.text
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles.text
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.text.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.text
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles.text
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles.text
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.text.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingTop",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingRight",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingBottom",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingLeft",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                const newValue =
                                  templateData.data.front.styles.text
                                    .overflowY === "auto"
                                    ? "hidden"
                                    : "auto";
                                handleUpdateTemplateData(
                                  "front.styles.text.overflowY",
                                  newValue
                                );
                              }}
                              className="text-left w-full text-sm"
                            >
                              {templateData.data.front.styles.text.overflowY ===
                                "auto"
                                ? "Enable"
                                : "Disable"}{" "}
                              Scroll
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "image" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Image</div>
                        <div className="space-y-3 w-full">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.image.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.image.width",
                                    `${e.target.value}${templateData.data.front.styles.image.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.image.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.image.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.image.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.image.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.image.height",
                                    `${e.target.value}${templateData.data.front.styles.image.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.image.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.image.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.image.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.imageContainer
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles
                                    .imageContainer.justifyContent ===
                                    "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles
                                      .imageContainer.justifyContent ===
                                      "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.imageContainer.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.imageContainer
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles
                                    .imageContainer.alignItems ===
                                    "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles
                                      .imageContainer.alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.imageContainer.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Object Fit
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.image
                                  .objectFit === "contain"
                                  ? "Contain"
                                  : templateData.data.front.styles.image
                                    .objectFit === "cover"
                                    ? "Cover"
                                    : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Contain" ? "contain" : "cover";
                                handleUpdateTemplateData(
                                  "front.styles.image.objectFit",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {canvasActiveComponent?.componentType === "globalBG" && (
                  <div className="flex flex-col space-y-3 justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Outer Container
                    </div>

                    <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                      <div className="text-left w-full text-sm ">
                        Background Color
                      </div>
                      <input
                        value={templateData.data.styles.global.backgroundColor}
                        onChange={(e) => {
                          console.log(e.target.value);
                          handleUpdateTemplateData(
                            "styles.global.backgroundColor",
                            e.target.value
                          );
                        }}
                        type="color"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </TabsContent> */}

                {/* <TabsContent value="layout" className="space-y-3">
              {selectedCard === "back" ? (
                <>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-left-image-right"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-left-image-right" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackimageRight}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-right-image-left"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-right-image-left" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackimageLeft}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-only-text"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-only-text" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSimpleText}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-only-text-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-only-text-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSmallSimple}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-image-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-image-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSmallWithImage}
                      alt="image"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-left-image-right"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-left-image-right" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={ImageRight}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-right-image-left"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-right-image-left" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={ImageLeft}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-only-text"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-only-text" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={SimpleText}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-only-text-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-only-text-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={FrontSmallSimple}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-image-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-image-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={FrontSmallWithImage}
                      alt="image"
                    />
                  </div>
                </>
              )}
            </TabsContent> */}
              </>
            )}

            {templateData.type === "flipcard" && (
              <>
                <TabsContent value="content">
                  <div className="flex flex-grow w-full">
                    <div className="flex flex-col w-full space-y-[1.25rem] p-3">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={addFlipcard}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Add New card</span>
                          <span className="text-md">+</span>
                        </button>
                        {showTooltip && (
                          <div className="absolute top-[0.69rem] left-0 bg-gray-800/70 text-white text-sm px-2 py-1 rounded shadow-lg">
                            Cannot add more than 4 flipcards
                          </div>
                        )}

                        <button
                          onClick={deleteFlipcard}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Delete Card</span>
                          <span>
                            <img src={DeleteIcon} alt="delete" />
                          </span>
                        </button>
                      </div>

                      {selectedFlipcard && (
                        <>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Select front/back
                            </div>
                            <Select
                              value={selectedFlipcard.selectedFace}
                              onValueChange={(value: string) => {
                                handleFaceSelection(value);
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Front">Front</SelectItem>
                                <SelectItem value="Back">Back</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Transition
                            </div>
                            <Select
                              value={
                                templateData.data.transition === "horizontal"
                                  ? "Horizontal"
                                  : "Vertical"
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Horizontal"
                                    ? "horizontal"
                                    : "vertical";
                                handleUpdateTemplateData(
                                  "transition",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Select Transition" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Horizontal">
                                  Horizontal flip
                                </SelectItem>
                                <SelectItem value="Vertical">
                                  Vertical flip
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          {selectedFlipcard.selectedFace === "Back" ? (
                            <>
                              {templateData.data.flipcards.filter(
                                (flipcard: any) =>
                                  flipcard._id === selectedFlipcard._id
                              )[0]?.layoutOption ===
                                "flipcard-image-with-text" && (
                                <div className="flex flex-col items-start space-y-4 w-full">
                                  <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                                    <span>Image</span>
                                  </div>
                                  <div className="rounded-md border-2 border-gray-300">
                                    <UploadImage
                                      name="imageFile"
                                      type="image"
                                      image={selectedFlipcard?.back?.imageFile}
                                      handleUploadFromDevice={(file) => {
                                        console.log(file);
                                        const updatedFlipcards =
                                          templateData.data.flipcards.map(
                                            (flipcard: any) =>
                                              flipcard._id ===
                                              selectedFlipcard._id
                                                ? {
                                                    ...flipcard,
                                                    back: {
                                                      ...flipcard.back,
                                                      imageFile: file,
                                                    },
                                                  }
                                                : flipcard
                                          );
                                        handleChange({
                                          ...templateData,
                                          data: {
                                            ...templateData.data,
                                            flipcards: updatedFlipcards,
                                          },
                                        });
                                        dispatch(
                                          setSelectedFlipcard({
                                            ...selectedFlipcard,
                                            back: {
                                              ...selectedFlipcard.back,
                                              imageFile: file,
                                            },
                                          })
                                        );
                                      }}
                                      handleUploadFromMedia={(file) => {
                                        console.log(file);
                                        const updatedFlipcards =
                                          templateData.data.flipcards.map(
                                            (flipcard: any) =>
                                              flipcard._id ===
                                              selectedFlipcard._id
                                                ? {
                                                    ...flipcard,
                                                    back: {
                                                      ...flipcard.back,
                                                      imageFile: file,
                                                    },
                                                  }
                                                : flipcard
                                          );
                                        handleChange({
                                          ...templateData,
                                          data: {
                                            ...templateData.data,
                                            flipcards: updatedFlipcards,
                                          },
                                        });
                                        dispatch(
                                          setSelectedFlipcard({
                                            ...selectedFlipcard,
                                            back: {
                                              ...selectedFlipcard.back,
                                              imageFile: file,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              {templateData.data.flipcards.filter(
                                (flipcard: any) =>
                                  flipcard._id === selectedFlipcard._id
                              )[0]?.layoutOption ===
                                "flipcard-video-with-text" && (
                                <div className="flex flex-col items-start space-y-4 w-full">
                                  <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                                    <span>Video Url</span>
                                  </div>
                                  <div className="rounded-md border-2 border-gray-300 p-2 w-full">
                                    <input
                                      type="text"
                                      value={selectedFlipcard?.back?.videoUrl}
                                      className="outline-none w-full"
                                      onChange={(e) => {
                                        const url = e.target.value;
                                        const updatedFlipcards =
                                          templateData.data.flipcards.map(
                                            (flipcard: any) =>
                                              flipcard._id ===
                                              selectedFlipcard._id
                                                ? {
                                                    ...flipcard,
                                                    back: {
                                                      ...flipcard.back,
                                                      videoUrl: url,
                                                    },
                                                  }
                                                : flipcard
                                          );
                                        handleChange({
                                          ...templateData,
                                          data: {
                                            ...templateData.data,
                                            flipcards: updatedFlipcards,
                                          },
                                        });
                                        dispatch(
                                          setSelectedFlipcard({
                                            ...selectedFlipcard,
                                            back: {
                                              ...selectedFlipcard.back,
                                              videoUrl: url,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              {templateData.data.flipcards.filter(
                                (flipcard: any) =>
                                  flipcard._id === selectedFlipcard._id
                              )[0]?.layoutOption ===
                                "flipcard-audio-with-text" && (
                                <div className="flex flex-col items-start space-y-4 w-full">
                                  <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                                    <span>Audio Url</span>
                                  </div>
                                  <div className="rounded-md border-2 border-gray-300 p-2 w-full ">
                                    <input
                                      type="text"
                                      value={selectedFlipcard?.back?.audioUrl}
                                      className="outline-none w-full"
                                      onChange={(e) => {
                                        const url = e.target.value;
                                        const updatedFlipcards =
                                          templateData.data.flipcards.map(
                                            (flipcard: any) =>
                                              flipcard._id ===
                                              selectedFlipcard._id
                                                ? {
                                                    ...flipcard,
                                                    back: {
                                                      ...flipcard.back,
                                                      audioUrl: url,
                                                    },
                                                  }
                                                : flipcard
                                          );
                                        handleChange({
                                          ...templateData,
                                          data: {
                                            ...templateData.data,
                                            flipcards: updatedFlipcards,
                                          },
                                        });
                                        dispatch(
                                          setSelectedFlipcard({
                                            ...selectedFlipcard,
                                            back: {
                                              ...selectedFlipcard.back,
                                              audioUrl: url,
                                            },
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="flex flex-col items-start space-y-4 w-full">
                                <div className="font-[500] text-[1.125rem] leading-normal">
                                  Text
                                </div>
                                <div className="w-full">
                                  <Editor
                                    apiKey="iiet6dxb5byz1gk6b3jkzloisyg5vvwodaoxllymfx6o01op"
                                    onInit={(_evt, editor) =>
                                      (editorRef.current = editor)
                                    }
                                    initialValue={selectedFlipcard?.back?.text}
                                    init={{
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "code",
                                        "help",
                                        "wordcount",
                                      ],
                                      toolbar:
                                        "undo redo | blocks | " +
                                        "bold italic forecolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                        "removeformat | help",
                                      content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    }}
                                    onChange={(e) => {
                                      const updatedText = e.target.getContent();
                                      const updatedFlipcards =
                                        templateData.data.flipcards.map(
                                          (flipcard: any) =>
                                            flipcard._id ===
                                            selectedFlipcard._id
                                              ? {
                                                  ...flipcard,
                                                  back: {
                                                    ...flipcard.back,
                                                    text: updatedText,
                                                  },
                                                }
                                              : flipcard
                                        );

                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          flipcards: updatedFlipcards,
                                        },
                                      });
                                      dispatch(
                                        setSelectedAccordion({
                                          ...selectedFlipcard,
                                          back: {
                                            ...selectedFlipcard.back,
                                            text: updatedText,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex flex-col items-start space-y-4 w-full">
                                <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                                  <span>Image</span>
                                </div>
                                <div className="rounded-md border-2 border-gray-300">
                                  <UploadImage
                                    name="imageFile"
                                    type="image"
                                    image={selectedFlipcard?.front?.imageFile}
                                    handleUploadFromDevice={(file) => {
                                      console.log(file);
                                      const updatedFlipcards =
                                        templateData.data.flipcards.map(
                                          (flipcard: any) =>
                                            flipcard._id ===
                                            selectedFlipcard._id
                                              ? {
                                                  ...flipcard,
                                                  front: {
                                                    ...flipcard.front,
                                                    imageFile: file,
                                                  },
                                                }
                                              : flipcard
                                        );
                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          flipcards: updatedFlipcards,
                                        },
                                      });
                                      dispatch(
                                        setSelectedFlipcard({
                                          ...selectedFlipcard,
                                          front: {
                                            ...selectedFlipcard.front,
                                            imageFile: file,
                                          },
                                        })
                                      );
                                    }}
                                    handleUploadFromMedia={(file) => {
                                      console.log(file);
                                      const updatedFlipcards =
                                        templateData.data.flipcards.map(
                                          (flipcard: any) =>
                                            flipcard._id ===
                                            selectedFlipcard._id
                                              ? {
                                                  ...flipcard,
                                                  front: {
                                                    ...flipcard.front,
                                                    imageFile: file,
                                                  },
                                                }
                                              : flipcard
                                        );
                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          flipcards: updatedFlipcards,
                                        },
                                      });
                                      dispatch(
                                        setSelectedFlipcard({
                                          ...selectedFlipcard,
                                          front: {
                                            ...selectedFlipcard.front,
                                            imageFile: file,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col items-start space-y-4 w-full">
                                <div className="font-[500] text-[1.125rem] leading-normal">
                                  Text
                                </div>
                                <div className="w-full">
                                  <Editor
                                    apiKey="iiet6dxb5byz1gk6b3jkzloisyg5vvwodaoxllymfx6o01op"
                                    onInit={(_evt, editor) =>
                                      (editorRef.current = editor)
                                    }
                                    initialValue={selectedFlipcard?.front?.text}
                                    init={{
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "code",
                                        "help",
                                        "wordcount",
                                      ],
                                      toolbar:
                                        "undo redo | blocks | " +
                                        "bold italic forecolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                        "removeformat | help",
                                      content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    }}
                                    onChange={(e) => {
                                      const updatedText = e.target.getContent();
                                      const updatedFlipcards =
                                        templateData.data.flipcards.map(
                                          (flipcard: any) =>
                                            flipcard._id ===
                                            selectedFlipcard._id
                                              ? {
                                                  ...flipcard,
                                                  front: {
                                                    ...flipcard.front,
                                                    text: updatedText,
                                                  },
                                                }
                                              : flipcard
                                        );

                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          flipcards: updatedFlipcards,
                                        },
                                      });
                                      dispatch(
                                        setSelectedAccordion({
                                          ...selectedFlipcard,
                                          front: {
                                            ...selectedFlipcard.front,
                                            text: updatedText,
                                          },
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Flipcard
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>

                <TabsContent value="layout" className="space-y-3">
                  {selectedFlipcard?.selectedFace === "Back" && (
                    <>
                      <div
                        onClick={() =>
                          handleUpdateTemplateData(
                            `flipcards[${selectedFlipcard?.index}].layoutOption`,
                            "flipcard-image-with-text"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            templateData.data.flipcards.filter(
                              (flipcard: any) =>
                                flipcard._id === selectedFlipcard._id
                            )[0]?.layoutOption === "flipcard-image-with-text" &&
                            "border-blue border-[3px] rounded-md"
                          }`}
                          src={FlipcardImageWithText}
                          alt="image"
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateTemplateData(
                            `flipcards[${selectedFlipcard?.index}].layoutOption`,
                            "flipcard-only-text"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            templateData.data.flipcards.filter(
                              (flipcard: any) =>
                                flipcard._id === selectedFlipcard._id
                            )[0]?.layoutOption === "flipcard-only-text" &&
                            "border-blue border-[3px] rounded-md"
                          }`}
                          src={FlipcardOnlyText}
                          alt="image"
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateTemplateData(
                            `flipcards[${selectedFlipcard?.index}].layoutOption`,
                            "flipcard-audio-with-text"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            templateData.data.flipcards.filter(
                              (flipcard: any) =>
                                flipcard._id === selectedFlipcard._id
                            )[0]?.layoutOption === "flipcard-audio-with-text" &&
                            "border-blue border-[3px] rounded-md"
                          } `}
                          src={FlipcardAudioWithText}
                          alt="image"
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateTemplateData(
                            `flipcards[${selectedFlipcard?.index}].layoutOption`,
                            "flipcard-video-with-text"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            templateData.data.flipcards.filter(
                              (flipcard: any) =>
                                flipcard._id === selectedFlipcard._id
                            )[0]?.layoutOption === "flipcard-video-with-text" &&
                            "border-blue border-[3px] rounded-md"
                          } `}
                          src={FlipcardVideoWithText}
                          alt="image"
                        />
                      </div>
                    </>
                  )}
                </TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
              </>
            )}

            {templateData.type === "accordion" && (
              <>
                <TabsContent value="content">
                  <div className="flex flex-grow">
                    <div className="flex flex-col w-full space-y-[1.25rem] p-3">
                      <div className="flex justify-between items-center">
                        <button
                          onClick={addAccordion}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Add New Accordion</span>
                          <span className="text-md">+</span>
                        </button>
                        {showTooltip && (
                          <div className="absolute top-[0.69rem] left-0 bg-gray-800/70 text-white text-sm px-2 py-1 rounded shadow-lg">
                            Cannot add more than 4 accordions
                          </div>
                        )}
                        <button
                          onClick={deleteAccordion}
                          className="p-2 text-sm w-[49%] bg-tertiary text-white rounded-md flex space-x-2 items-center justify-center"
                        >
                          <span>Delete Accordion</span>
                          <span>
                            <img src={DeleteIcon} alt="delete" />
                          </span>
                        </button>
                      </div>

                      {selectedAccordion && (
                        <>
                          <div className="flex flex-col items-start space-y-4 w-full">
                            <div className="font-[500] text-[1.125rem] leading-normal">
                              Accordion Title
                            </div>
                            <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                              <input
                                type="text"
                                placeholder="Enter title"
                                value={selectedAccordion.title}
                                onChange={(e) => {
                                  const updatedTitle = e.target.value;
                                  const updatedAccordions =
                                    templateData.data.accordions.map(
                                      (accordion: any) =>
                                        accordion._id === selectedAccordion._id
                                          ? {
                                              ...accordion,
                                              title: updatedTitle,
                                            }
                                          : accordion
                                    );

                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      accordions: updatedAccordions,
                                    },
                                  });
                                  dispatch(
                                    setSelectedAccordion({
                                      ...selectedAccordion,
                                      title: updatedTitle,
                                    })
                                  );
                                }}
                                className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                              />
                            </div>
                          </div>

                          <div className="font-[500] text-[1.125rem] leading-normal">
                            Accordion Content
                          </div>

                          <div className="flex flex-col items-start space-y-4 w-full">
                            <div className="font-[400] text-[1rem] leading-normal">
                              Text
                            </div>
                            <div className="w-full">
                              <Editor
                                apiKey="iiet6dxb5byz1gk6b3jkzloisyg5vvwodaoxllymfx6o01op"
                                onInit={(_evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                initialValue={selectedAccordion?.content?.text}
                                init={{
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "image",
                                    "charmap",
                                    "preview",
                                    "anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "code",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                    "table",
                                    "code",
                                    "help",
                                    "wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | blocks | " +
                                    "bold italic forecolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | help",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                                onChange={(e) => {
                                  const updatedDescription =
                                    e.target.getContent();
                                  const updatedAccordions =
                                    templateData.data.accordions.map(
                                      (accordion: any) =>
                                        accordion._id === selectedAccordion._id
                                          ? {
                                              ...accordion,
                                              content: {
                                                ...accordion.content,
                                                text: updatedDescription,
                                              },
                                            }
                                          : accordion
                                    );

                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      accordions: updatedAccordions,
                                    },
                                  });
                                  dispatch(
                                    setSelectedAccordion({
                                      ...selectedAccordion,
                                      content: {
                                        ...selectedAccordion.content,
                                        text: updatedDescription,
                                      },
                                    })
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col items-start space-y-4 w-full">
                            <div className="font-[400] text-[1rem] leading-normal">
                              Image
                            </div>
                            <div className="rounded-md border-2 border-gray-300">
                              <UploadImage
                                name="imageFile"
                                type="image"
                                image={selectedAccordion?.content?.image}
                                handleUploadFromDevice={(file) => {
                                  const updatedAccordions =
                                    templateData.data.accordions.map(
                                      (accordion: any) =>
                                        accordion._id === selectedAccordion._id
                                          ? {
                                              ...accordion,
                                              content: {
                                                ...accordion.content,
                                                image: file,
                                              },
                                            }
                                          : accordion
                                    );
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      accordions: updatedAccordions,
                                    },
                                  });
                                  dispatch(
                                    setSelectedAccordion({
                                      ...selectedAccordion,
                                      content: {
                                        ...selectedAccordion.content,
                                        image: file,
                                      },
                                    })
                                  );
                                }}
                                handleUploadFromMedia={(file) => {
                                  const updatedAccordions =
                                    templateData.data.accordions.map(
                                      (accordion: any) =>
                                        accordion._id === selectedAccordion._id
                                          ? {
                                              ...accordion,
                                              content: {
                                                ...accordion.content,
                                                image: file,
                                              },
                                            }
                                          : accordion
                                    );
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      accordions: updatedAccordions,
                                    },
                                  });
                                  dispatch(
                                    setSelectedAccordion({
                                      ...selectedAccordion,
                                      content: {
                                        ...selectedAccordion.content,
                                        image: file,
                                      },
                                    })
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </TabsContent>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Accordion
                      templateData={templateData}
                      handleUpdateTemplateData={
                        handleUpdateTemplateDataAccordion
                      }
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3">
                  {selectedAccordion && (
                    <>
                      <div
                        onClick={() =>
                          handleUpdateTemplateDataAccordion(
                            "layoutOption",
                            "text-left-image-right"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            selectedAccordion.layoutOption ===
                              "text-left-image-right" &&
                            "border-blue border-[3px] rounded-md"
                          }`}
                          src={Layout1}
                          alt="image"
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateTemplateDataAccordion(
                            "layoutOption",
                            "text-right-image-left"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            selectedAccordion.layoutOption ===
                              "text-right-image-left" &&
                            "border-blue border-[3px] rounded-md"
                          }`}
                          src={Layout4}
                          alt="image"
                        />
                      </div>
                      <div
                        onClick={() =>
                          handleUpdateTemplateDataAccordion(
                            "layoutOption",
                            "only-text"
                          )
                        }
                        className="h-[6rem] w-full flex justify-center items-center"
                      >
                        <img
                          className={`h-full aspect-auto ${
                            selectedAccordion.layoutOption === "only-text" &&
                            "border-blue border-[3px] rounded-md"
                          }`}
                          src={Layout2}
                          alt="image"
                        />
                      </div>
                    </>
                  )}
                </TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
              </>
            )}

            {templateData.type === "draganddrop" && (
              <>
                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <DragAndDrop
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="layout" className="space-y-3"></TabsContent>
              </>
            )}

            {templateData.type === "hotspot" && (
              <>
                <TabsContent value="content">
                  <div className="flex flex-grow">
                    <div className="flex flex-col w-full space-y-[1.25rem] p-3">
                      <div className="text-xl">Hotspot</div>

                      <div className="flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-sm leading-normal">
                          <span>Hotspot Image</span>
                        </div>
                        <div className="rounded-md border-2 border-gray-300">
                          <UploadImage
                            name="imageFile"
                            type="image"
                            image={templateData.data.image}
                            handleUploadFromDevice={(file) => {
                              console.log(file);
                              handleChange({
                                ...templateData,
                                data: { ...templateData.data, image: file },
                              });
                              dispatch(setSelectedHotspot(null));
                            }}
                            handleUploadFromMedia={(file) => {
                              console.log(file);
                              handleChange({
                                ...templateData,
                                data: { ...templateData.data, image: file },
                              });
                              dispatch(setSelectedHotspot(null));
                            }}
                          />
                        </div>
                      </div>

                      {templateData.data.image && (
                        <>
                          <div className="flex items-start space-x-2 font-[500] text-sm leading-normal">
                            Hotspot Content
                          </div>

                          <button
                            onClick={() =>
                              dispatch(setIsAddingHotspot(!isAddingHotspot))
                            }
                            className={`px-2 py-2 border-2 flex items-center justify-between ${
                              isAddingHotspot
                                ? "border-red-400 bg-red-300"
                                : "border-zinc-400 bg-zinc-300"
                            } rounded-md w-[9.5rem]`}
                          >
                            <div
                              style={{
                                ...templateData.data.styles.hotspotBackground,
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  ...templateData.data.styles.stroke,
                                  width: "60%",
                                  height: "60%",
                                  borderRadius: "50%",
                                }}
                              />
                            </div>
                            <span>Add hotspot</span>
                          </button>

                          <div className="flex justify-between items-center w-full">
                            <div className="font-[500] text-sm leading-normal">
                              Selected Hotspot
                            </div>
                            <button
                              onClick={deleteHotspot}
                              className={`px-2 py-1 border-2 border-zinc-400 bg-zinc-300 hover:bg-zinc-200 rounded-md`}
                            >
                              <img
                                className="w-5 h-5"
                                src={RemoveLogo}
                                alt="deleteHotspot"
                              />
                            </button>
                          </div>
                          {selectedHotspot && (
                            <>
                              <div className="flex flex-col items-start space-y-4 w-full">
                                <div className="flex font-[500] text-sm leading-normal">
                                  <span>Hotspot Name</span>
                                </div>
                                <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                                  <input
                                    type="text"
                                    value={selectedHotspot?.title}
                                    placeholder="Enter Hotspot name here..."
                                    className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                                    onChange={(e) => {
                                      const updatedName = e.target.value;
                                      const updatedHotspots =
                                        templateData.data.hotspots.map(
                                          (hotspot: any) =>
                                            hotspot._id === selectedHotspot._id
                                              ? {
                                                  ...hotspot,
                                                  title: updatedName,
                                                }
                                              : hotspot
                                        );
                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          hotspots: updatedHotspots,
                                        },
                                      });
                                      dispatch(
                                        setSelectedHotspot({
                                          ...selectedHotspot,
                                          title: updatedName,
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="flex flex-col items-start space-y-4 w-full">
                                <div className="font-[500] text-sm leading-normal">
                                  Text
                                </div>
                                <div className="w-full">
                                  <Editor
                                    apiKey="iiet6dxb5byz1gk6b3jkzloisyg5vvwodaoxllymfx6o01op"
                                    onInit={(_evt, editor) =>
                                      (editorRef.current = editor)
                                    }
                                    initialValue={selectedHotspot?.content}
                                    init={{
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                        "advlist",
                                        "autolink",
                                        "lists",
                                        "link",
                                        "image",
                                        "charmap",
                                        "preview",
                                        "anchor",
                                        "searchreplace",
                                        "visualblocks",
                                        "code",
                                        "fullscreen",
                                        "insertdatetime",
                                        "media",
                                        "table",
                                        "code",
                                        "help",
                                        "wordcount",
                                      ],
                                      toolbar:
                                        "undo redo | blocks | " +
                                        "bold italic forecolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent | " +
                                        "removeformat | help",
                                      content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    }}
                                    onChange={(e) => {
                                      const updatedContent =
                                        e.target.getContent();
                                      const updatedHotspots =
                                        templateData.data.hotspots.map(
                                          (hotspot: any) =>
                                            hotspot._id === selectedHotspot._id
                                              ? {
                                                  ...hotspot,
                                                  content: updatedContent,
                                                }
                                              : hotspot
                                        );

                                      handleChange({
                                        ...templateData,
                                        data: {
                                          ...templateData.data,
                                          hotspots: updatedHotspots,
                                        },
                                      });
                                      dispatch(
                                        setSelectedHotspot({
                                          ...selectedHotspot,
                                          content: updatedContent,
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Hotspot
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
              </>
            )}

            {templateData.type === "fillintheblanks" && (
              <>
                <TabsContent value="content">
                  <FillInTheBlanksContent
                    templateData={templateData}
                    handleUpdateTemplateData={(data) => {
                      console.log(data);
                      handleChange(data);
                    }}
                    canvasActiveComponent={canvasActiveComponent}
                  />
                </TabsContent>

                <TabsContent value="style">
                  <Suspense fallback={<div>Loading...</div>}>
                    <FillInTheBlanks
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                  </Suspense>
                </TabsContent>

                <TabsContent value="layout" className="space-y-3"></TabsContent>
                <TabsContent value="audio" className="p-3 space-y-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <AudioSettings
                      templateData={templateData}
                      handleChange={handleChange}
                    />
                  </Suspense>
                </TabsContent>
                <TabsContent value="grading">
                  <div className="p-3 flex flex-col space-y-3">
                    <div className="flex items-start justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                      <span>Graded</span>
                      <Switch
                        checked={templateData.data?.haveGraded}
                        onCheckedChange={() => {
                          const newValue = !templateData.data?.haveGraded;

                          const updatedData = {
                            ...templateData.data,
                            haveGraded: newValue,
                          };

                          console.log(updatedData);
                          handleChange({
                            ...templateData,
                            data: updatedData,
                          });
                        }}
                      />
                    </div>
                    {/* <div className="flex items-center justify-between font-[500] text-[1.125rem] leading-normal text-[#242424]">
                  <div className="font-[500] text-[1.125rem] leading-normal">
                    Score
                  </div>
                  <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                    <input
                      type="number"
                      placeholder="Enter score"
                      value={templateData.data?.score || 0}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        const updatedScore = e.target.value;

                        handleChange({
                          ...templateData,
                          data: {
                            ...templateData.data,
                            score: updatedScore,
                          },
                        });
                      }}
                      className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                    />
                  </div>
                </div> */}
                  </div>
                </TabsContent>
              </>
            )}
          </>
        )}
      </Tabs>
    </div>
  );
};

export default Settings;
