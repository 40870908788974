import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedHotspot: null,
};

export const selectedHotspotSlice = createSlice({
    name: "selectedHotspot",
    initialState,
    reducers: {
        setSelectedHotspot: (state, action) => {
            state.selectedHotspot = action.payload
        },
    },
})

export const { setSelectedHotspot } = selectedHotspotSlice.actions;
export default selectedHotspotSlice.reducer;