import React, { useEffect, useState } from "react";
// import Player from "./Player";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ChevronLeft, MenuIcon } from "lucide-react";
import QuizSettingsPage from "./QuizSettingsPage";

type Props = {};

function QuizSettings({}: Props) {
  const [course, setCourse] = useState<any>();

  const { courseId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          console.log("check response", res.data.data.course);
          const courseData = res.data.data.course;
          setCourse(courseData);
        }
      } catch (error) {
        console.log(error);
      } 
    })();
  }, [courseId, accessToken]);

  useEffect(() => {
    
  }, [course]);

  function handleDataChange(updatedSettings: any) {
    console.log("Updating quiz settings", updatedSettings);
    const updatedCourse = {...course, quizSettings: updatedSettings};
    setCourse(updatedCourse);

    axios
    .put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`,
      {
        quizSettings: updatedSettings,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then((res) => {
      console.log("Quiz Settings updated:", res.data);
    })
    .catch((error) => {
      console.error("Error updating Menu:", error);
    });
  }

  return (
    <main className="relative w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins">
      <div className="flex w-full space-x-3 h-[3rem] px-[1.25rem] py-[0.5625] items-center">
        <span
          onClick={() => navigate(-1)}
          className=""
        >
          <ChevronLeft size={28}/>
        </span>
        <span className="text-lg">Quiz Settings</span>
      </div>
      <section className="relative w-full h-[calc(100%-3rem)] flex">
        <div className="w-full h-full bg-[#d1d1d1] flex items-center justify-center">
          <div className=" containerplayer ">
            {course ? (
              <QuizSettingsPage 
                quizData={course.quizSettings}
                handleDataChange={handleDataChange}
              />
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default QuizSettings;
