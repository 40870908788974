import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setAccessToken } from "src/features/token/tokenSlice";
import { useSelector } from "react-redux";
import UploadImage from "../UploadImage/UploadImage";
// @ts-ignore
import AlignLeft from "../../../assets/Course/align-left.svg";
// @ts-ignore
import AlignCenter from "../../../assets/Course/align-center.svg";
// @ts-ignore
import AlignRight from "../../../assets/Course/align-right.svg";
import { Switch } from "src/components/ui/switch";
import { ComboboxDemo } from "src/components/ui/comboBox";
import { ChevronLeft, MenuIcon } from "lucide-react";
// @ts-ignore
import MenuThumbnail from "../../../assets/Course/clicktoreveal/menuThumbnail.png";
// @ts-ignore
import SidebarThumbnail from "../../../assets/Course/clicktoreveal/sidebarThumbnail.png";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/components/ui/tabs";
import UploadScorm from "../UploadScorm";

type Props = {};

function LmsSettings({}: Props) {
  const [course, setCourse] = useState<any>();
  const [isGrading, setIsGrading] = React.useState(false);
  const [isTraking, setIsTraking] = React.useState(true);

  const { courseId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          console.log("check response", res.data.data.course);
          const courseData = res.data.data.course;
          setCourse(courseData);
  
          // Ensure correct state sync
          setIsGrading(courseData.lmsSettings?.haveGrading || false);
          setIsTraking(courseData.lmsSettings?.haveTracking || false); // Fix typo and sync state
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [courseId, accessToken]);

  function handleToggle(updatedLMS: any) {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`,
        {
          lmsSettings: updatedLMS,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => {
        console.log("Settings updated:", res.data);
      })
      .catch((error) => {
        console.error("Error updating settings:", error.response?.data || error);
      });
  }

  return (
    <main className="relative w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins">
      <div className="flex w-full space-x-3 h-[3rem] px-[1.25rem] py-[0.5625] items-center">
        <span onClick={() => navigate(-1)} className="">
          <ChevronLeft size={28} />
        </span>
        <span className="text-lg">LMS Settings</span>
      </div>
      <section className="relative w-full h-[calc(100%-3rem)] flex flex-col">
        <div className="w-full h-full bg-[#d1d1d1] flex flex-col space-y-3 p-4">
          {/* <div className=" containerplayer "> */}
          <div className="flex space-x-4 items-center ">
            <span>Grading</span>
            <div>
              <Switch
                checked={isGrading}
                onCheckedChange={() => {
                  setIsGrading(!isGrading);
                  const updatedLMS = {
                    ...course.lmsSettings,
                    haveGrading: !isGrading,
                  };
                  const updatedCourse = {
                    ...course,
                    lmsSettings: updatedLMS,
                  };

                  console.log(
                    "updatedLMS and updatedCourse",
                    updatedLMS,
                    updatedCourse
                  );
                  handleToggle(updatedLMS);
                }}
              />
            </div>
          </div>

          <div className="flex space-x-4 items-center ">
            <span>Tracking</span>
            <div>
              <Switch
                checked={isTraking}
                onCheckedChange={() => {
                  setIsTraking(!isTraking);
                  const updatedLMS = {
                    ...course.lmsSettings,
                    haveTracking: !isTraking,
                  };

                  handleToggle(updatedLMS);
                }}
              />
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="w-full h-full">
          <UploadScorm />
        </div>
      </section>
    </main>
  );
}

export default LmsSettings;
