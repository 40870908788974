import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { GetPreviewTemplate } from "../../utils/GetPreviewTempalte";
import { motion, AnimatePresence } from "framer-motion";
// @ts-ignore
import DefaultImage from "src/assets/Course/defaultImage.jpg";
import { AccordionType } from "../../../Authoring/utils/DefaultTemplateData";
// @ts-ignore
import {Accordion, AccordionItem} from "@nextui-org/accordion";
import { setSelectedAccordion } from "src/features/selectedAccordion/selectedAccordionSlice";

type Props = {
  data: any;
  preview?: boolean;
  handleDataChange?: (data: any) => void;
};

function AccordionLayout({ data, handleDataChange, preview }: Props) {
  // const [openTemplate, setOpenTemplate] = useState(false);

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );

  const { selectedAccordion } = useSelector((state: any) => state.selectedAccordion);

  const dispatch = useDispatch();

  const resetTemplateState = () => {
    dispatch(setSelectedAccordion(null));
  };

  useEffect(() => {
    // Reset state when template changes
    console.log("reset");
    resetTemplateState();
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (handleDataChange) handleDataChange(data);
  }, [data]);

  useEffect(() => {
    // Set the first accordion item as the selectedAccordion if not already set
    if (data?.accordions?.length) {
      dispatch(setSelectedAccordion({...data.accordions[0], index: 0}));
    }
  }, []);

  return (
    <div 
      style={{ 
        ...data.styles.container,
      }} 
      onClick={() => {
        if (
          canvasActiveComponent?.templateType === "accordion" &&
          canvasActiveComponent?.componentType === "wholeAccordion"
        ) {
          dispatch(setCanvasActiveComponent(null));
        } else {
          dispatch(
            setCanvasActiveComponent({
              templateType: "accordion",
              componentType: "wholeAccordion",
            })
          );
        }
      }}
      className={`overflow-hidden ${!preview && canvasActiveComponent?.templateType === "accordion" &&
        canvasActiveComponent?.componentType === "wholeAccordion"
        ? "border-2 border-dashed border-blue"
        : ""
      }`}>
      <Accordion defaultExpandedKeys={[selectedAccordion?._id || data?.accordions?.[0]?._id,]}>
        {data?.accordions.map((accordion: any, idx: number) => (
          <AccordionItem key={accordion._id} aria-label={accordion?.title} title={accordion?.title} onPress={() => dispatch(setSelectedAccordion({ ...accordion, index: idx }))}>
            <div 
              style={{ ...data.styles?.TextImageWrap }}
              // style={{ ...accordion.styles?.TextImageWrap }}
              className={`flex ${accordion.layoutOption === "text-right-image-left" &&
                "flex-row-reverse"
                }`}
              >

              {accordion?.content?.text && (
                <div
                  style={{
                    ...data.styles?.textContainer,
                    width: accordion.layoutOption === "only-text" ? '100%' : data.styles?.textContainer.width
                  }}
                  className={`flex ${!preview && canvasActiveComponent?.templateType ===
                    "accordion" &&
                    canvasActiveComponent?.componentType === "text"
                    ? "border-2 border-dashed border-blue"
                    : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType ===
                      "accordion" &&
                      canvasActiveComponent?.componentType === "text"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "accordion",
                          componentType: "text",
                        })
                      );
                    }
                  }}
                >
                  <div style={data.styles?.text} className="text-sm flex flex-wrap grow">
                    <pre className='whitespace-pre-wrap font-sans' dangerouslySetInnerHTML={{ __html: accordion?.content?.text }}></pre>
                  </div>
                </div>
              )}
              
              {accordion.layoutOption !== "only-text" && (
                <div
                  style={{
                    ...data.styles?.imageContainer
                  }}
                  className={`flex ${!preview && canvasActiveComponent?.templateType ===
                    "accordion" &&
                    canvasActiveComponent?.componentType === "image"
                    ? "border-2 border-dashed border-blue"
                    : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      canvasActiveComponent?.templateType ===
                      "accordion" &&
                      canvasActiveComponent?.componentType === "image"
                    ) {
                      dispatch(setCanvasActiveComponent(null));
                    } else {
                      dispatch(
                        setCanvasActiveComponent({
                          templateType: "accordion",
                          componentType: "image",
                        })
                      );
                    }
                  }}
                >
                  {accordion?.content?.image ? (
                    <img
                      style={data.styles?.image}
                      src={accordion?.content?.image?.url}
                      alt="image"
                      className=""
                    />
                  ) : (
                    <img
                      style={data.styles.image}
                      src={DefaultImage}
                      alt="image"
                      className=""
                    />
                  )}
                </div>
              )}
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export default AccordionLayout;
