import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedAccordion: null,
};
export const selectedAccordionSlice = createSlice({
    name: "selectedAccordion",
    initialState,
    reducers: {
        setSelectedAccordion: (state, action) => {
            state.selectedAccordion = action.payload;
        },
    },
});

export const { setSelectedAccordion } = selectedAccordionSlice.actions;
export default selectedAccordionSlice.reducer;