import React, { useCallback, useEffect, useState } from "react";
import Layout from "./common/Layout";
import { Button } from "src/components/ui";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import {
  ReactFlow,
  Background,
  Controls,
  MiniMap,
  addEdge,
  useEdgesState,
  useNodesState,
  Edge,
  Node,
  Connection,
  ReactFlowProvider,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Draggable from "react-draggable";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import { useLMS } from "src/context/Lms";

type Props = {
  type?: string;
  topics?: any[];
  topic: any;
  quizSettings?: any;
  handleDataChange: (data: any) => void;
  data: any;
  handleOpenAuthoringModal?: (state: boolean) => void;
  openTemplate?: any;
  handleCancelTemplateForm?: () => void;
  nextValidation?: boolean;
  handleNextValidation?: () => void;
};

function Matching({
  data,
  topics,
  topic,
  type,
  quizSettings,
  handleDataChange,
  handleOpenAuthoringModal,
  openTemplate,
  handleCancelTemplateForm,
  nextValidation,
  handleNextValidation,
}: Props) {
  const templateType = "matching";

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [openFeedback, setOpenFeedback] = React.useState("");
  const [shuffledItems, setShuffledItems] = React.useState([]);
  const [shuffledTargets, setShuffledTargets] = React.useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState<any>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([]);

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData)

  const { isInitialized, setSuspendData, getSuspendData, setScore, setSuccessStatus } = useLMS();

  const dispatch = useDispatch();

  const minPassingScore = quizSettings?.passingRequirements?.passingScore || 0;

  useEffect(() => {
    console.log(isSubmitted);
  }, [isSubmitted]);

  function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const randomIndex = Math.floor(Math.random() * (i + 1));

      // Swap the elements at i and randomIndex
      [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const shuffledItems = shuffleArray(data.items);
    setShuffledItems(shuffledItems);
    const shuffledTargets = shuffleArray(data.targets);
    setShuffledTargets(shuffledTargets);

    // Initialize nodes for items and targets
    const itemNodes = shuffledItems.map((item: any, index: number) => ({
      id: item._id,
      data: {
        label: (
          <div className="col-span-1">
            <div className="flex item-center space-x-2 border-2 border-[#9f9f9f] p-1 shadow-lg h-[3.65rem] ">
              {item?.itemImage && (
                <div className="w-[5rem] h-[3rem] border-2 border-gray-300 flex-shrink-0 flex items-center justify-center overflow-hidden">
                  <img
                    className="max-w-max h-full object-contain"
                    src={item?.itemImage?.url}
                    alt="itemImage"
                  />
                </div>
              )}
              {item?.value && (
                <div className="flex min-w-[9rem] grow bg-white items-center flex-shrink">
                  <span className={`text-[14px] whitespace-nowrap`}>
                    {item?.value}
                  </span>
                </div>
              )}
            </div>
          </div>
        ),
      },
      position: { x: 25, y: index * 100 },
      type: "input",
      sourcePosition: "right",
      draggable: false,
    }));

    const targetNodes = shuffledTargets.map((target: any, index: number) => ({
      id: target._id,
      data: {
        label: (
          <div className="col-span-1">
            <div className="flex item-center space-x-2  border-2 border-[#9f9f9f] p-1 shadow-lg h-[3.65rem] ">
              {target?.targetImage && (
                <div className="w-[5rem] h-[3rem] border-2 border-gray-300 flex-shrink-0 flex items-center justify-center overflow-hidden">
                  <img
                    className="max-w-max h-full object-contain"
                    src={target?.targetImage?.url}
                    alt="targetImage"
                  />
                </div>
              )}
              {target?.value && (
                <div className="flex min-w-[9rem] grow bg-white items-center flex-shrink">
                  <span className={`text-[14px] whitespace-nowrap`}>
                    {target.value}
                  </span>
                </div>
              )}
            </div>
          </div>
        ),
      },
      position: { x: 320, y: index * 100 },
      type: "output",
      targetPosition: "left",
      draggable: false,
    }));

    setNodes([...itemNodes, ...targetNodes]);
  }, [data, setNodes]);

  // Handle new connections
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log("learnerData line: 168", learnerData);
    if (!isInitialized) return;
    console.log("index line : 105", learnerData);
    setSuspendData(JSON.stringify(learnerData));

    setScore(learnerData.score.raw);

    if (learnerData.score.raw >= minPassingScore) {
      setSuccessStatus("passed");
    } else {
      setSuccessStatus("failed");
    }
  }, [learnerData])

  // Validate Matches
  const handleSubmit = () => {
    const matches = edges.map((edge) => ({
      itemId: edge.source,
      targetId: edge.target,
    }));

    console.log("matches", matches);

    const response = data.options.filter((option: any) => {
      console.log("option", option);
      let itemId = option.item._id;
      let targetId = option.target._id;
      const res = matches.filter(
        (match) => match.itemId === itemId && match.targetId === targetId
      );
      return res.length > 0;
    });

    const isCorrect = response.length === data.options.length;

    setStatus(isCorrect ? "correct" : "incorrect");
    setIsSubmitted(true);

    setTimeout(() => {
      setOpenFeedback(
        isCorrect ? data.globalFeedbackCorrect : data.globalFeedbackIncorrect
      );
    }, 2000);

    if(!type) return;

    const quizTemplates = topics ? topics.filter((topic) => topic.template && (topic.template.type === "scr" || topic.template.type === "mcr" || topic.template.type === "true/false" || topic.template.type === "matching" || topic.template.type === "fillintheblanks" || topic.template.type === "draganddrop")) : [];
    const noOfQuizes = quizTemplates.length;

    console.log("line 123", learnerData);

    const findTemplate = learnerData.templates.find((template: any) => template.data.id === topic.template.id);

    var score = 0;
    if (isCorrect && noOfQuizes > 0) {
      score = learnerData.score.raw + Math.round(100 / noOfQuizes)
    } else {
      score = learnerData.score.raw + 0;
    }

    if (findTemplate) {
      dispatch(setLmsLearnerData(
        {
          templates: learnerData.templates.map((template: any) => {
            if (template.data.id === topic.template.id) {
              return { ...template, data: { ...template.data, completed: true } }
            }
            return template;
          }),
          score: { ...learnerData.score, raw: score }
        }
      ))
    } else {
      dispatch(setLmsLearnerData(
        {
          templates: [...learnerData.templates, { id: topic.template.id, title: topic.template.title, type: topic.template.type, data: { visited: true, completed: true } }],
          score: { ...learnerData.score, raw: score }
        }
      ))
    }

  };

  return (
    <>
      {!type ? (
        <Layout
          data={data}
          type={templateType}
          handleDataChange={(data) => handleDataChange(data)}
          handleOpenAuthoringModal={(state) => {
            handleOpenAuthoringModal && handleOpenAuthoringModal(state);
          }}
          handleCancelTemplateForm={() => {
            handleCancelTemplateForm && handleCancelTemplateForm();
          }}
          openTemplate={openTemplate}
        >
          <div
            style={data?.styles?.container}
            className={`h-full w-full flex flex-col items-start justify-start p-4 space-y-2 `}
          >
            <div className="">
              <div className={`text-[14px] font-light`}>{data.prompt}</div>
            </div>

            <div className="grow w-full">
              <div className="w-full grid grid-cols-2 gap-3 items-center pt-[1rem]">
                <div className="col-span-1">
                  {shuffledItems.map((item: any, index: number) => (
                    <div className="flex item-center space-x-2 mb-1 border-2 border-[#9f9f9f] p-1 shadow-lg h-[4.7rem]">
                      {item?.itemImage && (
                        <div className="w-[6rem] h-[4rem] border-2 border-gray-300 flex-shrink-0 flex items-center justify-center overflow-hidden">
                          <img
                            className="max-w-max h-full object-contain"
                            src={item?.itemImage?.url}
                            alt="itemImage"
                          />
                        </div>
                      )}
                      {item?.value && (
                        <div className="flex min-w-[13rem] grow bg-white items-center flex-shrink">
                          <span className={`text-[18px] whitespace-nowrap`}>
                            {item?.value}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="col-span-1">
                  {shuffledTargets.map((target: any, index: number) => (
                    <div className="flex item-center space-x-2 mb-1 border-2 border-[#9f9f9f] p-1 shadow-lg h-[4.7rem]">
                      {target?.targetImage && (
                        <div className="w-[6rem] h-[4rem] border-2 border-gray-300 flex-shrink-0 flex items-center justify-center overflow-hidden">
                          <img
                            className="max-w-max h-full object-contain"
                            src={target?.targetImage?.url}
                            alt="itemImage"
                          />
                        </div>
                      )}

                      {target?.value && (
                        <div className="flex min-w-[13rem] grow bg-white items-center flex-shrink">
                          <span className={`text-[18px] whitespace-nowrap`}>
                            {target.value}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex space-x-2">
              <Button disabled className="mt-4 p-2 bg-blue text-white">
                Submit
              </Button>
            </div>
          </div>
        </Layout>
      ) : (
        <div className="h-full w-full flex flex-col">
          <ReactFlowProvider>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              fitView
              panOnDrag={false}
              nodesDraggable={false}
              className="w-full grid grid-cols-2 gap-3 items-center pt-[1rem]"
            >
              {/* <MiniMap /> */}
              {/* <Controls /> */}
              {/* <Background /> */}
            </ReactFlow>
          </ReactFlowProvider>
          <div className="mt-4 pl-4 pb-1">
            <Button disabled={isSubmitted} onClick={handleSubmit} className="p-2 bg-blue text-white">
              Submit
            </Button>
          </div>
          {openFeedback && (
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col w-[60%] h-[50%] border border-gray-300 bg-white">
              <div
                className={`relative text-lg font-semibold p-2 ${status === "correct" ? "bg-green-500" : "bg-red-500"
                  }`}
              >
                {status === "correct" ? "Correct" : "Incorrect"}
                <span className="absolute right-2 cursor-pointer" onClick={() => setOpenFeedback("")}>X</span>
              </div>
              <div className="text-sm grow p-2">{openFeedback}</div>

              <div className="flex flex-col p-2">
                <div className="flex flex-row space-x-2">
                  <div className="flex-grow text-center font-semibold">Match Item</div>
                  <div className="flex-grow text-center font-semibold">Match Target</div>
                </div>
                {data.options.map((option: any, index: number) => (
                  <div key={index} className="flex flex-row space-x-2">
                    <div className="flex-grow text-center">
                      {option.item.value}
                    </div>
                    <div className="flex-grow text-center">
                      {option.target.value}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Matching;
