import React from 'react'

const frontLandScapeImageStyle: any = {
    parentContainer: {
        width: "100%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "#d1d1d1",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "60px",
        paddingRight: "70px",
        paddingBottom: "60px",
        paddingLeft: "70px",
        borderRadius: "15px",
        backgroundColor: "#DFFFD6",
        gap: "15px"
    },
    promptLabelWrap: {
        width: '100%',
        // height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '20px'
    },
    promptContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    prompt: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    questionLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '15px'
    },
    textContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    imageContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
        borderRadius: "10px",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};
const frontLandScapeStyle: any = {
    parentContainer: {
        width: "100%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "#d1d1d1",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "122px",
        paddingRight: "110px",
        paddingBottom: "122px",
        paddingLeft: "110px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: "30px"
    },
    promptLabelWrap: {
        width: '100%',
        // height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '20px'
    },
    promptContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    prompt: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    questionLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '15px'
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    imageContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
        borderRadius: "10px",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};
const frontPortraitStyle: any = {
    parentContainer: {
        width: "45%",
        height: "70%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "none",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "0px",
        paddingRight: "20px",
        paddingBottom: "30px",
        paddingLeft: "20px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: "30px"
    },
    promptLabelWrap: {
        width: '100%',
        // height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '15px'
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    promptContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    prompt: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "20px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    questionLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
};
const frontPortraitImageStyle: any = {
    parentContainer: {
        width: "50%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "none",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "20px",
        paddingRight: "20px",
        paddingBottom: "30px",
        paddingLeft: "20px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: '15px'
    },
    promptLabelWrap: {
        width: '100%',
        // height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '10px'
    },
    promptContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    prompt: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    questionLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '16px'
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    imageContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
        borderRadius: "10px",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};

const backLandScapeImageStyle: any = {
    parentContainer: {
        width: "100%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "#d1d1d1",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "60px",
        paddingRight: "70px",
        paddingBottom: "60px",
        paddingLeft: "70px",
        borderRadius: "15px",
        backgroundColor: "#DFFFD6",
        gap: "15px"
    },
    answerLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    answerLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    answerImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '15px'
    },
    textContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    imageContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "10px",
        // backgroundColor: "#ffffff",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};
const backLandScapeStyle: any = {
    parentContainer: {
        width: "100%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "#d1d1d1",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "122px",
        paddingRight: "110px",
        paddingBottom: "122px",
        paddingLeft: "110px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: "30px",
    },
    answerLabelContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    answerLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    answerImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '15px'
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    imageContainer: {
        width: "50%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "10px",
        // backgroundColor: "#ffffff",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};
const backPortraitStyle: any = {
    parentContainer: {
        width: "45%",
        height: "70%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "none",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "0px",
        paddingRight: "20px",
        paddingBottom: "30px",
        paddingLeft: "20px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: "30px"
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: "auto"
    },
    answerLabelContainer: {
        width: "100%",
        // height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    answerLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
};
const backPortraitImageStyle: any = {
    parentContainer: {
        width: "50%",
        height: "100%",
        paddingTop: "32px",
        paddingRight: "32px",
        paddingBottom: "32px",
        paddingLeft: "32px",
        // backgroundColor: "#none",
    },
    container: {
        width: "100%",
        height: "100%",
        paddingTop: "20px",
        paddingRight: "20px",
        paddingBottom: "30px",
        paddingLeft: "20px",
        borderRadius: "10px",
        backgroundColor: "#DFFFD6",
        gap: '15px'
    },
    answerLabelContainer: {
        width: "100%",
        // height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    answerLabel: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    questionImageWrap: {
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '16px'
    },
    textContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: "#ffffff",
        color: "#000000",
    },
    text: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowY: "auto"
    },
    imageContainer: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: "10px",
        // backgroundColor: "#ffffff",
    },
    image: {
        width: "100%",
        height: "100%",
        x: 0,
        y: 0,
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        objectFit: 'cover',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: "10px",
    }
};

// flipcard
const flipcardTextWithImage: any = {
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: '#82c2de',
      paddingTop: "5px",
      paddingRight: "5px",
      paddingBottom: "5px",
      paddingLeft: "5px",
    },
    textImageWrap: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "7px",
    },
    textContainer: {
      width: "100%",
      height: "60%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "#ffffff",
      color: "#000000",
    },
    text: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // overflowY: "auto",
    },
    imageContainer: {
      width: "100%",
      height: "40%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "10px",
      // backgroundColor: "#ffffff",
    },
    image: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "cover",
      justifyContent: "center",
      alignItems: "flex-start",
      // borderRadius: "10px",
    },
  }
const flipcardTextWithVideo: any = {
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: '#c7c7c7',
      paddingTop: "5px",
      paddingRight: "5px",
      paddingBottom: "5px",
      paddingLeft: "5px",
    },
    textImageWrap: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "7px",
    },
    textContainer: {
      width: "100%",
      height: "60%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "#ffffff",
      color: "#000000",
    },
    text: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // overflowY: "auto",
    },
    videoContainer: {
      width: "100%",
      height: "40%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "10px",
      // backgroundColor: "#ffffff",
    },
    video: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "cover",
      justifyContent: "center",
      alignItems: "flex-start",
      // borderRadius: "10px",
    },
  }
const flipcardTextWithAudio: any = {
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: '#ffe45c',
      paddingTop: "5px",
      paddingRight: "5px",
      paddingBottom: "5px",
      paddingLeft: "5px",
    },
    textImageWrap: {
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: "7px",
    },
    textContainer: {
      width: "100%",
      height: "50%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "#ffffff",
      color: "#000000",
    },
    text: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // overflowY: "auto",
    },
    audioContainer: {
      width: "100%",
      height: "60%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // borderRadius: "10px",
      // backgroundColor: "#ffffff",
    },
    audio: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "cover",
      justifyContent: "center",
      alignItems: "flex-start",
      // borderRadius: "10px",
    },
  }

const flipcardOnlyText: any = {
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: '#ffeadb',
      paddingTop: "5px",
      paddingRight: "5px",
      paddingBottom: "5px",
      paddingLeft: "5px",
    },
    textContainer: {
      width: "100%",
      height: "80%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: "#ffffff",
      color: "#000000",
    },
    text: {
      width: "100%",
      height: "100%",
      x: 0,
      y: 0,
      paddingTop: "0px",
      paddingRight: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      // overflowY: "auto",
    },
  }

export const LayoutStyles = new Map([
    ["front-text-left-image-right", frontLandScapeImageStyle],
    ["front-text-right-image-left", frontLandScapeImageStyle],
    ["front-only-text", frontLandScapeStyle],
    ["front-only-text-square", frontPortraitStyle],
    ["front-text-image-square", frontPortraitImageStyle],


    ["back-text-left-image-right", backLandScapeImageStyle],
    ["back-text-right-image-left", backLandScapeImageStyle],
    ["back-only-text", backLandScapeStyle],
    ["back-only-text-square", backPortraitStyle],
    ["back-text-image-square", backPortraitImageStyle],

    ["flipcard-image-with-text", flipcardTextWithImage],
    ["flipcard-video-with-text", flipcardTextWithVideo],
    ["flipcard-audio-with-text", flipcardTextWithAudio],
    ["flipcard-only-text", flipcardOnlyText],
])
