import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "src/components/ui/button";
// @ts-ignore
// import { ReactComponent as Playback } from "src/assets/Course/play_btn.svg"; this will cause an error in the build
// @ts-ignore
import { ReactComponent as TableOfContent } from "src/assets/Course/toc_btn.svg";
// import { FileSystemNode } from "../Navigation";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import {
  ChevronDown,
  ChevronRight,
  File,
  MenuIcon,
  PanelTop,
  Pause,
  Play,
  Volume2,
  VolumeX,
} from "lucide-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  PopoverArrow,
} from "@radix-ui/react-popover";
import { useDispatch } from "react-redux";
import {
  setCurrentTime,
  setIsPlayingAudio,
  setMuteAudio,
} from "src/features/templateAudio/templateAudioSlice";
import {
  MenuRoot,
  MenuTrigger,
  MenuContent,
  MenuTriggerItem,
  MenuItem,
  Menu,
  Portal,
  Box,
} from "@chakra-ui/react";
import { useLMS } from "src/context/Lms";

type FileSystemNode = {
  _id: string;
  title: string;
  template?: any;
  progress?: "completed" | "half" | "notVisited";
  children?: FileSystemNode[];
};

type Props = {
  children: React.ReactNode;
  handleBack: () => void;
  handleNext: () => void;
  isSubmitType?: any;
  isNextType?: any;
  haveResponse?: any;
  haveSubmitted?: any;
  data: any;
  dataTopics: any[];
  submitted?: boolean;
  coursePreview?: boolean;
  handleTopicChange: (topic: any) => void;
  courseData: any;
  courseSettings: any;
  menuTopics: any;
  menuData: any;
};

const CoursePreviewPlayer = ({
  children,
  handleBack,
  handleNext,
  handleTopicChange,
  courseData,
  isSubmitType,
  isNextType,
  haveResponse,
  haveSubmitted,
  data,
  dataTopics,
  submitted,
  coursePreview,
  courseSettings,
  menuTopics,
  menuData,
}: Props) => {
  const [timer, setTimer] = useState<number>(data?.timer);
  const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
  const [fullMenuOpen, setFullMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { userId, courseId } = useParams();

  const playerRef = useRef<HTMLDivElement>(null);
  const [playerDimensions, setplayerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerContainerDimensions, setplayerContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { mute, isPlaying } = useSelector((state: any) => state.templateAudio);
  const [courseMenus, setCourseMenus] = useState<any>();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);

  const [selectedTopic, setSelectedTopic] = useState("");

  const totalDuration = courseData?.reduce(
    (acc: number, template: any) =>
      acc + (template?.data?.audioFile?.duration || 0),
    0
  );
  const currentTimeRef = useRef<number>(0);

  const dispatch = useDispatch();

  const { isInitialized, setSuspendData, getSuspendData } = useLMS();
  const { learnerData } = useSelector((state: any) => state.lmsLearnerData);

  const audioRef = useRef<HTMLAudioElement>(null);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  useEffect(() => {
    const handleResize = () => {
      const player = playerRef.current;
      const playerContainer = playerContainerRef.current;
      if (playerContainer) {
        const playerContainerWidth = playerContainer.offsetWidth;
        const playerContainerHeight = (playerContainerWidth * 9) / 16 + 100;
        setplayerContainerDimensions({
          width: playerContainerWidth,
          height: playerContainerHeight,
        });
      }
      if (player) {
        const playerWidth = playerContainerDimensions.width - 20;
        const playerHeight = (playerWidth * 9) / 16;
        setplayerDimensions({ width: playerWidth, height: playerHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log("coursePreview", coursePreview, "settings", courseSettings, "data", data, "menuData", menuData);
  }, [coursePreview, courseSettings, menuTopics]);

  useEffect(() => {
    setTimer(data?.timer);
  }, [data?.timer]);

  useEffect(() => {
    if (coursePreview && timer > 0 && !submitted) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer, coursePreview, submitted]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimeOver(true);
    }
  }, [timer]);

  useEffect(() => {
    console.log(courseData);
  }, [courseData]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      // Ensure metadata is loaded before accessing duration
      const handleMetadataLoaded = () => {
        setCurrentProgress(0);
        if (audioElement.duration) {
          const elapsedTime = courseData
            ?.slice(0, currentTemplateIndex)
            ?.reduce(
              (acc: number, template: any) => acc + (template.data?.audioFile?.duration || 0),
              0
            );
          const totalElapsedTime = elapsedTime + audioElement.currentTime;

          // Update progress and dispatch current time
          setCurrentProgress((totalElapsedTime / totalDuration) * 100);
          dispatch(setCurrentTime(audioElement.currentTime));
        }
      };

      const updateProgress = () => {
        const elapsedTime = courseData
          .slice(0, currentTemplateIndex)
          .reduce(
            (acc: number, template: any) =>
              acc + (template.data.audioFile?.duration || 0),
            0
          );

        const totalElapsedTime = elapsedTime + audioElement.currentTime;
        setCurrentProgress((totalElapsedTime / totalDuration) * 100);
        dispatch(setCurrentTime(audioElement.currentTime))
      };

      // Attach event listeners
      audioElement.addEventListener("loadedmetadata", handleMetadataLoaded);
      audioElement.addEventListener("timeupdate", updateProgress);
      return () => {
        // Clean up listeners on unmount
        audioElement.removeEventListener("loadedmetadata", handleMetadataLoaded);
        audioElement.removeEventListener("timeupdate", updateProgress);
      };
    }
  }, [currentTemplateIndex, totalDuration, courseData]);

  useEffect(() => {
    // console.log(data);
    if (data?.autoPlay) {
      dispatch(setIsPlayingAudio(true));
    }
  }, [data]);

  const handlePlayPause = () => {
    console.log("audioRef", audioRef.current);
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      dispatch(setIsPlayingAudio(!isPlaying));
    }
  };

  // useEffect(() => {
  //   const audioElement = audioRef.current;
  //   if (audioElement) {
  //     const handleAudioEnd = () => {
  //       if (currentTemplateIndex < courseData.length - 1) {
  //         setCurrentTemplateIndex((prevIndex) => prevIndex + 1);
  //         handleNext();
  //         audioElement.src = courseData[currentTemplateIndex + 1].template.data.audioFile.url;
  //         audioElement.play();
  //       }
  //     };

  //     audioElement.addEventListener('ended', handleAudioEnd);
  //     return () => {
  //       audioElement.removeEventListener('ended', handleAudioEnd);
  //     };
  //   }
  // }, [currentTemplateIndex, courseData]);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      const handleAudioEnd = () => {
        console.log(
          "audio ended",
          currentTemplateIndex,
          courseData,
          courseData.length
        );
        if (currentTemplateIndex < courseData.length - 1) {
          setCurrentTemplateIndex((prevIndex) => prevIndex + 1);
          handleNext();
          const nextAudio =
            courseData[currentTemplateIndex + 1].template.data?.audioFile?.url;
          if (nextAudio && audioElement) {
            audioElement.src = nextAudio;
            audioElement.play();
          }
        } else {
          dispatch(setIsPlayingAudio(false));
        }
      };

      audioElement.addEventListener("ended", handleAudioEnd);
      return () => {
        audioElement.removeEventListener("ended", handleAudioEnd);
      };
    }
  }, [currentTemplateIndex, courseData]);

  // Updated handleSeek to prevent NaN issues
  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const audioElement = audioRef.current;
    if (audioElement && totalDuration) {
      const seekTime = Math.min(
        Math.max(0, Number(event.target.value)),
        totalDuration
      );
      audioElement.currentTime = seekTime;
      dispatch(setCurrentTime(seekTime));
    }
  };

  const formatDuration = (duration: number | undefined) => {
    if (duration === undefined || isNaN(duration)) return "00:00";
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return `${hours > 0 ? hours.toString().padStart(2, "0") + ":" : ""}${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // Function to format time in MM:SS format
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    // console.log(haveResponse, isSubmitType, isNextType, haveSubmitted);
    // console.log(isPlaying);
  }, [haveResponse, isSubmitType, isNextType, haveSubmitted, isPlaying]);

  // const submitCase =
  //   !haveResponse ||
  //   (haveResponse && isSubmitType && !haveSubmitted);
  // const nextCase =
  //   !haveResponse ||
  //   (haveResponse && !isNextType && !haveSubmitted);
  const isNextDisabled = false;
  // const isNextDisabled = !data?.courseController?.skip;
  // const isNextDisabled = submitCase || nextCase;

  const toggleNodeExpand = (id: string) => {
    const newSet = new Set(expandedNodes);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedNodes(newSet);
  };
  const toggleAccNodeExpand = (id: string, depth: number) => {
    setExpandedNodes((prev) => {
      const newSet = new Set(prev);
      const key = `${depth}-${id}`;

      // Check if the current node is already expanded
      if (newSet.has(key)) {
        newSet.delete(key); // Collapse the node
      } else {
        // Remove all nodes at the same depth level
        [...newSet].forEach((nodeId) => {
          if (nodeId.startsWith(`${depth}-`)) {
            newSet.delete(nodeId);
          }
        });
        newSet.add(key); // Expand the new node
      }

      return newSet;
    });
  };

  // Render default sidebar with progress bar
  const renderDefaultStructure = (
    nodes: FileSystemNode[],
    depth: number = 0,
    parentExpanded: boolean = false
  ) => (
    <ul className="w-full">
      {nodes?.map((node: any) => {
        console.log("default sidebar");
        console.log("node", node);

        // Skip rendering `tabsFolder` type nodes and render only its first child
        let tabTemplate: any;
        if (node.type === "tabsFolder") {
          tabTemplate = node.children?.[0];
          if (!tabTemplate) return null;
        }

        const isCompleted = learnerData.templates.find((topic: any) => {
          if (node && node.template !== null) {
            return topic.id === node.template?.id;
          } else if (node && node.template === null && node.tools.id) {
            return topic.id === node.tools?.id;
          }
        })?.data.completed;

        var topicCompletedChild = 0;
        var topicChild = 0;

        if (node.children && node.children.length > 0) {
          topicChild = node.children.length;
          console.log("topicChild", topicChild);

          learnerData.templates.forEach((topic: any) => {
            node.children &&
              node.children.forEach((childTopic: any) => {
                if (childTopic.children) return;

                console.log(childTopic?.template?.id);

                if (
                  childTopic.template?.id === topic.id ||
                  childTopic.tools?.id === topic.id
                )
                  topicCompletedChild = topicCompletedChild + 1;
              });
          });

          console.log("topicCompletedChild", topicCompletedChild);
        }

        return (
          <li key={node.id} className={`w-full`}>
            <div
              onClick={() => {
                if (node.type === "folder") {
                  toggleNodeExpand(node.id);
                } else {
                  if(node.type === "tabsFolder" && tabTemplate) {
                    handleTopicChange(tabTemplate);
                    setSelectedTopic(tabTemplate.id);
                  }
                  else {
                    handleTopicChange(node);
                    setSelectedTopic(node.id);
                  }
                }
              }}
              className={`relative w-full cursor-pointer ${
                depth === 0
                  ? "pl-[16px]"
                  : depth === 1
                  ? "pl-[32px]"
                  : depth === 2
                  ? "pl-[48px]"
                  : "pl-[64px]"
              } pr-4 py-1.5 border border-t-0 border-[#10103a] ${
                selectedTopic === node.id ? "bg-[#dedede] text-black" : ""
              } hover:bg-[#dedede] hover:text-black flex items-center space-x-2 group`}
            >
              {/* Progress Bar */}
              <div className="h-2 w-8 flex-shrink-0 relative">
                <div
                  className={`h-full border-2 ${
                    isCompleted ||
                    (node.type !== "file" && topicChild === topicCompletedChild)
                      ? "bg-green-500"
                      : "border-red-500"
                  } rounded-full overflow-hidden relative`}
                ></div>
              </div>

              {/* Node Content */}
              {node.type !== "file" ? (
                <>
                  <span className="flex items-center w-full justify-between">
                    {node.type === "folder" ? (
                      <>
                        <span className="text-base">{node.name}</span>
                        {expandedNodes.has(node.id) ? (
                          <ChevronDown size={16} />
                        ) : (
                          <ChevronRight size={16} />
                        )}
                      </>
                    ) : (
                      <span className="text-base">{tabTemplate.name}</span>
                    )}
                  </span>
                </>
              ) : (
                <span
                  onClick={() => {
                    // handleTopicChange(node);
                  }}
                  className="flex items-center "
                >
                  <span className="text-base">{node.name}</span>
                </span>
              )}
            </div>

            {/* Render Children if Node is Expanded */}
            {node.type !== "file" &&
              node.children &&
              node.children.length > 0 &&
              expandedNodes.has(node.id) && (
                <div className="">
                  {renderDefaultStructure(
                    node.children,
                    depth + 1,
                    expandedNodes.has(node.id)
                  )}
                </div>
              )}
          </li>
        );
      })}
    </ul>
  );

  // Render items with progress bar
  const renderStructure = (
    nodes: FileSystemNode[],
    depth: number = 0,
    parentExpanded: boolean = false
  ) => (
    <ul className="w-full">
      {nodes?.map((node: FileSystemNode) => {
        console.log("sidebar and menu");
        const dataTopic = dataTopics.filter(
          (top: any) => top?.id === node?.template?.id
        )[0];
        const isCompleted = learnerData.templates.find((topic: any) => {
          // console.log("topic", topic, "dataTopic", dataTopic)
          if (dataTopic && dataTopic.template !== null) {
            return topic.id === dataTopic.template?.id;
          } else if (
            dataTopic &&
            dataTopic.template === null &&
            dataTopic.tools.id
          ) {
            return topic.id === dataTopic.tools?.id;
          }
        })?.data.completed;

        var topicCompletedChild = 0;
        var topicChild = 0;

        if (
          node.template === "None" &&
          node.children &&
          node.children.length > 0
        ) {
          topicChild = node.children.length;
          console.log("topicChild", topicChild);

          learnerData.templates.forEach((topic: any) => {
            node.children &&
              node.children.forEach((childTopic: any) => {
                if (childTopic.template === "None") return;
                const dataTopic = dataTopics.filter(
                  (top: any) => top?.id === childTopic?.template?.id
                )[0];

                console.log(
                  dataTopic.template?.id,
                  dataTopic.tools?.id,
                  topic.id,
                  dataTopic
                );

                if (
                  dataTopic.template?.id === topic.id ||
                  dataTopic.tools?.id === topic.id
                )
                  topicCompletedChild = topicCompletedChild + 1;
              });
          });

          console.log("topicCompletedChild", topicCompletedChild);
        }

        return (
          <li key={node._id} className={`w-full`}>
            <div
              onClick={() => {
                if (node.template === "None") {
                  toggleNodeExpand(node._id);
                } else {
                  handleTopicChange(node);
                  setSelectedTopic(node._id);
                }
              }}
              className={`relative w-full cursor-pointer ${
                depth === 0
                  ? "pl-[16px]"
                  : depth === 1
                  ? "pl-[32px]"
                  : depth === 2
                  ? "pl-[48px]"
                  : "pl-[64px]"
              } pr-4 py-1.5 border border-t-0 border-[#10103a] ${
                courseSettings?.toc?.layout === "menu"
                  ? selectedTopic === node._id
                    ? "bg-[#10103a] text-white"
                    : ""
                  : selectedTopic === node._id
                  ? "bg-[#dedede] text-black"
                  : ""
              }  ${
                courseSettings?.toc?.layout === "menu"
                  ? "hover:bg-[#10103a] hover:text-white"
                  : "hover:bg-[#dedede] hover:text-black"
              } flex items-center space-x-2 group`}
            >
              {/* Progress Bar */}
              <div className="h-2 w-8 flex-shrink-0 relative">
                <div
                  className={`h-full border-2 ${
                    isCompleted ||
                    (node.template === "None" &&
                      topicChild === topicCompletedChild)
                      ? "bg-green-500"
                      : "border-red-500"
                  } rounded-full overflow-hidden relative`}
                >
                  <div
                  // className={`h-full ${
                  //   progressPercentage === 100
                  //     ? "bg-green-500"
                  //     : "bg-red-500"
                  // }`}
                  // style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
              </div>

              {/* Node Content */}
              {node.template === "None" ? (
                <span className="flex items-center w-full justify-between">
                  <span className="text-base">{node.title}</span>
                  {expandedNodes.has(node._id) ? (
                    <ChevronDown size={16} />
                  ) : (
                    <ChevronRight size={16} />
                  )}
                </span>
              ) : (
                <span
                  onClick={() => {
                    // handleTopicChange(node);
                  }}
                  className="flex items-center space-x-2"
                >
                  {/* <File color="#333333" size={16} /> */}
                  <span className="text-base">{node.title}</span>
                </span>
              )}
            </div>

            {/* Render Children if Node is Expanded */}
            {node.template === "None" &&
              node.children &&
              node.children.length > 0 &&
              expandedNodes.has(node._id) && (
                <div className="">
                  {renderStructure(
                    node.children,
                    depth + 1,
                    expandedNodes.has(node._id)
                  )}
                </div>
              )}
          </li>
        );
      })}
    </ul>
  );

  const accordionMenuStructure = (
    nodes: FileSystemNode[],
    depth: number = 0,
    parentExpanded: boolean = false
  ) => (
    <ul className="w-full">
      {nodes?.map((node) => {
        const key = `${depth}-${node._id}`;
        const isExpanded = expandedNodes.has(key);

        const dataTopic = dataTopics.filter(
          (top: any) => top?.id === node?.template?.id
        )[0];
        const isCompleted = learnerData.templates.find((topic: any) => {
          if (dataTopic && dataTopic.template !== null) {
            return topic.id === dataTopic.template?.id;
          } else if (
            dataTopic &&
            dataTopic.template === null &&
            dataTopic.tools.id
          ) {
            return topic.id === dataTopic.tools?.id;
          }
        })?.data.completed;

        var topicCompletedChild = 0;
        var topicChild = 0;

        if (
          node.template === "None" &&
          node.children &&
          node.children.length > 0
        ) {
          topicChild = node.children.length;
          console.log("topicChild", topicChild);

          learnerData.templates.forEach((topic: any) => {
            node.children &&
              node.children.forEach((childTopic: any) => {
                if (childTopic.template === "None") return;
                const dataTopic = dataTopics.filter(
                  (top: any) => top?.id === childTopic?.template?.id
                )[0];

                console.log(
                  dataTopic.template?.id,
                  dataTopic.tools?.id,
                  topic.id,
                  dataTopic
                );

                if (
                  dataTopic.template?.id === topic.id ||
                  dataTopic.tools?.id === topic.id
                )
                  topicCompletedChild = topicCompletedChild + 1;
              });
          });

          console.log("topicCompletedChild", topicCompletedChild);
        }

        return (
          <li key={node._id} className={`w-full`}>
            <div
              onClick={() => {
                if (node.template === "None") {
                  toggleAccNodeExpand(node._id, depth);
                } else {
                  handleTopicChange(node);
                  setSelectedTopic(node._id);
                }
              }}
              className={`relative cursor-pointer w-full px-3 py-3 ${
                selectedTopic === node._id && "bg-[#dadada]"
              } ${isExpanded && "bg-yellow-300 hover:bg-yellow-300"} ${
                parentExpanded ? "bg-[#e5e5e5]" : ""
              } border-b border-[#a2a2a2] hover:bg-[#dadada] flex items-center space-x-4 group`}
            >
              {/* Progress Bar */}
              <div className={`h-3.5 w-3.5 flex-shrink-0 relative`}>
                <div
                  className={`h-full ${
                    isCompleted ||
                    (node.template === "None" &&
                      topicChild === topicCompletedChild)
                      ? "bg-blue"
                      : "bg-[#b1b1b1]"
                  } bg- overflow-hidden relative`}
                >
                  <div></div>
                </div>
              </div>

              {/* Node Content */}
              {node.template === "None" ? (
                <span className="flex items-center w-full justify-between">
                  <span className="text-base text-blue font-medium">
                    {node.title}
                  </span>
                  {isExpanded ? (
                    <ChevronDown size={16} />
                  ) : (
                    <ChevronRight size={16} />
                  )}
                </span>
              ) : (
                <span
                  onClick={() => {
                    // handleTopicChange(node);
                  }}
                  className="flex items-center space-x-2"
                >
                  <span className="text-base text-blue font-medium">
                    {node.title}
                  </span>
                </span>
              )}
            </div>

            {/* Render Children if Node is Expanded */}
            {node.template === "None" &&
              node.children &&
              node.children.length > 0 &&
              isExpanded && (
                <div className="">
                  {accordionMenuStructure(node.children, depth + 1, isExpanded)}
                </div>
              )}
          </li>
        );
      })}
    </ul>
  );

  return (
    <main className=" w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins">
      <div className="flex w-full h-[3rem] px-[1.25rem] py-[0.5625] text-lg items-center justify-between">
        <span>preview</span>
        {coursePreview && (
          <>
            {/* try to comment this button before you create the bundle for scorm */}
            <button
              onClick={() => navigate(`/user/${userId}/course/${courseId}`)}
              className="absolute right-3 top-[0.18rem] mt-1 z-10 px-3 py-1 rounded-md bg-tertiary hover:bg-tertiary/60 text-white"
            >
              Exit
            </button>
          </>
        )}
      </div>

      <section className="relative w-full h-[calc(100%-3rem)] bg-[#d1d1d1] flex items-center justify-center">
        {coursePreview && (
          <>
            {data?.isTimer && (
              <div
                className={` absolute right-[4rem] top-0 border-2 w-52 rounded-md p-1 mt-1 ${
                  submitted
                    ? "border-black"
                    : !isTimeOver
                    ? "border-green-500"
                    : "border-red-500"
                }`}
              >
                <div
                  className={`flex justify-between font-semibold ${
                    submitted
                      ? "text-black"
                      : !isTimeOver
                      ? "text-green-500"
                      : "text-red-500"
                  } `}
                >
                  <span>
                    {!isTimeOver ? "Time remaining: " : "Timer has ended: "}
                  </span>
                  <span>{formatTime(timer)}</span>
                </div>
              </div>
            )}
          </>
        )}
        <div className=" containerplayer ">
          {courseSettings?.haveTOC &&
            courseSettings?.toc &&
            courseSettings?.toc.layout === "sidebar" && (
              <div
                style={{
                  height: "37.8rem",
                  width: "15rem",
                }}
                className="rounded-md overflow-y-scroll no-scrollbar bg-white z-10 border-2 border-gray-400 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
              >
                {renderStructure(menuData || [])}
              </div>
            )}
          {courseSettings?.haveTOC &&
            courseSettings?.toc?.layout === "defaultMenu" && (
              <div
                style={{
                  height: "37.8rem",
                  width: "15rem",
                }}
                className="rounded-md overflow-y-scroll no-scrollbar bg-white z-10 border-2 border-gray-400 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
              >
                {renderDefaultStructure(courseData || [])}
              </div>
            )}

          <div
            ref={playerContainerRef}
            // style={{
            //   height: `${playerContainerDimensions.height}px`,
            // }}
            style={{
              backgroundColor: courseSettings?.backgroundColor || "#ffffff",
            }}
            className="flex flex-col items-center canvas !rounded-t-none"
          >
            {courseSettings?.logoImageFile ||
            courseSettings?.title ||
            (courseSettings?.haveTOC &&
              courseSettings?.toc &&
              (courseSettings?.toc.layout === "menu" ||
                courseSettings?.toc.layout === "tiles" ||
                courseSettings?.toc.layout === "accordionMenu")) ? (
              <div
                className={`flex space-x-5 items-center w-full p-3 border-b-2 border-[#ccc] `}
              >
                {courseSettings?.haveTOC &&
                  courseSettings?.toc &&
                  courseSettings?.toc.layout === "menu" && (
                    <div className=" flex items-center hover:bg-gray-100">
                      <Popover>
                        <PopoverTrigger asChild>
                          <div role="button">
                            <MenuIcon size={28} />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent
                          align="start"
                          side="bottom"
                          sideOffset={12}
                          className="rounded-md overflow-y-scroll no-scrollbar fixed top-[2px] left-[-12px] bg-white z-10 border-2 border-gray-400 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
                          style={{
                            maxHeight: `${
                              (playerContainerDimensions.width * 9) / 16
                            }px`,
                            width: "17rem",
                            // overflowY: "auto"
                          }}
                        >
                          {renderStructure(menuData || [])}
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}

                {courseSettings?.haveTOC &&
                  courseSettings?.toc &&
                  courseSettings?.toc.layout === "tiles" && (
                    <div className=" flex items-center hover:bg-gray-100">
                      <div
                        role="button"
                        onClick={() => setFullMenuOpen(!fullMenuOpen)}
                      >
                        <MenuIcon size={28} />
                      </div>
                      {fullMenuOpen && (
                        <div className="absolute bg-white w-full h-[calc(100%-3rem)] z-10 top-[3.25rem] left-0 border-t-2 border-[#ccc]">
                          <MenuTemplate
                            menuTopics={menuTopics}
                            dataTopics={dataTopics}
                            handleTopicChange={(topic) => {
                              handleTopicChange(topic);
                              setFullMenuOpen(false);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                {courseSettings?.haveTOC &&
                  courseSettings?.toc &&
                  courseSettings?.toc.layout === "accordionMenu" && (
                    <div className=" flex items-center hover:bg-gray-100">
                      <Popover>
                        <PopoverTrigger asChild>
                          <div role="button">
                            <MenuIcon size={28} />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent
                          align="start"
                          side="bottom"
                          sideOffset={12}
                          className=" bg-white overflow-y-scroll no-scrollbar z-10 fixed top-[2px] left-[-12px] shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
                          style={{
                            height: `${
                              (playerContainerDimensions.width * 9) / 16
                            }px`,
                            width: "17rem",
                            // overflowY: "scroll",
                          }}
                        >
                          {accordionMenuStructure(menuData || [])}
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}

                <div
                  className={`flex space-x-5 items-center w-full ${
                    courseSettings?.alignment === "center"
                      ? "justify-center"
                      : courseSettings?.alignment === "left"
                      ? "justify-start"
                      : "justify-end"
                  }`}
                >
                  {courseSettings?.logoImageFile && (
                    <div className="w-[2.5rem] h-[2.5rem]">
                      <img
                        src={courseSettings?.logoImageFile?.url}
                        alt={courseSettings?.logoImageFile?.name}
                        className="!w-full !h-full object-cover"
                      />
                    </div>
                  )}

                  {courseSettings?.title && (
                    <div className="space-x-5 text-lg font-medium">
                      {courseSettings?.title}
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            <div className="w-full aspect-[16/9]">{children}</div>

            {/* Player Controls */}
            {courseSettings?.havePlayback ||
            courseSettings?.haveNavigation ||
            courseSettings?.havePagination ||
            courseSettings?.haveTOC ? (
              <div className="flex justify-between items-center w-full p-3 border-t-2 border-[#ccc]">
                <div className="flex space-x-5 items-center">
                  {courseSettings?.havePlayback && (
                    <Button
                      style={{
                        backgroundColor:
                          courseSettings?.primaryAccent || "#AB0273",
                      }}
                      className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded "
                      onClick={() => handlePlayPause()}
                    >
                      {isPlaying ? <Pause /> : <Play />}
                    </Button>
                  )}
                  {data?.audioFile && (
                    <div className="flex items-center space-x-2">
                      <div className="flex items-center justify-center">
                        {mute ? (
                          <button onClick={() => dispatch(setMuteAudio(false))}>
                            <VolumeX />
                          </button>
                        ) : (
                          <button onClick={() => dispatch(setMuteAudio(true))}>
                            <Volume2 />
                          </button>
                        )}
                        {/* <audio
                            ref={audioRef}
                            className="hidden rounded-[1.5rem] border-2 border-black/30 w-[100px] h-[100px]"
                            autoPlay={data?.autoPlay}
                            // controls
                            muted={mute}
                            onTimeUpdate={() => setCurrentTime(audioRef.current?.currentTime || 0)}
                          >
                            <source src={data?.audioFile?.url} />
                          </audio> */}
                      </div>

                      {/* {data.audioFile.duration > 0 && (
                          <div className='flex items-center space-x-1'>
                            <input
                              type="range"
                              min="0"
                              max={data.audioFile.duration}
                              value={currentTime}
                              onChange={handleSeek}
                            />
                            <span>{formatDuration(currentTime)} / {formatDuration(data.audioFile.duration)}</span>
                          </div>
                        )} */}

                      <audio
                        ref={audioRef}
                        className=" rounded-[1.5rem] border-2 border-black/30 w-[100px] h-[100px]"
                        src={
                          courseData[currentTemplateIndex]?.template.data
                            .audioFile.url
                        }
                        autoPlay={data?.autoPlay}
                        muted={mute}
                        // onTimeUpdate={() => currentTimeRef.current = audioRef.current?.currentTime || 0}
                      />

                      <input
                        type="range"
                        min="0"
                        max={totalDuration}
                        value={(totalDuration * currentProgress) / 100}
                        onChange={handleSeek}
                        className="w-[300px] h-[5px] rounded-full bg-gray-300 cursor-pointer appearance-none overflow-hidden"
                        style={{
                          background: `linear-gradient(to right, #AB0273 0%, #AB0273 ${currentProgress}%, #d1d1d1 ${currentProgress}%, #d1d1d1 100%)`,
                        }}
                      />
                      <span className="text-xs">
                        {formatDuration(
                          (totalDuration * currentProgress) / 100
                        )}
                      </span>
                      <span className="text-xs">/</span>
                      <span className="text-xs">
                        {formatDuration(totalDuration)}
                      </span>
                    </div>
                  )}
                </div>

                <div className="flex space-x-5 items-center">
                  {courseSettings?.havePagination && (
                    <div
                      style={{
                        color: courseSettings?.secondaryAccent || "#d1d1d1",
                      }}
                      className="text-gray-400 whitespace-nowrap"
                    >
                      {menuTopics.length}
                    </div>
                  )}

                  {courseSettings?.haveNavigation && (
                    <>
                      <Button
                        style={{
                          backgroundColor:
                            courseSettings?.primaryAccent || "#AB0273",
                        }}
                        className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                        onClick={handleBack}
                      >
                        Previous
                      </Button>
                      <Button
                        disabled={isNextDisabled}
                        style={{
                          backgroundColor:
                            courseSettings?.primaryAccent || "#AB0273",
                        }}
                        className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </main>
  );
};

export default CoursePreviewPlayer;

const MenuTemplate: React.FC<{
  menuTopics: any;
  dataTopics: any[];
  handleTopicChange: (topic: any) => void;
}> = ({ menuTopics, dataTopics, handleTopicChange }) => {
  const topicColors = useMemo(() => {
    const colorMap = new Map();
    const generateRandomColor = () => {
      // Generate a darker color by limiting the RGB values to a lower range
      const getRandomValue = () => Math.floor(Math.random() * 155); // Values from 0 to 155
      const r = getRandomValue();
      const g = getRandomValue();
      const b = getRandomValue();
      return `rgb(${r}, ${g}, ${b})`; // Create a color string in RGB format
    };

    menuTopics.forEach((topic: any) => {
      console.log("menuTopic topic", topic);
      if (!colorMap.has(topic._id)) {
        colorMap.set(topic._id, generateRandomColor());
      }
    });

    return colorMap;
  }, [menuTopics]);

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData);

  const formatDuration = (duration: number) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  console.log(menuTopics);

  return (
    <div className="w-full h-full px-[2rem] py-[1rem] bg-[#bcbcbc]">
      <div className="w-full h-full flex flex-wrap justify-between gap-y-4 overflow-y-scroll">
        {menuTopics &&
          menuTopics.map((topic: any, index: number) => {
            console.log("tile topic", topic);

            const dataTopic = dataTopics.filter(
              (top: any) => top?.id === topic?.template?.id
            )[0];

            const isCompleted = learnerData.templates.find((template: any) => {
              // console.log("topic", topic, "dataTopic", dataTopic)
              if (dataTopic && dataTopic.template !== null) {
                return template.id === dataTopic.template?.id;
              } else if (
                dataTopic &&
                dataTopic.template === null &&
                dataTopic.tools.id
              ) {
                return template.id === dataTopic.tools?.id;
              }
            })?.data.completed;

            return (
              <div
                key={index}
                onClick={() => handleTopicChange(topic)}
                className=" flex w-[48%] h-[6.5rem] cursor-pointer"
              >
                <div
                  style={{ backgroundColor: topicColors.get(topic._id) }}
                  className="w-[5.5rem] flex flex-col text-white p-2 font-medium flex-shrink-0"
                >
                  <span className="text-end text-xl">{index + 1}</span>
                  <span className="text-center text-lg">Module</span>
                  <span className="text-end text-xs mt-4">
                    {formatDuration(
                      topic.template.data?.audioFile?.duration || 0
                    )}
                  </span>
                </div>
                <div className="grow h-full flex flex-col ">
                  <div className="h-[5rem] bg-[#9a9a9a] flex items-center font-medium p-2">
                    {topic.title}
                  </div>
                  <div className="bg-white w-full text-center">
                    {isCompleted ? <>Visited</> : <>Not Visited</>}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
