import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    selectedTemplate: {
        id: number,
        title: string,
        type: string,
        description: string,
        data: any[],
        openTemplate: boolean,
    } | null,
    selectedTool: {
        id: number,
        title: string,
        type: string,
        description: string,
        data: any[]
    } | null,
    selectedToolId: string | null,
    selectedTools: any[] | null
}

const initialState: InitialState = {
    selectedTemplate: null,
    selectedTool: null,
    selectedToolId: null,
    selectedTools: null,
};

export const courseTemplateSlice = createSlice({
    name: "courseTemplate",
    initialState,
    reducers: {
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload;
        },
        removeSelectedTemplate: (state) => {
            state.selectedTemplate = null;
        },
        setSelectedTool: (state, action) => {
            state.selectedTool = action.payload;
        },
        removeSelectedTool: (state) => {
            state.selectedTool = null;
        },
        setSelectedToolId: (state, action) => {
            state.selectedToolId = action.payload
        },
        setSelectedTools: (state, action) => {
            state.selectedTools = action.payload
        },
        removeSelectedTools: (state) => {
            state.selectedTools = null;
        },
    },
})

export const { setSelectedTemplate, removeSelectedTemplate, setSelectedTool, removeSelectedTool, setSelectedToolId, setSelectedTools, removeSelectedTools } = courseTemplateSlice.actions;
export default courseTemplateSlice.reducer;