import { template } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button } from 'src/components/ui';

type Props = {
    templateData: any;
    handleUpdateTemplateData: (data: any) => void;
    canvasActiveComponent: any;
};

const FillInTheBlanksContent: React.FC<Props> = ({ templateData, handleUpdateTemplateData, canvasActiveComponent }) => {
    const [prompt, setPrompt] = useState<string>('');
    const [sentence, setSentence] = useState<string>('');
    const [inputValues, setInputValues] = useState<Map<string, string>>(new Map());
    const [blankCount, setBlankCount] = useState(Object.entries(templateData.data.inputValues).length + 1);

    useEffect(() => {
        console.log('templateData', templateData);
        if (templateData.data) {
            setPrompt(templateData.data.prompt);
            setSentence(templateData.data.content);
            if (templateData.data.inputValues) setInputValues(new Map(Object.entries(templateData.data.inputValues)));
        }
        console.log(blankCount);
    }, []);

    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(e.target.value);
        handleUpdateTemplateData({ ...templateData, data: { ...templateData.data, prompt: e.target.value } });
    };

    const handleSentenceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const updatedSentence = e.target.value;
        setSentence(updatedSentence);

        // Extract all input identifiers from the updated sentence
        const regex = /\[Blank_\d+\]/g;
        const matches = updatedSentence.match(regex) || [];
        const validIds = new Set(matches.map((match) => match.slice(1, -1)));

        // Remove input values for identifiers not present in the updated sentence
        const newInputValues = new Map(
            Array.from(inputValues.entries()).filter(([id]) => validIds.has(id))
        );
        setInputValues(newInputValues);

        handleUpdateTemplateData({
            ...templateData,
            data: { ...templateData.data, content: updatedSentence, inputValues: Object.fromEntries(newInputValues) },
        });
    };

    // const addBlankInput = () => {
    //     const uniqueId = `input_${Date.now()}`;
    //     setInputValues((prev) => new Map(prev).set(uniqueId, ''));
    //     setSentence((prev) => `${prev} [${uniqueId}]`);
    //     handleUpdateTemplateData({ ...templateData, data: { ...templateData.data, content: `${sentence} [${uniqueId}]` } });
    // };

    const addBlankInput = () => {
        const newBlankId = `Blank_${blankCount}`;
        setInputValues(new Map(inputValues.set(newBlankId, '')));
        setSentence((prev) => `${prev} [${newBlankId}]`);
        handleUpdateTemplateData({ ...templateData, data: { ...templateData.data, content: `${sentence} [${newBlankId}]` } });
        setBlankCount(blankCount + 1);
    };

    const handleCorrectAnswerChange = (id: string, value: string) => {
        console.log('id', id, 'value', value);
        const newValues = new Map(inputValues).set(id, value);
        console.log('newValues', newValues);
        setInputValues(newValues);
        handleUpdateTemplateData({ ...templateData, data: { ...templateData.data, inputValues: Object.fromEntries(newValues) } });
    };

    return (
        <div className='p-4'>
            <div className='mb-4'>
                <label className='block text-sm font-medium mb-2'>Prompt</label>
                <input
                    type='text'
                    className='border border-gray-300 p-2 rounded w-full'
                    value={prompt}
                    onChange={handlePromptChange}
                />
            </div>
            <div className='mb-4'>
                <label className='block text-sm font-medium mb-2'>Sentence</label>
                <textarea
                    className='border border-gray-300 p-2 rounded w-full h-[200px]'
                    value={sentence}
                    onChange={handleSentenceChange}
                />
                <Button
                    className=''
                    onClick={addBlankInput}
                >
                    Add Blank Input
                </Button>
            </div>
            <div className='mb-4'>
                <h3 className='text-sm'>Set Correct Answers for Blanks</h3>
                {Array.from(inputValues.keys()).map((id) => (
                    <div key={id} className='mb-2'>
                        <label className='block text-sm'>{id}</label>
                        <input
                            type='text'
                            value={inputValues.get(id)}
                            className='border border-gray-300 p-2 rounded w-full'
                            onChange={(e) => handleCorrectAnswerChange(id, e.target.value)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FillInTheBlanksContent;
