import React, { useEffect, useRef, useState } from "react";
import { FileSystemNode } from "../Navigation";
import Nav from "src/components/Navbar";
import { Cross, Expand, MenuIcon, Minimize, X } from "lucide-react";

type Props = {
  data?: any;
  handleTopicChange: (topic: any) => void;
  handleNextTopic: () => void;
  children: React.ReactNode;
};

function Layout({ children, data, handleTopicChange, handleNextTopic }: Props) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const sidebarRef = useRef<HTMLDivElement>(null);

  function sidebarExpand() {
    if (sidebarRef.current) {
      sidebarRef.current.style.width = "300px";
      setIsCollapsed(false);
    }
  }

  function sidebarCollapse() {
    if (sidebarRef.current) {
      sidebarRef.current.style.width = "0";
      setIsCollapsed(true);
    }
  }

  const renderStructure = (nodes: FileSystemNode[], depth: number = 0) => (
    <ul className="w-full ">
      {nodes.map((node) => (
        <li
          key={node.id}
          // className={` w-full ${node.id === searchParams.get('templateId') ? "bg-[#dedede]" : ""}`}
          className={` w-full `}
        >
          <div
            onClick={() => {
              if (node.type === "file") {
                handleTopicChange(node);
                // setSearchParams({ templateId: node.id });
              }
            }}
            className={`relative w-full ${node.depth === 0
              ? "pl-[16px]"
              : node.depth === 1
                ? "pl-[32px]"
                : node.depth === 2
                  ? "pl-[48px]"
                  : "pl-[64px]"
              } pr-4 py-1.5 hover:bg-[#dedede] flex items-center space-x-2 group`}
          >
            <>
              <span className="text-xl">{node.name}</span>
            </>
          </div>
          {node.type === "folder" &&
            node.children &&
            node.children.length > 0 &&
            renderStructure(node.children, depth + 1)}
        </li>
      ))}
    </ul>
  );

  return (
    <main className="relative w-full h-full mx-auto overflow-hidden font-poppins">
      {/* <Nav type='Course-Preview' /> */}
      <section
        className={`w-full h-full flex`}
      >
        {/* <aside className="relative min-w-[5rem]">
          <div
            ref={sidebarRef}
            className="h-full pt-[2.5rem] overflow-hidden bg-[#EFEFEF] relative flex w-0 flex-col"
          >
            {renderStructure(data, 0)}
          </div>
          {isCollapsed ? (
            <MenuIcon
              className="absolute top-3 left-5"
              role="button"
              onClick={() => sidebarExpand()}
              size={24}
            />
          ) : (
            <X
              className="absolute top-3 right-5"
              role="button"
              onClick={() => sidebarCollapse()}
              size={24}
            />
          )}

        </aside> */}
        <div className="w-full h-full flex justify-center items-center">
          {children}
        </div>
      </section>
    </main>
  );
}

export default Layout;
