import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import Text from "src/utils/Text/Txt";
import { Option } from "../utils/DefaultTemplateData";
import { set, values } from "lodash";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import { useSelector } from "react-redux";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "src/components/UploadDropdowns";
import UploadImage from "src/components/Course/UploadImage/UploadImage";

type Props = {
  type?: string;
  data: any;
  handleDataChange: (data: any) => void;
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
};

function Question({
  type,
  data,
  handleDataChange,
  optionQuantity,
  handleImageOrOptionClicked,
}: Props) {
  // const [templateData, setTemplateData] = useState(null);
  // const [question, setQuestion] = React.useState('');
  // const [prompt, setPrompt] = React.useState('');
  const [templateType, setTemplateType] = useState("");
  // const [options, setOptions] = useState([]);
  // const [globalFeedback, setGlobalFeedback] = useState('');
  const [currentOptionQuantity, setCurrentOptionQuantity] =
    useState<number>(optionQuantity);

  const [haveMainImage, setHaveMainImage] = useState<boolean>(
    data?.haveImage ?? false
  );
  const [haveOptionImage, setHaveOptionImage] =
    useState<boolean>(data.haveOptionImage ?? false);
  const [image, setImage] = useState<object | null>(data.imageFile ?? null);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
    // setQuestion(data.question);
    // setPrompt(data.prompt);
    if (type) setTemplateType(type);
    // setOptions(data.options);
    // setGlobalFeedback(data.globalFeedback);
  }, []);

  useEffect(() => {
    console.log("data:", data);
    // setTemplateData(data);
  }, [data]);

  // useEffect(() => {
  //   console.log("componentProperties:", data.componentProperties);
  // }, [data]);

  // useEffect(() => {
  //   const updatedOptions = data.options.map((option: Option) => {
  //     if (optionFeedback) {
  //       return {...option, feedback: option.feedback || "Write feedback text here.."}
  //     } else {
  //       const { feedback, ...rest} = option;
  //       return rest;
  //     }
  //   })
  //   console.log("updated option:", updatedOptions);
  //   setOptions(updatedOptions);
  //   setGlobFeedback(data.globFeedback);
  // }, [optionFeedback]);

  useEffect(() => {
    if (type) setTemplateType(type);
  }, [type]);

  useEffect(() => {
    setCurrentOptionQuantity(optionQuantity);
  }, [optionQuantity]);

  useEffect(() => { }, [templateType]);

  // useEffect(() => {

  //   handleDataChange({
  //     ...data,
  //     question,
  //     prompt,
  //     options,
  //     globalFeedback,
  //   });
  // }, [question, prompt, options, globalFeedback]);
  return (
    <div className="pb-[1.25rem]">
      <Text
        labelTitle="Question"
        value={data.question}
        onChange={(e) =>
          handleDataChange({ ...data, question: e.target.value })
        }
        maxLength={
          data.componentProperties?.find(
            (comp: any) => comp.type === "Question"
          )?.maxLength || 150
        }
        onFocus={(e) => {
          const existingComponent = data.componentProperties?.find(
            (comp: any) => comp.type === "Question"
          );
          if (existingComponent) {
            dispatch(
              setSelectedComponent({
                type: existingComponent.type,
                maxLength: existingComponent.maxLength,
                zone: existingComponent.zone,
              })
            );
          }
        }}
      />

      <div className="pt-[1.25rem] px-[1.63rem] flex items-start space-x-5">
        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <span>Image</span>
          <span className="text-[1rem] text-gray-400">(optional)</span>
          <span>
            <button
              onClick={() => {
                const newValue = !haveMainImage;
                setHaveMainImage(newValue);

                let updatedData = { ...data };
                if (newValue) {
                  updatedData = { ...data, haveImage: newValue };
                } else {
                  const { imageFile, ...rest } = data;
                  updatedData = { ...rest, haveImage: newValue };
                }

                console.log(updatedData);
                handleDataChange(updatedData);
              }}
              className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${haveMainImage ? "bg-[#5934ff]" : "bg-gray-400"
                }`}
            >
              <div
                className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${haveMainImage ? "transform translate-x-[18px]" : ""
                  }`}
              ></div>
            </button>
          </span>
        </div>
        <div
          onClick={() => {
            const existingComponent = data?.componentProperties?.find(
              (comp: any) => comp.type === "Image"
            );
            if (existingComponent) {
              dispatch(
                setSelectedComponent({
                  type: existingComponent.type,
                  maxLength: existingComponent.maxLength,
                  zone: existingComponent.zone,
                })
              );
            }
          }}
          className="rounded-md border-2 border-gray-300"
        >
          {data?.haveImage && (
            <div className="flex space-x-5 items-start">
              <UploadImage
                name="imageFile"
                type="image"
                image={data?.imageFile}
                handleUploadFromDevice={(file) => {
                  setImage(file);
                  console.log(file);
                  handleDataChange({ ...data, imageFile: file });
                }}
                handleUploadFromMedia={(file) => {
                  setImage(file);
                  console.log(file);
                  handleDataChange({ ...data, imageFile: file });
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Text
        labelTitle="Prompt"
        value={data.prompt}
        onChange={(e) => handleDataChange({ ...data, prompt: e.target.value })}
        maxLength={
          data.componentProperties?.find((comp: any) => comp.type === "Prompt")
            ?.maxLength || 80
        }
        onFocus={(e) => {
          const existingComponent = data.componentProperties?.find(
            (comp: any) => comp.type === "Prompt"
          );
          if (existingComponent) {
            dispatch(
              setSelectedComponent({
                type: existingComponent.type,
                maxLength: existingComponent.maxLength,
                zone: existingComponent.zone,
              })
            );
          }
        }}
      />

      {!type && (
        <Select
          value={templateType}
          onValueChange={(value: string) => setTemplateType(value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Template Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="light">Multiple Choice Response</SelectItem>
            <SelectItem value="dark">Single Choice Response</SelectItem>
            <SelectItem value="system">True/False</SelectItem>
          </SelectContent>
        </Select>
      )}

      {templateType && (
        <OptionChoices
          type={templateType}
          options={data.options}
          componentProperties={data.componentProperties}
          setOptions={(options: any) => handleDataChange({ ...data, options })}
          optionQuantity={currentOptionQuantity}
          handleImageOrOptionClicked={handleImageOrOptionClicked}
          haveOptionImage={data.haveOptionImage ?? false}
        />
      )}

      <Text
        labelTitle="Global Feedback"
        value={data.globalFeedback}
        placeholder="Enter Global text here..."
        onChange={(e) =>
          handleDataChange({ ...data, globalFeedback: e.target.value })
        }
        maxLength={
          data.componentProperties?.find(
            (comp: any) => comp.type === "Global Feedback"
          )?.maxLength || 150
        }
        onFocus={(e) => {
          const existingComponent = data.componentProperties?.find(
            (comp: any) => comp.type === "Global Feedback"
          );
          if (existingComponent) {
            dispatch(
              setSelectedComponent({
                type: existingComponent.type,
                maxLength: existingComponent.maxLength,
                zone: existingComponent.zone,
              })
            );
          }
        }}
      />
    </div>
  );
}

export default Question;

const OptionChoices = ({
  type,
  options,
  setOptions,
  componentProperties,
  optionQuantity,
  handleImageOrOptionClicked,
  haveOptionImage,
}: {
  type: string;
  options: Option[];
  setOptions: (options: Option[]) => void;
  componentProperties: any[];
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
  haveOptionImage: boolean;
}) => {
  const [templateType, setTemplateType] = useState("");
  const [optionImage, setOptionImage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) setTemplateType(type);
    console.log("options at start:", options);
  }, [type, options]);

  useEffect(() => {
    console.log(componentProperties);
  }, [componentProperties]);

  useEffect(() => {
    console.log(haveOptionImage);
  }, [haveOptionImage]);

  // useEffect(() => {
  //   const updatedOptions = options.map((option: Option) => {
  //     if (optionFeedback) {
  //       return {...option, feedback: option.feedback || "Write feedback text here.."}
  //     } else {
  //       const { feedback, ...rest} = option;
  //       return rest;
  //     }
  //   })
  //   setOptions(updatedOptions);
  //   console.log("updated option:", updatedOptions);
  // }, [optionFeedback]);

  const handleSelectionChange = (index: number) => {
    if (templateType === "scr" || templateType === "true/false") {
      setOptions(
        options.map((option, i) => ({
          ...option,
          correct: i === index, // Select only the clicked option
        }))
      );
    } else {
      setOptions(
        options.map((option, i) =>
          i === index ? { ...option, correct: !option.correct } : option
        )
      );
    }
  };

  const addOption = () => {
    if (options.length >= optionQuantity) return; // Prevent adding more options if limit is reached

    const defaultOptionImage = {
      _id: uuid(), // or some unique identifier
      url: 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg',
      name: 'default-image-name',
      type: 'image/jpg', // or the correct MIME type
      size: 12345,
      createdOn: Date.now(),
      folderId: null,
      userId: 'default-user-id',
      __v: 0,
    };

    const newOption = {
      _id: uuid(),
      value: "New Option",
      correct: false,
      response: false,
      ...(haveOptionImage && { optionImage: defaultOptionImage })
    };
    setOptions([...options, newOption]);
    console.log("New options after add:", [...options, newOption]);
  };

  const deleteOption = (index: number) => {
    setOptions(options.filter((option, idx) => idx !== index));
  };

  return (
    <div className="px-[1.63rem] pt-[1.25rem] pb-[0.5rem] w-full">
      <div className="flex justify-between w-full items-center">
        <div className="w-[49%] flex items-center justify-start pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <div className="w-[15%] ">
            <span>Correct</span>
          </div>
          {/* {options[0]?.optionImage && <div className="w-[8rem]"></div>} */}
          <div className={`w-[85%] ml-3 ${options[0]?.optionImage && "ml-[6.5rem]"}`}>
            <span>Choices</span>
          </div>
        </div>
        <div className="flex justify-start w-[49%] pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <span>FeedBack</span>
        </div>
      </div>

      <div className="space-y-1.5 w-full">
        {options.map((option, index) => (
          <div key={index} className="flex justify-between w-full items-center">
            <div
              onClick={() => {
                console.log("option clicked:", option._id, typeof handleImageOrOptionClicked);
                handleImageOrOptionClicked(option._id);
                const existingComponent = componentProperties?.find(
                  (comp: any) => comp.type === "Choices"
                );
                if (existingComponent) {
                  dispatch(
                    setSelectedComponent({
                      type: existingComponent.type,
                      maxLength: existingComponent.maxLength,
                      zone: existingComponent.zone,
                    })
                  );
                }
              }}
              className="w-[49%] flex items-center"
            >
              <div className="w-[15%] flex pl-7">
                {templateType === "scr" && (
                  <input
                    type="radio"
                    className="transform scale-150"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                  />
                )}
                {templateType === "true/false" && (
                  <input
                    type="radio"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                    className="transform scale-150"
                  />
                )}
                {templateType === "mcr" && (
                  <input
                    type="checkbox"
                    className="transform scale-150"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                  />
                )}
              </div>

              {haveOptionImage && (
                <div className="flex space-x-5 items-start">
                  <UploadImage
                    name="imageFile"
                    type="image"
                    image={option?.optionImage}
                    haveOptions={haveOptionImage}
                    handleUploadFromDevice={(file) => {
                      setOptionImage(file);
                      console.log(file);
                      setOptions(
                        options.map((op, i) =>
                          i === index ? { ...op, optionImage: file } : op
                        )
                      );
                    }}
                    handleUploadFromMedia={(file) => {
                      setOptionImage(file);
                      console.log(file);
                      setOptions(
                        options.map((op, i) =>
                          i === index ? { ...op, optionImage: file } : op
                        )
                      );
                    }}
                  />
                </div>
              )}

              <div className="flex w-[85%] items-center pr-[0.75rem] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600 ml-3">
                <input
                  type="text"
                  value={option.value}
                  maxLength={
                    componentProperties?.find(
                      (comp: any) => comp.type === "Choices"
                    )?.maxLength || 55
                  }
                  className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                  onChange={(e) => {
                    setOptions(
                      options.map((op, i) =>
                        i === index ? { ...op, value: e.target.value } : op
                      )
                    );
                  }}
                // onFocus={(e) => {
                //   const existingComponent = componentProperties?.find(
                //     (comp: any) => comp.type === "Options"
                //   );
                //   if (existingComponent) {
                //     dispatch(
                //       setSelectedComponent({
                //         type: existingComponent.type,
                //         maxLength: existingComponent.maxLength,
                //         zone: existingComponent.zone,
                //       })
                //     );
                //   }
                // }}
                />
                <span className="text-[14px] text-gray-600 font-medium">
                  <span
                    className={`${option?.value?.length >=
                      componentProperties?.find(
                        (comp: any) => comp.type === "Options"
                      )?.maxLength
                      ? "text-red-500"
                      : "text-green-500"
                      }`}
                  >
                    {option?.value?.length}
                  </span>
                  /
                  <span className={``}>
                    {componentProperties?.find(
                      (comp: any) => comp.type === "Choices"
                    )?.maxLength || 55}
                  </span>
                </span>
              </div>
            </div>

            <div className="flex w-[49%] space-x-5 items-center">
              <div className="w-full flex items-center pr-[0.75rem] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                <input
                  type="text"
                  value={option?.feedback || ""}
                  maxLength={
                    componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    )?.maxLength || 30
                  }
                  placeholder="Enter Choices feedback here..."
                  className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                  onChange={(e) => {
                    setOptions(
                      options.map((op, i) =>
                        i === index ? { ...op, feedback: e.target.value } : op
                      )
                    );
                  }}
                  onFocus={(e) => {
                    const existingComponent = componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    );
                    if (existingComponent) {
                      dispatch(
                        setSelectedComponent({
                          type: existingComponent.type,
                          maxLength: existingComponent.maxLength,
                          zone: existingComponent.zone,
                        })
                      );
                    }
                  }}
                />
                <span className="text-[14px] text-gray-600 font-medium">
                  <span
                    className={`${option?.feedback &&
                      option?.feedback?.length >=
                      componentProperties?.find(
                        (comp: any) => comp.type === "Choices Feedback"
                      )?.maxLength
                      ? "text-red-500"
                      : "text-green-500"
                      }`}
                  >
                    {option?.feedback?.length}
                  </span>
                  /
                  <span className={``}>
                    {componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    )?.maxLength || 500}
                  </span>
                </span>
              </div>

              <button
                onClick={() => deleteOption(index)}
                className="text-black text-md"
                aria-label="Delete Option"
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-10 ml-8">
      {templateType !== 'true/false' && (
        <>
        <button
          onClick={addOption}
          className={`bg-black/70 hover:bg-black/50 text-white py-2 px-4 rounded disabled:bg-black/25 disabled:text-black`}
          disabled={options.length >= optionQuantity}
        >
          Add New Choice
        </button>
        {options.length >= optionQuantity ? (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You cannot add more than {optionQuantity} choices
          </div>
        ) : (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You can add up to {optionQuantity} choices
          </div>
        )}
        </>
      )}
      </div>
    </div>
  );
};
