import React, { useEffect, useState } from "react";
import {
  Box,
  //   Button,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  IconButton,
  Separator,
  Editable,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Button } from "src/components/ui/button";
// @ts-ignore
import RenameLogo from "src/assets/TableMenu/PencilLine.svg";
// @ts-ignore
import RemoveLogo from "src/assets/remove1.png";
// import { ComboboxDemo } from "src/components/ui/comboBox2";
import { ComboboxDemo } from "src/components/ui/comboBox";
import { AnyARecord } from "dns";

type Props = {
  menus: any[];
  courseData: any[];
  handleDataChange: (menus: any[]) => void;
};

type TOCItem = {
  _id: string;
  title: string;
  template: any;
  children?: TOCItem[];
};

const Controller: React.FC<Props> = ({
  menus,
  courseData,
  handleDataChange,
}) => {
  const [menusData, setMenusData] = useState<any[]>(menus ?? []);
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedMenu) {
      const menuExists = menusData.some((menu) => menu._id === selectedMenu._id);
      if (!menuExists) {
        setSelectedMenu(null);
      }
    }
    // handleDataChange(menusData);
  }, [menusData]);

  useEffect(() => {
    console.log(selectedMenu);
  }, [selectedMenu]);

  // Helper function to create unique IDs
  const generateId = (): string =>
    "_" + Math.random().toString(36).substr(2, 9);

  // Add a new TOC item
  const addItem = (parentId?: string) => {
    if (!selectedMenu) return;

    const newItem: TOCItem = {
      _id: generateId(),
      title: "New Item",
      template: "None",
      children: [],
    };

    const updatedItems = parentId
      ? addSubItem(selectedMenu.items, parentId, newItem)
      : [...(selectedMenu.items || []), newItem];

    // Update both menusData and selectedMenu
    setMenusData((prevMenus) =>
      prevMenus.map((menu) =>
        menu._id === selectedMenu._id ? { ...menu, items: updatedItems } : menu
      )
    );
    setSelectedMenu({ ...selectedMenu, items: updatedItems }); // Ensure selectedMenu is updated
  };

  // Helper function to recursively add a sub-item
  const addSubItem = (
    items: TOCItem[],
    parentId: string,
    newItem: TOCItem
  ): TOCItem[] => {
    return items.map((item) => {
      if (item._id === parentId && item.template === "None") {
        return { ...item, children: [...(item.children || []), newItem] };
      }
      if (item.children) {
        return {
          ...item,
          children: addSubItem(item.children, parentId, newItem),
        };
      }
      return item;
    });
  };

  // Update item title or template
  const updateItem = (id: string, field: keyof TOCItem, value: any) => {
    if (!selectedMenu) return;

    const { template, ...rest } = value;
    console.log("rest", rest);

    const updatedItems = selectedMenu.items.map((item: TOCItem) => {
      if (item._id === id) {
        const updatedItem = { ...item, [field]: rest };
        if (field === "template" && rest !== "None") {
          updatedItem.children = [];
        }
        return updatedItem;
      }
      return {
        ...item,
        children: updateChildren(item.children, id, field, rest),
      };
    });

    setMenusData((prevMenus) =>
      prevMenus.map((menu) =>
        menu._id === selectedMenu._id ? { ...menu, items: updatedItems } : menu
      )
    );
    setSelectedMenu({ ...selectedMenu, items: updatedItems });
  };

  const updateChildren = (
    children: TOCItem[] | undefined,
    id: string,
    field: keyof TOCItem,
    value: string
  ): TOCItem[] | undefined => {
    if (!children) return undefined;
    return children.map((child) => {
      if (child._id === id) {
        const updatedChild = { ...child, [field]: value };
        if (field === "template" && value !== "None") {
          updatedChild.children = [];
        }
        return updatedChild;
      }
      return {
        ...child,
        children: updateChildren(child.children, id, field, value),
      };
    });
  };

  // Delete item
  const deleteItem = (id: string) => {
    if (!selectedMenu) return;

    const updatedItems = deleteFromItems(selectedMenu.items, id);

    setMenusData((prevMenus) =>
      prevMenus.map((menu) =>
        menu._id === selectedMenu._id ? { ...menu, items: updatedItems } : menu
      )
    );
    setSelectedMenu({ ...selectedMenu, items: updatedItems });
  };

  const deleteFromItems = (items: TOCItem[], id: string): TOCItem[] => {
    return items
      .filter((item) => item._id !== id)
      .map((item) => ({
        ...item,
        children: item.children
          ? deleteFromItems(item.children, id)
          : undefined,
      }));
  };

  // Generate TOC
  const generateTOC = () => {

    const hasTemplate = selectedMenu.items?.some(
        (item: any) =>
          item.template !== "None" ||
          item.children?.some((child: any) => child.template !== "None")
    );

    if (!hasTemplate) {
      alert("Please insert template");
      return;
    }
    
    handleDataChange(menusData);
    alert("Menu generated");
  };

  // Update item title
  const updateItemTitle = (id: string, newTitle: string) => {
    if (!selectedMenu) return;

    const updatedItems = selectedMenu.items.map((item: TOCItem) => {
      if (item._id === id) {
        return { ...item, title: newTitle };
      }
      return {
        ...item,
        children: updateChildrenTitle(item.children, id, newTitle),
      };
    });

    setMenusData((prevMenus) =>
      prevMenus.map((menu) =>
        menu._id === selectedMenu._id ? { ...menu, items: updatedItems } : menu
      )
    );
    setSelectedMenu({ ...selectedMenu, items: updatedItems });
    setEditingItemId(null); // Exit editing mode
  };

  const updateChildrenTitle = (
    children: TOCItem[] | undefined,
    id: string,
    newTitle: string
  ): TOCItem[] | undefined => {
    if (!children) return undefined;
    return children.map((child) => {
      if (child._id === id) {
        return { ...child, title: newTitle };
      }
      return {
        ...child,
        children: updateChildrenTitle(child.children, id, newTitle),
      };
    });
  };

  // Render TOC items
  const renderItems = (items: TOCItem[]) => {
    return items.map((item) => (
      <Box key={item._id} mb={2}>
        <HStack alignItems="center" width="100%" justifyContent="space-between">
          <HStack alignItems="center" gap="1.5rem">
            <Editable.Root
              textAlign="start"
              defaultValue={item.title}
              onValueChange={(e: any) => {
                updateItemTitle(item._id, e.value);
                setEditingItemId(null);
              }}
            >
              <Editable.Preview />
              <Editable.Input />
            </Editable.Root>
            {item.template === "None" && (
              <Button
                size="sm"
                variant="outline"
                className="bg-white hover:bg-white/50 rounded px-2"
                onClick={() => addItem(item._id)}
              >
                Add Sub-Topic
              </Button>
            )}
          </HStack>

          <HStack alignItems="center" width="20rem" justifyContent="end">
            <ComboboxDemo
              navData={courseData}
              menuItem={item}
              type="Template"
              handleTopicChange={(topic) => {
                console.log("template topic",topic);
                updateItem(item._id, "template", topic)
              }}
            />

            {/* <Button
              variant="ghost"
              onClick={() =>
                updateItem(
                  item._id,
                  "title",
                  prompt("Enter new title", item.title) || item.title
                )
              }
            >
              <img className="w-5 h-5" src={RenameLogo} alt="rename" />
            </Button> */}

            <Button variant="ghost" onClick={() => deleteItem(item._id)}>
              <img className="w-5 h-5" src={RemoveLogo} alt="remove" />
            </Button>
          </HStack>
        </HStack>
        <Separator />
        {item.children && <Box ml={6}>{renderItems(item.children)}</Box>}
      </Box>
    ));
  };

  const handleMenuChange = (updatedMenus: any) => {
    setMenusData(updatedMenus);
    handleDataChange(updatedMenus);
  };

  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="sm"
      width="65rem"
      height="40rem"
      backgroundColor="#e2e2e2"
    >
      <ComboboxDemo
        data={menusData}
        type="Menus"
        selectedMenu={selectedMenu}
        handleTopicChange={(menu) => {
          if (menu === null) setSelectedMenu(null);
          else setSelectedMenu(menu);
        }}
        handleMenuChange={handleMenuChange}
      />
      {selectedMenu && (
        <VStack align="start" width="100%" mt="1rem">
          <Editable.Root
            textAlign="start"
            fontSize="sm"
            fontWeight="normal"
            backgroundColor="white"
            width="50%"
            mb={4}
            value={selectedMenu?.title || ""}
            onValueChange={(e: any) => {
              const updatedTitle = e.value;

              setMenusData((prevMenus) =>
                prevMenus.map((menu) =>
                  menu._id === selectedMenu._id
                    ? { ...menu, title: updatedTitle }
                    : menu
                )
              );

              setSelectedMenu((prevSelectedMenu: any) => ({
                ...prevSelectedMenu,
                title: updatedTitle,
              }));
            }}
          >
            <Editable.Preview />
            <Editable.Input />
          </Editable.Root>
          <Button
            className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded "
            onClick={() => addItem()}
          >
            Add Section
          </Button>
          <VStack
            align="stretch"
            width="100%"
            height="24.5rem"
            overflowY="auto"
          >
            <HStack
              justifyContent="space-between"
              my="1rem"
              fontWeight="semibold"
            >
              <Text>Menu Items</Text>
              <HStack width="20rem" justifyContent="space-between">
                <Text>Templates</Text>
                <Text>Action</Text>
              </HStack>
            </HStack>
            {renderItems(selectedMenu?.items)}
          </VStack>
          <div className="flex w-full justify-end">
            <Button
              className="bg-blue hover:bg-blue/50 text-white px-4 py-2 rounded"
              onClick={generateTOC}
            >
              Generate Menu
            </Button>
          </div>
        </VStack>
      )}
    </Box>
  );
};

export default Controller;
