import React, { useState, useEffect, useRef } from "react";
import { Stepper } from "@mantine/core";
import "@mantine/core/styles.css";
import AWS from "aws-sdk";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import { Progress } from "src/components/ui/progress";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import axios from "axios";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";
import { useDispatch } from "react-redux";
import {
  GetTranscriptionJobCommand,
  StartTranscriptionJobCommand,
  TranscribeClient,
} from "@aws-sdk/client-transcribe";

const translate = new AWS.Translate({
  region: "ap-southeast-1",
  accessKeyId: `${process.env.REACT_APP_ACCESS_ID}`,
  secretAccessKey: `${process.env.REACT_APP_SECRET_ID}`,
});

type Props = {
  handleClose: () => void;
}

const translateText = async (
  text: string[],
  sourceLanguage: string,
  targetLanguage: string
): Promise<string[]> => {
  try {
    const promises = text.map((item) =>
      translate
        .translateText({
          Text: item,
          SourceLanguageCode: sourceLanguage,
          TargetLanguageCode: targetLanguage,
        })
        .promise()
        .then((response) => response.TranslatedText || "")
    );

    const results = await Promise.all(promises);
    console.log("Translated Results:", results);
    return results;
  } catch (error) {
    console.error("Translation Error:", error);
    return [];
  }
};

const translateVttContent = async (
  vttContent: string,
  sourceLanguage: string,
  targetLanguage: string
): Promise<string> => {
  try {
    // Extract text lines from the VTT content
    const lines = vttContent.split("\n");
    const textLines: { index: number; text: string }[] = [];

    lines.forEach((line, index) => {
      if (!line.match(/^\d+$/) && !line.match(/-->/) && line.trim() !== "") {
        textLines.push({ index, text: line });
      }
    });

    // Translate the text lines
    const translatedTexts = await Promise.all(
      textLines.map(({ text }) =>
        translate
          .translateText({
            Text: text,
            SourceLanguageCode: sourceLanguage,
            TargetLanguageCode: targetLanguage,
          })
          .promise()
          .then((response) => response.TranslatedText || text)
      )
    );

    // Replace original text with translated text in the VTT content
    const translatedLines = [...lines];
    textLines.forEach(({ index }, i) => {
      translatedLines[index] = translatedTexts[i];
    });

    const translatedVttContent = translatedLines.join("\n");
    console.log("Translated VTT Content:", translatedVttContent);
    return translatedVttContent;
  } catch (error) {
    console.error("Translation Error:", error);
    return vttContent; // Return original content in case of an error
  }
};

const formatTime = (time: any) => {
  const seconds = parseFloat(time);
  if (isNaN(seconds)) {
    console.warn(`Invalid time value: ${time}`);
    return "00:00:00.000"; // Fallback value for invalid inputs
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const milliseconds = Math.floor((seconds % 1) * 1000);

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(secs).padStart(2, "0")}.${String(milliseconds).padStart(3, "0")}`;
};

const convertToVTT = (transcribeOutput: any) => {
  const items = transcribeOutput?.results?.items || [];

  let vttContent = "WEBVTT\n\n";

  let currentGroup: any[] = [];
  let startTime = "";
  let endTime = "";

  items?.forEach((item: any, index: number) => {
    if (item.type === "punctuation") {
      // If punctuation, add it to the last word and end the group
      if (currentGroup.length > 0) {
        currentGroup[currentGroup.length - 1].content +=
          item.alternatives[0].content;
      }
    } else {
      // Start a new group if it's empty
      if (currentGroup.length === 0) {
        startTime = item.start_time;
      }
      // Add word to the current group
      currentGroup.push(item);

      // Update the end time
      endTime = item.end_time;
    }

    // Close the group if it reaches a certain size or end of sentence (punctuation)
    if (currentGroup.length >= 10 || item.type === "punctuation") {
      // Only add the group if it has content
      if (currentGroup.length > 0) {
        const text = currentGroup
          .map((word) => word.alternatives[0].content)
          .join(" ");
        const start = formatTime(startTime);
        const end = formatTime(endTime);

        vttContent += `${index + 1}\n${start} --> ${end}\n${text}\n\n`;

        // Reset group
        currentGroup = [];
      }
    }
  });

  return vttContent;
};

const CreateVideoUI: React.FC<Props> = ({handleClose}) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [languages, setLanguages] = useState([
    { code: "es", name: "Spanish", checked: false },
    { code: "fr", name: "French", checked: false },
    { code: "zh", name: "Chinese", checked: false },
    { code: "jp", name: "Japanese", checked: false },
  ]);

  const [sourceTranscript, setSourceTranscript] = useState<string>("");
  const [mandatoryStandardsText, setMandatoryStandardsText] =
    useState<string>("");
  const [mandatoryWords, setMandatoryWords] = useState<string[]>([]);
  const [targetMandatoryWords, setTargetMandatoryWords] = useState<{
    [key: string]: string[];
  }>({});
  const [targetLanguages, setTargetLanguages] = useState<string[]>([]);
  const [translations, setTranslations] = useState<{
    transcript: { [key: string]: string };
    mandatory: { [key: string]: string[] };
  }>({
    transcript: {},
    mandatory: { source: [], es: [], fr: [], zh: [], jp: [] },
  });

  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");
  const [transcribedData, setTranscribedData] = useState<string | null>(null);
  const [updatedVttContent, setUpdatedVttContent] = useState<any>();
  const [textInTimeFormat, setTextInTimeFormat] = useState(""); // State for transcribed text in time format
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [progressText, setProgressText] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const { folder } = useSelector((state: any) => state.fileFolder);
  const { authUser } = useSelector((state: any) => state.user);
  const folderId = searchParams.get("folderId");
  const { fileFolder } = useFolder({
    folderId: folderId === "null" ? null : folderId,
    folder,
  });
  const currentFolder = fileFolder?.folder;
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const [uploadedFile, setUploadedFile] = useState<any>("");
  const [videoUrl, setVideoUrl] = useState<any>(
    "https://sepmupload.s3.ap-southeast-1.amazonaws.com/uploads/users/1d99f9d7-202c-4e1e-ac1d-898d5ee574b3/clipped_emirates_flightsafety_video.mp4"
  );

  const [previewDataFile, setPreviewDataFile] = useState<any>();
  const [previewLangCode, setPreviewLangCode] = useState<string>("en");

  const videoRef = useRef<HTMLVideoElement>(null);

  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const transcribeVideo = async (s3Url: string) => {
    console.log("Start Transcribing...");

    const client = new TranscribeClient({
      region: "ap-southeast-1",
      credentials: {
        accessKeyId: `${process.env.REACT_APP_ACCESS_ID}`,
        secretAccessKey: `${process.env.REACT_APP_SECRET_ID}`,
      },
    });

    const jobName = `transcription-job-${Date.now()}`;
    let timer: NodeJS.Timeout | null = null; // Declare timer outside try block

    try {
      const startCommand = new StartTranscriptionJobCommand({
        TranscriptionJobName: jobName,
        Media: { MediaFileUri: s3Url },
        MediaFormat: "mp4",
        LanguageCode: "en-US",
        OutputBucketName: "sepmupload",
      });

      const startResponse = await client.send(startCommand);

      timer = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = Math.min(prevProgress + 6.67, 90);
          return nextProgress;
        });
      }, 1500);

      const checkStatus = async () => {
        if (!startResponse.TranscriptionJob?.TranscriptionJobName) return;

        const getCommand = new GetTranscriptionJobCommand({
          TranscriptionJobName:
            startResponse?.TranscriptionJob?.TranscriptionJobName,
        });

        const getResponse = await client.send(getCommand);

        if (
          getResponse.TranscriptionJob?.TranscriptionJobStatus === "COMPLETED"
        ) {
          if (timer) clearInterval(timer); // Stop progress updates when completed
          setProgress(100); // Set progress to 100% on completion
          setProgressText("Transcription completed.");

          const transcriptFileUri =
            getResponse.TranscriptionJob.Transcript?.TranscriptFileUri;
          if (transcriptFileUri) {
            const response = await fetch(transcriptFileUri);
            const data = await response.json();
            console.log(data.results.transcripts[0].transcript);
            setTranscribedData(JSON.stringify(data, null, 2));
          }
        } else if (
          getResponse.TranscriptionJob?.TranscriptionJobStatus === "FAILED"
        ) {
          if (timer) clearInterval(timer); // Stop progress updates on failure
          setError("Transcription job failed.");
          setProgressText("Transcription failed.");
        } else {
          // Keep checking if still in progress
          setProgressText("Transcription in progress...");
          setTimeout(checkStatus, 5000);
        }
      };

      checkStatus();
    } catch (err: any) {
      console.error("Error:", err);
      if (timer) clearInterval(timer); // Ensure timer is cleared on error
      setError(err.message || "An unknown error occurred");
      setProgressText("An error occurred.");
    }
  };

  useEffect(() => {
    // if (progress === 100) {
    //   setTimeout(() => {
    //     setTranscribedData(transcribedData);
    //   }, 1000);
    // }
  }, [progress]);

  useEffect(() => {
    console.log(transcribedData);

    const transcribeOutput = JSON.parse(`${transcribedData}`);
    console.log(transcribeOutput);

    const content = convertToVTT(transcribeOutput);
    console.log("vttcontent hhhhhh", content);

    setUpdatedVttContent(content)

    const transcriptTranslations: { [key: string]: string } = {};

        if (content) {
          transcriptTranslations["en"] = content;
          console.log("transcriptTranslations", transcriptTranslations);
        } else {
          transcriptTranslations["en"] = ""; 
        }

    // Merge english transcripts with existing translations
    setTranslations((prev) => ({
      ...prev,
      transcript: { ...prev.transcript, ...transcriptTranslations },
    }));

  }, [transcribedData]);

  useEffect(() => {}, [textInTimeFormat]);

  useEffect(() => {
    const savedTranslations = localStorage.getItem("translations");
    if (savedTranslations) {
      setTranslations(JSON.parse(savedTranslations));
    }

    const savedActiveStep = localStorage.getItem("activeStep");
    if (savedActiveStep) {
      setActiveStep(parseInt(savedActiveStep));
    }

    const savedLanguages = localStorage.getItem("languages");
    if (savedLanguages) {
      setLanguages(JSON.parse(savedLanguages));
    }

    const savedSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (savedSelectedLanguage) {
      console.log(JSON.parse(savedSelectedLanguage));
      setSelectedLanguage(JSON.parse(savedSelectedLanguage));
    }

    const savedTargetLanguages = localStorage.getItem("targetLanguages");
    if (savedTargetLanguages) {
      setTargetLanguages(JSON.parse(savedTargetLanguages));
    }
  }, []);

  useEffect(() => {
    console.log("translations:", translations);
  }, [translations]);

  useEffect(() => {
    console.log("selectedLanguage", selectedLanguage);
  }, [selectedLanguage])

  const updateTargetLanguages = (
    updatedLanguages: {
      code: string;
      name: string;
      checked: boolean;
    }[]
  ) => {
    const selectedLanguages = updatedLanguages
      .filter((lang) => lang.checked )
      .map((lang) => lang.code);
    setTargetLanguages(selectedLanguages);
  };

  const handleLanguageChange = (checked: boolean, code: string): void => {
    const updatedLanguages = languages.map((lang) =>
      lang.code === code ? { ...lang, checked } : lang
    );
    setLanguages(updatedLanguages);
    updateTargetLanguages(updatedLanguages);
  };

  const handleSaveStandards = () => {
    const words = mandatoryStandardsText.split(",").map((word) => word.trim());
    setMandatoryWords(words);
    setIsModalOpen(false);
  };

  const translateTranscript = async () => {
    const transcriptTranslations: { [key: string]: string } = {};

    // Ensure targetLanguages is not empty
    if (targetLanguages.length === 0) {
      console.error("No target languages selected.");
      return;
    }

    for (const lang of targetLanguages) {
      try {
        const translatedText = await translateVttContent(
          updatedVttContent,
          "en",
          lang
        );
        if (translatedText) {
          transcriptTranslations[lang] = translatedText;
        } else {
          console.error(`Translation failed for language: ${lang}`);
          transcriptTranslations[lang] = ""; // Default to empty string if translation fails
        }
      } catch (error) {
        console.error(`Error translating transcript for ${lang}:`, error);
        transcriptTranslations[lang] = ""; // Handle translation error gracefully
      }
    }

    // Merge new transcripts with existing translations
    setTranslations((prev) => ({
      ...prev,
      transcript: { ...prev.transcript, ...transcriptTranslations },
    }));
  };

  const translateMandatoryStandards = async () => {
    const mandatoryTranslations: { [key: string]: string[] } = {
      source: mandatoryWords,
    };

    // Ensure targetLanguages is not empty
    if (targetLanguages.length === 0) {
      console.error("No target languages selected.");
      return;
    }

    for (const lang of targetLanguages) {
      try {
        const translatedWords = await translateText(mandatoryWords, "en", lang);
        if (
          translatedWords &&
          translatedWords.length === mandatoryWords.length
        ) {
          mandatoryTranslations[lang] = translatedWords;
          // Set targetMandatoryWords as an independent copy
          setTargetMandatoryWords((prev) => ({
            ...prev,
            [lang]: [...translatedWords], // Ensure this is a new array
          }));
        } else {
          console.error(`Translation failed for mandatory standards: ${lang}`);
          mandatoryTranslations[lang] = Array(mandatoryWords.length).fill(""); // Ensure array length matches mandatoryWords
        }
      } catch (error) {
        console.error(
          `Error translating mandatory standards for ${lang}:`,
          error
        );
        mandatoryTranslations[lang] = Array(mandatoryWords.length).fill(""); // Handle translation error gracefully
      }
    }

    // Merge new mandatory translations with existing translations
    setTranslations((prev) => ({
      ...prev,
      mandatory: { ...prev.mandatory, ...mandatoryTranslations },
    }));
  };

  const getLanguageSpecificMandatoryWords = (
    lang: string
  ): { [key: string]: string } => {
    const languageSpecificWords: { [key: string]: string } = {};

    console.log("Target Mandatory Words:", targetMandatoryWords);

    targetMandatoryWords[lang].forEach((word, index) => {
      const translatedWord = translations.mandatory[lang]?.[index];
      if (translatedWord) {
        languageSpecificWords[word] = translatedWord; // Map source word to its translation
      }
    });

    return languageSpecificWords;
  };

  const updateTranscriptsWithMandatoryStandards = () => {
    const updatedTranscripts = { ...translations.transcript };

    // Iterate over all target languages
    for (const lang of targetLanguages) {
      let transcript = updatedTranscripts[lang]; // Current transcript for the language
      if (!transcript) continue;

      // Get language-specific mandatory words
      const languageSpecificWords = getLanguageSpecificMandatoryWords(lang);

      console.log("Language Specific Words:", languageSpecificWords);

      // Replace mandatory words in the transcript
      Object.entries(languageSpecificWords).forEach(
        ([sourceWord, translatedWord]) => {
          // Normalize strings to ensure consistency
          const normalizedSourceWord = sourceWord.normalize("NFC");
          const normalizedTranscript = transcript.normalize("NFC");

          // Debugging logs
          console.log(
            `Attempting to replace: "${normalizedSourceWord}" with "${translatedWord}"`
          );
          console.log(
            `Transcript before replacement: "${normalizedTranscript}"`
          );

          // Replace the sourceWord with the translatedWord directly
          transcript = normalizedTranscript
            .split(normalizedSourceWord)
            .join(translatedWord);

          // Debugging logs
          console.log(`Transcript after replacement: "${transcript}"`);
        }
      );

      updatedTranscripts[lang] = transcript; // Save the updated transcript
    }

    console.log("updated Transcripts:", updatedTranscripts);

    // Merge new transcripts with existing translations
    setTranslations((prev) => ({
      ...prev,
      transcript: { ...prev.transcript, ...updatedTranscripts },
    }));
  };

  const nextStep = async () => {
    if (activeStep === 0) {
      if (!videoUrl) {
        alert("Please upload a video.");
        return;
      }
      if (targetLanguages.length === 0) {
        alert("Please select at least one target language.");
        return;
      }
      setActiveStep((prev) => prev + 1);
      await transcribeVideo(videoUrl);
    }
    if (activeStep === 1) {
      try {
        // Wait for translations to complete
        await translateTranscript();

        // Wait for mandatory translations (if any)
        if (mandatoryWords.length > 0) {
          await translateMandatoryStandards();
        }
        console.log("Final Translations State:", translations);

        // Move to the next step
        setActiveStep((prev) => prev + 1);
      } catch (error) {
        console.error("Error during translation:", error);
        alert("An error occurred during translation. Please try again.");
      }
    }
    if (activeStep === 2) {
      updateTranscriptsWithMandatoryStandards();

      // Move to the next step
      setActiveStep((prev) => prev + 1);
    }
    if (activeStep === 3) {
      console.log(uploadedFile);
      const fileId = uploadedFile._id;
      axios
        .put(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/67534dc48523abba24498797`,
          // `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/${uploadedFile._id}`,
          {
            subtitles: {
              ...translations.transcript,
            },
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => {
          console.log("Subtitles updated:", res.data);
          setPreviewDataFile(res.data.data);
          setActiveStep((prev) => prev + 1);
        })
        .catch((error) => {
          console.error("Error updating Subtitles:", error);
        });
    }

    if (activeStep === 4) {
      handleFinish();
      handleClose();
    }
  };

  useEffect(() => {
    // console.log("active step", activeStep, "updatedVttContent", updatedVttContent);
  }, [activeStep, updatedVttContent]);

  useEffect(() => {
    console.log("previewDataFile", previewDataFile);

    // const blob = new Blob([updatedVttContent], { type: "text/vtt" });
    // console.log("blob", blob);
    // const url = URL.createObjectURL(blob);
    // console.log("url", url);

    if (videoRef.current) {
      // const track = document.createElement("track");
      // track.kind = "subtitles";
      // track.label = "English";
      // track.srclang = "en";
      // track.src = url;
      // track.default = true;
      // videoRef.current.appendChild(track);

      videoRef.current.textTracks[0].mode = "showing";
    }

    return () => {
      URL.revokeObjectURL(url);
    };

  }, [previewDataFile,]);

  const prevStep = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  // Update the handleFinish function
  const handleFinish = async () => {
    // const updatedAudioUrls = { ...audioUrls };

    // for (const lang of targetLanguages) {
    //   try {
        // const audioBlob = await fetch(audioUrls[lang]).then((res) =>
        //   res.blob()
        // );
        // const s3Url = await uploadToS3(audioBlob, lang);
        // updatedAudioUrls[lang] = s3Url;
    //   } catch (error) {
    //     console.error(`Error uploading audio for ${lang}:`, error);
    //   }
    // }


    // localStorage.setItem("audioUrls", JSON.stringify(updatedAudioUrls));
    localStorage.setItem("translations", JSON.stringify(translations));
    localStorage.setItem("activeStep", JSON.stringify(activeStep));
    localStorage.setItem("languages", JSON.stringify(languages));
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
    localStorage.setItem("targetLanguages", JSON.stringify(targetLanguages));
  };

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = (e.target as HTMLInputElement).files![0];

    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles: any) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/${
            file.name
          }`
        : `${
            currentFolder.path.map((item: any) => item.name).join("/") &&
            `/${currentFolder.path.map((item: any) => item.name).join("/")}`
          }/${currentFolder.name}/${file.name}`;

    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}${filePath}`,
      file.type
    );
    console.log("upload url: ", UploadUrl, authUser.oid, filePath, file);
    axios
      .put(`${UploadUrl}`, file, {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => {
        console.log(response.data);
        //   // Set logoFile state with uploaded file data
        // setLogoFile({
        //   name: file.name,
        //   type: file.type,
        //   size: file.size,
        //   url: UploadUrl.split("?")[0], // or SignedUrl.split("?")[0] depending on your requirement
        // });
      })
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(
      `uploads/users/${authUser.oid}${filePath}`
    );

    console.log("this is the signed url: ", SignedUrl);

    setUrl(SignedUrl.split("?")[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: file.name,
        type: file.type,
        size: file.size,
        folderId,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    console.log("Added files", response.data.data);
    dispatch(setAddToChildFiles(response.data.data));
    setUploadedFile(response.data.data);
  }

  useEffect(() => {
    if (uploadedFile) {
      console.log("uploadedFile", uploadedFile);
      setVideoUrl(uploadedFile.url);
    }
  }, [uploadedFile]);

  return (
    <div className="p-8 max-w-4xl mx-auto">
      <Stepper active={activeStep} onStepClick={setActiveStep} size="sm">
        {/* Step 1: Transcribing video */}
        <Stepper.Step label="Upload video" description="">
          <div>
            <h2 className="text-xl font-semibold">Step 1: Import Video</h2>
            <div className="w-full h-[6rem] flex-col border border-gray-300 rounded-md p-2 mt-4 flex items-center justify-center">
              <input
                type="file"
                accept="video/*"
                id="video-upload"
                className="hidden"
                onChange={async (e) => {
                  handleUpload(e);

                  console.log("uploadedFile", uploadedFile);
                }}
              />

              <label
                htmlFor="video-upload"
                className="px-3 py-1 rounded-md text-white bg-blue hover:bg-blue/75 cursor-pointer"
              >
                Browse
              </label>

              {uploadedFile && (
                <span className="mt-2 text-sm text-gray-600 p-1 bg-gray-200">
                  {uploadedFile.name}
                </span>
              )}
            </div>
            <div className="flex items-center space-x-4 mt-2">
              {languages.map((language, idx) => (
                <label key={idx} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={language.checked}
                    onChange={(e) =>
                      handleLanguageChange(e.target.checked, language.code)
                    }
                    className="form-checkbox"
                  />
                  <span>
                    {
                      {
                        es: "Spanish (es)",
                        fr: "French (fr)",
                        zh: "Chinese (zh)",
                        jp: "Japanese (jp)",
                      }[language.code]
                    }
                  </span>
                </label>
              ))}
            </div>
            <div className="mt-4">
              <label htmlFor="ai-disclaimer" className="flex items-center">
                <input
                  id="ai-disclaimer"
                  type="checkbox"
                  className="form-checkbox mr-2"
                />
                <span>
                  I understand that AI-generated content may not always be
                  accurate and should be reviewed and validated before use.
                </span>
              </label>
            </div>
          </div>
        </Stepper.Step>

        {/* Step 2: Transcribe */}
        <Stepper.Step label="Transcribe video" description="">
          <div className="flex flex-col w-full justify-center items-center space-y-2">
            <span className="mb-2">Transcribing video</span>
            {/* Progress Bar */}
            {progress < 100 && (
              <>
                <Progress value={progress} className="w-full" />
                <span>{progress}%</span>
              </>
            )}

            {/* Display Transcribed Text After Progress Completes */}
            {/* {progress === 100 && ( */}
            <>
              <div className="p-2 w-full bg-gray-100 border border-gray-300 rounded">
                {updatedVttContent ? (
                  <>
                    <h2 className="text-base font-medium">Subtitle</h2>
                    <p className="text-gray-700">{updatedVttContent}</p>
                  </>
                ) : (
                  <>
                    <p className="text-sm text-gray-500 mt-4">
                      Waiting for transcription...
                    </p>
                  </>
                )}
              </div>

              {/* Mandatory Standards Button */}
              <div className=" flex w-full justify-start">
                <button
                  type="button"
                  className="text-blue-500 underline"
                  onClick={() => setIsModalOpen(true)}
                >
                  Mandatory Standards
                </button>

                {/* Modal */}
                {isModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-1/2">
                      <h2 className="text-lg font-semibold mb-4">
                        Mandatory Standards
                      </h2>
                      <p className="text-sm mb-2">
                        Enter mandatory standards separated by commas ","
                      </p>
                      <textarea
                        className="w-full border rounded-md p-3 mt-1"
                        rows={4}
                        value={mandatoryStandardsText}
                        onChange={(e) =>
                          setMandatoryStandardsText(e.target.value)
                        }
                      />
                      <div className="flex justify-end space-x-4 mt-4">
                        <button
                          type="button"
                          className="px-4 py-2 border rounded"
                          onClick={() => setIsModalOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="px-4 py-2 bg-blue text-white rounded"
                          onClick={handleSaveStandards}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
            {/* )} */}
          </div>
        </Stepper.Step>

        {/* Step 2: Map Mandatory Standards */}
        <Stepper.Step label="Mandatory Standards" description="">
          <div className="p-8 max-w-4xl mx-auto">
            <h1 className="text-xl font-semibold mb-4">
              Mandatory Standards Table
            </h1>
            <div className="overflow-x-auto">
              <table className="table-auto border-collapse border border-gray-300 w-full text-left">
                <thead>
                  <tr>
                    <th className="border border-gray-300 p-2 bg-gray-100">
                      English
                    </th>
                    {languages.map((lang) => (
                      <th
                        key={lang.code}
                        className="border border-gray-300 p-2 bg-gray-100"
                      >
                        {lang.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {translations.mandatory.source.map((word, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">{word}</td>
                      {languages.map((lang) => (
                        <td
                          key={`${lang.code}-${index}`}
                          className="border border-gray-300 p-2"
                        >
                          <input
                            type="text"
                            className="w-full border rounded-md p-2"
                            value={
                              translations.mandatory[lang.code]?.[index] || "" // Use fallback
                            }
                            onChange={(e) => {
                              const updatedMandatoryTranslations = {
                                ...translations.mandatory,
                              };
                              if (!updatedMandatoryTranslations[lang.code]) {
                                updatedMandatoryTranslations[lang.code] = Array(
                                  mandatoryWords.length
                                ).fill(""); // Initialize array if undefined
                              }
                              updatedMandatoryTranslations[lang.code][index] =
                                e.target.value;
                              setTranslations({
                                ...translations,
                                mandatory: updatedMandatoryTranslations,
                              });
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Stepper.Step>

        {/* Step 3: Review / Confirm */}
        <Stepper.Step label="Translate" description="">
          <div>
            <h2 className="text-xl font-semibold mb-4">Step 4: Translate</h2>

            {/* Custom Tabs for Languages */}
            <div className="flex space-x-4 border-b pb-2">
              <button
                className={`px-4 py-2 border rounded-t ${
                  selectedLanguage === 'en'
                    ? "bg-blue text-white border-blue"
                    : "bg-gray-200 text-gray-600 border-gray-300"
                }`}
                onClick={() => setSelectedLanguage('en')}
              >
                English
              </button>
              {targetLanguages.map((lang) => (
                <button
                  key={lang}
                  className={`px-4 py-2 border rounded-t ${
                    selectedLanguage === lang
                      ? "bg-blue text-white border-blue"
                      : "bg-gray-200 text-gray-600 border-gray-300"
                  }`}
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {languages.find((l) => l.code === lang)?.name || lang}
                </button>
              ))}
            </div>

            {/* Display Transcripts */}
            <textarea
              value={translations.transcript[selectedLanguage] || ""}
              readOnly
              className="w-full mt-4 border rounded-md p-2"
              rows={10}
            />
          </div>
        </Stepper.Step>

        {/* Step 3: Review / Confirm */}
        <Stepper.Step label="Preview" description="">
          <div>
            <h2 className="text-xl font-semibold mb-4">Step 5: Preview</h2>

            {/* Custom Tabs for Languages */}
            <video id="video" ref={videoRef} src={previewDataFile?.url} controls width="600">
              {previewDataFile && Object.entries(previewDataFile.subtitles as Record<string, string>).map(([key, value]) => {
                console.log("value", value);
                return (
                <track 
                  src={URL.createObjectURL(new Blob([value], { type: "text/vtt" }))}
                  kind="subtitles"
                  srcLang={key || "en"}
                  label={languages.find((lang) => lang.code === key)?.name || "English"}
                 />
                )
              })}
            </video>
            {/* <video
              ref={videoRef}
              src={previewDataFile?.url}
              controls
              width="600"
            /> */}

            {/* <div>
              <button onClick={() => setPreviewLangCode("en")}>English</button>
              <button onClick={() => setPreviewLangCode("es")}>Spanish</button>
              <button onClick={() => setPreviewLangCode("fr")}>French</button>
              <button onClick={() => setPreviewLangCode("zh")}>Chinese</button>
            </div> */}
          </div>
        </Stepper.Step>
      </Stepper>

      {/* Navigation Buttons */}
      <div className="mt-8 flex justify-between">
        <button
          className="px-4 py-2 bg-gray-300 rounded"
          disabled={activeStep === 0}
          onClick={prevStep}
        >
          Back
        </button>
        <button
          className="px-4 py-2 bg-blue text-white rounded"
          onClick={nextStep}
        >
          {activeStep === 4 ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default CreateVideoUI;
