import { createSlice } from "@reduxjs/toolkit";

type TemplateInfo = {
    id: string;
    title: string;
    type: string;
    data: {
        visited: boolean;
        completed: boolean;
    };
}
type LearnerData = {
    templates: TemplateInfo[],
    score: {
        min: number;
        max: number;
        raw: number;
    }
}

const initialState = {
    learnerData: {
        templates: [],
        score: {
            min: 0,
            max: 100,
            raw: 0,
        },
    } as LearnerData,
};
export const lmsLearnerData = createSlice({
    name: "lmsLearnerData",
    initialState,
    reducers: {
        setLmsLearnerData: (state, action) => {

            if(action.payload.score) {
                state.learnerData.score = action.payload.score;
            }

            if(action.payload.templates) {
                state.learnerData.templates = action.payload.templates;
            }
            return state;
        },
    },
});

export const { setLmsLearnerData } = lmsLearnerData.actions;
export default lmsLearnerData.reducer;