import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPlaying: true,
    mute: true,
    currentTime: 0,
} as {
    isPlaying: boolean;
    mute: boolean;
    currentTime: number;
};
export const templateAudioSlice = createSlice({
    name: "templateAudio",
    initialState,
    reducers: {
        setIsPlayingAudio: (state, action) => {
            state.isPlaying = action.payload;
        },
        setMuteAudio: (state, action) => {
            state.mute = action.payload;
        },
        setCurrentTime: (state, action) => {
            state.currentTime = action.payload
        }
    },
});

export const { setIsPlayingAudio, setMuteAudio, setCurrentTime } = templateAudioSlice.actions;
export default templateAudioSlice.reducer;
