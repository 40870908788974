import React, { useEffect } from 'react'
import Layout from './common/Layout';
import TextWithImageLayout from './layouts/textwithimage';
import { useLMS } from "src/context/Lms";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

type Props = {
    type?: string;
    topic: any;
    quizSettings?: any;
    topics?: any[];
    handleDataChange: (data: any) => void;
    data: any;
    openTemplate: any;
    handleOpenAuthoringModal?: (state: boolean) => void;
    handleCancelTemplateForm?: () => void;
    nestedTemplate?: boolean;
}

function TextWImage({ type, topic, topics, quizSettings, handleDataChange, data, openTemplate, handleOpenAuthoringModal, handleCancelTemplateForm, nestedTemplate }: Props) {

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData)

  const { isInitialized, setSuspendData, getSuspendData, setScore, setSuccessStatus } = useLMS();

  const dispatch = useDispatch();

  useEffect(() => {
    if(!isInitialized) return;
    setSuspendData(JSON.stringify(learnerData));
  }, [learnerData])


    useEffect(() => {

      if(!type) return;
      
      const findTemplate = learnerData.templates.find((template: any) => template.id === topic?.template.id);

      if (findTemplate) {
        dispatch(setLmsLearnerData(
          {
            templates: learnerData.templates.map((template: any) => {
              if (template.id === topic.template.id) {
                return { ...template, data: { ...template.data, completed: true } }
              }
              return template;
            }),
            score: learnerData.score,
          }
        ))
      } else {
        dispatch(setLmsLearnerData(
          {
            templates: [...learnerData.templates, { id: topic?.template.id, title: topic?.template.title, type: topic?.template.type, data: { visited: true, completed: true } }],
            score: learnerData.score,
          }
        ))
      }
          
    }, [])

    const templateType = 'textwithimage';

    return (
        <>
            {!type ? (
                <>
                    {
                        !nestedTemplate ? (
                            // <Layout
                            //     type={templateType}
                            //     data={data}
                            //     handleDataChange={(data) => handleDataChange(data)}
                            //     openTemplate={openTemplate}
                            //     handleOpenAuthoringModal={(state) => { handleOpenAuthoringModal && handleOpenAuthoringModal(state) }}
                            //     handleCancelTemplateForm={() => { handleCancelTemplateForm && handleCancelTemplateForm() }}
                            // >
                                <>
                                    <TextWithImageLayout data={data} handleDataChange={(data) => handleDataChange(data)} />
                                </>

                            // </Layout>
                        ) : (
                            <>
                                <TextWithImageLayout data={data} />

                            </>
                        )
                    }
                </>
            ) : (
                <>
                    <TextWithImageLayout data={data} preview={true} />
                </>
            )
            }
        </>
    )
}

export default TextWImage