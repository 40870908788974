import React, { useEffect, useRef, useState } from 'react'
import Layout from './common/Layout';
import TextWithImageLayout from './layouts/textwithimage';
import ClickToRevealLayout from './layouts/ClickToReveal';
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { Button } from 'src/components/ui';
import Box from '../../AnimationComponent/Box';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCanvasActiveComponent } from 'src/features/canvasActiveComponent/canvasActiveComponent';
import { set } from 'lodash';
import { useLMS } from "src/context/Lms";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";



type Props = {
    type?: string;
    quizSettings?: any;
    topics?: any[];
    topic: any;
    handleDataChange: (data: any) => void;
    data: any;
    openTemplate: any;
    handleOpenAuthoringModal?: (state: boolean) => void;
    handleCancelTemplateForm?: () => void;
    nestedTemplate?: boolean;
}

function FillInTheBlanks({ type, topics, topic, quizSettings, handleDataChange, data, openTemplate, handleOpenAuthoringModal, handleCancelTemplateForm, nestedTemplate }: Props) {

    const templateType = 'fillintheblanks';

    const [content, setContent] = useState('');
    const [inputValues, setInputValues] = useState<Map<string, string>>(new Map());

    const editorRef = useRef<TinyMCEEditor | null>(null);



    useEffect(() => {
        console.log("data", data);
        if (data) {
            setContent(data.content);
            setInputValues(new Map(Object.entries(data.inputValues || {})));
            // updateInputValues(data.content);
        }
    }, [data]);

    useEffect(() => {
        console.log("inputValues", inputValues, content);
    }, [content, inputValues]);

    const handleContentChange = (newContent: string) => {
        console.log("newContent", newContent);
        setContent(newContent);
        updateInputValues(newContent);
        handleDataChange({ content: newContent, inputValues: Object.fromEntries(inputValues) });
    };

    // Update state for each input field based on the editor's content
    const updateInputValues = (content: string) => {
        const tempMap = new Map(inputValues);
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const inputs = doc.querySelectorAll('input.blank-input');

        inputs.forEach((input) => {
            const id = input.getAttribute('data-id');
            if (id && !tempMap.has(id)) {
                tempMap.set(id, '');
            }
        });

        setInputValues(tempMap);
    };

    // Handler to update the value for a specific input field
    const handleInputChange = (id: string, value: string) => {
        setInputValues((prev) => {
            const newInputValues = new Map(prev).set(id, value);
            if (!type) handleDataChange({ ...data, content, inputValues: Object.fromEntries(newInputValues) });
            return newInputValues;
        });
    };

    const addUniqueInput = () => {
        const uniqueId = `input_${Date.now()}`;
        // Insert a unique input element into the editor content
        editorRef.current?.insertContent(
            `<input type="text" data-id="${uniqueId}" class="blank-input" />`
        );
    };

    return (
        <>
            {!type ? (
                <>
                    {
                        !nestedTemplate ? (
                            // <Layout
                            //     type={templateType}
                            //     data={data}
                            //     handleDataChange={(data) => handleDataChange(data)}
                            //     openTemplate={openTemplate}
                            //     handleOpenAuthoringModal={(state) => { handleOpenAuthoringModal && handleOpenAuthoringModal(state) }}
                            //     handleCancelTemplateForm={() => { handleCancelTemplateForm && handleCancelTemplateForm() }}
                            // >
                            <>

                                <RenderPreview data={data} topics={topics} topic={topic} quizSettings={quizSettings} inputValues={inputValues} onInputChange={handleInputChange} handleDataChange={(data) => handleDataChange(data)} />

                            </>

                            // </Layout>
                        ) : (
                            <>
                                <RenderPreview data={data} topics={topics} topic={topic} quizSettings={quizSettings} inputValues={inputValues} onInputChange={handleInputChange} />

                            </>
                        )
                    }
                </>
            ) : (
                <>
                    <RenderPreview data={data} topics={topics} topic={topic} quizSettings={quizSettings} inputValues={inputValues} onInputChange={handleInputChange} preview={true} />
                </>
            )
            }
        </>
    )
}

// Component to render the preview and manage inputs in the DOM
const RenderPreview: React.FC<{
    data: any;
    quizSettings: any;
    topics?: any[];
    topic: any;
    inputValues: Map<string, string>;
    onInputChange: (id: string, value: string) => void;
    preview?: boolean;
    handleDataChange?: (data: any) => void;
}> = ({ data, topics, topic, quizSettings, inputValues, onInputChange, preview, handleDataChange }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [windowIsReady, setWindowIsReady] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const { canvasActiveComponent } = useSelector(
        (state: any) => state.canvasActiveComponent
    );

    const { learnerData } = useSelector((state: any) => state.lmsLearnerData)

    const { isInitialized, setSuspendData, getSuspendData, setScore, setSuccessStatus } = useLMS();
    const minPassingScore = quizSettings?.passingRequirements?.passingScore || 0;

    const [userInputValues, setUserInputValues] = useState<Map<string, string>>(new Map());
    const [showAnswer, setShowAnswer] = useState(false);

    const dispatch = useDispatch();
    // const parser = new DOMParser();

    // const doc = parser.parseFromString(content, 'text/html');

    useEffect(() => {
        setWindowIsReady(true);
    }, []);

    useEffect(() => {
        if (windowIsReady) {
            // Simulate loading process
            const timer = setTimeout(() => {
                setIsLoaded(true);
            }, 1000); // Adjust the timeout as needed

            return () => clearTimeout(timer);
        }
    }, [windowIsReady]);

    // useEffect(() => {
    //     console.log("containerRef.current:", containerRef.current); // Log the containerRef.current
    // }, [containerRef]);

    // useEffect(() => {
    //     const container = containerRef.current;
    //     if (!container) return;

    //     const inputs = container.querySelectorAll('input.blank-input');

    //     const handleChange = (event: Event) => {
    //         const target = event.target as HTMLInputElement;
    //         const id = target.getAttribute('data-id');
    //         if (id) {
    //             onInputChange(id, target.value);
    //         }
    //     };

    //     console.log("inputValues before conversion:", inputValues); // Log the inputValues object before conversion

    //     inputs.forEach((input) => {
    //         const id = input.getAttribute('data-id');
    //         console.log("Input ID:", id); // Log the ID of each input
    //         if (id) {
    //             const htmlInput = input as HTMLInputElement;
    //             const value = inputValues.get(id); // Use inputValues directly if it is already a Map
    //             console.log("Retrieved value:", value); // Log the retrieved value
    //             htmlInput.value = value !== undefined ? String(value) : '';
    //             htmlInput.addEventListener('input', handleChange);
    //         }
    //     });

    //     // Cleanup event listeners on unmount
    //     return () => {
    //         inputs.forEach((input) => {
    //             const htmlInput = input as HTMLInputElement;
    //             htmlInput.removeEventListener('input', handleChange);
    //         });
    //     };
    // }, [data, inputValues, onInputChange]);

    useEffect(() => {
        if (handleDataChange) {
            handleDataChange(data);
        }
    }, []);

    useEffect(() => {
        console.log("data:", data); // Log the userInputValues
    }, [data]);

    // function validateInputs() {
    //     const container = containerRef.current;
    //     console.log("container:", container); // Log the container
    //     if (container) {
    //         const inputs = container.querySelectorAll('input');
    //         console.log("inputs:", inputs); // Log the inputs
    //         inputs.forEach((input) => {
    //             const htmlInput = input as HTMLInputElement;
    //             if (htmlInput.value.trim() !== '') {
    //                 htmlInput.style.color = 'green';
    //                 htmlInput.style.fontWeight = 'semibold';
    //             } else {
    //                 // htmlInput.style.color = 'red'; 
    //             }
    //         });
    //     }
    // }

    // function submit() {
    //     console.log("clicked")
    //     validateInputs();
    //     setIsSubmitted(true);
    // }

    const handleUserInputChange = (id: string, value: string) => {
        setUserInputValues((prev) => new Map(prev).set(id, value));
    };

    const validateAnswers: any = () => {
        // console.log("data.inputValues:", data.inputValues); // Log the inputValues
        if (preview && isSubmitted) {
            const results = Array.from(new Map(Object.entries(data.inputValues || {})).entries()).map((value: any) => {
                console.log("value:", value); // Log the value
                const userValue = userInputValues.get(value[0]) || '';
                return { id: value[0], isCorrect: userValue.trim() === value[1].trim() };
            });
            return results;
        }
    };


    const renderSentence = () => {
        const parts = data.content.split(/(\[Blank_\d+\])/).filter(Boolean);
        return parts.map((part: string, index: number) => {
            const match = part.match(/\[Blank_(\d+)\]/);
            if (match) {
                const id = match[0].slice(1, -1);
                const isCorrect = validateAnswers()?.find((result: any) => result.id === id)?.isCorrect;
                return (
                    <span className='relative'>
                        <input
                            key={id}
                            className={`border ${isCorrect === undefined ? 'border-gray-300' : isCorrect ? 'border-green-500' : 'border-red-500'} p-1 m-1`}
                            onChange={(e) => handleUserInputChange(id, e.target.value)}
                            onMouseEnter={(e) => {
                                if (isSubmitted && !isCorrect) {
                                    setShowAnswer(true);
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (isSubmitted && !isCorrect) {
                                    setShowAnswer(false);
                                }
                            }}
                        />
                        {
                            showAnswer && !isCorrect && (
                                <span className={`absolute -top-16 left-0 bg-white border px-4 py-2 text-lg ${isCorrect ? 'text-green-500' : 'text-red-500'}`}>
                                    {data.inputValues[id]}
                                </span>
                            )
                        }
                    </span>
                );
            }
            return <span key={index}>{part}</span>;
        });
    };

    useEffect(() => {
        console.log("learnerData line: 307", learnerData);
        if (!isInitialized) return;
        console.log("index line : 105", learnerData);
        setSuspendData(JSON.stringify(learnerData));

        setScore(learnerData.score.raw);

        if (learnerData.score.raw >= minPassingScore) {
            setSuccessStatus("passed");
        } else {
            setSuccessStatus("failed");
        }
    }, [learnerData])

    const handleSubmit = () => {
        setIsSubmitted(true)
        const results = validateAnswers();
        console.log('Validation Results:', results);
        const status = results?.every((result: any) => result.isCorrect) ? "correct" : "incorrect";

        if (!preview) return;

        const quizTemplates = topics ? topics.filter((topic) => topic.template && (topic.template.type === "scr" || topic.template.type === "mcr" || topic.template.type === "true/false" || topic.template.type === "matching" || topic.template.type === "fillintheblanks" || topic.template.type === "draganddrop")) : [];
        const noOfQuizes = quizTemplates.length;

        console.log("line 123", learnerData);

        const findTemplate = learnerData.templates.find((template: any) => template.data.id === topic.template.id);

        var score = 0;
        if (status === "correct" && noOfQuizes > 0) {
            score = learnerData.score.raw + Math.round(100 / noOfQuizes)
        } else {
            score = learnerData.score.raw + 0;
        }

        if (findTemplate) {
            dispatch(setLmsLearnerData(
                {
                    templates: learnerData.templates.map((template: any) => {
                        if (template.data.id === topic.template.id) {
                            return { ...template, data: { ...template.data, completed: true } }
                        }
                        return template;
                    }),
                    score: { ...learnerData.score, raw: score }
                }
            ))
        } else {
            dispatch(setLmsLearnerData(
                {
                    templates: [...learnerData.templates, { id: topic.template.id, title: topic.template.title, type: topic.template.type, data: { visited: true, completed: true } }],
                    score: { ...learnerData.score, raw: score }
                }
            ))
        }

    };

    if (!isLoaded) {
        return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
    }

    return (
        // <div
        //     style={data.styles?.container}
        //     onClick={(e) => {
        //         e.stopPropagation();
        //         if (
        //             canvasActiveComponent?.templateType === "fillintheblanks" &&
        //             canvasActiveComponent?.componentType === "container"
        //         ) {
        //             dispatch(setCanvasActiveComponent(null));
        //         } else {
        //             dispatch(
        //                 setCanvasActiveComponent({
        //                     templateType: "fillintheblanks",
        //                     componentType: "container",
        //                 })
        //             );
        //         }
        //     }}
        //     className={`h-full w-full flex flex-col items-start justify-start space-y-2 ${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
        //         canvasActiveComponent?.componentType === "container"
        //         ? "border-2 border-dashed border-blue"
        //         : ""
        //         }`}>
        //     <Box
        //         animate={(preview && data?.animate) || false}
        //         variant='slide'
        //         className="w-full"
        //     >
        //         <div
        //             style={data.styles?.promptContainer}
        //             onClick={(e) => {
        //                 e.stopPropagation();
        //                 if (
        //                     canvasActiveComponent?.templateType === "fillintheblanks" &&
        //                     canvasActiveComponent?.componentType === "prompt"
        //                 ) {
        //                     dispatch(setCanvasActiveComponent(null));
        //                 } else {
        //                     dispatch(
        //                         setCanvasActiveComponent({
        //                             templateType: "fillintheblanks",
        //                             componentType: "prompt",
        //                         })
        //                     );
        //                 }
        //             }}
        //             className={`${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
        //                 canvasActiveComponent?.componentType === "prompt"
        //                 ? "border-2 border-dashed border-blue"
        //                 : ""
        //                 }`}>
        //             <div style={data.styles?.prompt}>
        //                 {data.prompt}
        //             </div>
        //         </div>
        //     </Box>
        //     <Box animate={(preview && data?.animate) || false} variant='zoom' className='grow' >
        //         <div
        //             style={data.styles?.contentContainer}
        //             onClick={(e) => {
        //                 e.stopPropagation();
        //                 if (
        //                     canvasActiveComponent?.templateType === "fillintheblanks" &&
        //                     canvasActiveComponent?.componentType === "content"
        //                 ) {
        //                     dispatch(setCanvasActiveComponent(null));
        //                 } else {
        //                     dispatch(
        //                         setCanvasActiveComponent({
        //                             templateType: "fillintheblanks",
        //                             componentType: "content",
        //                         })
        //                     );
        //                 }
        //             }}
        //             className={`h-full w-full space-y-2 grow ${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
        //                 canvasActiveComponent?.componentType === "content"
        //                 ? "border-2 border-dashed border-blue"
        //                 : ""
        //                 }`}
        //         >
        //             <div style={data.styles?.content} ref={containerRef} dangerouslySetInnerHTML={{ __html: data.content }} />
        //         </div>
        //     </Box>
        //     <div className="flex space-x-2">
        //         <Button
        //             disabled={(preview !== undefined) ? false : true}
        //             onClick={() => {
        //                 submit();
        //             }}
        //             className="mt-4 p-2 bg-blue text-white"
        //         >
        //             Submit
        //         </Button>
        //     </div>
        // </div>

        <div
            style={data.styles?.container}
            onClick={(e) => {
                e.stopPropagation();
                if (
                    canvasActiveComponent?.templateType === "fillintheblanks" &&
                    canvasActiveComponent?.componentType === "container"
                ) {
                    dispatch(setCanvasActiveComponent(null));
                } else {
                    dispatch(
                        setCanvasActiveComponent({
                            templateType: "fillintheblanks",
                            componentType: "container",
                        })
                    );
                }
            }}
            className={`h-full w-full flex flex-col items-start justify-start space-y-2 ${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
                canvasActiveComponent?.componentType === "container"
                ? "border-2 border-dashed border-blue"
                : ""
                }`}>
            <Box
                isAnimate={(data?.animate) || false}
                effect={data?.animateComponent?.prompt?.effect || 'slide'}
                direction={data?.animateComponent?.prompt?.direction || 'right'}
                inStart={data?.animateComponent?.prompt?.animationConfig.in.start || 0}
                inDuration={(data?.animateComponent?.prompt?.animationConfig.in.end - data?.animateComponent?.prompt?.animationConfig.in.start) || 0}
                outStart={data?.animateComponent?.prompt?.animationConfig.out.start || 0}
                outDuration={(data?.animateComponent?.prompt?.animationConfig.out.end - data?.animateComponent?.prompt?.animationConfig.out.start) || 0}
                className="w-full"
            >
                <div
                    style={data.styles?.promptContainer}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (
                            canvasActiveComponent?.templateType === "fillintheblanks" &&
                            canvasActiveComponent?.componentType === "prompt"
                        ) {
                            dispatch(setCanvasActiveComponent(null));
                        } else {
                            dispatch(
                                setCanvasActiveComponent({
                                    templateType: "fillintheblanks",
                                    componentType: "prompt",
                                })
                            );
                        }
                    }}
                    className={`${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
                        canvasActiveComponent?.componentType === "prompt"
                        ? "border-2 border-dashed border-blue"
                        : ""
                        }`}>
                    <div style={data.styles?.prompt}>
                        {data.prompt}
                    </div>
                </div>
            </Box>
            <Box
                isAnimate={(data?.animate) || false}
                effect={data?.animateComponent?.content?.effect || 'slide'}
                direction={data?.animateComponent?.content?.direction || 'right'}
                inStart={data?.animateComponent?.prompt?.animationConfig.in.start || 0}
                inDuration={(data?.animateComponent?.prompt?.animationConfig.in.end - data?.animateComponent?.prompt?.animationConfig.in.start) || 0}
                outStart={data?.animateComponent?.prompt?.animationConfig.out.start || 0}
                outDuration={(data?.animateComponent?.prompt?.animationConfig.out.end - data?.animateComponent?.prompt?.animationConfig.out.start) || 0}
                className="grow"
            >
                <div
                    style={data.styles?.contentContainer}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (
                            canvasActiveComponent?.templateType === "fillintheblanks" &&
                            canvasActiveComponent?.componentType === "content"
                        ) {
                            dispatch(setCanvasActiveComponent(null));
                        } else {
                            dispatch(
                                setCanvasActiveComponent({
                                    templateType: "fillintheblanks",
                                    componentType: "content",
                                })
                            );
                        }
                    }}
                    className={`h-full w-full space-y-2 grow ${!preview && canvasActiveComponent?.templateType === "fillintheblanks" &&
                        canvasActiveComponent?.componentType === "content"
                        ? "border-2 border-dashed border-blue"
                        : ""
                        }`}
                >
                    <div style={data.styles?.content}>{renderSentence()}</div>
                </div>
            </Box>
            <div className="flex space-x-2">
                <Button
                    disabled={(preview !== undefined) ? isSubmitted ? true : false : true}
                    onClick={() => {
                        handleSubmit();
                    }}
                    className="mt-4 p-2 bg-blue text-white"
                >
                    Submit
                </Button>
            </div>
        </div>
    )
};

export default FillInTheBlanks