import React, { ElementRef, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch, AnyIfEmpty } from "react-redux";
import FolderLogo from "../../assets/Course/folder.png";
import TemplateFileLogo from "../../assets/Course/layout.png";
import { cn } from "../lib/utils";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import RenameLogo from "../../assets/TableMenu/PencilLine.svg";
import DeleteLogo from "../../assets/TableMenu/TrashSimple.svg";
import ListLogo from "../../assets/list_2.png";
import CopyLogo from "../../assets/TableMenu/Copy.svg";
import MoveUp from "../../assets/move_up.png";
import MoveDown from "../../assets/move_down.png";
import RemoveLogo from "../../assets/remove1.png";
import ExportLogo from "../../assets/TableMenu/UploadSimple.svg";
import ImportLogo from "../../assets/Course/importIcon.png";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import { setLog } from "src/features/errorLog/errorLogSlice";
import Tools from "./Tools";
import {
  ChevronDown,
  ChevronRight,
  FolderTree,
  MenuIcon,
  Plus,
  File,
  PanelTop,
} from "lucide-react";
import { Template } from "./TemplateRibbon";
import { toNumber } from "lodash";
import { setSelectedTab } from "src/features/selectedTab/selectedTabSlice";
import { setSelectedCard } from "src/features/selectedCard/selectedCardSlice";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { json } from "d3";

export type FileSystemNode = {
  id: string;
  name: string;
  type: "file" | "folder" | "tabsFolder";
  tools?: any;
  template?: Template | null;
  depth: number; // Track the depth of the folder
  children?: FileSystemNode[];
};

type Props = {
  handleTopic: (topic: any) => void;
  topic: any;
  tabTemplate: any;
  handleTabTemplate: () => void;
};

function Navigation({
  handleTopic,
  topic,
  tabTemplate,
  handleTabTemplate,
}: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [data, setData] = useState<FileSystemNode[]>([]);
  const [inputState, setInputState] = useState<{
    type: "folder" | "file";
    parentId: string | null;
  } | null>(null);
  const [newNodeName, setNewNodeName] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  // To rename
  const [editNodeId, setEditNodeId] = useState<string | null>(null);
  const [renameNodeName, setRenameNodeName] = useState<string>("");
  const renameInputRef = useRef<HTMLInputElement>(null);
  const creationInputRef = useRef<HTMLInputElement>(null);

  const [openOptions, setOpenOptions] = useState("");
  const [tableMenuActive, setTableMenuActive] = useState<string>("");
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  const [windowIsReady, setWindowIsReady] = useState(true);

  const [activeNodeId, setActiveNodeId] = useState<string | null>(null);

  // const [topic, setTopic] = useState<any>();
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isResizingRef = useRef(false);
  const sidebarRef = useRef<ElementRef<"div">>(null);
  const selectedRef = useRef<ElementRef<"div">>(null);

  const [isResetting, setIsResetting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [folder, setFolder] = useState<any>(null);
  const [topicData, setTopicData] = useState<any>(null);
  const [error, setError] = useState<string>("");

  const [powerpointFile, setPowerpointFile] = useState<File | null>(null);

  const { pageId, userId, businessUnitId, courseId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { selectedTemplate, selectedTool } = useSelector(
    (state: any) => state.courseTemplate
  );
  const dispatch = useDispatch();

  // Fetch existing data from API
  useEffect(() => {
    (async () => {
      setWindowIsReady(false);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          setData(res.data.data.course?.data || []); // Ensure data is set as an array
          setWindowIsReady(true);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [courseId, accessToken]);

  // Save data when the structure changes (debounced)
  useEffect(() => {
    if (windowIsReady) {
      const debounceTimeout = setTimeout(async () => {
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`,
            { data },
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          if (res && res.data) console.log(res.data);
        } catch (error) {
          console.log(error);
        }
      }, 500); // 500ms debounce time

      return () => clearTimeout(debounceTimeout);
    }
  }, [data, accessToken]);

  useEffect(() => {
    console.log("topic:", topic);
    const updateContent = (items: any[]) => {
      const newArr = items?.map((item: any) => {
        if (item.id === topic?.id) {
          item = topic;
        } else if (item.type === "folder") {
          item.children = updateContent(item.children);
        }
        else if (item.type === "tabsFolder") {
          item.children = updateContent(item.children);
        }
        return item;
      });
      return newArr;
    };

    const newData = updateContent(data);

    console.log("newData:", newData);
    setData(newData);
  }, [topic]);

  useEffect(() => {
    console.log(tabTemplate);
    if (tabTemplate) handleCreateNode();
  }, [tabTemplate]);

  useEffect(() => {
    console.log(selectedTemplate);
    if (selectedTemplate?.type === "tabs") handleCreateNode();
  }, [selectedTemplate]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    isResizingRef.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizingRef.current) return;
    let newWidth = e.clientX;

    if (newWidth < 240) newWidth = 240;
    if (newWidth > 480) newWidth = 480;

    if (sidebarRef.current) {
      sidebarRef.current.style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const resetWidth = () => {
    if (sidebarRef.current) {
      setIsCollapsed(false);
      setIsResetting(true);
      sidebarRef.current.style.width = isMobile ? "100%" : "20rem";
      // navbarRef.current.style.setProperty("left", isMobile ? "100%" : "30rem");
      // navbarRef.current.style.setProperty(
      //   "width",
      //   isMobile ? "0" : "calc(100% - 30rem)"
      // );
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  const collapse = () => {
    if (sidebarRef.current) {
      setIsCollapsed(true);
      setIsResetting(true);
      sidebarRef.current.style.width = "0";
      // navbarRef.current.style.setProperty("left", "0");
      // navbarRef.current.style.setProperty("width", "100%");
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data])

  const addNodeToParent = (
    node: FileSystemNode,
    parent: FileSystemNode | null
  ) => {
    if (!parent) {
      setData((prevData) => [...prevData, node]);
    } else {
      const addToParent = (nodes: FileSystemNode[]): FileSystemNode[] =>
        nodes.map((n) =>
          n === parent
            ? { ...n, children: [...(n.children || []), node] }
            : {
                ...n,
                children: n.children ? addToParent(n.children) : undefined,
              }
        );
      setData(addToParent(data));
    }
  };

  function findTabTemplate(
    selectedNodeId: string,
    data: any
  ): { deletedTemplate: FileSystemNode | null; updatedData: any } {
    var deletedTemplate: FileSystemNode | null = null;

    const removeSelectedTemplateFromData = (
      nodes: FileSystemNode[]
    ): FileSystemNode[] => {
      return nodes.filter((n) => {
        if (n.id === selectedNodeId) {
          deletedTemplate = n;
          return false;
        }
        if (n.children && n.children.length > 0) {
          n.children = removeSelectedTemplateFromData(n.children);
        }
        return true;
      });
    };

    const updatedData = removeSelectedTemplateFromData(data);

    return { deletedTemplate, updatedData };
  }

  function findTabsParent(topic: any, nodes: any): any {
    // Iterate through the nodes array
    for (const node of nodes) {
      // Check if the node has children
      if (node.children && Array.isArray(node.children)) {
        // Check if any child node's id matches the topic's id
        if (node.children.some((child: any) => child.id === topic.id)) {
          return node; // Return the node as the parent
        }

        // Recursively search in the children array
        const found = findTabsParent(topic, node.children);
        if (found) {
          return found; // Return the found node
        }
      }
    }

    // If no parent is found, return null
    return null;
  }

  const handleCreateNode = () => {
    const parentNode = findNodeById(data, selectedNodeId);
    console.log(selectedNodeId, parentNode, selectedTemplate);
    if (topic?.id && selectedTemplate?.type === "tabs") {
      const { deletedTemplate, updatedData } = findTabTemplate(topic?.id, data);

      if (!deletedTemplate) return;
      setData(updatedData);

      const tabsParent: FileSystemNode = {
        id: (Math.round(Math.random() * (10000 - 1)) + 1).toString(),
        name: "Tabs",
        type: "tabsFolder",
        depth: parentNode ? parentNode.depth + 1 : 0,
        children: [{ ...deletedTemplate, template: selectedTemplate }],
      };
      if (tabsParent.children && tabsParent.children[0]) {
        tabsParent.children[0] = {
          ...tabsParent.children[0],
          depth: tabsParent.depth + 1, // Set depth relative to tabsParent
        };
      }
      const updatedTabsParent = { ...tabsParent };
      console.log("TabsParent after updating depth:", updatedTabsParent);
      addNodeToParent(tabsParent, parentNode);
      if (tabsParent?.children?.length) {
        setSelectedNodeId(tabsParent.children[0].id);
      } else {
        setSelectedNodeId(null);
      }
    }
    if (
      (!inputState || newNodeName.trim() === "") &&
      topic?.template?.type !== "tabs"
    )
      return;
    else {
      const newNode: FileSystemNode = {
        id: (Math.round(Math.random() * (10000 - 1)) + 1).toString(),
        name: tabTemplate ? tabTemplate.title : newNodeName,
        type: tabTemplate ? "file" : inputState?.type ?? "folder",
        tools: tabTemplate
          ? { id: new Date().getTime(), data: [] }
          : inputState?.type === "file"
          ? { id: new Date().getTime(), data: [] }
          : {},
        template: tabTemplate
          ? null
          : inputState?.type === "file"
          ? null
          : undefined,
        depth: parentNode ? parentNode.depth + 1 : 0, // If parentNode is undefined, default depth is 0
        children: inputState?.type === "folder" ? [] : undefined,
      };

      setSelectedNodeId(newNode.id);
      if (selectedNodeId !== null) {
        setExpandedNodes(new Set([...expandedNodes, selectedNodeId])); // Automatically expand the chapter
      }
      if (tabTemplate) {
        const tabsParent = findTabsParent(topic, data);
        console.log("tabsParent", tabsParent);

        
        if (tabsParent) {
          newNode.depth = tabsParent.depth + 1; // Update depth based on tabsParent depth
        }

        console.log("tabsParent", tabsParent);

        addNodeToParent(newNode, tabsParent);
        if (newNode.type === "file") {
          handleTopic(newNode);
          handleTabTemplate();
        } else if (newNode.type === "tabsFolder") {
          handleTopic(null);
        }

      } else {
        addNodeToParent(newNode, parentNode);
        if (newNode.type === "file") {
          handleTopic(newNode);
        } else if (newNode.type === "folder") {
          handleTopic(null);
        }
      }
      setNewNodeName("");
      setInputState(null);
      setSelectedNodeId(newNode?.id);
    }
  };

  const findNodeById = (
    nodes: FileSystemNode[],
    id: string | null
  ): FileSystemNode | null => {
    if (id === null) return null;
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDelete = (id: string) => {
    const deleteNode = (
      nodes: FileSystemNode[],
      nodeId: string
      // additionalIdsToDelete: string[] = []
    ): FileSystemNode[] => {
      return nodes.reduce((acc: FileSystemNode[], node) => {
        // const additionalNodesToDelete: any[] = data.filter((topic: any) => topic.template.id === )

        // Skip the node to delete
        if (node.id === nodeId) return acc;

        // Recursively delete from children
        const updatedChildren = node.children
          ? deleteNode(node.children, nodeId)
          : [];

        // Include node only if it has children or is not a folder being deleted
        if (updatedChildren.length > 0 || node.id !== nodeId) {
          acc.push({ ...node, children: updatedChildren });
        }

        return acc;
      }, []);
    };

    setData(deleteNode(data, id));

    // window.location.reload();
  };

  const handleRenameNode = (nodeId: string, currentName: string) => {
    setEditNodeId(nodeId);
    setRenameNodeName(currentName);
  };

  const handleSaveRename = () => {
    if (!editNodeId || renameNodeName.trim() === "") return;

    const updateNodeName = (nodes: FileSystemNode[]): FileSystemNode[] =>
      nodes.map((node) =>
        node.id === editNodeId
          ? { ...node, name: renameNodeName }
          : {
              ...node,
              children: node.children
                ? updateNodeName(node.children)
                : undefined,
            }
      );

    setData(updateNodeName(data));
    setEditNodeId(null);
    setRenameNodeName("");
  };

  useEffect(() => {
    if (creationInputRef.current && inputState) {
      creationInputRef.current.focus();
    }
    if (renameInputRef.current && editNodeId) {
      renameInputRef.current.focus();
    }
  }, [editNodeId, inputState]);

  // Function to find and swap the nodes
  const swapNodes = (
    nodes: FileSystemNode[],
    nodeId: string,
    direction: "up" | "down"
  ): FileSystemNode[] => {
    const index = nodes.findIndex((node) => node.id === nodeId);
    if (index === -1) return nodes; // Node not found, return unchanged nodes

    if (direction === "up" && index > 0) {
      // Swap with the previous node
      [nodes[index - 1], nodes[index]] = [nodes[index], nodes[index - 1]];
    }

    if (direction === "down" && index < nodes.length - 1) {
      // Swap with the next node
      [nodes[index + 1], nodes[index]] = [nodes[index], nodes[index + 1]];
    }

    return [...nodes]; // Return a new array with swapped nodes
  };

  // Recursive function to move nodes up/down in the entire structure
  const moveNode = (
    nodes: FileSystemNode[],
    nodeId: string,
    direction: "up" | "down"
  ): FileSystemNode[] => {
    const index = nodes.findIndex((node) => node.id === nodeId);

    if (index === -1) {
      // Node not found at this level, so check in children nodes recursively
      return nodes.map((node) => ({
        ...node,
        children: node.children
          ? moveNode(node.children, nodeId, direction)
          : node.children,
      }));
    }

    if (direction === "up" && index > 0) {
      // Swap with the previous node
      [nodes[index - 1], nodes[index]] = [nodes[index], nodes[index - 1]];
    } else if (direction === "down" && index < nodes.length - 1) {
      // Swap with the next node
      [nodes[index + 1], nodes[index]] = [nodes[index], nodes[index + 1]];
    }

    return [...nodes]; // Return the modified nodes array
  };

  const handleMoveNode = (nodeId: string, direction: "up" | "down") => {
    setData((prevData) => moveNode(prevData, nodeId, direction));
  };

  const handleNodeSelect = (nodeId: string) => {
    setSelectedNodeId(nodeId);
  };

  // Creating a function to handle expanding and collapsing chapters
  const toggleNodeExpand = (id: string) => {
    const newSet = new Set(expandedNodes);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedNodes(newSet);
  };

  const tableRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!tableRef?.current?.contains(event.target as Node)) {
        setOpenOptions("");
        setTableMenuActive("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tableRef]);

  const handleExport = async (id: string) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          responseType: "blob",
        }
      );
      if (res && res.data) {
        console.log(res.data);

        // Create a URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;

        // Set the download file name
        link.setAttribute("download", `template_${id}.json`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderStructure = (nodes: FileSystemNode[], depth: number = 0) => (
    <ul className="w-full ">
      {nodes.map((node) => (
        <div key={node.id} className=" w-full ">
          <div
            // ref={selectedRef}
            onClick={() => {
              handleNodeSelect(node.id);
              // if (node.type === "file") {
              //   handleTopic(node);
              // }
            }}
            className={`relative w-full ${
              node.depth === 0
                ? "pl-[16px]"
                : node.depth === 1
                ? "pl-[32px]"
                : node.depth === 2
                ? "pl-[48px]"
                : "pl-[64px]"
            } pr-4 py-1.5 hover:bg-[#dedede] flex items-center space-x-2 group ${
              selectedNodeId === node.id
                ? "bg-[#dedede] border border-tertiary"
                : ""
            } ${node.id === topic?.id && "bg-[#dedede]"}`}
          >
            {editNodeId === node.id ? (
              <input
                ref={renameInputRef}
                className="outline-tertiary px-[1.5rem] py-[0.5rem] w-full text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                value={renameNodeName}
                onChange={(e) => setRenameNodeName(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleSaveRename()}
                onBlur={() => handleSaveRename()}
              />
            ) : (
              <div className="w-full flex items-center justify-between">
                {node.type === "folder" ? (
                  <button
                    onClick={() => toggleNodeExpand(node.id)}
                    className="grow flex items-center space-x-1"
                  >
                    <div>
                      {expandedNodes.has(node.id) ? (
                        <ChevronDown size={24} />
                      ) : (
                        <ChevronRight size={24} />
                      )}
                    </div>
                    <div className="text-sm line-clamp-1 text-start">
                      {node.name}
                    </div>
                  </button>
                ) : (
                  <div
                    onClick={() => {
                      if (node.type === "file") {
                        handleTopic(node);
                        console.log(node);
                        if (node.template && node.template.type !== "tabs")
                          dispatch(setSelectedTab(null));
                        else if (node.template && node.template.type === "tabs")
                          dispatch(
                            setSelectedTab({
                              ...node.template.data.tabs[0],
                              index: 0,
                            })
                          );
                      }

                      if (node.type === "tabsFolder") {
                        toggleNodeExpand(node.id);
                      }
                    }}
                    className="grow flex items-center space-x-1"
                  >
                    {node.type === "tabsFolder" ? (
                      <PanelTop size={24} />
                    ) : (
                      <div>
                        <File color="#333333" size={20} />
                      </div>
                    )}

                    <div className="text-sm line-clamp-1 py-2">{node.name}</div>
                  </div>
                )}
                <div
                  className={`${
                    tableMenuActive !== node.id ? "invisible" : "visible"
                  } group-hover:visible flex space-x-1 items-center relative`}
                >
                  {node.type === "folder" && (
                    <>
                      {node.depth < 2 && (
                        <button
                          onClick={() => {
                            node.type === "folder" &&
                              node?.depth < 2 &&
                              setInputState({
                                type: "folder",
                                parentId: node.id,
                              });
                          }}
                          className="tooltip-container py-2"
                        >
                          <FolderTree
                            strokeWidth={"2"}
                            color="#333333"
                            size={20}
                          />
                          <span className="tooltip-text">Add Topic</span>
                        </button>
                      )}
                      <button
                        onClick={() => {
                          node.type === "folder" &&
                            setInputState({
                              type: "file",
                              parentId: node.id,
                            });
                        }}
                        className="tooltip-container py-2"
                      >
                        <File color="#333333" size={20} />
                        <span className="tooltip-text">Add Template</span>
                      </button>
                    </>
                  )}
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          if (!openOptions) {
                            setOpenOptions(node.id);
                            setTableMenuActive(node.id);
                          } else setOpenOptions("");
                        }}
                        className={``}
                        variant="destructive"
                      >
                        <OverflowMenuHorizontal size="24" />
                      </Button>
                    </DropdownMenuTrigger>
                    {openOptions === node.id && (
                      <DropdownMenuContent
                        ref={tableRef}
                        className="relative right-0 flex flex-col w-[11.4375rem] h-[17rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] px-[0.7rem] py-[0.4rem] space-y-[1.94rem] z-[99999]"
                      >
                        <DropdownMenuGroup>
                          <DropdownMenuItem className="flex items-center">
                            <button
                              className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                              onClick={() =>
                                handleRenameNode(node.id, node.name)
                              }
                            >
                              <span>
                                <img src={RenameLogo} alt="logo" />
                              </span>
                              <span>Rename</span>
                            </button>
                          </DropdownMenuItem>
                          <DropdownMenuItem className="flex items-center">
                            <button
                              onClick={() => handleMoveNode(node.id, "up")}
                              className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                            >
                              <span>
                                <img
                                  src={MoveUp}
                                  alt="logo"
                                  className="w-7 h-7"
                                />
                              </span>
                              <span>Move Up</span>
                            </button>
                          </DropdownMenuItem>
                          <DropdownMenuItem className="flex items-center">
                            <button
                              onClick={() => handleMoveNode(node.id, "down")}
                              className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                            >
                              <span>
                                <img
                                  src={MoveDown}
                                  alt="logo"
                                  className="w-7 h-7"
                                />
                              </span>
                              <span>Move Down</span>
                            </button>
                          </DropdownMenuItem>
                          <DropdownMenuItem className="flex items-center">
                            <button
                              onClick={() => {}}
                              className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                            >
                              <span>
                                <img src={CopyLogo} alt="logo" />
                              </span>
                              <span>Duplicate</span>
                            </button>
                          </DropdownMenuItem>
                          {node.type === "file" && (
                            <DropdownMenuItem className="flex items-center">
                              <button
                                onClick={() => {
                                  if (node.type === "file") {
                                    handleExport(node.id);
                                  }
                                }}
                                disabled={
                                  node.template === null ||
                                  node.tools === undefined
                                }
                                className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem] disabled:text-[#ababab]"
                              >
                                <span>
                                  <img src={ExportLogo} alt="logo" />
                                </span>
                                <span>Export</span>
                              </button>
                            </DropdownMenuItem>
                          )}
                          {node.type === "folder" && (
                            <DropdownMenuItem className="flex items-center">
                              <button
                                onClick={() => {
                                  if (node.type === "folder") {
                                    setOpenImportDialog(true);
                                    setFolder(node);
                                  }
                                }}
                                className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem] disabled:text-[#ababab]"
                              >
                                <span>
                                  <img
                                    src={ImportLogo}
                                    alt="logo"
                                    className="w-6 h-6"
                                  />
                                </span>
                                <span>Import</span>
                              </button>
                            </DropdownMenuItem>
                          )}
                          {/* <DropdownMenuSeparator className="bg-[#2C2C2C]" /> */}
                          <DropdownMenuItem className="flex items-center">
                            <button
                              onClick={() => handleDelete(node.id)}
                              className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                              //   onClick={() => handleDeletePopup(unit._id)}
                            >
                              <span>
                                <img
                                  src={RemoveLogo}
                                  alt="logo"
                                  className="w-6 h-6"
                                />
                              </span>
                              <span>Remove</span>
                            </button>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    )}
                  </DropdownMenu>
                </div>
              </div>
            )}
            {node.type === "folder" &&
              selectedNodeId === node.id &&
              inputState && (
                <>
                  {/* Render input field below the selected folder */}
                  <div
                    className={`absolute top-6 right-0 w-48 mt-2 transition-all duration-300 z-10 ${
                      selectedNodeId ? "block" : "hidden"
                    }`}
                    ref={inputRef}
                  >
                    <input
                      ref={creationInputRef}
                      className="outline-tertiary w-full px-[1.5rem] py-[0.5rem] text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                      value={newNodeName}
                      onChange={(e) => setNewNodeName(e.target.value)}
                      onKeyDown={(e) => e.key === "Enter" && handleCreateNode()}
                      placeholder={`${
                        inputState?.type === "folder" ? "Topic" : "Template"
                      } name`}
                    />
                  </div>
                </>
              )}
          </div>

          {expandedNodes.has(node.id) &&
            node.children &&
            // node.children.length > 0 &&
            renderStructure(node.children, depth + 1)}
        </div>
      ))}
    </ul>
  );

  // Handle clicks outside the input fields to close them
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedRef.current &&
        !selectedRef.current.contains(event.target as Node)
      ) {
        // Reset selectedNodeId if clicking outside the sidebar
        setSelectedNodeId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // if (
      //   sidebarRef.current &&
      //   !sidebarRef.current.contains(event.target as Node)
      // ) {
      //   // Reset selectedNodeId if clicking outside the sidebar
      //   setSelectedNodeId(null);
      // }

      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        // Close the input field if clicking outside
        setInputState(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {}, [topicData]);

  function handleImport(node: FileSystemNode, parent: FileSystemNode) {
    if (!parent) {
      setData((prevData) => [...prevData, node]);
    } else {
      const addToParent = (nodes: FileSystemNode[]): FileSystemNode[] =>
        nodes.map((n) =>
          n.id === parent.id
            ? { ...n, children: [...(n.children || []), node] }
            : {
                ...n,
                children: n.children ? addToParent(n.children) : undefined,
              }
        );
      setData(addToParent(data));
    }

    setTopicData(null);
    setFolder(null);
  }

  async function handleImportPPT() {
    if (!powerpointFile) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("pptFile", powerpointFile);

    try {
      const res = await axios.post(
        `${
          process.env.REACT_APP_SERVER_BASE_URL
        }/api/v1/course1/importPPT/${courseId}?topicId=${
          topic ? topic.id : ""
        }`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res && res.data) {
        console.log("Conversion Successful", res.data);
        const { images } = res.data;

        console.log(images);

        // Example: Set the images in your state or pass them to a function
        // setTopic({
        //   ...topic,
        //   tools: images.map((url, index) => ({
        //     id: `slide-${index + 1}`,
        //     type: "image",
        //     src: url,
        //   })),
        // });
      }
    } catch (error) {
      console.error("Error importing PPT:", error);
    }
  }

  return (
    <>
      <aside className="flex">
        {/* <div
          ref={navbarRef}
          className={cn(
            "absolute top-0 z-[99999] left-60 w-[calc(100%-20rem)]",
            isResetting && "transition-all ease-in-out duration-300",
            isMobile && "left-0 w-full"
          )}
        > */}
        <nav className="bg-transparent px-3 py-2 border border-r-2 flex flex-col items-center max-w-[4rem]">
          {/* {isCollapsed && ( */}
          <>
            <div
              // ref={selectedRef}
              className="flex items-center justify-center p-2 cursor-pointer hover:bg-gray-200"
            >
              <MenuIcon
                onClick={() => (isCollapsed ? resetWidth() : collapse())}
                role="button"
                size={24}
              />
            </div>
            <Tools />
          </>
          {/* )} */}
        </nav>
        {/* </div> */}

        <div
          ref={sidebarRef}
          className={cn(
            "group/sidebar h-full overflow-hidden bg-[#EFEFEF] relative flex w-[15rem] flex-col",
            isMobile && "w-0"
          )}
        >
          <div
            ref={selectedRef}
            className="w-full h-12 flex items-center justify-end space-x-3 bg-gray-200 border-b-2 border-gray-300 px-[1.28rem] py-[0.5rem]"
          >
            <div>
              <button
                onClick={() => {
                  setOpenImportDialog(true);
                  setFolder(null);
                }}
                className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem] disabled:text-[#ababab]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="lucide lucide-file-down"
                >
                  <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                  <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                  <path d="M12 18v-6" />
                  <path d="m9 15 3 3 3-3" />
                </svg>
                {/* <span>
                  <img src={ImportLogo} alt="logo" className="w-6 h-6" />
                </span> */}
              </button>
            </div>
            <div className="relative group">
              <button
                className=""
                onClick={() => {
                  setSelectedNodeId(null);
                  setInputState({ type: "folder", parentId: null });
                }}
              >
                <FolderTree strokeWidth={"2"} color="#333333" size={24} />
              </button>
              <span className="hidden absolute bottom-[-35px] left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 z-[99999]">
                Create Topic
              </span>
            </div>
            <div className="relative group">
              <button
                className=""
                onClick={() => {
                  setSelectedNodeId(null);
                  setInputState({ type: "file", parentId: null });
                }}
              >
                <File color="#333333" size={24} />
              </button>
              <span className="hidden absolute bottom-[-35px] left-1/4 transform -translate-x-1/2 -ml-7 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 z-[99999]">
                Create Template
              </span>
            </div>
          </div>

          {/* Input fields for folder/file creation */}
          {inputState && selectedNodeId === null && (
            <div className="absolute w-48 top-10 right-0 z-10" ref={inputRef}>
              <input
                ref={creationInputRef}
                className="outline-tertiary w-full px-[1.5rem] py-[0.5rem] text-[1rem] text-[#242424] font-[400] leading-normal bg-[#F5F5F5] border-2 border-black/70 rounded-md"
                value={newNodeName}
                onChange={(e) => setNewNodeName(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleCreateNode()}
                placeholder={`${
                  inputState.type === "folder" ? "Topic" : "Template"
                } name`}
              />
            </div>
          )}
          <div className="w-full overflow-y-scroll overflow-x-hidden no-scrollbar">
            {renderStructure(data)}
          </div>
        </div>
      </aside>

      <Dialog
        open={openImportDialog}
        onOpenChange={(open) => setOpenImportDialog(open)}
      >
        <DialogContent className="max-w-[600px] flex justify-center">
          <DialogHeader>
            <DialogTitle></DialogTitle>
            {/* <DialogDescription>
              Make changes to your profile here. Click save when you're done.
            </DialogDescription> */}
          </DialogHeader>
          <Tabs defaultValue="importTemplate" className="w-[400px]">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="importTemplate">Import Template</TabsTrigger>
              <TabsTrigger value="importPPT">Import PPT</TabsTrigger>
            </TabsList>
            <TabsContent value="importTemplate">
              <div className="w-[400px] h-[350px] flex flex-col justify-center items-center space-y-6">
                <div className="grid w-full max-w-sm items-center gap-3">
                  <label htmlFor="picture">Import Template</label>
                  <input
                    id="picture"
                    type="file"
                    accept=".json"
                    onChange={(e) => {
                      const file = e.target?.files?.[0]; // Get the first uploaded file
                      console.log(file);

                      if (file) {
                        const reader = new FileReader();

                        reader.onload = (e) => {
                          const result = e.target?.result;

                          if (typeof result === "string") {
                            // Ensure result is a string
                            try {
                              const parsedData = JSON.parse(result); // Parse the JSON data
                              const updatedData = {
                                ...parsedData,
                                id: (
                                  Math.round(Math.random() * (10000 - 1)) + 1
                                ).toString(),
                                depth: folder ? folder.depth + 1 : 0,
                              };
                              console.log(updatedData);
                              setTopicData(updatedData); // Set the parsed data in state
                              setError(""); // Clear any previous errors
                            } catch (err) {
                              setError("Invalid JSON file"); // Handle JSON parsing error
                            }
                          } else {
                            setError("File content could not be read as text."); // Handle non-string results
                          }
                        };

                        reader.onerror = () => {
                          setError("Error reading file"); // Handle file reading error
                        };

                        reader.readAsText(file); // Read the file as text
                      } else {
                        setError("No file selected"); // Handle case when no file is selected
                      }
                    }}
                  />
                </div>
                <Button
                  disabled={!topicData}
                  onClick={() => handleImport(topicData, folder)}
                  className="text-white bg-blue"
                >
                  Import
                </Button>
              </div>
            </TabsContent>
            <TabsContent value="importPPT">
              <div className="w-[400px] h-[350px] flex flex-col justify-center items-center space-y-6">
                <div className="grid w-full max-w-sm items-center gap-3">
                  <label htmlFor="picture">Import PPT</label>
                  <input
                    id="powerpoint"
                    type="file"
                    accept=".ppt, .pptx"
                    onChange={(e) => {
                      const file = e.target?.files?.[0]; // Get the first uploaded file
                      console.log(file);
                      if (file) setPowerpointFile(file);
                    }}
                  />
                </div>
                <Button
                  onClick={handleImportPPT}
                  className="text-white bg-blue"
                >
                  Import
                </Button>
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Navigation;
