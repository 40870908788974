import { color } from "d3";
import { max } from "lodash";
import { Type } from "lucide-react";
import React from "react";
import { v4 as uuid } from "uuid";

export type Option = {
    _id: string;
    value: string;
    correct: boolean;
    response: boolean;
    feedback?: string;
    optionImage?: {
        _id: string;
        url: string;
        name: string;
        type: string;
        size: number;
        createdOn: number;
        folderId: string | null;
        userId: string;
        __v: number;
    };
};

export type ComponentProperty = {
    type: string;
    maxLength?: number;
    zone: number[][];
};

export type Question = {
    question: string;
    prompt: string;
    optionquantity?: number;
    timer?: number;
    options: Option[];
    haveImage: boolean;
    imageFile: object | null;
    globalFeedback?: string;
    isSubmitType?: boolean;
    isNextType?: boolean;
    isGlobalPopup?: boolean;
    isTimer?: boolean;
    haveOptionImage?: boolean;
    componentProperties?: ComponentProperty[];
};

const defaultMcrData: any = {
    question: "Choose the airbuses that are available in CATHAY PACIFIC",
    prompt: "Select multiple options",
    options: [
        {
            _id: uuid(),
            value: "A320",
            correct: true,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "A330",
            correct: true,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "A340",
            correct: true,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "A350",
            correct: true,
            response: false,
            feedback: "",
        },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    courseController: {
        skip: true
    },
    componentProperties: [
        {
            type: "Question",
            maxLength: 150,
            zone: [
                [1, 1],
                [0, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Image",
            zone: [
                [0, 0],
                [0, 1],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Prompt",
            maxLength: 80,
            zone: [
                [0, 0],
                [1, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices",
            maxLength: 55,
            zone: [
                [0, 0],
                [0, 0],
                [1, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices Feedback",
            maxLength: 30,
            zone: [
                [0, 0],
                [0, 0],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Global Feedback",
            maxLength: 150,
            zone: [
                [0, 0],
                [0, 0],
                [0, 0],
                [1, 1],
            ],
        },
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff",
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },

        option: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: "contain",
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
};

const defaultScrData: any = {
    question:
        "During the safety demonstration, passengers are shown how to use which of the following?",
    prompt: "Select an option",
    options: [
        {
            _id: uuid(),
            value: "The overhead reading light",
            correct: false,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "The in-flight Wi-Fi system",
            correct: false,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "The oxygen mask and life vest",
            correct: true,
            response: false,
            feedback: "",
        },
        {
            _id: uuid(),
            value: "The food and beverage menu",
            correct: false,
            response: false,
            feedback: "",
        },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isSubmitType: true,
    isNextType: false,
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    courseController: {
        skip: true
    },
    componentProperties: [
        {
            type: "Question",
            maxLength: 150,
            zone: [
                [1, 1],
                [0, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Image",
            zone: [
                [0, 0],
                [0, 1],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Prompt",
            maxLength: 80,
            zone: [
                [0, 0],
                [1, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices",
            maxLength: 55,
            zone: [
                [0, 0],
                [0, 0],
                [1, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices Feedback",
            maxLength: 30,
            zone: [
                [0, 0],
                [0, 0],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Global Feedback",
            maxLength: 150,
            zone: [
                [0, 0],
                [0, 0],
                [0, 0],
                [1, 1],
            ],
        },
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff",
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },

        option: {
            width: "96%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: "contain",
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
};

const defaultTrueFalseData: any = {
    question:
        "Passengers are allowed to carry more than one piece of hand luggage onboard if the total weight is under 7 kg.",
    prompt: "Select True or False",
    options: [
        { _id: uuid(), value: "True", correct: false, response: false },
        { _id: uuid(), value: "False", correct: true, response: false },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isSubmitType: true,
    isNextType: false,
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    courseController: {
        skip: true
    },
    componentProperties: [
        {
            type: "Question",
            maxLength: 150,
            zone: [
                [1, 1],
                [0, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Image",
            zone: [
                [0, 0],
                [0, 1],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Prompt",
            maxLength: 80,
            zone: [
                [0, 0],
                [1, 0],
                [0, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices",
            maxLength: 55,
            zone: [
                [0, 0],
                [0, 0],
                [1, 0],
                [0, 0],
            ],
        },
        {
            type: "Choices Feedback",
            maxLength: 30,
            zone: [
                [0, 0],
                [0, 0],
                [0, 1],
                [0, 0],
            ],
        },
        {
            type: "Global Feedback",
            maxLength: 150,
            zone: [
                [0, 0],
                [0, 0],
                [0, 0],
                [1, 1],
            ],
        },
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff",
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },

        option: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: "contain",
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
};

export type TextWImageData = {
    logoImageFile: object | null;
    text: string;
    imageFile: object | null;
};

const defaultTextWImageData: any = {
    logoImageFile: null,
    text: `<p style="text-align: left;"><strong><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 30px;">Add a heading</span></span></span></span></strong></p>
<p><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></span></span></span></p>`,
    imageFile: null,
    styles: {
        globalBG: {
            backgroundColor: "ffffff",
        },
        textContainer: {
            width: "50%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        logoImageContainer: {
            width: "100%",
            height: "100%",
            maxHeight: "max-content",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            // backgroundColor: "#ffffff",
        },
        logoImage: {
            width: "100px",
            height: "100px",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
        },
        text: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "45px",
            paddingRight: "45px",
            paddingBottom: "45px",
            paddingLeft: "45px",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto",
        },
        imageContainer: {
            width: "50%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "contain",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "cover",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    layoutOption: "text-left-image-right",
    courseController: {
        skip: true
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
};

export type Tab = {
    _id: string;
    content: {
        templateType: string;
        templateData: any;
    };
    title: string;
};

export type Tabs = {
    tabs: Tab[];
    layoutOption: string;
};

const defaultTabsData: any = {
    tabs: [
        {
            _id: uuid(),
            content: "",
            title: "Tab 1",
        },
    ],
    layoutOption: "horizontal-tab-top",
    courseController: {
        skip: true
    },
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff",
        },
        tabContainer: {
            width: "100%",
            gap: "8px",
        },
        tabButton: {
            width: "200px",
            height: "35px",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "5px",
            color: "#333333",
            backgroundColor: "#BABABA",
            selected: {
                color: "#BABABA",
                backgroundColor: "#333333",
            },
            // textAlign: "left",
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        content: {
            width: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            marginTop: "5px",
            marginLeft: "0px",
            backgroundColor: "#ffffff",
        },
    },
};

const prompt = "Sort the items into the correct sections."; // change the prompt message for the dnd (Image)

const defaultDragAndDropData: any = {
    question: "Identify the description of each turbulance classifications.",
    prompt: "Drag each statement and place them in the correct role. Select submit when you are done.",
    options: [
        { _id: uuid(), value: "Items are fallen over" },
        { _id: uuid(), value: "Rapid bumps or jolts" },
        { _id: uuid(), value: "Carts are difficult to maneuver" },
        { _id: uuid(), value: "Cause variations in indicated airspeed" },
    ],
    courseController: {
        skip: true
    },
    matches: null,
    haveoptionimage: false,
    optionquantity: 9,
    matchingType: { _id: "1", value: "Table", label: "Table" },
    globalFeedbackCorrect: "That's correct. You are able to identify the descriptions of each turbulence classification.",
    globalFeedbackIncorrect: "That's not correct. Close the feedback box to view the correct answer before you move forward.",
    componentProperties: [
        { type: 'Question', maxLength: 150 },
        { type: 'Prompt', maxLength: 80 },
        { type: 'Choices', maxLength: 55 },
        {
            type: 'Global Feedback Correct',
            maxLength: 150
        },
        {
            type: 'Global Feedback InCorrect',
            maxLength: 150
        }
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "16px",
            paddingRight: "16px",
            paddingBottom: "16px",
            paddingLeft: "16px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        tableHead: {
            color: "#000000",
            backgroundColor: "#ffffff"
        },
        tableBody: {
            color: "#000000",
            backgroundColor: "#ffffff"
        },
        tableCell: {
            outlineColor: "black",
            outlineWidth: "1px",
            outlineStyle: "solid",
        },
        dragItem: {
            width: "100%",
            height: "100%",
            paddingTop: "8px",
            paddingRight: "8px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            borderRadius: "5px",
            backgroundColor: "#BABAF2",
            color: "#000000",
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#ffffff",
            color: "#000000",
        },
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
};

export type Front = {
    question: string;
    prompt: string;
    imageFile: object | null;
};
export type Back = {
    answer: string;
    imageFile: object | null;
};

export type ClickAndReveal = {
    front: Front;
    back: Back;
    transition: string;
};

const defaultClickAndRevealData: any = {
    front: {
        question:
            "What should you do if the oxygen masks drop down during the flight?",
        prompt: "Do you know what is the answer?",
        imageFile: null,
        layoutOption: "front-text-left-image-right",
        styles: {
            parentContainer: {
                width: "100%",
                height: "100%",
                paddingTop: "32px",
                paddingRight: "32px",
                paddingBottom: "32px",
                paddingLeft: "32px",
                // backgroundColor: "#d1d1d1",
            },
            container: {
                width: "100%",
                height: "100%",
                paddingTop: "60px",
                paddingRight: "70px",
                paddingBottom: "60px",
                paddingLeft: "70px",
                borderRadius: "15px",
                backgroundColor: "#DFFFD6",
                gap: "15px",
            },
            promptLabelWrap: {
                width: "100%",
                // height: '100%',
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "20px",
            },
            promptContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            prompt: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowY: "auto",
            },
            questionLabelContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            questionLabel: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowY: "auto",
            },
            questionImageWrap: {
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "15px",
            },
            textContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            text: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                overflowY: "auto",
            },
            imageContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
                borderRadius: "10px",
            },
            image: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "cover",
                justifyContent: "center",
                alignItems: "flex-start",
                borderRadius: "10px",
            },
        },
    },
    back: {
        answer: "Secure your own mask first before assisting others",
        imageFile: null,
        layoutOption: "back-text-left-image-right",
        styles: {
            parentContainer: {
                width: "100%",
                height: "100%",
                paddingTop: "32px",
                paddingRight: "32px",
                paddingBottom: "32px",
                paddingLeft: "32px",
                // backgroundColor: "#d1d1d1",
            },
            container: {
                width: "100%",
                height: "100%",
                paddingTop: "60px",
                paddingRight: "70px",
                paddingBottom: "60px",
                paddingLeft: "70px",
                borderRadius: "15px",
                backgroundColor: "#DFFFD6",
                gap: "15px",
            },
            answerLabelContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            answerLabel: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowY: "auto",
            },
            answerImageWrap: {
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "15px",
            },
            textContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            text: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                overflowY: "auto",
            },
            imageContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "contain",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                // backgroundColor: "#ffffff",
            },
            image: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: "cover",
                justifyContent: "center",
                alignItems: "flex-start",
                borderRadius: "10px",
            },
        },
    },
    courseController: {
        skip: true
    },
    transition: "horizontal",
    styles: {
        global: {
            backgroundColor: "#4F7982",
        },
    },
};

export type FlipCardType = {
    _id: string;
    front: {
        text: string;
        imageFile: object | null;
    };
    back: {
        text: string;
        imageFile: object | null;
        videoUrl: string;
        audioUrl: string;
    };
};

export type FlipCard = {
    prompt: string;
    flipcards: FlipCardType[];
    transition: string;
};

const defaultFlipCardData: any = {
    prompt: "Do you know what is the answer?",
    flipcards: [
        {
            _id: uuid(),
            front: {
                text: "Title 1",
                imageFile: null,
                styles: {
                    // aboveContainer:{
                    //     width: "100%",
                    //     height: "100%",
                    //     justifyContent: "flex-start",
                    //     alignItems: "center",
                    // },
                    container: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    },
                    textContainer: {
                        width: "100%",
                        height: "12%",
                        paddingTop: "5px",
                        paddingRight: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "5px",
                        objectFit: "contain",
                        backgroundColor: "black",
                        opacity: "0.4",
                        color: "#ffffff",
                        overflowY: "auto",
                    },
                    text: {
                        width: "100%",
                        height: "100%",
                        paddingTop: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "0px",
                        justifyContent: "flex-start",
                        alignItems: "center",

                    },
                },
            },
            back: {
                text: `<p><strong><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 12px;">Lorem ipsum dolor sit amet</span></span></span></span></strong></p>
<p><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 10px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 10px;">dolor sit amet, consectetur adipiscing elit. Suspendisse dapibus posuere ultricies. Nulla sit amet enim cursus, feugiat lacus id, varius orci. Nullam elementum lorem ac posuere elementum. Vestibulum quam enim, gravida et justo sed, pretium pulvinar mauris. Nam faucibus hendrerit accumsan.<br> Quisque et blandit enim, nec vulputate urna. Suspendisse convallis, purus finibus congue malesuada, neque elit bibendum ex, in tincidunt dolor ante id leo. Maecenas a erat elementum massa sagittis faucibus vitae sit amet arcu. Nullam vel mi et diam pellentesque sagittis. Aenean ac purus justo.</span></span></span></span></p>`,
                imageFile: null,
                videoUrl: 'https://youtu.be/rT6GiYEIdGs?si=BTlMHzV1B0v1f32Q',
                audioUrl: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
                styles: {
                    container: {
                        width: "100%",
                        height: "100%",
                        backgroundColor: '#82c2de',
                        paddingTop: "5px",
                        paddingRight: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "5px",
                    },
                    textImageWrap: {
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: "7px",
                    },
                    textContainer: {
                        width: "100%",
                        height: "60%",
                        x: 0,
                        y: 0,
                        paddingTop: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "0px",
                        objectFit: "contain",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#ffffff",
                        color: "#000000",
                    },
                    text: {
                        width: "100%",
                        height: "100%",
                        x: 0,
                        y: 0,
                        paddingTop: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "0px",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        // overflowY: "auto",
                    },
                    imageContainer: {
                        width: "100%",
                        height: "40%",
                        x: 0,
                        y: 0,
                        paddingTop: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "0px",
                        objectFit: "contain",
                        justifyContent: "center",
                        alignItems: "center",
                        // borderRadius: "10px",
                        // backgroundColor: "#ffffff",
                    },
                    image: {
                        width: "100%",
                        height: "100%",
                        x: 0,
                        y: 0,
                        paddingTop: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingLeft: "0px",
                        objectFit: "cover",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        // borderRadius: "10px",
                    },
                }
            },
            selectedFace: "Front",
            layoutOption: "flipcard-image-with-text",
        },
    ],
    courseController: {
        skip: true
    },
    transition: "horizontal",
    styles: {
        global: {
            width: "100%",
            height: "100%",
            paddingTop: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            paddingLeft: "10px",
            backgroundColor: "#f1f1f1",
        },
        containerBelowPrompt: {
            width: "100%",
            height: "88%",
            gap: '4px',
        },
        parentContainer: {
            width: "24.50%",
            height: "85%",
            paddingTop: "5px",
            paddingRight: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            backgroundColor: "#ffffff",
            // opacity: '0.4'
        },
        promptContainer: {
            width: "100%",
            height: "12%",
            x: 0,
            y: 0,
            justifyContent: "center",
            alignItems: "center",
        },
        prompt: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            alignItems: "center",
            overflowY: "auto",
            color: "#000000",
        },
    },
};

export type AccordionType = {
    _id: string;
    title: string;
    content: {
        text: string;
        image: object | null;
    };
};

export type Accordions = {
    accordions: AccordionType[];
};

const defaultAccordionsData: any = {
    accordions: [
        {
            _id: uuid(),
            title: "Accordion 1",
            content: {
                text: `<p style="text-align: left;"><strong><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 24px;">Add The Heading</span></span></span></span></strong></p>
<p><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></span></span></span></p>`,
                image: null,
            },
            layoutOption: "text-left-image-right",
            // styles: {
            //     TextImageWrap: {
            //         width: '100%',
            //         height: '100%',
            //         justifyContent: 'center',
            //         alignItems: 'flex-start',
            //         gap: '15px'
            //     },
            //     textContainer: {
            //         width: "50%",
            //         height: "50%",
            //         x: 0,
            //         y: 0,
            //         paddingTop: "0px",
            //         paddingRight: "0px",
            //         paddingBottom: "0px",
            //         paddingLeft: "0px",
            //         objectFit: 'contain',
            //         justifyContent: 'center',
            //         alignItems: 'center',
            //         // backgroundColor: "#ffffff",
            //         color: "#000000",
            //     },
            //     text: {
            //         width: "100%",
            //         height: "100%",
            //         x: 0,
            //         y: 0,
            //         paddingTop: "0px",
            //         paddingRight: "0px",
            //         paddingBottom: "0px",
            //         paddingLeft: "0px",
            //         justifyContent: 'flex-start',
            //         alignItems: 'flex-start',
            //         overflowY: "auto"
            //     },
            //     imageContainer: {
            //         width: "50%",
            //         height: "100%",
            //         x: 0,
            //         y: 0,
            //         paddingTop: "0px",
            //         paddingRight: "0px",
            //         paddingBottom: "0px",
            //         paddingLeft: "0px",
            //         objectFit: 'contain',
            //         justifyContent: 'center',
            //         alignItems: 'center',
            //         // backgroundColor: "#ffffff",
            //         color: "#000000",
            //         borderRadius: "10px",
            //     },
            //     image: {
            //         width: "100%",
            //         height: "100%",
            //         x: 0,
            //         y: 0,
            //         paddingTop: "0px",
            //         paddingRight: "0px",
            //         paddingBottom: "0px",
            //         paddingLeft: "0px",
            //         objectFit: 'cover',
            //         justifyContent: 'center',
            //         alignItems: 'flex-start',
            //         borderRadius: "10px",
            //     }
            // }
        },
    ],
    courseController: {
        skip: true
    },
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0",
            paddingRight: "14px",
            paddingBottom: "16px",
            paddingLeft: "14px",
            backgroundColor: "#ffffff",
            color: "#000000"
        },
        TextImageWrap: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "15px",
        },
        textContainer: {
            width: "50%",
            height: "167px",
            display: "flex",
            overflowY: "auto",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "center",
            alignItems: "center",
            color: "#000000",
            flexGrow: 1,
        },
        text: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            overflowY: "auto",
        },
        imageContainer: {
            width: "50%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#ffffff",
            color: "#000000",
            // borderRadius: "10px",
            overflow: "hidden",
            flexGrow: 1,
        },
        image: {
            width: "70%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: "cover",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            // borderRadius: "10px",
        },
    },
};

const defaultHotspotData: any = {
    image: null,
    hotspots: [],
    courseController: {
        skip: true
    },
    styles: {
        hotspotBackground: {
            backgroundColor: "#000000",
            selected: {
                backgroundColor: "#FF0000",
            },
        },
        stroke: {
            border: "3px",
            borderStyle: "solid",
            borderColor: "#ffffff",
            selected: {
                border: "4px",
                borderColor: "#ffffff",
            },
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        }
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
}

const defaultMatchingData: any = {
    question: "Identify the description of each turbulance classifications.",
    prompt: "Match the items into the correct sections.",
    options: [],
    items: [],
    targets: [],
    matches: null,
    haveoptionimage: false,
    optionquantity: 4,
    matchingType: { _id: "1", value: "Table", label: "Table" },
    globalFeedbackCorrect: "That's correct. You are able to identify the descriptions of each turbulence classification.",
    globalFeedbackIncorrect: "That's not correct. Close the feedback box to view the correct answer before you move forward.",
    courseController: {
        skip: true
    },
    componentProperties: [
        { type: 'Question', maxLength: 150 },
        { type: 'Prompt', maxLength: 80 },
        { type: 'Choices', maxLength: 55 },
        {
            type: 'Global Feedback Correct',
            maxLength: 150
        },
        {
            type: 'Global Feedback InCorrect',
            maxLength: 150
        },
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "16px",
            paddingRight: "16px",
            paddingBottom: "16px",
            paddingLeft: "16px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        tableHead: {
            color: "#000000",
            backgroundColor: "#ffffff"
        },
        tableBody: {
            color: "#000000",
            backgroundColor: "#ffffff"
        },
        tableCell: {
            outlineColor: "black",
            outlineWidth: "1px",
            outlineStyle: "solid",
        },
        dragItem: {
            width: "100%",
            height: "100%",
            paddingTop: "8px",
            paddingRight: "8px",
            paddingBottom: "8px",
            paddingLeft: "8px",
            borderRadius: "5px",
            backgroundColor: "#BABAF2",
            color: "#000000",
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#ffffff",
            color: "#000000",
        },
    },
};

const defaultFillInTheBlanksData: any = {
    prompt: "Enter the correct answer in the blanks",
    content: `Airbus aircraft has [Blank_1] engines. Airbus aircrafts are manufactured in [Blank_2]. Airbus flight controls are [Blank_3] systems.`,
    inputValues: {
        Blank_1: "2",
        Blank_2: "France",
        Blank_3: "fly-by-wire",
    },
    courseController: {
        skip: true
    },
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "16px",
            paddingRight: "16px",
            paddingBottom: "16px",
            paddingLeft: "16px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        promptContainer: {
            width: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            color: "#000000",
        },
        contentContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        content: {
            width: "100%",
            height: "100px",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
        }
    },
    autoPlay: true,
    pageAnimate: true,
    customAnimate: false
}


export const DefaultTemplateData = new Map([
    ["mcr", defaultMcrData],
    ["scr", defaultScrData],
    ["true/false", defaultTrueFalseData],
    ["textwithimage", defaultTextWImageData],
    ["tabs", defaultTabsData],
    ["draganddrop", defaultDragAndDropData],
    ["clicktoreveal", defaultClickAndRevealData],
    ["accordion", defaultAccordionsData],
    ["flipcard", defaultFlipCardData],
    ["hotspot", defaultHotspotData],
    ["fillintheblanks", defaultFillInTheBlanksData],
    ["matching", defaultMatchingData],
]);
