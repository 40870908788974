export { default as Mcr } from './Mcr';
export { default as Scr } from './Scr';
export { default as TrueFalse } from './TrueFalse';
export { default as TextWImage } from './TextWImage';
export { default as Tabs } from './TabsV2';
export { default as ClickToReveal } from './ClickToReveal';
export { default as Accordion } from './Accordion';
export { default as DragAndDrop } from './DragAndDrop';
export { default as FlipCard } from './FlipCard';
export { default as FillInTheBlanks } from './FillInTheBlanks';
export { default as Hotspot } from './Hotspot';
export { default as Matching } from './Matching';