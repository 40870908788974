import React from "react";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  labelTitle: string;
  autoFocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
};

function Text({
  value,
  onChange,
  onFocus,
  placeholder,
  labelTitle,
  autoFocus,
  maxLength,
  disabled,
}: Props) {
  return (
    <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">
      <label
        className="font-[500] text-[1.125rem] leading-normal text-[#242424]"
        htmlFor="input"
      >
        {labelTitle}
      </label>
      <div className="flex items-center space-x-2 pr-4 overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
        <input
          onFocus={(e) => onFocus ? onFocus(e) : e.target.select }
          autoFocus={autoFocus}
          className="grow outline-none px-[1.25rem] py-[0.75rem] text-[14px] disabled:bg-gray-200"
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          name="input"
          maxLength={maxLength}
          disabled={disabled}
        />
        {!disabled && (
          <span className="text-[14px] text-gray-600 font-medium">
            <span
              className={`${
                maxLength && value?.length >= maxLength ? "text-red-500" : "text-green-500"
              }`}
            >
              {value?.length}
            </span>
            /<span className={``}>{maxLength}</span>
          </span>
        )}
      </div>
    </div>
  );
}

export default Text;
