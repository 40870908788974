import * as React from 'react';
import ReactDOM from "react-dom/client";
import "./index.css";
import 'draft-js/dist/Draft.css';
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./app/store";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { PublicClientApplication } from '@azure/msal-browser';
import { MantineProvider } from '@mantine/core';
import { defaultSystem, ChakraProvider } from '@chakra-ui/react';
import { LMSProvider } from './context/Lms';

const pca = new PublicClientApplication({
  auth: {
    clientId: "f4789d8e-6631-4410-84e7-94a0870d0be3",
    authority: "https://login.microsoftonline.com/961dd5ff-89cd-4696-b85f-e9f17511d9ed",
    redirectUri: `${process.env.REACT_APP_CLIENT_BASE_URL}/dashboard`, // This is the redirect URL of the app (http://localhost:3000/dashboard)
    postLogoutRedirectUri: `${process.env.REACT_APP_CLIENT_BASE_URL}`, // This is the base URL of the app (http://localhost:3000)
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
})

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  // <React.StrictMode>
  <LMSProvider>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <MantineProvider>
            <ChakraProvider value={defaultSystem}>
              <App msalInstance={pca} />
            </ChakraProvider>
          </MantineProvider>
        </DndProvider>
      </PersistGate>
    </Provider>
  </LMSProvider>
  
  // </React.StrictMode>
);
