// src/context/LmsContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
// @ts-ignore
import pipwerks from "pipwerks-scorm-api-wrapper";
import XAPI, { SendStatementParams, Statement } from "@xapi/xapi"

type LMSContextType = {
  getValue: (key: string) => string | null;
  setValue: (key: string, value: string) => boolean;
  setCompletionStatus: (status: "completed" | "incomplete" | "not attempted") => boolean;
  setSuccessStatus: (status: "passed" | "failed") => boolean;
  getCompletionStatus: () => string | null;
  getSuccessStatus: () => string | null;
  setScore: (score: number) => boolean;
  getScore: () => string | null;
  setBookmark: (location: string) => boolean;
  getBookmark: () => string | null;
  setSuspendData: (data: string) => boolean;
  getSuspendData: () => string | null;
  commit: () => boolean;
  terminate: () => void;
  isInitialized: boolean;
  sendXAPIStatement: (statement: SendStatementParams) => void;
};

// Global xAPI configuration
// let xAPIConfig: {
//   endpoint: string;
//   user: string;
//   password: string;
// } | null = null;

const LMSContext = createContext<LMSContextType | null>(null);

export const useLMS = () => {
  const context = useContext(LMSContext);
  if (!context) {
    throw new Error("useLMS must be used within an LMSProvider.");
  }
  return context;
};

export const LMSProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [xApi, setXapi] = useState<XAPI>()

  useEffect(() => {
    const initializeLMS = () => {
      try {
        const scorm = pipwerks.SCORM;
        const scormVersion = document.body.getAttribute('data-scorm-version') || '1.2';
        console.log("SCORM API version:", scormVersion);
        scorm.version = scormVersion; // Default to SCORM 1.2
        const initialized = scorm.init();

        if (!initialized) {
          const errorCode = scorm.handle.GetLastError();
          const errorString = scorm.handle.GetErrorString(errorCode);
          console.error("SCORM API initialization failed:", errorString);
          return;
        }

        setIsInitialized(true);
        console.log("SCORM initialized successfully.");
      } catch (error) {
        console.error("An error occurred during SCORM initialization:", error);
      }
    };

    // Initialize xAPI
    const initializeXAPI = () => {
      const endpoint = "https://cloud.scorm.com/lrs/4NOXW3OJCW/sandbox/";
      const username = "Toc_j4TKG1L2rg87RqI";
      const password = "3bb1eO71qkD-zFA_Uuk";
      const auth = XAPI.toBasicAuth(username, password);
      const xapi = new XAPI({
        endpoint: endpoint,
        auth: auth
      });

      setXapi(xapi)
      setIsInitialized(true)
      console.log("xAPI initialized successfully.");
    };

    if (document.body.getAttribute('data-scorm-version') !== "xApi") {
      initializeLMS();
    } else {
      initializeXAPI();
    }

    return () => {
      if (isInitialized) {
        terminate();
        console.log("SCORM terminated.");
      }
    };
  }, [isInitialized]);

  const sendXAPIStatement = (statement: SendStatementParams) => {
    if (!xApi) {
      console.error("xAPI is not initialized.");
      return;
    }
    xApi.sendStatement(statement).then(() => {
      console.log("xAPI statement sent successfully.");
    }).catch((error) => {
      console.error("Error sending xAPI statement:", error);
    });
  };

  const getValue = (key: string): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }
    return pipwerks.SCORM.get(key);
  };

  const setValue = (key: string, value: string): boolean => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }
    return pipwerks.SCORM.set(key, value);
  };

  const setCompletionStatus = (status: "completed" | "incomplete" | "not attempted") => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return setValue("cmi.core.lesson_status", status);
    }
    // For SCORM 2004, use `cmi.completion_status`

    if (pipwerks.SCORM.version === "2004") {
      return setValue("cmi.completion_status", status);
    }

    if (xApi) {
      const statement: SendStatementParams = {
        statement: {
          actor: { name: "Vishal Jaiswal", mbox: "mailto:vishaljaiswal9551@gmail.com" },
          verb: { id: "http://adlnet.gov/expapi/verbs/completed", display: { "en-US": status } },
          object: { id: "http://example.com/learning-object", definition: { name: { "en-US": "Learning Object" } } },
        }
      };
      sendXAPIStatement(statement);
      return true;
    }
    return false;
  };

  const setSuccessStatus = (status: "passed" | "failed") => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return setValue("cmi.core.lesson_status", status);
    }

    if (pipwerks.SCORM.version === "2004") {
      return setValue("cmi.success_status", status);
    }

    if (xApi) {
      const statement: SendStatementParams = {
        statement: {
          actor: { name: "Vishal Jaiswal", mbox: "mailto:vishaljaiswal9551@gmail.com" },
          verb: { id: "http://adlnet.gov/expapi/verbs/" + status, display: { "en-US": status } },
          object: { id: "http://example.com/learning-object", definition: { name: { "en-US": "Learning Object" } } },
        }
      };
      sendXAPIStatement(statement);
      return true;
    }
    return false;
  };

  const getCompletionStatus = (): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return getValue("cmi.core.lesson_status");
    }

    if (pipwerks.SCORM.version === "2004") {
      return getValue("cmi.completion_status");
    }
    return null;
  };

  const getSuccessStatus = (): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return getValue("cmi.core.lesson_status");
    }

    if (pipwerks.SCORM.version === "2004") {
      return getValue("cmi.success_status");
    }
    return null;
  };

  const setScore = (score: number): boolean => {
    console.log("setting score: ", score)
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return setValue("cmi.core.score.raw", score.toString());
    }

    if (pipwerks.SCORM.version === "2004") {
      // Scale the score to the range [-1.0, 1.0]
      const scaledScore = Math.min(Math.max(score / 100, -1), 1);
      return setValue("cmi.score.scaled", scaledScore.toString());
    }

    if (xApi) {
      const statement: SendStatementParams = {
        statement: {
          actor: { name: "Vishal Jaiswal", mbox: "mailto:vishaljaiswal9551@gmail.com" },
          verb: { id: "http://adlnet.gov/expapi/verbs/scored", display: { "en-US": "scored" } },
          object: {
            id: "http://example.com/learning-object",
            definition: { name: { "en-US": "Learning Object" } },
          },
          result: { score: { scaled: Math.min(Math.max(score / 100, -1), 1) } },
        }
      };
      sendXAPIStatement(statement);
      return true;
    }
    return false;
  }

  const getScore = (): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return getValue("cmi.core.score.raw");
    }

    if (pipwerks.SCORM.version === "2004") {
      // Retrieve the scaled score for SCORM 2004
      const scaledScore = getValue("cmi.score.scaled");

      // Convert scaled score to a percentage (assuming it needs to be shown as a percentage)
      if (scaledScore !== null) {
        const percentageScore = parseFloat(scaledScore) * 100;
        return percentageScore.toFixed(2); // Return as a string with 2 decimal places
      }
    }
    return null;
  }

  const setBookmark = (location: string): boolean => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return setValue("cmi.core.lesson_location", location);
    }

    if (pipwerks.SCORM.version === "2004") {
      return setValue("cmi.location", location);
    }

    if (xApi) {
      const statement: SendStatementParams = {
        statement: {
          actor: { name: "Learner", mbox: "mailto:learner@example.com" },
          verb: { id: "http://adlnet.gov/expapi/verbs/bookmarked", display: { "en-US": "bookmarked" } },
          object: { id: `http://example.com/${location}`, definition: { name: { "en-US": location } } },
        }
      };
      sendXAPIStatement(statement);
      return true;
    }
    return false;
  };

  const getBookmark = (): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return getValue("cmi.core.lesson_location");
    }

    if (pipwerks.SCORM.version === "2004") {
      return getValue("cmi.location");
    }
    return null;
  };

  const setSuspendData = (data: string): boolean => {

    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return setValue("cmi.suspend_data", data);
    }

    if (pipwerks.SCORM.version === "2004") {
      return setValue("cmi.suspend_data", data);
    }
    return false;
  };

  const getSuspendData = (): string | null => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return null;
    }

    if (pipwerks.SCORM.version === "1.2") {
      return getValue("cmi.suspend_data");
    }

    if (pipwerks.SCORM.version === "2004") {
      return getValue("cmi.suspend_data");
    }
    return null;
  };

  const commit = (): boolean => {
    if (!isInitialized) {
      console.error("SCORM is not initialized.");
      return false;
    }
    return pipwerks.SCORM.commit();
  };

  const terminate = (): void => {
    if (isInitialized) {
      pipwerks.SCORM.quit();
      console.log("SCORM terminated.");
    }
  };

  return (
    <LMSContext.Provider value={{
      sendXAPIStatement, // xAPI
      getValue,
      setValue,
      setCompletionStatus,
      setSuccessStatus,
      getCompletionStatus,
      getSuccessStatus,
      setScore,
      getScore,
      setBookmark,
      getBookmark,
      setSuspendData,
      getSuspendData,
      commit,
      terminate,
      isInitialized,
    }}>
      {children}
    </LMSContext.Provider>
  );
};
