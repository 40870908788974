import React, { useState, useEffect } from "react";
import { ComboboxDemo } from "src/components/ui/comboBox2";
import Text from "src/utils/Text/Txt";
import UploadImage from "src/components/Course/UploadImage/UploadImage";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import { Option } from "./utils/DefaultTemplateData";
import { SelectCreatable } from "src/components/ui/SelectCreatable";
import CreatableSelectDropdown from "src/utils/CreatableSelectDropdown";
import { CreatableSelect } from "src/components/ui/CreatableSelect";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Button } from "src/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "src/components/ui/command";
import { cn } from "src/components/lib/utils";
import CustomCombobox from "src/components/ui/CustomCombobox";

type Props = {
  data: any;
  handleDataChange: (data: any) => void;
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
};

function Matching({
  data,
  handleDataChange,
  optionQuantity,
  handleImageOrOptionClicked,
}: Props) {
  const [options, setOptions] = useState(data.options ?? []);

  // const [dragData, setDragData] = useState(data);
  const [templateType, setTemplateType] = useState("");

  const [currentOptionQuantity, setCurrentOptionQuantity] =
    useState<number>(optionQuantity);

  const [haveOptionImage, setHaveOptionImage] = useState<boolean>(
    data.haveOptionImage ?? false
  );

  const [optionImage, setOptionImage] = useState(null);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (type) setTemplateType(type);

  // }, [type]);

  useEffect(() => {
    setCurrentOptionQuantity(optionQuantity);
  }, [optionQuantity]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <div className="grow overflow-y-scroll pt-2 px-4 pb-[1.25rem]">
        <Text
          labelTitle="Question"
          value={data.question}
          onChange={(e) =>
            handleDataChange({ ...data, question: e.target.value })
          }
          maxLength={
            data.componentProperties?.find(
              (comp: any) => comp.type === "Question"
            )?.maxLength || 150
          }
          onFocus={(e) => {
            const existingComponent = data.componentProperties?.find(
              (comp: any) => comp.type === "Question"
            );
            if (existingComponent) {
              dispatch(
                setSelectedComponent({
                  type: existingComponent.type,
                  maxLength: existingComponent.maxLength,
                })
              );
            }
          }}
        />

        <Text
          labelTitle="Prompt"
          value={data.prompt}
          onChange={(e) =>
            handleDataChange({ ...data, prompt: e.target.value })
          }
          maxLength={
            data.componentProperties?.find(
              (comp: any) => comp.type === "Prompt"
            )?.maxLength || 80
          }
          onFocus={(e) => {
            const existingComponent = data.componentProperties?.find(
              (comp: any) => comp.type === "Prompt"
            );
            if (existingComponent) {
              dispatch(
                setSelectedComponent({
                  type: existingComponent.type,
                  maxLength: existingComponent.maxLength,
                })
              );
            }
          }}
        />

      {/* {templateType && ( */}
      <OptionChoices
        type={templateType}
        options={data?.options}
        items={data?.items}
        targets={data?.targets}
        componentProperties={data.componentProperties}
        // setItems={(items: any[]) => handleDataChange({ ...data, items })}
        // setTargets={(targets: any[]) => handleDataChange({ ...data, targets })}
        setOptions={(items: any[], targets: any[], options: any[]) => handleDataChange({ ...data, items, targets, options })}
        optionQuantity={currentOptionQuantity}
        handleImageOrOptionClicked={handleImageOrOptionClicked}
        haveOptionImage={data?.matchingType?.value === "Image"}
      />

      <div className="flex flex-col space-y-[0.5rem] pt-[1.25rem]">
        <Text
          labelTitle="GlobalFeedback"
          value={data.globalFeedbackCorrect}
          onChange={(e) => {
            handleDataChange({
              ...data,
              globalFeedbackCorrect: e.target.value,
            });
          }}
          maxLength={
            data.componentProperties?.find(
              (comp: any) => comp.type === "Global Feedback Correct"
            )?.maxLength || 150
          }
          onFocus={(e) => {
            const existingComponent = data.componentProperties?.find(
              (comp: any) => comp.type === "Global Feedback Correct"
            );
            if (existingComponent) {
              dispatch(
                setSelectedComponent({
                  type: existingComponent.type,
                  maxLength: existingComponent.maxLength,
                })
              );
            }
          }}
        />
      </div>
      <div className="flex flex-col space-y-[0.5rem] pt-[1.25rem]">
        <Text
          labelTitle=""
          value={data.globalFeedbackIncorrect}
          onChange={(e) => {
            handleDataChange({
              ...data,
              globalFeedbackIncorrect: e.target.value,
            });
          }}
          maxLength={
            data.componentProperties?.find(
              (comp: any) => comp.type === "Global Feedback InCorrect"
            )?.maxLength || 150
          }
          onFocus={(e) => {
            const existingComponent = data.componentProperties?.find(
              (comp: any) => comp.type === "Global Feedback InCorrect"
            );
            if (existingComponent) {
              dispatch(
                setSelectedComponent({
                  type: existingComponent.type,
                  maxLength: existingComponent.maxLength,
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
}

export default Matching;

const OptionChoices = ({
  type,
  items,
  targets,
  options,
//   setItems,
//   setTargets,
  setOptions,
  componentProperties,
  optionQuantity,
  handleImageOrOptionClicked,
  haveOptionImage,
}: {
  type: string;
  items: any[];
  targets: any[];
  options: any[];
//   setItems: (items: any[]) => void;
//   setTargets: (targets: any[]) => void;
  setOptions: (item: any[], targets: any[], options: any[]) => void;
  componentProperties: any[];
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
  haveOptionImage: boolean;
}) => {
  const [templateType, setTemplateType] = useState("");
  const [optionImage, setOptionImage] = useState(null);
  const [currentItems, setCurrentItems] = useState<any[]>(items ?? []);
  const [currentTargets, setCurrentTargets] = useState<any[]>(targets ?? []);
  const [currentOptions, setCurrentOptions] = useState<any[]>(options ?? []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) setTemplateType(type);
    console.log("items at start:", options);
  }, [type,]);

  useEffect(() => {
    // setItems(currentItems);
    // setTargets(currentTargets);
    setOptions(currentItems, currentTargets, currentOptions);
  }, [currentItems, currentTargets, currentOptions])

  useEffect(() => {
    console.log(componentProperties);
  }, [componentProperties]);

  useEffect(() => {
    console.log(haveOptionImage);
  }, [haveOptionImage]);

  const addOption = () => {
    if (options.length >= optionQuantity) return;

    const defaultOptionImage = {
      _id: uuid(),
      url: "https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg",
      name: "default-image-name",
      type: "image/jpg",
      size: 12345,
      createdOn: Date.now(),
      folderId: null,
      userId: "default-user-id",
      __v: 0,
    };

    const newItem = {
        _id: uuid(),
        value: `New Item ${options.length + 1}`,
    }
    const newTarget = {
        _id: uuid(),
        value: `New Target ${options.length + 1}`,
    }

    const newOption = { 
        _id: uuid(),
        item: newItem,
        target: newTarget,
    }
    setCurrentItems((prevItems) => [...prevItems, newItem]); // Update items array
    setCurrentTargets((prevTargets) => [...prevTargets, newTarget]); // Update targets array
    setCurrentOptions((prevOptions) => [...prevOptions, newOption]); // Update options array

    console.log("Items after add:", [...items, newItem]);
    console.log("Targets after add:", [...targets, newTarget]);
    console.log("Options after add:", [...options, newOption]);
  };

   const deleteOption = (optionToDelete: any) => {
    setCurrentOptions((prevOptions) =>
      prevOptions.filter((option) => option._id !== optionToDelete._id)
    );
    setCurrentItems((prevItems) =>
      prevItems.filter((item) => item._id !== optionToDelete.item._id)
    );
    setCurrentTargets((prevTargets) =>
      prevTargets.filter((target) => target._id !== optionToDelete.target._id)
    );
  };

  return (
    <div className="pt-[1.25rem] px-[1.63rem] pb-[0.5rem] w-full">
      <label
        className="font-[500] text-[1.125rem] leading-normal text-[#242424]"
        htmlFor="input"
      >
        Matches
      </label>
      <div className="w-full flex justify-between pt-[1rem] items-start">
        <div className="w-[47%] flex flex-col space-y-2 font-[400] text-[1rem] leading-normal text-[#242424]">
          <span>Match Items</span>
          <div className="space-y-1.5 w-full">
            {options?.map((option: any, index: number) => (
              <div
                key={index}
                onClick={() => {
                  console.log(
                    "item clicked:",
                    option?.item._id,
                    typeof handleImageOrOptionClicked
                  );
                  handleImageOrOptionClicked(option?.item._id);
                  const existingComponent = componentProperties?.find(
                    (comp: any) => comp.type === "Choices"
                  );
                  if (existingComponent) {
                    dispatch(
                      setSelectedComponent({
                        type: existingComponent.type,
                        maxLength: existingComponent.maxLength,
                      })
                    );
                  }
                }}
                className="w-full flex items-center"
              >
                {/* {haveOptionImage && ( */}
                  <div className="flex space-x-5 items-start">
                    <UploadImage
                      name="imageFile"
                      type="image"
                      image={option?.item?.itemImage}
                      haveOptions={true}
                      handleUploadFromDevice={(file) => {
                        setOptionImage(file);
                        console.log(file);
                        setCurrentOptions(
                          options.map((option: any, i: number) =>
                            i === index ? { ...option, item: {...option.item, itemImage: file}} : option
                          )
                        );
                        setCurrentItems(
                            items.map((item: any) => 
                                item._id === option.item._id ? {...item, itemImage: file} : item
                            )
                        )
                      }}
                      handleUploadFromMedia={(file) => {
                        setOptionImage(file);
                        console.log(file);
                        setCurrentOptions(
                          options.map((option: any, i: number) =>
                            i === index ? { ...option, item: {...option.item, itemImage: file}} : option
                          )
                        );
                        setCurrentItems(
                            items.map((item: any) => 
                                item._id === option.item._id ? {...item, itemImage: file} : item
                            )
                        )
                      }}
                    />
                  </div>
                {/* )} */}

                <div className="flex w-full items-center pr-[0.75rem] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600 ">
                  <input
                    type="text"
                    value={option?.item.value}
                    maxLength={
                      componentProperties?.find(
                        (comp: any) => comp.type === "Choices"
                      )?.maxLength || 55
                    }
                    className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                    onChange={(e) => {
                      setCurrentOptions(
                        options.map((option: any, i: number) =>
                          i === index
                            ? { ...option, item: {...option.item, value: e.target.value} }
                            : option
                        )
                      );
                      setCurrentItems(
                        items.map((item: any) => 
                            item._id === option.item._id ? {...item, value: e.target.value} : item
                        )
                    )
                    }}
                  />
                  <span className="text-[14px] text-gray-600 font-medium">
                    <span
                      className={`${
                        option?.item?.value?.length >=
                        componentProperties?.find(
                          (comp: any) => comp.type === "Options"
                        )?.maxLength
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {option?.item?.value?.length}
                    </span>
                    /
                    <span className={``}>
                      {componentProperties?.find(
                        (comp: any) => comp.type === "Choices"
                      )?.maxLength || 55}
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-[51%] flex flex-col space-y-2 font-[400] text-[1rem] leading-normal text-[#242424]">
          <span>Match Targets</span>
          <div className="space-y-1.5 w-full">
            {options.map((option: any, index: number) => (
              <div
                key={index}
                className="flex justify-between w-full items-center"
              >
                <div
                  onClick={() => {
                    console.log(
                      "target clicked:",
                      option?.target._id,
                      typeof handleImageOrOptionClicked
                    );
                    handleImageOrOptionClicked(option?.target._id);
                    const existingComponent = componentProperties?.find(
                      (comp: any) => comp.type === "Choices"
                    );
                    if (existingComponent) {
                      dispatch(
                        setSelectedComponent({
                          type: existingComponent.type,
                          maxLength: existingComponent.maxLength,
                        })
                      );
                    }
                  }}
                  className="w-full flex items-center"
                >
                  {/* {haveOptionImage && ( */}
                    <div className="flex space-x-5 items-start">
                      <UploadImage
                        name="imageFile"
                        type="image"
                        image={option?.target?.targetImage}
                        haveOptions={true}
                        handleUploadFromDevice={(file) => {
                          setOptionImage(file);
                          console.log(file);
                          setCurrentOptions(
                            options.map((option: any, i: number) =>
                              i === index ? { ...option, target: {...option.target, targetImage: file}} : option
                            )
                          );
                          setCurrentTargets(
                              targets.map((target: any) => 
                                  target._id === option.target._id ? {...target, targetImage: file} : target
                              )
                          )
                        }}
                        handleUploadFromMedia={(file) => {
                          setOptionImage(file);
                          console.log(file);
                          setCurrentOptions(
                            options.map((option: any, i: number) =>
                              i === index ? { ...option, target: {...option.target, targetImage: file}} : option
                            )
                          );
                          setCurrentTargets(
                              targets.map((target: any) => 
                                  target._id === option.target._id ? {...target, targetImage: file} : target
                              )
                          )
                        }}
                      />
                    </div>
                  {/* )} */}

                  <div className="flex w-[100%] items-center pr-[0.75rem] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600 ">
                    <input
                      type="text"
                      value={option?.target.value}
                      maxLength={
                        componentProperties?.find(
                          (comp: any) => comp.type === "Choices"
                        )?.maxLength || 55
                      }
                      className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                      onChange={(e) => {
                        setCurrentOptions(
                          options.map((option: any, i: number) =>
                            i === index
                              ? { ...option, target: {...option.target, value: e.target.value} }
                              : option
                          )
                        );
                        setCurrentTargets(
                            targets.map((target: any) => 
                                target._id === option.target._id ? {...target, value: e.target.value} : target
                            )
                        )
                      }}
                    />
                    <span className="text-[14px] text-gray-600 font-medium">
                      <span
                        className={`${
                          option?.target?.value?.length >=
                          componentProperties?.find(
                            (comp: any) => comp.type === "Options"
                          )?.maxLength
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {option?.target?.value?.length}
                      </span>
                      /
                      <span className={``}>
                        {componentProperties?.find(
                          (comp: any) => comp.type === "Choices"
                        )?.maxLength || 55}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="w-[6%] flex items-center justify-center">
                  <button
                    onClick={() => deleteOption(option)}
                    className="text-black text-md"
                    aria-label="Delete Option"
                  >
                    X
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-10 ml-8">
        <button
          onClick={addOption}
          className={`bg-black/70 hover:bg-black/50 text-white py-2 px-4 rounded disabled:bg-black/25 disabled:text-black`}
          disabled={options?.length >= optionQuantity}
        >
          Add Match Item
        </button>
        {options?.length >= optionQuantity ? (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You cannot add more than {optionQuantity} choices
          </div>
        ) : (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You can add up to {optionQuantity} choices
          </div>
        )}
      </div>
    </div>
  );
};
