import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setSelectFile,
  setUpdateFolder,
} from "src/features/fileFolder/fileFolderSlice";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setAddPageState } from "src/features/templatePageState/templatePageStateSlice";
import { setLog } from "src/features/errorLog/errorLogSlice";

type Props = {
  data: any;
  handleUploadFromMedia: (file: any) => void;
};

function MediaTable({
  data, handleUploadFromMedia
}: Props) {
  const [rows, setRows] = useState<any>([]);
  const [tableMenuActive, setTableMenuActive] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<any>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // console.log(data);
  useEffect(() => {
    console.log(data);
    setRows(data);
  }, [data]);

  function formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    console.log(uploadedFile);

    if (uploadedFile && handleUploadFromMedia) {
        handleUploadFromMedia(uploadedFile);
    }
  }, [uploadedFile])

  function handleClick(row: any) {
    console.log("Clicked row:", row);

    const currentType = searchParams.get("type"); 
    const folderId = searchParams.get("folderId"); 
  
    // If the row represents a folder, navigate into the folder
    if (!row.type) {
      searchParams.set("folderId", row._id);
      setSearchParams(searchParams);
      return;
    }
  
    // If the row represents a file, validate its type
    if (currentType !== row.type.split("/")[0]) {
        dispatch(
            setLog({
            mode: "warn",
            message: "Selected file does not match the required file type",
            })
        );
        return;
    }

    // If the type matches, upload the file
    setUploadedFile(row);
  }

  return (
    <>
      <table className="min-w-[600px] overflow-auto m-1">
        <thead className="">
          <tr className="">
            <th align="left" className="py-2 min-w-[200px]">
              Name
            </th>
            <th align="left" className="py-2 ">
              Kind
            </th>
            <th align="left" className="py-2 ">
              Size
            </th>
            <th align="left" className="py-2 ">
              Created By
            </th>
            <th align="left" className="py-2 ">
              Date Modified
            </th>
          </tr>
          <tr className="border-b"></tr>
        </thead>
        <tbody className="">
          {rows &&
            rows.map((row: any, idx: number) => (
              <tr
                key={row._id}
                className={`relative group hover:bg-gray-200 ${tableMenuActive === row._id && "bg-gray-200"
                  }  `}
              >
                <td align="left" className="py-2">
                  <button
                    onClick={() => handleClick(row)}
                    className="hover:underline"
                  >
                    {row?.type?.split("/")[0] === "image" && <>🖼️ </>}
                    {row?.type?.split("/")[0] === "audio" && <>🎙️ </>}
                    {row?.type?.split("/")[0] === "video" && <>📷 </>}
                    {row?.type?.split("/")[0] === "application" && <>📄 </>}
                    {row?.type ? "" : "📁 "}
                    {row.title || row.name || "(Unknown)"}
                  </button>
                </td>
                <td align="left" className="py-2">
                  {row.type ?? "folder"}
                </td>
                <td align="left" className="py-2">
                  {formatBytes(row.size) ?? "-"}
                </td>
                <td align="left" className="py-2">
                  {row.createdBy ?? "user"}
                </td>
                <td align="left" className="py-2">
                  {new Date(row.createdOn / 1).toUTCString()}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

export default MediaTable;
