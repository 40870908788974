import React, { useEffect, useRef, useState } from "react";
import { Tab } from "src/components/Course/Templates/Authoring/utils/DefaultTemplateData";
import { GetPreviewTemplate } from "../../utils/GetPreviewTempalte";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "src/features/selectedTab/selectedTabSlice";
import { useLMS } from "src/context/Lms";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import axios from "axios";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  preview?: boolean;
  handleDataChange?: (data: any) => void;
};

function TabsLayout({ data, handleDataChange, preview }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentDimensions, setContentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [windowIsReady, setWindowIsReady] = useState(false);
  
  const { courseId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );
  const { selectedTab } = useSelector((state: any) => state.selectedTab);

  const [courseNavData, setCourseNavData] = useState<any>();

  const dispatch = useDispatch();

  const resetTemplateState = () => {
    dispatch(setSelectedTab(null));
  };

  useEffect(() => {
    // Reset state when template changes
    console.log("reset");
    resetTemplateState();
  }, []);

  useEffect(() => {
    console.log("data", data);
    if (handleDataChange) handleDataChange(data);
  }, [data]);

  // Fetch existing data from API
  useEffect(() => {
    (async () => {
      setWindowIsReady(false)
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          console.log(res.data.data.course?.data);
          const navTopics = getTopics(res.data.data.course?.data || [])
          setCourseNavData(navTopics); // Ensure data is set as an array

          setWindowIsReady(true)
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [courseId, accessToken]);

  useEffect(() => {
    console.log(data);
    if (data?.tabs?.length > 0) dispatch(setSelectedTab({ ...data.tabs[0] }));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const content = contentRef.current;
      if (content) {
        const contentWidth = content.offsetWidth;
        const contentHeight = content.offsetHeight;
        setContentDimensions({ width: contentWidth, height: contentHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() =>{
    console.log(courseNavData);
  }, [courseNavData]);

  function getTopics(data: any) {
    let topics: any[] = [];
    data.forEach((item: any) => {
      if (item.type === "file") {
        topics.push(item);
      } else if (item.children) {
        topics = [...topics, ...getTopics(item.children)];
      }
    });
    return topics;
  }

  return (
    <>
      {!preview ? (
        <div
          style={{
            ...data.styles.container,
          }}
          onClick={() => {
            if (
              canvasActiveComponent?.templateType === "tabs" &&
              canvasActiveComponent?.componentType === "wholeContainer"
            ) {
              dispatch(setCanvasActiveComponent(null));
            } else {
              dispatch(
                setCanvasActiveComponent({
                  templateType: "tabs",
                  componentType: "wholeContainer",
                })
              );
            }
          }}
          className={`h-full w-full ${
            data.layoutOption === "horizontal-tab-top" ? "flex flex-col" : "flex"
          }  ${
            canvasActiveComponent?.templateType === "tabs" &&
            canvasActiveComponent?.componentType === "wholeContainer"
              ? "border-2 border-dashed border-blue"
              : ""
          }`}
        >
          {selectedTab !== null && (
            <>
              <div
                style={{
                  ...data?.styles?.tabContainer,
                  width:
                    data.layoutOption === "vertical-tab-left"
                      ? "max-content"
                      : "100%",
                }}
                className={`${
                  data.layoutOption === "horizontal-tab-top"
                    ? "flex flex-wrap"
                    : "flex flex-col"
                }`}
              >
                {data?.tabs?.map((tab: Tab, idx: number) => (
                  <button
                    key={tab._id}
                    onClick={() => dispatch(setSelectedTab({ ...tab, index: idx }))}
                    style={{
                      ...data?.styles?.tabButton,
                      backgroundColor:
                        selectedTab._id !== tab._id
                          ? data?.styles?.tabButton.backgroundColor
                          : data?.styles?.tabButton.selected.backgroundColor,
                      color:
                        selectedTab._id !== tab._id
                          ? data?.styles?.tabButton.color
                          : data?.styles?.tabButton.selected.color,
                    }}
                    className={`flex`}
                    // className={`block line-clamp-1 rounded-md text-left mr-2 mb-2 bg-gray-200 hover:bg-[#c4c4c4]`}
                    title={tab?.title}
                  >
                    <span className="whitespace-nowrap">{tab?.title}</span>
                  </button>
                ))}
              </div>
              <div className="h-full w-full flex items-center justify-center">
                <div
                  ref={contentRef}
                  style={{
                    ...data?.styles?.content,
                    height: `${(contentDimensions.width * 9) / 16}px`
                  }}
                  className={`relative aspect-[16/9] ${
                    data.layoutOption === "horizontal-tab-top" ? "w-full" : "flex"
                  }`}
                >
                  {(() => {
                    console.log(courseNavData, selectedTab);
                    const template = courseNavData?.find((topic: any) => topic?.template?.id === selectedTab?.content?.templateId)?.template;
                    console.log("template", template);
                    const TemplateComponent = GetPreviewTemplate.get(template?.type);
    
                    return TemplateComponent ? (
                      <TemplateComponent
                        data={template?.data}
                        handleDataChange={handleDataChange}
                        canvasDimensions={contentDimensions}
                        istabtemplate={true}
                        nestedTemplate={true}
                      />
                    ) : null;
                  })()}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            ...data.styles.container,
          }}
          onClick={() => {
            if (
              canvasActiveComponent?.templateType === "tabs" &&
              canvasActiveComponent?.componentType === "wholeContainer"
            ) {
              dispatch(setCanvasActiveComponent(null));
            } else {
              dispatch(
                setCanvasActiveComponent({
                  templateType: "tabs",
                  componentType: "wholeContainer",
                })
              );
            }
          }}
          className={`h-full w-full ${
            data.layoutOption === "horizontal-tab-top" ? "flex flex-col" : "flex"
          }  ${
            canvasActiveComponent?.templateType === "tabs" &&
            canvasActiveComponent?.componentType === "wholeContainer"
              ? "border-2 border-dashed border-blue"
              : ""
          }`}
        >
          {selectedTab !== null && (
            <>
              <div
                style={{
                  ...data?.styles?.tabContainer,
                  width:
                    data.layoutOption === "vertical-tab-left"
                      ? "max-content"
                      : "100%",
                }}
                className={`${
                  data.layoutOption === "horizontal-tab-top"
                    ? "flex flex-wrap"
                    : "flex flex-col"
                }`}
              >
                {data?.tabs?.map((tab: Tab, idx: number) => (
                  <button
                    key={tab._id}
                    onClick={() => dispatch(setSelectedTab({ ...tab, index: idx }))}
                    style={{
                      ...data?.styles?.tabButton,
                      backgroundColor:
                        selectedTab._id !== tab._id
                          ? data?.styles?.tabButton.backgroundColor
                          : data?.styles?.tabButton.selected.backgroundColor,
                      color:
                        selectedTab._id !== tab._id
                          ? data?.styles?.tabButton.color
                          : data?.styles?.tabButton.selected.color,
                    }}
                    className={`flex`}
                    // className={`block line-clamp-1 rounded-md text-left mr-2 mb-2 bg-gray-200 hover:bg-[#c4c4c4]`}
                    title={tab?.title}
                  >
                    <span className="whitespace-nowrap">{tab?.title}</span>
                  </button>
                ))}
              </div>
              <div className="h-full w-full flex items-center justify-center">
                <div className="w-full aspect-[16/9]">
                  <div
                    ref={contentRef}
                    style={{
                      ...data?.styles?.content,
                      height: `${(contentDimensions.width * 9) / 16}px`
                    }}
                    className={`${
                      data.layoutOption === "horizontal-tab-top" ? "w-full" : "flex"
                    }`}
                  >
                    {(() => {
                      console.log(courseNavData, selectedTab);
                      const template = courseNavData?.find((topic: any) => topic?.template?.id === selectedTab?.content?.templateId);
                      console.log("template", template);
                      const TemplateComponent = GetPreviewTemplate.get(template?.template?.type);
      
                      return TemplateComponent ? (
                        <TemplateComponent
                          data={template?.template?.data}
                          topic={template}
                          handleDataChange={handleDataChange}
                          canvasDimensions={contentDimensions}
                          type={"coursepreview"}
                          handleNextDisable={(res: any, submitted: any) => { }}
                        />
                      ) : null;
                    })()}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default TabsLayout;
