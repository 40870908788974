import { CircleSlash, FileEdit, LayoutList, List, ListTodo, PanelTop } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedTemplate, setSelectedTools } from 'src/features/courseTemplate/courseTemplateSlice';
import { DefaultTemplateData } from './Templates/Authoring/utils/DefaultTemplateData';
import MatchingLogo from '../../assets/Course/templateRibbonIcons/matching.png';
import FlipcardLogo from '../../assets/Course/templateRibbonIcons/flipcard.png';
import DragAndDropLogo from '../../assets/Course/templateRibbonIcons/dragAndDrop.png';
import HotspotLogo from '../../assets/Course/templateRibbonIcons/hotspot.png';
import FillInTheBlanksLogo from '../../assets/Course/templateRibbonIcons/fillInTheBlanks.png';
import axios from "axios"
import { useSelector } from 'react-redux';

type Props = {
    insertDropdown: boolean;
    setInsertDropdown: (insertDropdown: boolean) => void;
    handleOpenTemplate?: () => void;
}

const TemplatesArray = [
    {
        id: 1,
        title: "MCR",
        type: "mcr",
        description: "Multiple Choice Response",
        icon: <ListTodo size={24} />
    },
    {
        id: 2,
        title: "SCR",
        type: "scr",
        description: "Single Choice Response",
        icon: <List size={24} />
    },
    {
        id: 3,
        title: "True/False",
        type: "true/false",
        description: "True or False",
        icon: <CircleSlash size={24} />
    },
    {
        id: 4,
        title: "Text with Image",
        type: "textwithimage",
        description: "Text with Image",
        icon: <LayoutList size={24} />
    }, 
    {
        id: 5,
        title: "Tabs",
        type: "tabs",
        description: "Tabs",
        icon: <PanelTop size={24} />
    },
    // Click to reveal is disabled for now
    // {
    //     id: 6,
    //     title: "Click To Reveal",
    //     type: "clicktoreveal",
    //     description: "Click to Reveal the content",
    //     icon: <PanelTop size={24} />
    // },
    {
        id: 7,
        title: "Drag And Drop",
        type: "draganddrop",
        description: "Drag and drop",
        icon: <span><img src={DragAndDropLogo} className='w-6 h-6' /></span>
    },
    {
        id: 8,
        title: "Accordion",
        type: "accordion",
        description: "Accordion",
        icon: <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-list-collapse"><path d="m3 10 2.5-2.5L3 5" /><path d="m3 19 2.5-2.5L3 14" /><path d="M10 6h11" /><path d="M10 12h11" /><path d="M10 18h11" /></svg></span>
    },
    {
        id: 9,
        title: "FlipCard",
        type: "flipcard",
        description: "Flip card to see back content",
        icon: <span><img src={FlipcardLogo} className='w-6 h-6' alt="" /></span>
    },
    {
        id: 10,
        title: "Fill In The Blanks",
        type: "fillintheblanks",
        description: "fillintheblanks",
        icon: <span><img src={FillInTheBlanksLogo} className='w-6 h-6' alt="" /></span>
    },
    {
        id: 11,
        title: "Hotspot",
        type: "hotspot",
        description: "Hotspot",
        icon: <span><img src={HotspotLogo} className='w-6 h-6' alt="" /></span>
    },
    {
        id: 12,
        title: "Matching",
        type: "matching",
        description: "Match the following",
        icon: <span><img src={MatchingLogo} className='w-6 h-6' alt="" /></span>
    },

]

export type Template = {
    id: number;
    title: string;
    type: string;
    description: string;
    data?: any;
}

function TemplateRibbon({ insertDropdown, setInsertDropdown, handleOpenTemplate }: Props) {

    const [templates, setTemplates] = useState<any>(TemplatesArray)

    const { accessToken } = useSelector((state: any) => state.accessToken)

    const dispatch = useDispatch();

    useEffect(() => {
        getSavedTemplates()
    }, [])

    useEffect(() => {
        console.log(templates)
    }, [templates])

    function handleTemplateSelection(template: Template) {
        dispatch(setSelectedTemplate({
            id: new Date().getTime(),
            title: template.title,
            type: template.type,
            description: template.description,
            data: template.data || DefaultTemplateData.get(template.type)
        }))
    }

    function handleCustomTemplateSelection(template: any) {
        console.log(template.tools)
        dispatch(setSelectedTools(template.tools))
    }

    async function getSavedTemplates() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/customTemplate`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );

            if (response && response.data) {
                console.log(response.data);
                setTemplates([...TemplatesArray, ...response.data.data])
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="absolute top-[3rem] flex flex-col p-3 bg-gray-100 border border-gray-300 space-y-2 shadow-lg rounded-lg z-10">
            {templates.map((template: any, index: number) => (
                // <div className="relative group" >
                <button key={template.id} onClick={() => {
                    if (template.type !== "file")
                        handleTemplateSelection(template);
                    else handleCustomTemplateSelection(template)
                    setInsertDropdown(!insertDropdown);
                    if (handleOpenTemplate) handleOpenTemplate();
                }} className='w-full px-2 py-1 flex space-x-2 items-center hover:bg-gray-300 rounded-md'>
                    <span>{template.type !== "file" ? template.icon : <FileEdit size={24} />}</span>
                    <span className=" px-2 py-1 max-w-max whitespace-nowrap text-sm rounded ">
                        {template.type !== "file" ? template.title : template.name}
                    </span>
                </button>
                //     <span className="hidden absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300">
                //         {template.description}
                //     </span>
                // </div>
            ))}
        </div>
    )
}

export default TemplateRibbon