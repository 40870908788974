import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
// @ts-ignore
import DefaultImage from "src/assets/Course/defaultImage.jpg";
import { setIsAddingHotspot } from "src/features/isAddingHotspot/isAddingHotspotSlice";
import { setSelectedHotspot } from "src/features/selectedHotspot/selectedHotspotSlice";
import {
  Button,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverRoot,
  PopoverTitle,
  PopoverTrigger,
  Text,
  PopoverCloseTrigger,
} from "@chakra-ui/react";

type Props = {
  data: any;
  preview?: boolean;
  handleDataChange?: (data: any) => void;
};

const HotspotLayout = ({ data, handleDataChange, preview }: Props) => {
  const [hotspots, setHotspots] = useState(data.hotspots || []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openHotspotId, setOpenHotspotId] = useState<string | null>(null);
  const { isAddingHotspot } = useSelector(
    (state: any) => state.isAddingHotspot
  );
  const { selectedHotspot } = useSelector(
    (state: any) => state.selectedHotspot
  );
  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (handleDataChange) handleDataChange(data);
  }, [data]);

  useEffect(() => {
    setHotspots(data.hotspots || []);
  }, [data.hotspots]);

  useEffect(() => {
    console.log(selectedHotspot);
  }, [selectedHotspot]);

  // Add a new hotspot
  const addHotspot = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isAddingHotspot || !containerRef.current) return; // Check for null ref

    const rect = containerRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const newHotspot = {
      _id: uuid(),
      title: `Hotspot ${hotspots.length + 1} Title`,
      content: `<p><span><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></span></span></span></p>`,
      x,
      y,
    };

    setHotspots([...hotspots, newHotspot]);

    dispatch(setSelectedHotspot(newHotspot));

    if (handleDataChange) {
      handleDataChange({ ...data, hotspots: [...data.hotspots, newHotspot] });
    }
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     containerRef.current &&
  //     !containerRef.current.contains(event.target as Node)
  //   ) {
  //     dispatch(setIsAddingHotspot(false)); // Disable adding mode if clicked outside
  //   }
  // };

  // useEffect(() => {
  //   if (isAddingHotspot) {
  //     document.addEventListener("click", handleClickOutside);
  //   }
  //   return () => document.removeEventListener("click", handleClickOutside);
  // }, [isAddingHotspot]);

  return (
    <>
      {!preview ? (
        <div
          ref={containerRef}
          onClick={(e) => {
            addHotspot(e);
            if (
              canvasActiveComponent?.templateType === "hotspot" &&
              canvasActiveComponent?.componentType === "hotspotContainer"
            ) {
              dispatch(setCanvasActiveComponent(null));
            } else {
              dispatch(
                setCanvasActiveComponent({
                  templateType: "hotspot",
                  componentType: "hotspotContainer",
                })
              );
            }
          }}
          style={{ position: "relative" }}
          className={`w-full h-full flex items-center justify-center ${!preview &&
            canvasActiveComponent?.templateType === "hotspot" &&
            canvasActiveComponent?.componentType === "hotspotContainer"
              ? "border-2 border-dashed border-blue"
              : ""
          }`}
        >
          {data.image ? (
            <img
              style={{ ...data.styles.image }}
              className="w-full h-full"
              src={data.image?.url || ""}
              alt="Uploaded"
            />
          ) : (
            <img
              style={{ ...data.styles.image }}
              className="w-full h-full"
              src={DefaultImage}
              alt="Uploaded"
            />
          )}
          {hotspots.map((hotspot: any, index: number) => (
            <button 
              key={hotspot._id}
              className="flex space-x-0.5 items-center"
              style={{
                position: "absolute",
                  left: hotspot.x,
                  top: hotspot.y,
              }}
            >
              <span className="text-lg">{index+1}.</span>
              <button
                onClick={() => dispatch(setSelectedHotspot(hotspot))}
                style={{
                  ...data.styles.hotspotBackground,
                  // backgroundColor: hotspot._id === selectedHotspot?._id ? data.styles.hotspotBackground.selected.backgroundColor : data.styles.hotspotBackground.backgroundColor,
                  width: hotspot._id === selectedHotspot?._id ? "25px" : "20px",
                  height: hotspot._id === selectedHotspot?._id ? "25px" : "20px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    hotspot._id === selectedHotspot?._id
                      ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
                      : "none",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                <button
                  style={{
                    ...data.styles.stroke,
                    // border: hotspot._id === selectedHotspot?._id ? data.styles.stroke.selected.border : data.styles.stroke.border,
                    // borderColor: hotspot._id === selectedHotspot?._id ? data.styles.stroke.selected.borderColor : data.styles.stroke.borderColor,
                    width: hotspot._id === selectedHotspot?._id ? "67%" : "60%",
                    height: hotspot._id === selectedHotspot?._id ? "67%" : "60%",
                    borderRadius: "50%",
                  }}
                />
              </button>
            </button>
          ))}
        </div>
      ) : (
        <div
          ref={containerRef}
          style={{ position: "relative", overflow: "visible" }}
          className="w-full h-full"
        >
          <img
            style={{ ...data.styles.image }}
            className="w-full h-full"
            src={data.image?.url || ""}
            alt="Uploaded"
          />

          {hotspots.map((hotspot: any) => (
            <div
              key={hotspot._id}
              style={{
                position: "absolute",
                left: hotspot.x,
                top: hotspot.y,
              }}
            >
              <PopoverRoot
                unmountOnExit
                key={hotspot._id}
                positioning={{
                  placement: "bottom-start",
                  modifiers: [
                    {
                      name: "flip",
                      options: {
                        fallbackPlacements: ["top", "left", "right"], // List of fallback positions
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        boundary: "clippingParents", // Ensure it stays within the container
                        padding: 8, // Add space between the popover and the container's edge
                      },
                    },
                  ],
                }}
              >
                <PopoverTrigger>
                  <Button>
                    <div
                      style={{
                        ...data.styles.hotspotBackground,
                        // backgroundColor: hotspot._id === openHotspotId ? data.styles.hotspotBackground.selected.backgroundColor : data.styles.hotspotBackground.backgroundColor,
                        width: openHotspotId === hotspot._id ? "25px" : "20px",
                        height: openHotspotId === hotspot._id ? "25px" : "20px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow:
                          openHotspotId === hotspot._id
                            ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
                            : "none",
                        transition: "all 0.2s ease-in-out",
                      }}
                    >
                      <div
                        style={{
                          ...data.styles.stroke,
                          // border: hotspot._id === openHotspotId ? data.styles.stroke.selected.border : data.styles.stroke.border,
                          // borderColor: hotspot._id === openHotspotId ? data.styles.stroke.selected.borderColor : data.styles.stroke.borderColor,
                          width: openHotspotId === hotspot._id ? "67%" : "60%",
                          height: openHotspotId === hotspot._id ? "67%" : "60%",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                >
                  <PopoverArrow />
                  <PopoverBody>
                    <PopoverTitle>{hotspot?.title}</PopoverTitle>
                    <Text
                      dangerouslySetInnerHTML={{ __html: hotspot?.content }}
                      my="4"
                    />
                  </PopoverBody>
                </PopoverContent>
              </PopoverRoot>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default HotspotLayout;

// open={openHotspotId === hotspot._id} onOpenChange={(isOpen: boolean) => setOpenHotspotId(isOpen ? hotspot._id : null)}
