import React, { useEffect, useState } from "react";
import { Card, Box } from "@chakra-ui/react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { Switch } from "src/components/ui/switch";

type Props = {
  quizData: any;
  handleDataChange: (quizData: any) => void;
};

const QuizSettingsPage = ({ quizData, handleDataChange }: Props) => {
  const [currentQuizData, setCurrentQuizData] = useState<any>(quizData);
  const [isPassingScore, setIsPassingScore] = useState(quizData?.passingRequirements?.isPassingScore ?? true);

  // console.log("isPassingScore", isPassingScore);

  useEffect(() => {
    handleDataChange(currentQuizData);
  }, [currentQuizData]);

  const isPercentage = currentQuizData?.passingRequirements?.scoreIn === "Percentage";

  return (
    <div className="w-[70%] h-[40rem] bg-white p-5 space-y-3">
      <div className="font-semibold">Scoring points</div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Correct answer</div>
        <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="number"
            placeholder="Enter score"
            value={currentQuizData?.scoringPoints?.correctAnswer || 10}
            min={0}
            max={100}
            onChange={(e) => {
              const updatedAnswer = e.target.value;

              setCurrentQuizData({
                ...currentQuizData,
                scoringPoints: {
                  ...currentQuizData?.scoringPoints,
                  correctAnswer: updatedAnswer,
                },
              });
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>

      <div className="font-semibold">Penalty</div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Incorrect answer</div>
        <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="number"
            placeholder="Enter score"
            value={0}
            min={0}
            max={100}
            disabled
            onChange={(e) => {
              
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">For each attempt</div>
        <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="number"
            placeholder="Enter score"
            value={0}
            min={0}
            max={100}
            disabled
            onChange={(e) => {
              
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <span>Tracking</span>
        <div>
          <Switch disabled
          // checked={isTraking}
          // onCheckedChange={() => {
          //   setIsTraking(!isTraking);
          //   const updatedLMS = {
          //     ...course.lmsSettings,
          //     haveTracking: !isTraking,
          //   };
          //   handleToggle(updatedLMS);
          // }}
          />
        </div>
      </div>

      <div className="font-semibold">Passing requirements</div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <span>Passing score</span>
        <div>
          <Switch
          checked={isPassingScore}
          onCheckedChange={() => {
            const newValue = !isPassingScore;

            setIsPassingScore(newValue);

            setCurrentQuizData({
              ...currentQuizData,
              passingRequirements: {
                ...currentQuizData?.passingRequirements,
                  isPassingScore: newValue,
              },
            });
          }}
          />
        </div>
      </div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Score in</div>
        <select value={currentQuizData?.passingRequirements?.scoreIn || "Percentage"} 
        onChange={(e) => {
          const updatedValue = e.target.value;

          setCurrentQuizData({
            ...currentQuizData,
            passingRequirements: {
              ...currentQuizData?.passingRequirements,
              scoreIn: updatedValue,
            },
          });
        }}>
          <option value="Percentage">Percentage</option>
          <option value="Points">Points</option>
        </select>
      </div>
      <div className="flex items-center justify-between w-[50%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Passing score</div>
        <div className="flex items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="number"
            value={isPassingScore ? currentQuizData?.passingRequirements?.passingScore || 40 : 0}
            min={0}
            max={100}
            onChange={(e) => {
              if(isPassingScore) {
                const updatedScore = parseFloat(e.target.value);
                setCurrentQuizData({
                  ...currentQuizData,
                  passingRequirements: {
                    ...currentQuizData?.passingRequirements,
                    passingScore: updatedScore,
                  },
                });
              }
            }}
            disabled={!isPassingScore}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>

      <div className="font-semibold">Feedback</div>
      <div className="flex items-center justify-between w-[70%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Partially correct</div>
        <div className="flex items-center w-[55%] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="text"
            placeholder="You have not selected all the choices"
            value=""
            disabled
            onChange={(e) => {
              
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>

      <div className="font-semibold">Results</div>
      <div className="flex items-center justify-between w-[70%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Passed</div>
        <div className="flex items-center w-[55%] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="text"
            placeholder="Congratulations, you have passed!"
            value=""
            disabled
            onChange={(e) => {
              
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>
      <div className="flex items-center justify-between w-[70%] text-smleading-normal text-[#242424]">
        <div className="leading-normal">Failed</div>
        <div className="flex items-center w-[55%] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
          <input
            type="text"
            placeholder="You did not pass."
            value=""
            disabled
            onChange={(e) => {
              
            }}
            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.25rem] text-[14px]"
          />
        </div>
      </div>
    </div>
  );
};

export default QuizSettingsPage;
