import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useFolder from "src/hooks/useFolder";
import FolderBreadcrums from "./FolderBreadcrums";
import Table from "src/utils/Table";
import MediaTable from "../MediaLibrary/MediaTable";

type Props = {
  searchResults: any[];
  onHandleClickedSearchResult: () => void;
  searchInput: string;
  handleUploadFromMedia: (file: any) => void;
};

function Main({ searchResults, onHandleClickedSearchResult, searchInput, handleUploadFromMedia}: Props) {
  const [ searchParams, setSearchParams ]: any = useSearchParams();
  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);

  const {fileFolder} = useFolder({ folderId: (folderId === "null" ? null : folderId), folder });

//   console.log(fileFolder)

  return (
    <div className="flex flex-col">
      <div className="border-b border-tertiary p-2 bg-[#36BFFA] text-white font-bold">
        <FolderBreadcrums searchInput={searchInput} currentFolder={fileFolder.folder} onHandleClickedSearchResult={onHandleClickedSearchResult}/>
      </div>

      <div className="overflow-y-scroll h-screen ">
        <MediaTable data={searchResults.length > 0 ? searchResults : [...fileFolder.childFolders, ...fileFolder.childFiles]} handleUploadFromMedia={handleUploadFromMedia} />
      </div>
    </div>
  );
}

export default Main;
