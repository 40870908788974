import React, { useState } from 'react';
import { Button, Input, Popover, Command, CommandInput, CommandList, CommandItem, CommandEmpty, CommandGroup, PopoverTrigger, PopoverContent } from '../ui';
import { ChevronsUpDown } from 'lucide-react';
import { v4 as uuid } from "uuid";
import UploadImage from "src/components/Course/UploadImage/UploadImage";
import Modal from 'src/utils/Modal';
import Text from 'src/utils/Text/Txt';

type Props = {
  options: any;
  handleChangeOptions: (options: any) => void;
  haveOptionImage: boolean;
}

const CustomCombobox = ({ options = [], handleChangeOptions, haveOptionImage }: Props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [sections, setSections] = useState(options);
  const [filteredSections, setFilteredSections] = useState(sections);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState<any>(null);
  const [editedLabel, setEditedLabel] = useState('');

  const handleInputChange = (search: string) => {
    setValue(search);
    setFilteredSections(sections.filter((section: any) => section.label.toLowerCase().includes(search.toLowerCase())));
  };

  const handleAddSection = () => {
    const newSection = { _id: uuid(), value: value, label: value };
    setSections([...sections, newSection]);
    setFilteredSections([...sections, newSection]);
    handleChangeOptions([...sections, newSection]);
    setValue('');
    setOpen(false);
  };

  const handleDeleteSection = (id: string) => {
    const updatedSections = sections.filter((section: any) => section._id !== id);
    setSections(updatedSections);
    setFilteredSections(updatedSections);
    handleChangeOptions(updatedSections);
  };

  const handleEditSection = (section: any) => {
    setCurrentSection(section);
    setEditedLabel(section.label);
    setEditModalOpen(true);
  };

  const handleSaveEdit = () => {
    const newOptions = filteredSections.map((opt: any) => {
      if (opt._id === currentSection._id) {
        return { ...opt, label: editedLabel };
      }
      return opt;
    });

    handleChangeOptions(newOptions);
    setEditModalOpen(false);
  };

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[200px] justify-between"
          >
            {"Create section"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[99999] w-[500px] p-0 " align="start" side="bottom">
          <Command>
            <CommandInput placeholder="Search section" value={value} onValueChange={handleInputChange} />
            <CommandList>
              {filteredSections.length > 0 ? (
                <CommandGroup className="overflow-y-scroll">
                  {filteredSections.map((section: any) => (
                    <div className='flex space-x-2 items-center justify-between'>
                      {haveOptionImage && (
                        <div className="flex space-x-5 items-start">
                          <UploadImage
                            name="imageFile"
                            type="image"
                            image={section?.image}
                            haveOptions={haveOptionImage}
                            handleUploadFromDevice={(file) => {
                              const newOptions = filteredSections.map((opt: any) => {
                                if (opt._id === section._id) {
                                  return { ...opt, image: file };
                                }
                                return opt;
                              });

                              handleChangeOptions(newOptions);
                            }}
                            handleUploadFromMedia={(file) => {
                              const newOptions = filteredSections.map((opt: any) => {
                                if (opt._id === section._id) {
                                  return { ...opt, image: file };
                                }
                                return opt;
                              });

                              handleChangeOptions(newOptions);
                            }}
                          />
                        </div>
                      )}
                      <CommandItem
                        key={section._id}
                        onSelect={() => {
                          setValue(section.label);
                          setOpen(false);
                        }}
                        className="w-full cursor-pointer select-none py-2 pl-4 pr-4 hover:bg-blue-600 hover:text-white flex justify-between items-center"
                      >
                        {section.label}


                        {/* <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering onSelect
                            handleEditSection(section._id);
                          }}
                          className="ml-2 p-1 bg-red-600 text-white rounded-md"
                        >
                          Edit
                        </button> */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering onSelect
                            handleDeleteSection(section._id);
                          }}
                          className="ml-2 p-1 bg-red-600 text-white rounded-md"
                        >
                          Delete
                        </button>
                      </CommandItem>
                    </div>
                  ))}
                </CommandGroup>
              ) : (
                <CommandEmpty>
                  <div className="flex flex-col justify-between items-center space-y-2">
                    <span className='text-center'>No section found.</span>
                    <Button onClick={handleAddSection} className="ml-2 p-2 bg-blue text-white rounded-md">
                      Add "{value}"
                    </Button>
                  </div>
                </CommandEmpty>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {/* Edit Modal */}
      {editModalOpen && (
        <Modal
          onOpened={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          title="Rename"
          height="calc(100%-400px)"
        >
          <div className="flex flex-col h-full">
            <div className="grow space-y-4">
              <div>
                <Text
                  value={editedLabel}
                  onChange={(e) => setEditedLabel(e.target.value)}
                  labelTitle="Project name"
                  autoFocus={true}
                />
              </div>
            </div>
            <div className=" border-t p-5 flex justify-between items-center">
              <button
                onClick={() => {
                  handleSaveEdit();
                }}
                className="px-7 py-3 rounded-md text-white bg-tertiary"
              >
                Done
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CustomCombobox;