import React, { useEffect, useState } from "react";
import Layout from "./common/Layout";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Button } from "src/components/ui";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { useLMS } from "src/context/Lms";
import { setLmsLearnerData } from "src/features/lmsLearnerData/lmsLearnerDataSlice";

type Props = {
  type?: string;
  quizSettings?: any;
  topics?: any[];
  topic: any;
  handleDataChange: (data: any) => void;
  data: any;
  handleOpenAuthoringModal?: (state: boolean) => void;
  openTemplate?: any;
  handleCancelTemplateForm?: () => void;
  nextValidation?: boolean;
  handleNextValidation?: () => void;
};

interface Item {
  _id: string;
  value: string;
  targetValue?: any;
  sectionId?: string;
  optionImage?: any;
  isDropped: boolean;
}

interface Cell {
  id: string;
  items: Item[];
}

function DragAndDrop({
  data,
  topics,
  topic,
  type,
  quizSettings,
  handleDataChange,
  handleOpenAuthoringModal,
  openTemplate,
  handleCancelTemplateForm,
  nextValidation,
  handleNextValidation,
}: Props) {
  const templateType = "draganddrop";

  const [items, setItems] = React.useState<Item[]>([]);
  const [cells, setCells] = React.useState<Cell[]>([]);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [openFeedback, setOpenFeedback] = React.useState("");
  // Track dropped items in a state
  const [droppedItemIds, setDroppedItemIds] = useState<Set<string>>(new Set());

  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData);

  const {
    isInitialized,
    setSuspendData,
    getSuspendData,
    setScore,
    setSuccessStatus,
  } = useLMS();

  const dispatch = useDispatch();

  const minPassingScore = quizSettings?.passingRequirements?.passingScore || 0;

  useEffect(() => {
    console.log(data);
    setItems(data.options);
  }, [data]);

  useEffect(() => {
    console.log(items);
  }, [items]);

  const onImageDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    console.log(result);

    if (!destination) {
      return;
    }

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    if (
      source.droppableId === "items" &&
      destination.droppableId.startsWith("section-")
    ) {
      // Moving item from items array to a section
      const updatedItems = items.map((item, index) => {
        if (item._id === draggableId) {
          return { ...item, sectionId: destination.droppableId.split("-")[1] };
        }
        return item;
      });
      setItems(updatedItems);
    } else if (
      source.droppableId.startsWith("section-") &&
      destination.droppableId === "items"
    ) {
      // Moving item from a section back to items array
      const updatedItems = items.map((item) => {
        if (item._id === draggableId) {
          return { ...item, sectionId: undefined };
        }
        return item;
      });
      setItems(updatedItems);
    } else if (
      source.droppableId.startsWith("section-") &&
      destination.droppableId.startsWith("section-")
    ) {
      // Moving item between sections
      const updatedItems = items.map((item) => {
        if (item._id === draggableId) {
          return { ...item, sectionId: destination.droppableId.split("-")[1] };
        }
        return item;
      });
      setItems(updatedItems);
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === "items" &&
      destination.droppableId.startsWith("section-")
    ) {
      // Move item from unassigned to a section
      const updatedItems = items.map((item) => {
        if (item._id === draggableId) {
          return {
            ...item,
            sectionId: destination.droppableId.split("-")[1],
            isDropped: true,  // Mark the item as dropped
          };
        }
        return item;
      });
      setItems(updatedItems);
    } else if (
      source.droppableId.startsWith("section-") &&
      destination.droppableId === "items"
    ) {
      // Move item from a section back to unassigned
      const updatedItems = items.map((item) => {
        if (item._id === draggableId) {
          return {
            ...item,
            sectionId: undefined,
            isDropped: false,  // Mark the item as not dropped
          };
        }
        return item;
      });
      setItems(updatedItems);
    } else if (
      source.droppableId.startsWith("section-") &&
      destination.droppableId.startsWith("section-")
    ) {
      // Move item between sections
      const updatedItems = items.map((item) => {
        if (item._id === draggableId) {
          return {
            ...item,
            sectionId: destination.droppableId.split("-")[1],
          };
        }
        return item;
      });
      setItems(updatedItems);
    }
  };

  useEffect(() => {
    console.log(isSubmitted);
  }, [isSubmitted]);

  function reset() {
    setItems(
      data.options.map((option: any) => ({ ...option, sectionId: undefined }))
    );
  }

  useEffect(() => {
    console.log("learnerData line: 202", learnerData);
    if (!isInitialized) return;
    // console.log("index line : 105", learnerData);
    setSuspendData(JSON.stringify(learnerData));

    setScore(learnerData.score.raw);

    if (learnerData.score.raw >= minPassingScore) {
      setSuccessStatus("passed");
    } else {
      setSuccessStatus("failed");
    }
  }, [learnerData]);

  function submit() {
    const isCorrect = items.every(
            (option) =>
              option.targetValue?._id.split("-")[0] === option.sectionId
          );
    console.log(
      isCorrect,
      items.every(
        (option) => option.targetValue?._id.split("-")[0] === option.sectionId
      )
    );
    setStatus(isCorrect ? "correct" : "incorrect");
    setIsSubmitted(true);

    setTimeout(() => {
      setOpenFeedback(
        isCorrect ? data.globalFeedbackCorrect : data.globalFeedbackIncorrect
      );
    }, 2000);

    if (nextValidation && handleNextValidation) {
      handleNextValidation();
    }

    if(!type) return;

    const quizTemplates = topics ? topics.filter((topic) => topic.template && (topic.template.type === "scr" || topic.template.type === "mcr" || topic.template.type === "true/false" || topic.template.type === "matching" || topic.template.type === "fillintheblanks" || topic.template.type === "draganddrop")) : [];
    const noOfQuizes = quizTemplates.length;

    console.log("line 123", learnerData);

    const findTemplate = learnerData.templates.find(
      (template: any) => template.data.id === topic.template.id
    );

    var score = 0;
    if (isCorrect && noOfQuizes > 0) {
      score = learnerData.score.raw + Math.round(100 / noOfQuizes);
    } else {
      score = learnerData.score.raw + 0;
    }

    if (findTemplate) {
      dispatch(
        setLmsLearnerData({
          templates: learnerData.templates.map((template: any) => {
            if (template.data.id === topic.template.id) {
              return {
                ...template,
                data: { ...template.data, completed: true },
              };
            }
            return template;
          }),
          score: { ...learnerData.score, raw: score },
        })
      );
    } else {
      dispatch(
        setLmsLearnerData({
          templates: [
            ...learnerData.templates,
            {
              id: topic.template.id,
              title: topic.template.title,
              type: topic.template.type,
              data: { visited: true, completed: true },
            },
          ],
          score: { ...learnerData.score, raw: score },
        })
      );
    }
  }

  return (
    <>
      {!type ? (
        <Layout
          data={data}
          type={templateType}
          handleDataChange={(data) => handleDataChange(data)}
          handleOpenAuthoringModal={(state) => {
            handleOpenAuthoringModal && handleOpenAuthoringModal(state);
          }}
          handleCancelTemplateForm={() => {
            handleCancelTemplateForm && handleCancelTemplateForm();
          }}
          openTemplate={openTemplate}
        >
          {data.dragDropType?.value === "Image" ? (
            <div
              style={data?.styles?.container}
              onClick={() => {
                if (
                  canvasActiveComponent?.templateType === "draganddrop" &&
                  canvasActiveComponent?.componentType === "container"
                ) {
                  dispatch(setCanvasActiveComponent(null));
                } else {
                  dispatch(
                    setCanvasActiveComponent({
                      templateType: "draganddrop",
                      componentType: "container",
                    })
                  );
                }
              }}
              className={`h-full w-full flex flex-col items-start justify-start p-4 space-y-2 ${
                canvasActiveComponent?.templateType === "draganddrop" &&
                canvasActiveComponent?.componentType === "container"
                  ? "border-2 border-dashed border-blue"
                  : ""
              }`}
            >
              <div className="">
                <div className={`text-sm`}>{data.question}</div>
              </div>
              <div className="">
                <div className={`text-xs font-light`}>{data.prompt}</div>
              </div>
              <div className="w-full grow flex flex-col justify-between space-y-2">
                <div className="h-[300px] w-full flex gap-5">
                  {data?.sections?.map((section: any) => (
                    <div className="border border-gray-300 p-2 relative w-full">
                      <img
                        src={section.image?.url}
                        alt={section.value}
                        className="w-full h-full object-contain"
                      />
                      {items
                        .filter(
                          (option) =>
                            option.sectionId === section._id.split("-")[0]
                        )
                        .map((option, index) => (
                          <div
                            key={option._id}
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          >
                            <img
                              style={{
                                boxShadow: "0px 0px 10px 2px rgb(0,0,0, 0.3)",
                              }}
                              src={option.optionImage?.url}
                              alt={option.value}
                              className="h-[100px] w-auto border-[3px] border-white shadow-lg rounded-md p-[5px]"
                            />
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
                <div className={"w-full mt-4 flex gap-5"}>
                  {items
                    .filter((option) => !option.sectionId)
                    .map((option, index) => (
                      <div
                        key={option._id}
                        className="grow flex items-center justify-center"
                      >
                        <img
                          style={{
                            boxShadow: "0px 0px 10px 2px rgb(0,0,0, 0.3)",
                          }}
                          src={option.optionImage?.url}
                          alt={option.value}
                          className="h-[50px] w-auto border-[3px] border-white shadow-lg rounded-md p-[5px]"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex space-x-2">
                <Button
                  disabled
                  onClick={() => {
                    submit();
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={data?.styles?.container}
              onClick={() => {
                if (
                  canvasActiveComponent?.templateType === "draganddrop" &&
                  canvasActiveComponent?.componentType === "container"
                ) {
                  dispatch(setCanvasActiveComponent(null));
                } else {
                  dispatch(
                    setCanvasActiveComponent({
                      templateType: "draganddrop",
                      componentType: "container",
                    })
                  );
                }
              }}
              className={`h-full w-full flex flex-col items-start justify-start p-4 space-y-2 ${
                canvasActiveComponent?.templateType === "draganddrop" &&
                canvasActiveComponent?.componentType === "container"
                  ? "border-2 border-dashed border-blue"
                  : ""
              }`}
            >
              <div className="">
                <div className={`text-sm`}>{data.question}</div>
              </div>
              <div className="">
                <div className={`text-xs font-light`}>{data.prompt}</div>
              </div>
              <div className="grow">
                {/* <DragDropContext onDragEnd={onDragEnd}> */}
                <div
                  className="w-full flex gap-5"
                  style={data?.styles?.sectionContainer}
                >
                  {data.sections?.map((section: any) => (
                    <div
                      key={section._id}
                      className="flex-1 border border-gray-300 p-2 relative"
                      style={data?.styles?.section}
                    >
                      <div className="text-center font-medium">
                        {section.value}
                      </div>
                      <div className="mt-2 min-h-[100px] flex flex-wrap gap-2">
                        {items
                          .filter((item) => item.sectionId === section._id)
                          .map((item) => (
                            <div
                              key={item._id}
                              className="p-2 border border-gray-300 bg-white rounded-md shadow"
                            >
                              {item.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="w-full mt-4 grid grid-cols-3 gap-4">
                  {items.map((option: any, index: number) => (
                    <div
                      style={data?.styles?.dragItem}
                      className={`p-2 text-sm rounded-[5px] border-[2px] border-black ${
                        option.sectionId && "bg-[#8d8dd2] text-black"
                      } `}
                    >
                      {option.value}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex space-x-2">
                <Button
                  disabled
                  onClick={() => {
                    const isCorrect = cells.every(
                      (option) =>
                        option.items[0] &&
                        option.items[0].targetValue._id.split("-")[0] ===
                          option.items[0].sectionId
                    );
                    alert(
                      isCorrect
                        ? "All items are correctly placed!"
                        : "Some items are incorrectly placed."
                    );
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Layout>
      ) : (
        <>
          {data.dragDropType?.value === "Image" ? (
            <div
              style={data?.styles?.container}
              className="h-full w-full flex flex-col items-start justify-start p-4 space-y-2"
            >
              <div className="">
                <div className={`text-sm`}>{data.question}</div>
              </div>
              <div className="">
                <div className={`text-xs font-light`}>{data.prompt}</div>
              </div>
              <div className="w-full grow flex flex-col justify-between space-y-2">
                <DragDropContext onDragEnd={onImageDragEnd}>
                  <div className="h-[300px] w-full flex gap-5">
                    {data.sections.map((section: any) => (
                      <Droppable
                        key={section._id}
                        droppableId={`section-${section._id}`}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="border border-gray-300 p-2 relative w-full"
                          >
                            <img
                              src={section.image?.url}
                              alt={section.value}
                              className="w-full h-full object-contain"
                            />
                            {items
                              .filter(
                                (option) =>
                                  option.sectionId === section._id.split("-")[0]
                              )
                              .map((option, index) => (
                                <Draggable
                                  key={option._id}
                                  draggableId={option._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    >
                                      <img
                                        style={{
                                          boxShadow:
                                            "0px 0px 10px 2px rgb(0,0,0, 0.3)",
                                        }}
                                        src={option.optionImage?.url}
                                        alt={option.value}
                                        className="h-[100px] w-auto border-[3px] border-white shadow-lg rounded-md p-[5px]"
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                  <Droppable droppableId="items" direction="horizontal">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={"w-full mt-4 flex gap-5"}
                      >
                        {items
                          .filter((option) => !option.sectionId)
                          .map((option, index) => (
                            <Draggable
                              key={option._id}
                              draggableId={option._id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex items-center justify-center grow"
                                >
                                  <img
                                    style={{
                                      boxShadow:
                                        "0px 0px 10px 2px rgb(0,0,0, 0.3)",
                                    }}
                                    src={option.optionImage?.url}
                                    alt={option.value}
                                    className="h-[50px] w-auto border-[3px] border-white shadow-lg rounded-md p-[5px]"
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="flex space-x-2">
                <Button disabled={isSubmitted}
                  onClick={() => {
                    submit();
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Submit
                </Button>
                <Button
                  disabled={isSubmitted}
                  onClick={() => {
                    reset();
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Reset
                </Button>
              </div>
              {openFeedback && (
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col w-[60%] h-[75%] border border-gray-300 bg-white">
                  <div
                    className={`relative text-lg font-semibold p-2 ${
                      status === "correct" ? "bg-green-500" : "bg-red-500"
                    }`}
                  >
                    {status === "correct" ? "Correct" : "Incorrect"}
                    <span
                      className="absolute right-2 cursor-pointer"
                      onClick={() => setOpenFeedback("")}
                    >
                      X
                    </span>
                  </div>
                  <div className="p-2 flex flex-col space-y-4 overflow-y-scroll no-scrollbar">
                    {data.sections?.map((section: any) => (
                      <div
                        key={section._id}
                        className="flex flex-col space-y-2"
                      >
                        <div className="font-semibold text-base">
                          {section.value}
                        </div>
                        <div className="flex flex-wrap gap-5">
                          {items
                            .filter(
                              (item: any) =>
                                item.targetValue?._id === section?._id
                            )
                            .map((item: any) => (
                              <div
                                key={item._id}
                                className="flex flex-col items-center justify-center space-y-2"
                              >
                                <img
                                  src={item.optionImage.url}
                                  alt={item.value}
                                  className="w-16 h-16 object-contain"
                                />
                                <div className="text-sm">{item.value}</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              style={data?.styles?.container}
              className="relative h-full w-full flex flex-col items-start justify-start p-4 space-y-2"
            >
              <div className="">
                <div className={`text-sm`}>{data.question}</div>
              </div>
              <div className="">
                <div className={`text-xs font-light`}>{data.prompt}</div>
              </div>
              <div className="grow">
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="w-full flex gap-5">
                    {data?.sections?.map((section: any) => (
                      <Droppable
                        key={section._id}
                        droppableId={`section-${section._id}`}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="border border-gray-300 p-4 relative w-full"
                          >
                            <div className="text-center font-medium">
                              {section.value}
                            </div>
                            <div className="mt-2 min-h-[100px] flex flex-wrap gap-2">
                              {items
                                .filter(
                                  (options) => options.sectionId === section._id.split("-")[0]
                                )
                                .map((option, index) => (
                                  <Draggable
                                    key={option._id}
                                    draggableId={option._id}
                                    index={index}
                                    isDragDisabled={isSubmitted}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`p-2 border w-full rounded-md shadow ${isSubmitted ? option.targetValue?._id.split("-")[0] === option.sectionId ? "border-green-500" : " border-red-500" : "border-gray-300"}`}
                                      >
                                        {option.value}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    ))}
                  </div>
                  <Droppable droppableId="items" direction="horizontal">
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="w-full mt-4 grid grid-cols-3 gap-4"
                      >
                        {items
                          .filter((option) => !option.sectionId)
                          .map((option, index) => (
                            <Draggable
                              key={option._id}
                              draggableId={option._id}
                              index={index}
                              isDragDisabled={option.isDropped || isSubmitted}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`p-2 text-sm border-[2px] border-black rounded-[5px] ${option.isDropped ? "bg-gray-300" : "bg-gray-100"}`}
                                >
                                  {option.value}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <div className="flex space-x-2">
                <Button disabled={isSubmitted}
                  onClick={() => {
                    submit();
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Submit
                </Button>
                <Button
                  disabled={isSubmitted}
                  onClick={() => {
                    reset();
                  }}
                  className="mt-4 p-2 bg-blue text-white"
                >
                  Reset
                </Button>
              </div>
              {openFeedback && (
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col w-[60%] h-[50%] border border-gray-300 bg-white">
                  <div
                    className={`relative text-lg font-semibold p-2 ${
                      status === "correct" ? "bg-green-500" : "bg-red-500"
                    }`}
                  >
                    {status === "correct" ? "Correct" : "Incorrect"}
                    <span
                      className="absolute right-2 cursor-pointer"
                      onClick={() => setOpenFeedback("")}
                    >
                      X
                    </span>
                  </div>
                  <div className="text-sm grow p-2">{openFeedback}</div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default DragAndDrop;
