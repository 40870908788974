import React from 'react'

type Props = {
    label: string;
    value: string | number;
    inputType: string;
    onChange: (value: string) => void;
}

const KeyValue: React.FC<Props> = ({ label, value, inputType, onChange }) => {
    return (
        <div className='flex py-1 w-full justify-between items-center'>
            <div className='text-left w-full text-sm '>{label}</div>
            {
                inputType === 'range' ? (
                    <input
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        type={inputType}
                        min={0}
                        max={1}
                        step={0.1}
                        name=""
                        id=""
                    />
                ) :
                    (

                        <input
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            type={inputType}
                            name=""
                            id=""
                        />
                    )
            }
        </div>
    )
}

export default React.memo(KeyValue);