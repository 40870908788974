import React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select';

type SelectInputProps = {
    label: string;
    value: string;
    onChange: (value: string) => void;
    options: { value: string, label: string }[];
}

const SelectInput: React.FC<SelectInputProps> = ({ label, value, onChange, options }) => {
    return (
        <div className='flex justify-between items-center'>
            <div className='text-left w-full text-sm'>{label}</div>
            <Select
                value={value}
                onValueChange={(value: string) => {
                    onChange(value);
                }}>
                <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    {options.map(option => (
                        <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}

export default React.memo(SelectInput);