import React, { useState, useEffect } from "react";
import { ComboboxDemo } from "src/components/ui/comboBox2";
import Text from "src/utils/Text/Txt";
import UploadImage from "src/components/Course/UploadImage/UploadImage";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import { Option } from "./utils/DefaultTemplateData";
import { SelectCreatable } from "src/components/ui/SelectCreatable";
import CreatableSelectDropdown from "src/utils/CreatableSelectDropdown";
import { CreatableSelect } from "src/components/ui/CreatableSelect";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Button } from "src/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "src/components/ui/command";
import { cn } from "src/components/lib/utils";
import CustomCombobox from "src/components/ui/CustomCombobox";



type Props = {

  data: any;
  handleDataChange: (data: any) => void;
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
};

const Sections = [
  {
    value: "lower",
    label: <div>Lower</div>
  },
  {
    value: "Moderate",
    label: <div>Moderate</div>
  },
  {
    value: "Severe",
    label: <div>Severe</div>
  }
]

const sections = {}



function DragAndDrop({ data, handleDataChange, optionQuantity, handleImageOrOptionClicked }: Props) {

  const [sectionData, setSectionData] = useState([])
  const [options, setOptions] = useState(data.sections ?? []);

  // const [dragData, setDragData] = useState(data);
  const [templateType, setTemplateType] = useState("");

  const [currentOptionQuantity, setCurrentOptionQuantity] =
    useState<number>(optionQuantity);

  const [haveOptionImage, setHaveOptionImage] =
    useState<boolean>(data.haveOptionImage ?? false);

  const [optionImage, setOptionImage] = useState(null);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (type) setTemplateType(type);

  // }, [type]);

  useEffect(() => {
    setCurrentOptionQuantity(optionQuantity);
  }, [optionQuantity]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  function handleChangeOptions(options: any) {
    console.log("options:", options);
    setSectionData(options);
    handleDataChange({ ...data, sections: options });
  }


  return (
    <div className="grow overflow-y-scroll ml-8 mt-2">
      <div className="pb-[1.25rem]">
        <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">
          <Text
            labelTitle="Question"
            value={data.question}
            onChange={(e) =>
              handleDataChange({ ...data, question: e.target.value })
            }
            maxLength={
              data.componentProperties?.find((comp: any) => comp.type === "Question")
                ?.maxLength || 150
            }
            onFocus={(e) => {
              const existingComponent = data.componentProperties?.find(
                (comp: any) => comp.type === "Question"
              );
              if (existingComponent) {
                dispatch(
                  setSelectedComponent({
                    type: existingComponent.type,
                    maxLength: existingComponent.maxLength,
                  })
                );
              }
            }}
          />
        </div>
        <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">
          <Text labelTitle="Prompt"
            value={data.prompt}
            onChange={(e) =>
              handleDataChange({ ...data, prompt: e.target.value })
            }
            maxLength={
              data.componentProperties?.find((comp: any) => comp.type === "Prompt")
                ?.maxLength || 80
            }
            onFocus={(e) => {
              const existingComponent = data.componentProperties?.find(
                (comp: any) => comp.type === "Prompt"
              );
              if (existingComponent) {
                dispatch(
                  setSelectedComponent({
                    type: existingComponent.type,
                    maxLength: existingComponent.maxLength,
                  })
                );
              }
            }}
          />

        </div>
        <div className="flex flex-col ml-7 space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">
          <label
            className="font-[500] text-[1.125rem] leading-normal text-[#242424]"
            htmlFor="input"
          >
            Sections
          </label>
          <CustomCombobox options={options} handleChangeOptions={handleChangeOptions} haveOptionImage={(data?.dragDropType?.value === "Image")} />
        </div>

        {/* {templateType && ( */}
        <OptionChoices
          type={templateType}
          sections={data.sections}
          options={data.options}
          componentProperties={data.componentProperties}
          setOptions={(options: any) => handleDataChange({ ...data, options })}
          optionQuantity={currentOptionQuantity}
          handleImageOrOptionClicked={handleImageOrOptionClicked}
          haveOptionImage={(data?.dragDropType?.value === "Image")}
        />
        {/* )} */}

        {/* <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]"> */}
        {/* <label
            className="font-[500] text-[1.125rem] leading-normal text-[#242424]"
            htmlFor="input"
          >
            Matches
          </label> */}
        {/* <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border text-left border-gray-100 px-2 py-1 font-normal">
                  Drag Items
                </th>
                <th className="border text-left border-gray-200 px-2 py-1 font-normal">
                  Drag Target
                </th>
              </tr>
            </thead>
            <tbody> */}
        {/* <tr> */}
        {/* <td className="border border-gray-300 w-1/2 px-2 py-1">
                <div className="flex items-center space-x-2">
                     <img
                        className="w-12 h-12 object-contain rounded-md"
                        src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
                         alt=""
                    />
                 <span>Items are fallen over</span>
                </div>
                </td>
                <td className="border border-gray-300 w-1/2 px-2 py-1"> */}
        {/* <input
                    className="col-span-2 w-32 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="text"
                    value="LIGHT"
                  /> */}
        {/* <ComboboxDemo/> */}
        {/* </td>
              </tr> */}
        {/* <tr>
                <td className="border border-gray-300 px-2 py-1">
                <div className="flex items-center space-x-2">
                     <img
                        className="w-12 h-12 object-contain rounded-md"
                        src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
                         alt=""
                    />
                 <span>Rapid bumps or jolts</span>
                </div>
                </td>
                <td className="border border-gray-300 px-2 py-1"> */}
        {/* <input
                    className="col-span-2 w-32 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="text"
                    value="SEVERE"
                  /> */}
        {/* <ComboboxDemo/>
                </td>
              </tr> */}
        {/* <tr>
                <td className="border border-gray-300 px-2 py-1">
                <div className="flex items-center space-x-2">
                     <img
                        className="w-12 h-12 object-contain rounded-md"
                        src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
                         alt=""
                    />
                 <span>Carts are dificult to maneuver</span>
                </div>
                </td>
                <td className="border border-gray-300 px-2 py-1"> */}
        {/* <input
                    className="col-span-2 w-32 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="text"
                    value="MODERATE"
                  /> */}
        {/* <ComboboxDemo/>
                </td>
              </tr> */}
        {/* <tr>
                <td className="border border-gray-300 px-2 py-1">
                <div className="flex items-center space-x-2">
                     <img
                        className="w-12 h-12 object-contain rounded-md"
                        src="https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg"
                         alt=""
                    />
                 <span>Cause variations in indicated airspeed</span>
                </div>
                </td>
                <td className="border border-gray-300 px-2 py-1"> */}
        {/* <input
                    className="col-span-2 w-32 px-2 py-1 border border-gray-400 rounded-md outline-none"
                    type="text"
                    value="LIGHT"
                  /> */}
        {/* <ComboboxDemo/>
                </td>
              </tr> */}
        {/* </tbody> */}
        {/* </table> */}
        {/* </div> */}
        <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">

          <Text
            labelTitle="GlobalFeedback Correct"
            value={data.globalFeedbackCorrect}
            onChange={(e) => {
              handleDataChange({ ...data, globalFeedbackCorrect: e.target.value })
            }}
            maxLength={
              data.componentProperties?.find(
                (comp: any) => comp.type === "Global Feedback Correct"
              )?.maxLength || 150
            }
            onFocus={(e) => {
              const existingComponent = data.componentProperties?.find(
                (comp: any) => comp.type === "Global Feedback Correct"
              );
              if (existingComponent) {
                dispatch(
                  setSelectedComponent({
                    type: existingComponent.type,
                    maxLength: existingComponent.maxLength,
                  })
                );
              }
            }}
          />
        </div>
        <div className="flex flex-col space-y-[0.5rem] px-[1.63rem] pt-[1.25rem]">
          <Text
            labelTitle="GlobalFeedback Incorrect"
            value={data.globalFeedbackIncorrect}
            onChange={(e) => {
              handleDataChange({ ...data, globalFeedbackIncorrect: e.target.value })
            }}
            maxLength={
              data.componentProperties?.find(
                (comp: any) => comp.type === "Global Feedback InCorrect"
              )?.maxLength || 150
            }
            onFocus={(e) => {
              const existingComponent = data.componentProperties?.find(
                (comp: any) => comp.type === "Global Feedback InCorrect"
              );
              if (existingComponent) {
                dispatch(
                  setSelectedComponent({
                    type: existingComponent.type,
                    maxLength: existingComponent.maxLength,
                  })
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}


export default DragAndDrop;

const OptionChoices = ({
  type,
  sections,
  options,
  setOptions,
  componentProperties,
  optionQuantity,
  handleImageOrOptionClicked,
  haveOptionImage,
}: {
  type: string;
  sections: any;
  options: any;
  setOptions: (options: any) => void;
  componentProperties: any[];
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
  haveOptionImage: boolean;
}) => {
  const [templateType, setTemplateType] = useState("");
  const [optionImage, setOptionImage] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (type) setTemplateType(type);
    console.log("options at start:", options);
  }, [type, options]);

  useEffect(() => {
    console.log(componentProperties);
  }, [componentProperties]);

  useEffect(() => {
    console.log(haveOptionImage);
  }, [haveOptionImage]);

  const addOption = () => {
    if (options.length >= optionQuantity) return; // Prevent adding more options if limit is reached

    const defaultOptionImage = {
      _id: uuid(), // or some unique identifier
      url: 'https://www.shutterstock.com/image-vector/default-ui-image-placeholder-wireframes-600nw-1037719192.jpg',
      name: 'default-image-name',
      type: 'image/jpg', // or the correct MIME type
      size: 12345,
      createdOn: Date.now(),
      folderId: null,
      userId: 'default-user-id',
      __v: 0,
    };

    const newOption = {
      _id: uuid(),
      value: "New Option",
      correct: false,
      response: false,
      ...(haveOptionImage && { optionImage: defaultOptionImage })
    };
    setOptions([...options, newOption]);
    console.log("New options after add:", [...options, newOption]);
  };

  const deleteOption = (index: number) => {
    setOptions(options.filter((option: any, idx: number) => idx !== index));
  };

  return (
    <div className="px-[1.63rem] pt-[1.25rem] pb-[0.5rem] w-full">
      <label
        className="font-[500] text-[1.125rem] ml-7 leading-normal text-[#242424]"
        htmlFor="input"
      >
        Matches
      </label>
      <div className="flex justify-between w-full items-center">
        <div className="w-[49%] flex items-center justify-start pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <div className="w-[15%] ">
            {/* <span>Correct</span> */}
          </div>
          {/* {options[0]?.optionImage && <div className="w-[8rem]"></div>} */}
          <div className={`w-[85%] ml-3  "ml-[6.5rem]"}`}>
            <span>Drag Items</span>
          </div>
        </div>
        <div className="flex justify-start w-[49%] pb-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
          <span>Drag Target</span>
        </div>
      </div>
      <div className="space-y-1.5 w-full">
        {options.map((option: any, index: number) => (
          <div key={index} className="flex justify-between w-full items-center">
            <div
              onClick={() => {
                console.log("option clicked:", option._id, typeof handleImageOrOptionClicked);
                handleImageOrOptionClicked(option._id);
                const existingComponent = componentProperties?.find(
                  (comp: any) => comp.type === "Choices"
                );
                if (existingComponent) {
                  dispatch(
                    setSelectedComponent({
                      type: existingComponent.type,
                      maxLength: existingComponent.maxLength,
                    })
                  );
                }
              }}
              className="w-[49%] flex items-center"
            >
              <div className="w-[15%] flex pl-7">
                {/* {templateType === "scr" && (
                  <input
                    type="radio"
                    className="transform scale-150"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                  />
                )}
                {templateType === "true/false" && (
                  <input
                    type="radio"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                    className="transform scale-150"
                  />
                )}
                {templateType === "mcr" && (
                  <input
                    type="checkbox"
                    className="transform scale-150"
                    checked={option.correct}
                    onChange={() => handleSelectionChange(index)}
                  />
                )} */}
              </div>

              {haveOptionImage && (
                <div className="flex space-x-5 items-start">
                  <UploadImage
                    name="imageFile"
                    type="image"
                    image={option?.optionImage}
                    haveOptions={haveOptionImage}
                    handleUploadFromDevice={(file) => {
                      setOptionImage(file);
                      console.log(file);
                      setOptions(
                        options.map((op: any, i: number) =>
                          i === index ? { ...op, optionImage: file } : op
                        )
                      );
                    }}
                    handleUploadFromMedia={(file) => {
                      setOptionImage(file);
                      console.log(file);
                      setOptions(
                        options.map((op: any, i: number) =>
                          i === index ? { ...op, optionImage: file } : op
                        )
                      );
                    }}
                  />
                </div>
              )}

              <div className="flex w-[85%] items-center pr-[0.75rem] overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600 ml-3">
                <input
                  type="text"
                  value={option.value}
                  maxLength={
                    componentProperties?.find(
                      (comp: any) => comp.type === "Choices"
                    )?.maxLength || 55
                  }
                  className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                  onChange={(e) => {
                    setOptions(
                      options.map((op: any, i: number) =>
                        i === index ? { ...op, value: e.target.value } : op
                      )
                    );
                  }}
                />
                <span className="text-[14px] text-gray-600 font-medium">
                  <span
                    className={`${option?.value?.length >=
                      componentProperties?.find(
                        (comp: any) => comp.type === "Options"
                      )?.maxLength
                      ? "text-red-500"
                      : "text-green-500"
                      }`}
                  >
                    {option?.value?.length}
                  </span>
                  /
                  <span className={``}>
                    {componentProperties?.find(
                      (comp: any) => comp.type === "Choices"
                    )?.maxLength || 55}
                  </span>
                </span>
              </div>
            </div>

            <div className="flex w-[49%] space-x-5 items-center">
              <div className="flex items-center ">
                <ComboboxDemo sections={sections} targetValue={option.targetValue} handleTargetValue={(targetValue) => {
                  setOptions(
                    options.map((op: any, i: number) =>
                      i === index ? { ...op, targetValue: targetValue } : op
                    )
                  );
                }} />
                {/* <input
                  type="text"
                  value={option?.feedback || ""}
                  maxLength={
                    componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    )?.maxLength || 30
                  }
                  placeholder="Enter Choices feedback here..."
                  className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                  onChange={(e) => {
                    setOptions(
                      options.map((op, i) =>
                        i === index ? { ...op, feedback: e.target.value } : op
                      )
                    );
                  }}
                  onFocus={(e) => {
                    const existingComponent = componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    );
                    if (existingComponent) {
                      dispatch(
                        setSelectedComponent({
                          type: existingComponent.type,
                          maxLength: existingComponent.maxLength,
                          zone: existingComponent.zone,
                        })
                      );
                    }
                  }}
                /> */}
                {/* <span className="text-[14px] text-gray-600 font-medium">
                  <span
                    className={`${option?.feedback &&
                      option?.feedback?.length >=
                      componentProperties?.find(
                        (comp: any) => comp.type === "Choices Feedback"
                      )?.maxLength
                      ? "text-red-500"
                      : "text-green-500"
                      }`}
                  >
                    {option?.feedback?.length}
                  </span>
                  /
                  <span className={``}>
                    {componentProperties?.find(
                      (comp: any) => comp.type === "Choices Feedback"
                    )?.maxLength || 500}
                  </span>
                </span> */}
              </div>

              <button
                onClick={() => deleteOption(index)}
                className="text-black text-md"
                aria-label="Delete Option"
              >
                X
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10 ml-8">
        <button
          onClick={addOption}
          className={`bg-black/70 hover:bg-black/50 text-white py-2 px-4 rounded disabled:bg-black/25 disabled:text-black`}
          disabled={options.length >= optionQuantity}
        >
          Add New Choice
        </button>
        {options.length >= optionQuantity ? (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You cannot add more than {optionQuantity} choices
          </div>
        ) : (
          <div className="text-black/50 bg-gray-200 p-2 w-[35%] mt-3">
            You can add up to {optionQuantity} choices
          </div>
        )}
      </div>
    </div>

  );
}
