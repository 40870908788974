"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "src/components/lib/utils"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Combobox/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./Combobox/popover"

// export const sections = [
//   {
//     value: "light",
//     label: "Light",
//   },
//   {
//     value: "moderate",
//     label: "Moderate",
//   },
//   {
//     value: "severe",
//     label: "Severe",
//   },
// ]

type Props = {
  targetValue: any;
  sections: any;
  handleTargetValue: (targetValue: any) => void;
}

export function ComboboxDemo({ targetValue, sections, handleTargetValue }: Props) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<any>(targetValue)

  React.useEffect(() => {
  }, [value])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {value
            ? sections.find((section: any) => section._id === value._id)?.label
            : "Select section..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[99999] w-[200px] p-0"
        align="start"
        side="bottom" >
        <Command>
          <CommandInput placeholder="Select section..." />
          <CommandList>
            <CommandEmpty>No Section found.</CommandEmpty>
            <CommandGroup>
              {sections?.map((section: any) => (
                <CommandItem
                  key={section._id}
                  value={section.value}
                  onSelect={(currentValue) => {
                    setValue(currentValue === value ? "" : section)
                    handleTargetValue(currentValue === value ? {} : section)
                    setOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === section.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {section.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
