import React, { useState, useEffect } from "react";
import { Stepper } from "@mantine/core";
import '@mantine/core/styles.css';
import AWS from "aws-sdk";
import { name } from "@azure/msal-browser/dist/packageMetadata";

const translate = new AWS.Translate({
  region: "ap-southeast-1",
  accessKeyId: `${process.env.REACT_APP_ACCESS_ID}`,
  secretAccessKey: `${process.env.REACT_APP_SECRET_ID}`,
});

const translateText = async (
  text: string[],
  sourceLanguage: string,
  targetLanguage: string
): Promise<string[]> => {
  try {
    const promises = text.map((item) =>
      translate
        .translateText({
          Text: item,
          SourceLanguageCode: sourceLanguage,
          TargetLanguageCode: targetLanguage,
        })
        .promise()
        .then((response) => response.TranslatedText || "")
    );

    const results = await Promise.all(promises);
    console.log("Translated Results:", results);
    return results;
  } catch (error) {
    console.error("Translation Error:", error);
    return [];
  }
};

const polly = new AWS.Polly({
  region: "ap-southeast-1",
  accessKeyId: `${process.env.REACT_APP_ACCESS_ID}`,
  secretAccessKey: `${process.env.REACT_APP_SECRET_ID}`,
});

const languageVoiceMap: { [key: string]: { languageCode: string; voiceId: string } } = {
  es: { languageCode: "es-ES", voiceId: "Miguel" }, // Spanish
  fr: { languageCode: "fr-FR", voiceId: "Celine" }, // French
  zh: { languageCode: "cmn-CN", voiceId: "Zhiyu" }, // Simplified Chinese
  en: { languageCode: "en-US", voiceId: "Joanna" }, // English (default)
  // Add more languages if needed
};

const generateAudio = async (
  text: string,
  language: string,
): Promise<string> => {
  try {
    const { languageCode, voiceId } = languageVoiceMap[language] || languageVoiceMap["en"]; // Default to English if unsupported

    const params = {
      Text: text,
      OutputFormat: "mp3",
      VoiceId: voiceId,
      LanguageCode: languageCode,
    };

    const response = await polly.synthesizeSpeech(params).promise();

    if (response.AudioStream) {
      // Convert AudioStream (Uint8Array) to a Blob
      const audioBlob = new Blob([response.AudioStream as Uint8Array], { type: "audio/mp3" });

      // Create an object URL for the Blob
      const audioUrl = URL.createObjectURL(audioBlob);

      return audioUrl;
    } else {
      throw new Error("No AudioStream returned from Polly");
    }
  } catch (error) {
    console.error(`Error generating audio with AWS Polly:`, error);
    throw error;
  }
};

// Add this S3 configuration
const s3 = new AWS.S3({
  region: "ap-southeast-1",
  accessKeyId: `${process.env.REACT_APP_ACCESS_ID}`,
  secretAccessKey: `${process.env.REACT_APP_SECRET_ID}`,
});

// Function to upload audio to S3
const uploadToS3 = async (blob: Blob, language: string): Promise<string> => {
  const fileName = `audio/${language}-${Date.now()}.mp3`;
  const params = {
    Bucket: "sepmupload", // Replace with your S3 bucket name
    Key: fileName,
    Body: blob,
    ContentType: "audio/mp3",
    // ACL: "public-read", // Make the file publicly accessible
  };

  try {
    await s3.putObject(params).promise();
    const fileUrl = `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${fileName}`;
    return fileUrl;
  } catch (error) {
    console.error("Error uploading audio to S3:", error);
    throw error;
  }
};



const CreateAudioUI: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [languages, setLanguages] = useState([
    { code: "es", name: "Spanish", checked: false },
    { code: "fr", name: "French", checked: false },
    { code: "zh", name: "Chinese", checked: false },
    { code: "jp", name: "Japanese", checked: false },
  ]);

  const [sourceTranscript, setSourceTranscript] = useState<string>("");
  const [mandatoryStandardsText, setMandatoryStandardsText] = useState<string>("");
  const [mandatoryWords, setMandatoryWords] = useState<string[]>([]);
  const [targetMandatoryWords, setTargetMandatoryWords] = useState<{ [key: string]: string[] }>({});
  const [targetLanguages, setTargetLanguages] = useState<string[]>([]);
  const [translations, setTranslations] = useState<{
    transcript: { [key: string]: string };
    mandatory: { [key: string]: string[] };
  }>({
    transcript: {},
    mandatory: { source: [], es: [], fr: [], zh: [], jp: [] },
  });
  const [selectedLanguage, setSelectedLanguage] = useState<string>("es");
  const [audioUrls, setAudioUrls] = useState<{ [key: string]: string }>({}); // To store audio URLs

  useEffect(() => {
    const savedTranslations = localStorage.getItem("translations");
    if (savedTranslations) {
      setTranslations(JSON.parse(savedTranslations));
    }

    const savedAudioUrls = localStorage.getItem("audioUrls");
    if (savedAudioUrls) {
      setAudioUrls(JSON.parse(savedAudioUrls));
    }

    const savedActiveStep = localStorage.getItem("activeStep");
    if (savedActiveStep) {
      setActiveStep(parseInt(savedActiveStep));
    }

    const savedLanguages = localStorage.getItem("languages");
    if (savedLanguages) {
      setLanguages(JSON.parse(savedLanguages));
    }

    const savedSelectedLanguage = localStorage.getItem("selectedLanguage");
    if (savedSelectedLanguage) {
      setSelectedLanguage(JSON.parse(savedSelectedLanguage));
    }

    const savedTargetLanguages = localStorage.getItem("targetLanguages");
    if (savedTargetLanguages) {
      setTargetLanguages(JSON.parse(savedTargetLanguages));
    }
  }, []);

  useEffect(() => {
    console.log("translations:", translations, "audio urls: ", audioUrls);
  }, [translations, audioUrls]);

  const updateTargetLanguages = (updatedLanguages: {
    code: string;
    name: string;
    checked: boolean;
  }[]) => {
    const selectedLanguages = updatedLanguages
      .filter((lang) => lang.checked)
      .map((lang) => lang.code);
    setTargetLanguages(selectedLanguages);
  };

  const handleLanguageChange = (checked: boolean, code: string): void => {
    const updatedLanguages = languages.map((lang) => (lang.code === code ? { ...lang, checked } : lang));
    setLanguages(updatedLanguages);
    updateTargetLanguages(updatedLanguages);
  };

  const handleSaveStandards = () => {
    const words = mandatoryStandardsText.split(",").map((word) => word.trim());
    setMandatoryWords(words);
    setIsModalOpen(false);
  };

  const translateTranscript = async () => {
    const transcriptTranslations: { [key: string]: string } = {};

    // Ensure targetLanguages is not empty
    if (targetLanguages.length === 0) {
      console.error("No target languages selected.");
      return;
    }

    for (const lang of targetLanguages) {
      try {
        const translatedText = await translateText([sourceTranscript], "en", lang);
        if (translatedText && translatedText.length > 0) {
          transcriptTranslations[lang] = translatedText[0];
        } else {
          console.error(`Translation failed for language: ${lang}`);
          transcriptTranslations[lang] = ""; // Default to empty string if translation fails
        }
      } catch (error) {
        console.error(`Error translating transcript for ${lang}:`, error);
        transcriptTranslations[lang] = ""; // Handle translation error gracefully
      }
    }

    // Merge new transcripts with existing translations
    setTranslations((prev) => ({
      ...prev,
      transcript: { ...prev.transcript, ...transcriptTranslations },
    }));
  };


  const translateMandatoryStandards = async () => {
    const mandatoryTranslations: { [key: string]: string[] } = {
      source: mandatoryWords,
    };

    // Ensure targetLanguages is not empty
    if (targetLanguages.length === 0) {
      console.error("No target languages selected.");
      return;
    }

    for (const lang of targetLanguages) {
      try {
        const translatedWords = await translateText(mandatoryWords, "en", lang);
        if (translatedWords && translatedWords.length === mandatoryWords.length) {
          mandatoryTranslations[lang] = translatedWords;
          // Set targetMandatoryWords as an independent copy
          setTargetMandatoryWords((prev) => ({
            ...prev,
            [lang]: [...translatedWords], // Ensure this is a new array
          }));
        } else {
          console.error(`Translation failed for mandatory standards: ${lang}`);
          mandatoryTranslations[lang] = Array(mandatoryWords.length).fill(""); // Ensure array length matches mandatoryWords
        }
      } catch (error) {
        console.error(`Error translating mandatory standards for ${lang}:`, error);
        mandatoryTranslations[lang] = Array(mandatoryWords.length).fill(""); // Handle translation error gracefully
      }
    }

    // Merge new mandatory translations with existing translations
    setTranslations((prev) => ({
      ...prev,
      mandatory: { ...prev.mandatory, ...mandatoryTranslations },
    }));
  };

  const getLanguageSpecificMandatoryWords = (lang: string): { [key: string]: string } => {
    const languageSpecificWords: { [key: string]: string } = {};

    console.log("Target Mandatory Words:", targetMandatoryWords);

    targetMandatoryWords[lang].forEach((word, index) => {
      const translatedWord = translations.mandatory[lang]?.[index];
      if (translatedWord) {
        languageSpecificWords[word] = translatedWord; // Map source word to its translation
      }
    });

    return languageSpecificWords;
  };

  const updateTranscriptsWithMandatoryStandards = () => {
    const updatedTranscripts = { ...translations.transcript };

    // Iterate over all target languages
    for (const lang of targetLanguages) {
      let transcript = updatedTranscripts[lang]; // Current transcript for the language
      if (!transcript) continue;

      // Get language-specific mandatory words
      const languageSpecificWords = getLanguageSpecificMandatoryWords(lang);

      console.log("Language Specific Words:", languageSpecificWords);

      // Replace mandatory words in the transcript
      Object.entries(languageSpecificWords).forEach(([sourceWord, translatedWord]) => {
        // Normalize strings to ensure consistency
        const normalizedSourceWord = sourceWord.normalize("NFC");
        const normalizedTranscript = transcript.normalize("NFC");

        // Debugging logs
        console.log(`Attempting to replace: "${normalizedSourceWord}" with "${translatedWord}"`);
        console.log(`Transcript before replacement: "${normalizedTranscript}"`);

        // Replace the sourceWord with the translatedWord directly
        transcript = normalizedTranscript.split(normalizedSourceWord).join(translatedWord);

        // Debugging logs
        console.log(`Transcript after replacement: "${transcript}"`);
      });



      updatedTranscripts[lang] = transcript; // Save the updated transcript
    }

    console.log("updated Transcripts:", updatedTranscripts);

    // Merge new transcripts with existing translations
    setTranslations((prev) => ({
      ...prev,
      transcript: { ...prev.transcript, ...updatedTranscripts },
    }));
  };



  const nextStep = async () => {
    if (activeStep === 0) {
      if (!sourceTranscript) {
        alert("Please enter a transcript.");
        return;
      }
      if (targetLanguages.length === 0) {
        alert("Please select at least one target language.");
        return;
      }
      try {
        // Wait for translations to complete
        await translateTranscript();

        // Wait for mandatory translations (if any)
        if (mandatoryWords.length > 0) {
          await translateMandatoryStandards();
        }
        console.log("Final Translations State:", translations);

        // Only call updateTranscriptsWithMandatoryStandards after both promises resolve
        // updateTranscriptsWithMandatoryStandards();

        // Move to the next step
        setActiveStep((prev) => prev + 1);
      } catch (error) {
        console.error("Error during translation:", error);
        alert("An error occurred during translation. Please try again.");
      }
    }
    if (activeStep === 1) {
      updateTranscriptsWithMandatoryStandards();

      // Move to the next step
      setActiveStep((prev) => prev + 1);
    }
    if (activeStep === 2) {
      handleFinish();
    }
  };


  const prevStep = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleGenerateAudio = async () => {
    const audioPromises = targetLanguages.map(async (lang) => {
      try {
        const text = translations.transcript[lang];
        const audioUrl = await generateAudio(text, lang);
        return { lang, audioUrl };
      } catch (error) {
        console.error(`Error generating audio for ${lang}:`, error);
        return { lang, audioUrl: "" };
      }
    });

    const audioResults = await Promise.all(audioPromises);
    // Define the type for audioUrls
    const newAudioUrls: { [key: string]: string } = audioResults.reduce(
      (acc: { [key: string]: string }, { lang, audioUrl }) => {
        acc[lang] = audioUrl; // TypeScript now knows 'lang' is a key in this object
        return acc;
      },
      {} // Initialize with an empty object
    );

    setAudioUrls(newAudioUrls);
  };

  // Update the handleFinish function
  const handleFinish = async () => {
    const updatedAudioUrls = { ...audioUrls };

    for (const lang of targetLanguages) {
      try {
        const audioBlob = await fetch(audioUrls[lang]).then((res) => res.blob());
        const s3Url = await uploadToS3(audioBlob, lang);
        updatedAudioUrls[lang] = s3Url;
      } catch (error) {
        console.error(`Error uploading audio for ${lang}:`, error);
      }
    }

    setAudioUrls(updatedAudioUrls);

    // Display success message

    //persist the audioURLS, translations, and activeStep to the local storage

    localStorage.setItem("audioUrls", JSON.stringify(updatedAudioUrls));
    localStorage.setItem("translations", JSON.stringify(translations));
    localStorage.setItem("activeStep", JSON.stringify(activeStep));
    localStorage.setItem("languages", JSON.stringify(languages));
    localStorage.setItem("selectedLanguage", JSON.stringify(selectedLanguage));
    localStorage.setItem("targetLanguages", JSON.stringify(targetLanguages));
  };

  return (
    <div className="p-8 max-w-4xl mx-auto">
      <Stepper active={activeStep}
        onStepClick={setActiveStep}
        size="sm"
      >
        {/* Step 1: Transcript */}
        <Stepper.Step label="Transcript" description="Create AI Audio">
          <div>
            <h2 className="text-xl font-semibold">Step 1: Transcript</h2>
            <textarea
              placeholder="Enter transcript here..."
              value={sourceTranscript}
              onChange={(e) => setSourceTranscript(e.target.value)}
              className="w-full border rounded-md p-2 mt-4"
            />
            <div>
              {/* Mandatory Standards Button */}
              <button
                type="button"
                className="text-blue-500 underline"
                onClick={() => setIsModalOpen(true)}
              >
                Mandatory Standards
              </button>

              {/* Modal */}
              {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                  <div className="bg-white rounded-lg shadow-lg p-6 w-1/2">
                    <h2 className="text-lg font-semibold mb-4">
                      Mandatory Standards
                    </h2>
                    <p className="text-sm mb-2">
                      Enter mandatory standards separated by commas ","
                    </p>
                    <textarea
                      className="w-full border rounded-md p-3 mt-1"
                      rows={4}
                      value={mandatoryStandardsText}
                      onChange={(e) => setMandatoryStandardsText(e.target.value)}
                    />
                    <div className="flex justify-end space-x-4 mt-4">
                      <button
                        type="button"
                        className="px-4 py-2 border rounded"
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="px-4 py-2 bg-blue text-white rounded"
                        onClick={handleSaveStandards}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex items-center space-x-4 mt-2">
                {languages.map((language, idx) => (
                  <label key={idx} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={language.checked}
                      onChange={(e) =>
                        handleLanguageChange(e.target.checked, language.code)
                      }
                      className="form-checkbox"
                    />
                    <span>
                      {
                        {
                          es: "Spanish (es)",
                          fr: "French (fr)",
                          zh: "Chinese (zh)",
                          jp: "Japanese (jp)",
                        }[language.code]
                      }
                    </span>
                  </label>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="ai-disclaimer" className="flex items-center">
                <input
                  id="ai-disclaimer"
                  type="checkbox"
                  className="form-checkbox mr-2"
                />
                <span>
                  I understand that AI-generated content may not always be
                  accurate and should be reviewed and validated before use.
                </span>
              </label>
            </div>
          </div>
        </Stepper.Step>

        {/* Step 2: Map Mandatory Standards */}
        <Stepper.Step label="Map Mandatory Standards" description="Map Standards">
          <div className="p-8 max-w-4xl mx-auto">
            <h1 className="text-xl font-semibold mb-4">Mandatory Standards Table</h1>
            <div className="overflow-x-auto">
              <table className="table-auto border-collapse border border-gray-300 w-full text-left">
                <thead>
                  <tr>
                    <th className="border border-gray-300 p-2 bg-gray-100">
                      English
                    </th>
                    {languages.map((lang) => (
                      <th
                        key={lang.code}
                        className="border border-gray-300 p-2 bg-gray-100"
                      >
                        {lang.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {translations.mandatory.source.map((word, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 p-2">{word}</td>
                      {languages.map((lang) => (
                        <td
                          key={`${lang.code}-${index}`}
                          className="border border-gray-300 p-2"
                        >
                          <input
                            type="text"
                            className="w-full border rounded-md p-2"
                            value={
                              translations.mandatory[lang.code]?.[index] || "" // Use fallback
                            }
                            onChange={(e) => {
                              const updatedMandatoryTranslations = { ...translations.mandatory };
                              if (!updatedMandatoryTranslations[lang.code]) {
                                updatedMandatoryTranslations[lang.code] = Array(
                                  mandatoryWords.length
                                ).fill(""); // Initialize array if undefined
                              }
                              updatedMandatoryTranslations[lang.code][index] = e.target.value;
                              setTranslations({
                                ...translations,
                                mandatory: updatedMandatoryTranslations,
                              });
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Stepper.Step>

        {/* Step 3: Review / Confirm */}
        <Stepper.Step label="Review / Confirm" description="Finalize Audio">
          <div>
            <h2 className="text-xl font-semibold mb-4">Step 3: Review and Confirm</h2>

            {/* Custom Tabs for Languages */}
            <div className="flex space-x-4 border-b pb-2">
              {targetLanguages.map((lang) => (
                <button
                  key={lang}
                  className={`px-4 py-2 border rounded-t ${selectedLanguage === lang
                    ? "bg-blue text-white border-blue"
                    : "bg-gray-200 text-gray-600 border-gray-300"
                    }`}
                  onClick={() => setSelectedLanguage(lang)}
                >
                  {languages.find((l) => l.code === lang)?.name || lang}
                </button>
              ))}
            </div>

            {/* Display Transcripts */}
            <textarea
              value={translations.transcript[selectedLanguage] || ""}
              readOnly
              className="w-full mt-4 border rounded-md p-2"
              rows={10}
            />

            {/* Generate Audio Button */}
            <button
              className="mt-4 px-4 py-2 bg-blue text-white rounded"
              onClick={handleGenerateAudio}
            >
              Generate Audio
            </button>

            {/* Audio Player */}
            <div className="mt-4">
              {audioUrls[selectedLanguage] && (
                <div>
                  <audio
                    key={selectedLanguage}
                    controls
                  >
                    <source src={audioUrls[selectedLanguage]} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <p className="text-sm mt-2">
                    {audioUrls[selectedLanguage]}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Stepper.Step>
      </Stepper>

      {/* Navigation Buttons */}
      <div className="mt-8 flex justify-between">
        <button
          className="px-4 py-2 bg-gray-300 rounded"
          disabled={activeStep === 0}
          onClick={prevStep}
        >
          Back
        </button>
        <button
          className="px-4 py-2 bg-blue text-white rounded"
          onClick={nextStep}
        >
          {activeStep === 2 ? "Finish" : "Next"}
        </button>
      </div>
    </div >
  );
};

export default CreateAudioUI;
