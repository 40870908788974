"use client";

import * as React from "react";
import {
  Check,
  ChevronDown,
  ChevronRight,
  ChevronsUpDown,
  File,
  PanelTop,
} from "lucide-react";

import { cn } from "src/components/lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Combobox/command";
import { Popover, PopoverContent, PopoverTrigger } from "./Combobox/popover";
import { uuid } from "src/helpers";
import { FileSystemNode } from "../Course/Navigation";
import { HStack } from "@chakra-ui/react";
// @ts-ignore
import RemoveLogo from "src/assets/remove1.png";

// const frameworks = [
//   {
//     value: "next.js",
//     label: "Next.js",
//   },
//   {
//     value: "sveltekit",
//     label: "SvelteKit",
//   },
//   {
//     value: "nuxt.js",
//     label: "Nuxt.js",
//   },
//   {
//     value: "remix",
//     label: "Remix",
//   },
//   {
//     value: "astro",
//     label: "Astro",
//   },
// ]

type TOCItem = {
  _id: string;
  title: string;
  template: any;
  children?: TOCItem[];
};

type Props = {
  data?: any;
  navData?: any;
  type: string;
  menuItem?: TOCItem;
  selectedMenu?: any;
  selectedPlayerMenu?: any;
  handleTopicChange: (topic: any) => void;
  handleMenuChange?: (data: any) => void;
};

export function ComboboxDemo({
  data,
  navData,
  type,
  menuItem,
  selectedMenu,
  selectedPlayerMenu,
  handleTopicChange,
  handleMenuChange,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState("");
  const [menuId, setMenuId] = React.useState("");
  const [filesData, setFilesData] = React.useState<any>();
  const [menusdata, setMenusData] = React.useState<any>();

  const [expandedNodes, setExpandedNodes] = React.useState<Set<string>>(
    new Set()
  );

  React.useEffect(() => {
    console.log(id, type);
  }, [id, menuId]);

  React.useEffect(() => {
    console.log("menusdata", menusdata);
  }, [menusdata]);

  React.useEffect(() => {
    if(type === "Template") {
      if (menuItem?.template?.type === "file") {
        setId(menuItem.template.id);
      } else {
        setId(""); 
      }
    }
    if (type === "PlayerSettingMenu") {
      const menuId = data?.find((menu: any) => menu?._id === selectedPlayerMenu?._id)?._id;
      if (menuId) {
        setId(menuId); 
      } else {
        setId(""); 
      }
    }
    if (type === "Menus") {
      if (selectedMenu) {
        console.log(selectedMenu._id, type);
        setMenuId(selectedMenu._id); // Initialize the selected ID
      } else {
        setMenuId(""); // Reset if no valid template is provided
      }
    }
  }, [menuItem, selectedMenu, selectedPlayerMenu]);

  React.useEffect(() => {
    if (type === "PlayerSettings") {
      const topics = getTopics(data);
      setFilesData(topics);
    } else if (type === "Menus") {
      setMenusData(data || []);
    }
  }, [data, type]);

  function getTopics(data: any) {
    let topics: any[] = [];
    data?.forEach((item: any) => {
      if (item?.type === "file") {
        topics.push(item);
      } else if (item?.children) {
        topics = [...topics, ...getTopics(item?.children)];
      }
    });
    return topics;
  }

  const toggleNodeExpand = (id: string) => {
    const newSet = new Set(expandedNodes);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedNodes(newSet);
  };

  const renderStructure = (nodes: FileSystemNode[], depth: number = 0): JSX.Element => (
    <>
      {nodes?.map((node) => (
        <React.Fragment key={node.id}>
          <CommandItem
            onSelect={() => {
              console.log(node);
              if (node.type === "file") {
                setId(node.id); // Set the selected template ID
                handleTopicChange(node); // Trigger topic change
                setOpen(false); // Close the dropdown
              } else {
                toggleNodeExpand(node.id); // Toggle folder expand/collapse
              }
            }}
          >
            <div
              className={`relative w-full flex items-center space-x-2 ${
                depth === 0
                  ? "pl-0"
                  : depth === 1
                  ? "pl-[16px]"
                  : depth === 2
                  ? "pl-[32px]"
                  : "pl-[48px]"
              }`}
            >
              {node.type === "folder" ? (
                <span>
                  {expandedNodes.has(node.id) ? (
                    <ChevronDown size={16} />
                  ) : (
                    <ChevronRight size={16} />
                  )}
                </span>
              ) : (
                <span>
                  {node.type === "tabsFolder" ? (
                      <PanelTop size={16} />
                    ) : (
                      <div>
                        <File color="#333333" size={16} />
                      </div>
                    )}
                </span>
              )}
  
              <span className="text-sm">{node.name}</span>
  
              {node.type === "file" && (
                <Check
                  className={cn(
                    "ml-auto h-4 w-4",
                    id === node.id ? "opacity-100" : "opacity-0"
                  )}
                />
              )}
            </div>
          </CommandItem>
          {/* Recursively render children if the folder is expanded */}
          {expandedNodes.has(node.id) && node.children && (
            <div>
              {renderStructure(node.children, depth + 1)}
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );

  function findNodeById(
    nodes: FileSystemNode[],
    id: string
  ): FileSystemNode | undefined {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[235px] justify-between"
          size="md"
        >
          {type === "PlayerSettings" && (
            <span className="text-sm fond-normal line-clamp-1">
              {id
                ? filesData?.find((topic: any) => topic?.id === id)?.name
                : "Select template"}
            </span>
          )}
          {type === "Menus" && (
            <span className="text-sm fond-normal line-clamp-1">
              {menuId
                ? menusdata?.find((menu: any) => menu?._id === menuId)?.title
                : "Select a menu"}
            </span>
          )}
          {type === "Template" && (
            <span className="text-sm fond-normal line-clamp-1">
              {id
                ? findNodeById(navData, id)?.name || "None"
                : menuItem
                ? menuItem?.template?.name || "None"
                : "None"}
            </span>
          )}
          {type === "PlayerSettingMenu" && (
            <span className="text-sm fond-normal line-clamp-1">
              {id
                ? data?.find((menu: any) => menu?._id === id)?.title
                : "Select a menu"}
            </span>
          )}
          <span className="">
            <ChevronsUpDown className="h-4 w-4 opacity-50" />
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandList>
            <CommandEmpty>No template found.</CommandEmpty>
            <CommandGroup>
              {type === "PlayerSettings" &&
                filesData?.map((topic: any) => (
                  <CommandItem
                    key={topic?.id}
                    onSelect={() => {
                      console.log(topic?.id, id);
                      setId(topic?.id === id ? "" : topic?.id);
                      setOpen(false);
                      if (topic?.type === "file") {
                        handleTopicChange(topic);
                      }
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        id === topic?.id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {topic?.name}
                  </CommandItem>
                ))}
              {type === "Menus" && (
                <CommandItem
                  key="create-menu"
                  onSelect={() => {
                    const newMenu = {
                      _id: uuid(),
                      title: "New Menu",
                      items: [],
                    };
                    setMenusData((prevMenus: any) => {
                      const updatedMenus = [...prevMenus, newMenu];

                      // Send the updated data back to the parent component
                      if (handleMenuChange) handleMenuChange(updatedMenus);

                      return updatedMenus;
                    });
                    handleTopicChange(newMenu);
                    setMenuId(newMenu._id);
                    setOpen(false);
                  }}
                >
                  <span className="font-medium text-blue-500">
                    + Create New Menu
                  </span>
                </CommandItem>
              )}
              {type === "Menus" &&
                menusdata?.map((menu: any) => (
                  <CommandItem
                    key={menu?._id}
                    onSelect={() => {
                      setMenuId(menu?._id === menuId ? "" : menu?._id);
                      // setOpen(false);
                      handleTopicChange(menu);
                    }}
                  >
                    <HStack
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <HStack alignItems="center" gap="0.5rem">
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            menuId === menu?._id ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {menu?.title}
                      </HStack>
                      <HStack>
                        <Button
                          variant="ghost"
                          onClick={() => {
                            const updatedMenus = menusdata.filter(
                              (m: any) => m._id !== menu._id
                            );
                            setMenusData(updatedMenus);
                            
                            // If no menus are left, clear the selection
                            setMenuId("");
                            handleTopicChange(null);
                            if (handleMenuChange)
                              handleMenuChange(updatedMenus);
                          }}
                        >
                          <img
                            className="w-[0.9rem] h-[0.9rem]"
                            src={RemoveLogo}
                            alt="remove"
                          />
                        </Button>
                      </HStack>
                    </HStack>
                  </CommandItem>
                ))}
              {type === "Template" && (
                <>
                  {/* Add the "None" option */}
                  <CommandItem
                    key="none-option"
                    onSelect={() => {
                      setId("");
                      setOpen(false);
                      handleTopicChange("None"); // Clear the selection
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        id === "" ? "opacity-100" : "opacity-0"
                      )}
                    />
                    None
                  </CommandItem>

                  {renderStructure(navData, 0)}
                </>
              )}
              {type === "PlayerSettingMenu" &&
                data?.map((menu: any) => (
                  <CommandItem
                    key={menu?._id}
                    onSelect={() => {
                      setId(menu?._id === id ? "" : menu?._id);
                      setOpen(false);
                      handleTopicChange(menu);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        id === menu?._id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {menu?.title}
                  </CommandItem>
                ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
