import React, { useEffect, useState } from "react";
import Nav from "../Navbar";
import Navigation from "./Navigation";
import ProgramNav from "src/utils/ProgramNav";
import PreviewLogo from "../../assets/TemplateNavbar/Play.svg";
import MediaLogo from "../../assets/TemplateNavbar/Image.svg";
import SettingLogo from "../../assets/TemplateNavbar/GearSix.svg";
import ShareLogo from "../../assets/TemplateNavbar/Share.svg";
import ExportLogo from "../../assets/TemplateNavbar/UploadSimple.svg";
import InsertLogo from "../../assets/Course/insert.png";
import { useParams, useSearchParams } from "react-router-dom";
import PreviewModal from "src/utils/PreviewModal";
import { useSelector } from "react-redux";
import { Device } from "../Preview/Layout";
import Navbar from "../Preview/Navbar";
import { cn } from "./../lib/utils";
import { cva } from "class-variance-authority";
import Preview from "../Preview";
import SettingsModal from "src/utils/SettingsModal";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";
import { SketchPicker } from "react-color";
import axios from "axios";
import Tools from "./Tools";
import Settings from "./Settings";
import { LayoutTemplate } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui";
import CreateAudioUI from "./Ai/Audio";
import CreateVideoUI from "./Ai/Video";
import TranscribeVideo from "./Ai/Video/TranscribeVideo";
import { Template } from "./TemplateRibbon";
import { setSelectedTemplate } from "src/features/courseTemplate/courseTemplateSlice";
import { DefaultTemplateData } from "./Templates/Authoring/utils/DefaultTemplateData";
import { useDispatch } from "react-redux";

type Props = {
  children: JSX.Element;
  handleTopic: (topic: any) => void;
  topic: any;
  handleOpenTemplate?: () => void;
  handleTabTemplates?: (tabTemplate: any) => void;
};

type ButtonType = {
  name: string;
  buttonIcon: React.ReactNode;
  navigate: boolean;
  navigateUrl?: string;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
});

function Layout({ children, handleTopic, topic, handleOpenTemplate, handleTabTemplates }: Props) {
  const [course, setCourse] = useState<any>();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [currentColor, setCurrentColor] = useState<any>("#AB0273");
  const [headingFont, setHeadingFont] = useState<any>("");
  const [bodyFont, setBodyFont] = useState<any>("");
  const [logoFile, setLogoFile] = useState<any>(null);
  const [openColorPalette, setOpenColorPalette] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [deviceVariantState, setDeviceVariantState] =
    useState<Device>("default");
  const { businessUnitId, courseId, templateId, userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [audioModal, setAudioModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tabTemplate, setTabTemplate] = useState<any>();

  const dispatch = useDispatch();

  const programButtonsArr: ButtonType[] = [
    {
      name: "Audio",
      buttonIcon: <LayoutTemplate color='#AB0273' size={24} />,
      navigate: false,
      // navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/course/${courseId}/audio`,
    },
    {
      name: "Video",
      buttonIcon: <LayoutTemplate color='#AB0273' size={24} />,
      navigate: false,
      // navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/course/${courseId}/audio`,
    },
    {
      name: "Insert",
      buttonIcon: <LayoutTemplate color='#AB0273' size={24} />,
      navigate: false,
    },
    {
      name: "Media Library",
      buttonIcon: <img src={MediaLogo} alt="Midea Library" />,
      navigate: true,
      navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/course/${courseId}/media`,
    },
    {
      name: "Share",
      buttonIcon: <img src={ShareLogo} alt="Share" />,
      navigate: true,
      navigateUrl: ``,
    },
    {
      name: "Preview",
      buttonIcon: <img src={PreviewLogo} alt="Publish" />,
      navigate: true,
      navigateUrl: `/user/${userId}/course/${courseId}/preview`,
    },
    {
      name: "Publish",
      buttonIcon: <img src={ExportLogo} alt="Publish" />,
      navigate: false,
    },
    {
      name: "Settings",
      buttonIcon: <img src={SettingLogo} alt="Settings" />,
      navigate: false,
      // navigateUrl: `/user/${userId}/course/${courseId}/settings`,
    },
  ];

  useEffect(() => {
    console.log(topic);
  }, [topic])

  const handleDialogCloseRequest = (open: boolean) => {
    if (!open) {
      // User is attempting to close the dialog
      setShowConfirmation(true);
    } else {
      setVideoModal(open);
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmation(false); // Close the confirmation dialog
    setVideoModal(false); // Close the main dialog
  };

  const handleCancelClose = () => {
    setShowConfirmation(false); // Dismiss the confirmation dialog
  };

  useEffect(() => {
    console.log(tabTemplate);
  }, [tabTemplate]);

  function handleTemplateSelection(template: Template) {
    dispatch(setSelectedTemplate({
      id: template.id,
      title: template.title,
      type: template.type,
      description: template.description,
      data: template.data || DefaultTemplateData.get(template.type)
    }))
  }

  return (
    <main className="relative w-screen h-screen mx-auto overflow-hidden font-poppins">
      <Nav type="Course" />
      <ProgramNav type="Course" buttonsArray={programButtonsArr} handleAudioModal={(open) => { setAudioModal(open) }} handleVideoModal={(open) => { setVideoModal(open) }} handleCourse={(course) => setCourse(course)} handleOpenTemplate={handleOpenTemplate} />
      <section className="relative w-full h-[calc(100%-10rem)] flex bg-[#f6f6f6]">

        <Navigation
          topic={topic}
          tabTemplate={tabTemplate}
          handleTopic={(topic) => {
            console.log(topic);
            handleTopic(topic)
          }}
          handleTabTemplate={() => {
            handleTemplateSelection(tabTemplate);
            if(handleOpenTemplate) handleOpenTemplate();
            if(handleTabTemplates) handleTabTemplates(tabTemplate);
            setTabTemplate(null);
          }}
        />

        <div className="flex grow">
          {children}
        </div>

        {/* {props.type === "courses" && <RightPanel />} */}
        <Settings
          templateData={topic?.template ?? []}
          topic={topic}
          tools={topic?.tools?.data}
          handleChange={(templateData) => {
            console.log('templateData', templateData)
            handleTopic({
              ...topic,
              template: templateData,
            })
          }}
          handleToolDataChange={(toolsData) => {
            console.log(toolsData)
            handleTopic({
              ...topic,
              tools: {
                ...topic.tools,
                data: toolsData.tools.data
              }
            })
          }}
          handleTabTemplate={(template: any) => {
            console.log('template', template);
            setTabTemplate(template);
          }}
        />
      </section>

      <Dialog open={audioModal} onOpenChange={(open) => setAudioModal(open)}>
        <DialogContent className="max-w-[800px]">
          <DialogHeader>
            <DialogTitle>AI Audio Generation</DialogTitle>
            {/* <DialogDescription>
              Make changes to your profile here. Click save when you're done.
            </DialogDescription> */}
          </DialogHeader>
          <CreateAudioUI />
        </DialogContent>
      </Dialog>

      <Dialog open={videoModal} onOpenChange={handleDialogCloseRequest}>
        <DialogContent className="max-w-[1000px]">
          <DialogHeader>
            <DialogTitle>AI Video Generation</DialogTitle>
            {/* <DialogDescription>
              Make changes to your profile here. Click save when you're done.
            </DialogDescription> */}
          </DialogHeader>
          <CreateVideoUI handleClose={() => setVideoModal(false)}/>
          {/* <TranscribeVideo /> */}
        </DialogContent>
      </Dialog>

      {showConfirmation && (
        <Dialog open={showConfirmation} onOpenChange={setShowConfirmation}>
          <DialogContent className="max-w-[400px]">
            <DialogHeader>
              <DialogTitle>Confirm Close</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <p>Are you sure you want to close this dialog?</p>
              <div className="flex justify-end space-x-2">
                <button
                  className="px-4 py-2 bg-gray-200 rounded"
                  onClick={handleCancelClose}
                >
                  Continue
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded"
                  onClick={handleConfirmClose}
                >
                  Close
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}

    </main>
  );
}

export default Layout;
