import React, { useEffect } from 'react'
import Layout from './common/Layout';
import TextWithImageLayout from './layouts/textwithimage';
import ClickToRevealLayout from './layouts/ClickToReveal';
import AccordionLayout from './layouts/Accordion';
import { useSelector } from 'react-redux';
import { useLMS } from 'src/context/Lms';
import { useDispatch } from 'react-redux';
import { setLmsLearnerData } from 'src/features/lmsLearnerData/lmsLearnerDataSlice';
import TabsLayout from './layouts/Tabs';

type Props = {
  type?: string;
  quizSettings?: any;
  topic: any;
  topics?: any[];
  handleDataChange: (data: any) => void;
  data: any;
  openTemplate: any;
  handleOpenAuthoringModal?: (state: boolean) => void;
  handleCancelTemplateForm?: () => void;
  nestedTemplate?: boolean;
}

function Tabs({ type, topic, topics, quizSettings, handleDataChange, data, openTemplate, handleOpenAuthoringModal, handleCancelTemplateForm, nestedTemplate }: Props) {

  const templateType = 'tabs';

  const { learnerData } = useSelector((state: any) => state.lmsLearnerData)

  const { isInitialized, setSuspendData, getSuspendData, setScore, setSuccessStatus } = useLMS();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("learnerData line: 34", learnerData);
    if (!isInitialized) return;
    console.log("index line : 105", learnerData);
    setSuspendData(JSON.stringify(learnerData));
  }, [learnerData])

  useEffect(() => {
    console.log(topic)
  }, [])


  useEffect(() => {

    console.log("line 123", learnerData);

    if (!type) return

    const findTemplate = learnerData.templates.find((template: any) => template.data.id === topic.template.id);

    if (findTemplate) {
      dispatch(setLmsLearnerData(
        {
          templates: learnerData.templates.map((template: any) => {
            if (template.data.id === topic.template.id) {
              return { ...template, data: { ...template.data, completed: true } }
            }
            return template;
          }),
          score: learnerData.score,
        }
      ))
    } else {
      dispatch(setLmsLearnerData(
        {
          templates: [...learnerData.templates, { id: topic.template.id, title: topic.template.title, type: topic.template.type, data: { visited: true, completed: true } }],
          score: learnerData.score,
        }
      ))
    }

  }, [])

  return (
    <>
      {!type ? (
        <>
          <TabsLayout data={data} handleDataChange={(data) => handleDataChange(data)} />
        </>
      ) : (
        <>
          <TabsLayout data={data} preview={true} />
        </>
      )
      }
    </>
  )
}

export default Tabs